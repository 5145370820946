




import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModalComponent from "./modals/NewErrorModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";

@Component({
  components: { SuccessModal, ErrorModal, ConfirmModal }
})
export default class PointOfSaleSummary extends Vue {
  $refs!: {
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
  };
}
