var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumb',{attrs:{"menu":[
      { title: _vm.$t('erp.sales.title'), routeName: 'erp.sales.invoices' },
      {
        title: _vm.saleName,
        routeName: 'erp.sales.sale',
        params: { type: _vm.type, folio: _vm.folio }
      }
    ]}}),(_vm.type && _vm.folio)?_c('div',{staticClass:"max-w-2xl mx-auto"},[_c('div',{staticClass:"bg-white rounded-sm shadow-md border pb-16"},[_c('sale-details',{attrs:{"type":_vm.type,"folio":_vm.folio},on:{"deleted":_vm.deleted}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }