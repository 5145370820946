





























































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Selector extends Vue {
  @Prop({ required: true }) options: any;
  @Prop() selected: any;
  @Prop() translatePath: any;
  private dropdownVisible = false;
  private droppedDown = false;
  private chosen = this.selected;
  toggle() {
    this.dropdownVisible = !this.dropdownVisible;
  }
  setValue(option) {
    this.closeDropdown();
    this.chosen = option;
    this.toggle();
    this.$emit("selected", option);
  }
  closeDropdown() {
    this.droppedDown = false;
  }
  translate(value) {
    if (!this.translatePath || this.translatePath === "") {
      return value;
    }
    if (value) {
      const translated = this.$t(this.translatePath + "." + value);
      if (translated) {
        return translated;
      } else {
        return value;
      }
    }
    return value;
  }
}
