export const b2bRoutes = [
  {
    name: "billing",
    path: "/facturacion",
    alias: "/billing",
    component: require("@/views/b2b/billing/Index.vue").default,
  },
  {
    name: "billing.invoice",
    path: "/facturacion/:workspaceId/:taxId/:folio",
    alias: "/billing",
    component: require("@/views/b2b/billing/SaleFullScreenDetails.vue").default,
    // name: "b2b",
    // path: "/b2b",
    // component: require("@/views/b2b/Index.vue").default,
    // redirect: "/b2b/clientes",
    // children: [
    //   {
    //     name: "b2b.customers",
    //     path: "clientes",
    //     alias: "customers",
    //     component: require("@/views/b2b/customers/Index.vue").default,
    //     redirect: "/b2b/clientes/facturas",
    //     children: [
    //       {
    //         name: "b2b.customers.invoices",
    //         path: "facturas",
    //         alias: "invoices",
    //         component: require("@/views/b2b/customers/CustomerInvoices.vue").default,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    name: "store",
    path: "/tienda",
    alias: "/store",
    component: require("@/views/b2b/store/Index.vue").default,
    children: [
      {
        name: "store",
        path: "/tienda",
        alias: "/store",
        component: require("@/views/b2b/store/Stores.vue").default,
      },
    ],
  },
];
