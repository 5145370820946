

























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class BadgeExpense extends Vue {
  @Prop({ default: "h-3" }) height!: string;
  @Prop({ default: "w-20 px-2 leading-5" }) width!: string;
  @Prop({ default: true }) withText!: boolean;
  @Prop({ default: "" }) text!: string;
}
