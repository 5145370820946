



























import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import NewModal from "@/components/shared/modals/NewModal.vue";
import { Prop } from "vue-property-decorator";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import { ProjectDto } from "../../../application/dtos/app/projects/ProjectDto";
import services from "@/services";

@Component({
  components: {
    NewModal
  }
})
export default class ProjectMembers extends BaseComponent {
  @Prop({ default: "", type: String }) proyectoId;
  private users: TenantUserDto[] = [];
  private proyecto = {} as ProjectDto;
  mounted() {
    services.projects.get(this.proyectoId).then((response: ProjectDto) => {
      this.proyecto = response;
    });
    services.tenantUsers.getAll().then((response: TenantUserDto[]) => {
      this.users = response;
    });
  }
}
