






















import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import EcommerceService from "@/services/api/ecommerce/EcommerceService";

@Component({
  components: {}
})
export default class EcommerceLayout extends Vue {
  private loading: boolean = false;
  private stores: any[] = [];
  mounted() {
    this.loading = true;
    EcommerceService.stores
      .getAllStores()
      .then((response: any[]) => {
        this.stores = response;
        this.$router.push({
          name: "ecommerce.store",
          params: { store: this.stores[0] }
        });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
