
































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { TransactionDto } from "@/application/dtos/app/transactions/TransactionDto";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { mapGetters } from "vuex";
import { CfdiDto } from "@/application/dtos/app/cfdis/CfdiDto";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import DropdownSimple from "@/components/shared/dropdowns/DropdownSimple.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import SideModal from "../../../components/shared/modals/SideModal.vue";
import * as projectHelpers from "@/application/modules/projects/ProjectHelpers";
import { TransactionType } from "@/application/enums/app/transactions/TransactionType";
import services from "@/services";

@Component({
  components: {
    TableRowSkeleton,
    NewErrorModal,
    DropdownSimple,
    SideModal
  },
  computed: {
    ...mapGetters("account", {
      isAdmin: "isAdmin"
    })
  }
})
export default class TransactionItemDetails extends BaseComponent {
  $refs!: {
    errorModal: NewErrorModal;
    confirmEliminar: ConfirmModalComponent;
    dropdownOptions: DropdownSimple;
  };
  // @Prop() id!: string;
  private transaction = {} as TransactionDto;
  private showDetails: boolean = false;
  private showMoreTransactions: boolean = false;
  private showTransactions: number = 8;
  private imageFullSize: boolean = false;
  mounted() {
    // if (!this.id && this.$route.params.id) {
    //   this.id = this.$route.params.id;
    // }
    this.reload();
  }
  reload() {
    this.loading = true;
    // console.log("loading ticket-item-detailes: " + this.id);
    services.transactions
      .get(this.$route.params.id)
      .then((response: TransactionDto) => {
        // console.log(JSON.stringify(response));
        this.transaction = response;
        // console.log(JSON.stringify(response))
      })
      .catch(error => {
        // ignore
        this.$refs.errorModal.show(this.$t(error));
        // this.close();
      })
      .finally(() => {
        this.loading = false;
      });
  }
  close() {
    this.$router.back();
    this.$emit("closed", 1, this.$route.params.id);
  }
  createdByUserName() {
    if (this.transaction.createdByUser) {
      return (
        this.transaction.createdByUser.firstName ??
        this.transaction.createdByUser.email
      );
    } else {
      return "?";
    }
  }
  avatarText() {
    if (this.transaction && this.transaction.createdByUser) {
      if (
        this.transaction.createdByUser?.firstName &&
        this.transaction.createdByUser?.lastName
      ) {
        return (
          this.transaction.createdByUser?.firstName[0] +
          this.transaction.createdByUser?.lastName[0]
        );
      } else if (this.transaction.createdByUser?.firstName) {
        return this.transaction.createdByUser?.firstName[0];
      }
    } else {
      return "?";
    }
  }
  hasFile(type: any): boolean {
    if (this.transaction) {
      if (this.transaction?.cfdi) {
        if (type === "xml") {
          return this.transaction?.cfdi.tieneXml;
        } else if (type === "pdf") {
          return this.transaction?.cfdi.tienePdf;
        }
      }
    }
    return false;
  }
  downloadCfdi(type: string) {
    if (!this.transaction?.cfdi || !this.transaction?.cfdi?.id) {
      this.$refs.errorModal.show("No tiene CFDi");
    } else {
      const cfdiId = this.transaction.cfdi.id;
      if (cfdiId) {
        services.cfdis
          .download(this.transaction.cfdi.id, type)
          .then((response: any) => {
            const contentType: string = response.headers["content-type"];

            const fileUrl = window.URL.createObjectURL(
              new Blob([response.data], { type: contentType })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", cfdiId);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch(error => {
            this.$refs.errorModal.show(this.$t(error));
          });
      }
    }
  }
  eliminar() {
    this.$refs.dropdownOptions.close();
    if (this.transaction.ticket) {
      this.$refs.confirmEliminar.show(
        `El ticket #${this.transaction.ticket.number} ya fue facturado, ¿eliminar de todas formas?`
      );
      // this.$refs.errorModal.show("No puedes eliminar un ticket ya facturado");
    } else {
      this.$refs.confirmEliminar.show(`¿Eliminar transacción?`);
    }
  }
  confirmarEliminar() {
    services.transactions
      .delete(this.transaction.id)
      .then((response: any) => {
        this.$emit("deleted");
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      });
  }
  projectColor() {
    return projectHelpers.getProjectColor(this.transaction?.project?.color);
  }
  get cfdi() {
    return this.transaction?.cfdi;
  }
  get transactionType() {
    return this.transaction.type;
  }
  get tipo() {
    return TransactionType[this.transaction.type];
  }
}
