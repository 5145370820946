export default [
  { key: "P01", name: "P01 - Por definir" },
  { key: "G01", name: "G01 - Adquisición de mercancias" },
  { key: "G02", name: "G02 - Devoluciones, descuentos o bonificaciones" },
  { key: "G03", name: "G03 - Gastos en general" },
  { key: "I01", name: "I01 - Construcciones" },
  { key: "I02", name: "I02 - Mobilario y equipo de oficina por inversiones" },
  { key: "I03", name: "I03 - Equipo de transporte" },
  { key: "I04", name: "I04 - Equipo de computo y accesorios" },
  {
    key: "I05",
    name: "I05 - Dados, troqueles, moldes, matrices y herramental",
  },
  { key: "I06", name: "I06 - Comunicaciones telefónicas" },
  { key: "I07", name: "I07 - Comunicaciones satelitales" },
  { key: "I08", name: "I08 - Otra maquinaria y equipo" },
  {
    key: "D01",
    name: "D01 - Honorarios médicos, dentales y gastos hospitalarios",
  },
  { key: "D02", name: "D02 - Gastos médicos por incapacidad o discapacidad" },
  { key: "D03", name: "D03 - Gastos funerales" },
  { key: "D04", name: "D04 - Donativos" },
  { key: "D05", name: "D05 - Intereses reales efectivamente..." },
  { key: "D06", name: "D06 - Aportaciones voluntarias al SAR" },
  { key: "D07", name: "D07 - Primas por seguros de gastos médicos" },
  { key: "D08", name: "D08 - Gastos de transportación escolar obligatoria" },
  { key: "D09", name: "Depósitos en cuentas para el ahorro..." },
  { key: "D10", name: "Pagos por servicios educativos (colegiaturas)" },
];
