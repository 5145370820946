

























































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class SuccessModalComponent extends Vue {
  $refs!: {
    buttonClose: HTMLButtonElement;
  };
  private title!: string;
  private closeText!: any;
  private showing: boolean = false;
  private successDescription!: string;
  private subtitle!: string;
  private warnings: string[] = [];
  mounted() {
    this.title = this.$t("shared.success").toString();
    this.closeText = this.$t("shared.close").toString();
  }
  public show(
    description: any,
    subtitle?: any,
    warnings?: string[],
    closeText?: string
  ) {
    this.successDescription = description;
    this.subtitle = subtitle;
    this.showing = true;
    if (warnings) {
      this.warnings = warnings;
    }
    if (closeText) {
      this.closeText = closeText;
    }
    window.addEventListener("keyup", this.keyEvent);
    this.$nextTick(() => {
      this.$refs.buttonClose.focus();
    });
  }
  public close() {
    window.removeEventListener("keyup", this.keyEvent);
    this.$emit("closed");
    this.showing = false;
  }
  keyEvent(event) {
    if (event.ctrlKey && event.keyCode) {
      if (event.keyCode === 13) {
        this.close();
      }
    }
  }
}
