import { Module } from "vuex";
import { LayoutState, RootState } from "@/types/stateTypes";

export const state: LayoutState = {
  sidebarOpen: false,
  posTableCollapsed: false,
};

export const layout: Module<LayoutState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    reset(state) {
      state.sidebarOpen = false;
      state.posTableCollapsed = true;
    },
    toggleSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    sidebarOpen(state, payload: boolean) {
      state.sidebarOpen = payload;
    },
    togglePosTableCollapsed(state) {
      state.posTableCollapsed = !state.posTableCollapsed;
    },
    posTableCollapsed(state, payload: boolean) {
      state.posTableCollapsed = payload;
    },
  },
};
