export enum SummaryCountTotalType {
  WORKSPACE,
  SUPPLIER,
  CUSTOMER,
  CATEGORY,
  YEAR,
  MONTH,
  WEEK,
  USER,
  PROJECT,
}
