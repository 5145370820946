







































































































































import Vue from "vue";
import Component from "vue-class-component";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import { CustomerTaxIdDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerTaxIdDto";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { PaymentDto } from "@/application/dtos/app/erp/v3/contacts/customers/PaymentDto";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { PaySaleRequest } from "@/application/contracts/app/erp/sales/PaySaleRequest";
import { PaymentForm } from "@/application/enums/app/erp/contacts/customers/PaymentForm";
import { BalanceDueDto } from "@/application/dtos/app/erp/v3/contacts/customers/BalanceDueDto";
import { filters } from "@/plugins/filters";
import ButtonGroup from "@/components/shared/buttons/ButtonGroup.vue";

@Component({
  components: {
    NewModalComponent,
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    ButtonGroup
  }
})
export default class PaySaleForm extends Vue {
  $refs!: {
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
    confirmModal: ConfirmModalComponent;
    confirmPayModal: ConfirmModalComponent;
    nameInput: HTMLInputElement;
    taxIdInput: HTMLInputElement;
    amountInput: HTMLInputElement;
  };
  private balance: BalanceDueDto | null = null;
  private item: PaySaleRequest = {
    saleType: "",
    saleFolio: 0,
    toBankId: 0,
    reference: "",
    paymentForm: PaymentForm.Cash,
    amount: 0
  };
  private loading: boolean = false;
  private showTaxInfo: boolean = false;
  private paymentForm: string = "01";
  // clear() {
  //   this.item = null;
  // }
  add(saleType: string, saleFolio: number) {
    // this.$nextTick(() => {
    //   this.$refs.nameInput.focus();
    //   this.$refs.nameInput.select();
    // });
    this.loading = true;
    ErpService.sales.sales
      .getBalance(saleType, saleFolio)
      .then((response: BalanceDueDto) => {
        this.balance = response;
        this.item = {
          saleType,
          saleFolio,
          toBankId: 0,
          reference: "",
          paymentForm: PaymentForm.Cash,
          amount: response.due
        };
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.amountInput.focus();
          this.$refs.amountInput.select();
        });
      })
      .catch(error => {
        this.loading = false;
        this.$refs.errorModal.show(this.$t(error));
      });
    // this.$refs.modal.show();
  }
  // update(id: number) {
  //   this.loading = true;
  //   ErpService.sales.sales.pay
  //     .get(id.toString())
  //     .then((response: CustomerDto) => {
  //       this.item = response;
  //       const taxIds = response.taxIds.filter(f => f.customerId !== 777);
  //       if (taxIds && taxIds.length > 0) {
  //         this.taxId = taxIds[0];
  //       } else {
  //         this.taxId = {
  //           customerId: 0,
  //           taxId: "XAXX010101000",
  //           name: "VENTAS DE MOSTRADOR",
  //           phone: "",
  //           postalCode: "",
  //           country: "MEX",
  //           email1: "",
  //           email2: "",
  //           paymentsEmail: ""
  //         };
  //       }
  //       this.$nextTick(() => {
  //         this.$refs.nameInput.focus();
  //         this.$refs.nameInput.select();
  //       });
  //     })
  //     .catch(error => {
  //       this.$refs.errorModal.show(this.$t(error));
  //     })
  //     .finally(() => {
  //       this.loading = false;
  //     });
  // }
  confirmPay() {
    if (!this.item) {
      return;
    }
    this.loading = true;
    ErpService.sales.sales
      .pay(this.item)
      .then((response: BalanceDueDto) => {
        tinyEventBus().emitter.emit("payment-added", response);
        this.$emit("created", response);
        // this.$refs.successModal.show(
        //   this.$t("Pago registrado"),
        //   "Pago registrado, saldo: " + filters.moneyFormat(response.due)
        // );
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  save() {
    if (!this.balance || !this.item) {
      this.$refs.errorModal.show(this.$t("Documento inválido"));
      return;
    }
    if (this.item.amount <= 0) {
      this.$refs.errorModal.show(this.$t("Importe no puede ser menor a cero"));
      return;
    }

    if (this.paymentForm === "01") {
      this.item.paymentForm = PaymentForm.Cash;
    } else if (this.paymentForm === "02") {
      this.item.paymentForm = PaymentForm.Check;
    } else if (this.paymentForm === "03") {
      this.item.paymentForm = PaymentForm.Transfer;
    } else {
      this.$refs.errorModal.show(this.$t("Selecciona una forma de pago"));
      return;
    }

    this.$refs.confirmPayModal.show(
      `Pagar ${this.balance?.saleType}-${this.balance?.saleFolio}`,
      "Pagar",
      "Cancelar",
      `Registrarás un pago de ${filters.moneyFormat(
        this.balance.due
      )} dejando saldo de ${filters.moneyFormat(
        this.balance.due - this.item.amount
      )}.`
    );
    // this.item
    // this.item =
    // {
    //       saleType: this.sale.cve_movto,
    //       saleFolio: this.sale?.folio,
    //       toBankId: 1,
    //       reference: "",
    //       paymentForm: this.paymentForm,
    //       amount: this.amount
    //       date: new Date()
    //     }

    // } else {
    //   ErpService.customers.customers
    //     .update(this.item.id.toString(), this.item)
    //     .then((response: CustomerDto) => {
    //       this.item = response;
    //       this.$emit("updated", response);
    //       this.$refs.successModal.show(this.$t("Cliente actualizado"));
    //     })
    //     .catch(error => {
    //       this.$refs.errorModal.show(this.$t(error));
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // }
  }
  // deleteCustomer() {
  //   this.$refs.confirmModal.show(
  //     `¿Eliminar cliente #${this.item?.id}`,
  //     "Eliminar",
  //     "Cancelar",
  //     "ADVERTENCIA: Esta acción es irreversible."
  //   );
  // }
  // yesDelete() {
  //   if (!this.item || this.item.id <= 0) {
  //     return;
  //   }
  //   this.loading = true;
  //   ErpService.customers.customers
  //     .delete(this.item.id.toString())
  //     .then(() => {
  //       this.item = null;
  //       this.taxId = null;
  //       this.$refs.successModal.show(this.$t("Cliente eliminado"));
  //     })
  //     .catch(error => {
  //       this.$refs.errorModal.show(this.$t(error));
  //     })
  //     .finally(() => {
  //       this.loading = false;
  //     });
  // }
  close() {
    this.$emit("close");
  }
  closed() {
    //   this.
  }
}
