


















import Vue from "vue";
import DropdownWithSearch from "@/components/shared/dropdowns/DropdownWithSearch.vue";
import Component from "vue-class-component";
import { CL_CondicionDeVenta } from "@/application/dtos/app/erp/v2/contacts/customers/CL_CondicionDeVenta";

@Component({
  components: {
    DropdownWithSearch
  }
})
export default class CreditSelect extends Vue {
  $refs!: {
    dropdown: DropdownWithSearch;
  };
  public select(item: number) {
    this.$refs.dropdown.selectValue(item);
  }
  private selected(value, item) {
    this.$emit("selected", value, item);
  }
}
