









































































































































































import Vue from "vue";
// import { VueTyper } from "vue-typer";
import Component from "vue-class-component";
import { mapGetters } from "vuex";
import Header from "@/components/marketing/Header.vue";

@Component({
  components: {
    Header
  },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor"
    })
  }
})
export default class Hero extends Vue {
  private email: string = "";
  get isDarkMode() {
    return this.$store.state.theme.marketingTheme === "theme-bg-dark";
  }
}
