


























































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { TicketDto } from "../../../application/dtos/app/transactions/TicketDto";
import DropImages from "../../../components/app/uploads/DropImages.vue";
import { WorkspaceDto } from "../../../application/dtos/app/workspaces/WorkspaceDto";
import { ProjectDto } from "../../../application/dtos/app/projects/ProjectDto";
import DropImage from "../../../components/app/uploads/DropImage.vue";
import services from "../../../services/index";
import { UploadTicketsRequest } from "../../../application/contracts/app/tickets/UploadTicketsRequest";
import NewErrorModal from "../../../components/shared/modals/NewErrorModal.vue";
import ErrorModalComponent from "../../../components/shared/modals/NewErrorModal.vue";
import { FileBase64 } from "@/application/shared/files/FileBase64";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { Period } from "@/application/enums/app/common/Period";
import { mapGetters } from "vuex";
import CfdiUses from "@/application/shared/CfdiUses";
import { SummaryResponse } from "@/application/contracts/app/summaries/SummaryResponse";
import { WhoInvoices } from "@/application/enums/app/cfdis/WhoInvoices";
import { TicketStatus } from "@/application/enums/app/transactions/TicketStatus";
import store from "@/store";

@Component({
  components: {
    NewErrorModal,
    DropImage,
    DropImages,
    LoadingButton,
    SuccessModal,
    ConfirmModal
  },
  computed: {
    ...mapGetters("tenant", {
      isOwnerOrAdmin: "isOwnerOrAdmin",
      workspaces: "workspaces"
    }),
    ...mapGetters("account", {
      isAdmin: "isAdmin"
    })
  }
})
export default class UploadTicketsDragAndDrop extends Vue {
  $refs!: {
    modalSuccess: SuccessModalComponent;
    errorModal: ErrorModalComponent;
    loadingButton: LoadingButton;
    modalConfirm: ConfirmModalComponent;
    dropImages: DropImages;
  };
  public openGalleryWhenReady: boolean = false;
  private isOwnerOrAdmin!: boolean;
  private tickets: TicketDto[] = [];
  private expanded: any[] = [];
  private projects: ProjectDto[] = [];
  private selectedProjectId: string = "";
  private workspaces!: WorkspaceDto[];
  private isAdmin!: boolean;
  private selectedCfdiUse: string = "";
  private loadingRfcs: boolean = false;
  private loadingProjects: boolean = false;
  private loadingUploading: boolean = false;
  private cfdiUses = CfdiUses;
  private addProject: boolean = false;
  private summary: SummaryResponse = {
    monthlyTickets: 0,
    ticketsRemaining: 0,
    ticketsByStatus: [],
    incomeCount: 0,
    expenseCount: 0,
    incomeTotal: 0,
    expenseTotal: 0,
    whoInvoices: WhoInvoices.ADMIN
  };
  private loadingSummary: boolean = false;
  private newProjectName: string = "";
  mounted() {
    this.selectedProjectId = "";
    this.selectedCfdiUse = this.currentWorkspace?.cfdiUse ?? "P01";
    this.loadProjects();
    this.loadSummary();
  }
  loadSummary() {
    this.loadingSummary = true;
    services.dashboard
      .getSummary({
        period: Period.MONTH,
        withDetails: false
      })
      .then((response: SummaryResponse) => {
        this.summary = response;
      })
      .catch(error => {
        console.error("[ERROR]: " + error);
      })
      .finally(() => {
        this.loadingSummary = false;
      });
  }
  tryOpenGallery() {
    if (!this.openGalleryWhenReady) {
      return;
    }
    if (
      !this.loadingRfcs &&
      !this.loadingProjects &&
      !this.loadingSummary &&
      this.openGalleryWhenReady
    ) {
      if (this.$refs.dropImages && this.anyWorkspaces) {
        this.openGalleryWhenReady = false;
        this.$refs.dropImages.openGallery();
      }
    }
  }
  loadProjects() {
    this.projects = [];
    this.loadingProjects = true;
    services.projects
      .getAllProjects(true)
      .then((response: ProjectDto[]) => {
        response.forEach(element => {
          this.projects.push(element);
        });
      })
      .catch(error => {
        // ignore
      })
      .finally(() => {
        this.loadingProjects = false;
        if (this.projects.length > 0) {
          this.projects.forEach(project => {
            if (project.default) {
              this.selectedProjectId = project.id;
            }
          });
        }
      });
  }
  errorModalClosed() {
    if (this.workspaces.length === 0) {
      this.$router.push({ name: "app.workspaces" });
    }
  }
  droppedFiles(files: FileBase64[]) {
    if (!this.currentWorkspace) {
      return;
    }
    files.forEach(file => {
      const image = file.base64;
      let foundWithoutImage = -1;
      let i = 0;
      this.tickets.forEach(element => {
        if (!element.image || element.image === "") {
          foundWithoutImage = i;
        }
        i++;
      });
      if (foundWithoutImage >= 0) {
        this.tickets[foundWithoutImage].image = image;
      } else {
        this.tickets.push({
          number: 0,
          id: undefined,
          status: TicketStatus.PENDING,
          taxId: this.currentWorkspace?.taxId ?? "",
          legalName: this.currentWorkspace?.legalName ?? "",
          cfdiUse: this.selectedCfdiUse ?? this.currentWorkspace?.cfdiUse,
          image: image ?? "",
          whoInvoices: WhoInvoices.ADMIN
        });
      }
    });
  }
  toggleTicketsOpen(idx: number) {
    if (this.expanded.includes(idx)) {
      this.expanded = [];
    } else {
      this.expanded = [idx];
    }
  }
  removeTickets(index: number) {
    this.tickets.splice(index, 1);
    if (index - 1 >= 0) {
      this.expanded = [index - 1];
    } else {
      this.expanded = [index + 1];
    }
  }

  isExpanded(idx): boolean {
    return this.expanded.includes(idx);
  }
  changedAllCfdiUse(event) {
    const usos = this.cfdiUses.filter(f => f.key === event.target.value);
    this.tickets.forEach(item => {
      if (usos && usos.length > 0) {
        item.cfdiUse = usos[0].key;
      }
    });
  }
  dropped(file: FileBase64, index: number) {
    let foundWithoutImage = -1;
    let i = 0;
    this.tickets.forEach(element => {
      if (!element.image || element.image === "") {
        foundWithoutImage = i;
      }
      i++;
    });
    if (foundWithoutImage >= 0) {
      this.tickets[foundWithoutImage].image = file.base64;
    } else {
      this.addTicket(file.base64);
    }
  }
  addTicket(image: string = "") {
    this.tickets.push({
      number: 0,
      id: undefined,
      status: TicketStatus.PENDING,
      taxId: this.currentWorkspace?.taxId ?? "",
      legalName: this.currentWorkspace?.legalName ?? "",
      cfdiUse: this.currentWorkspace?.cfdiUse ?? "",
      image: image ?? "",
      whoInvoices: WhoInvoices.ADMIN
    });
    return this.tickets.length - 1;
  }
  getDefaultProject(): ProjectDto | undefined {
    const projects = this.projects.filter(f => f.default);
    if (projects && projects.length > 0) {
      return projects[0];
    }
  }
  successClosed() {
    this.$emit("uploaded");
  }
  upload() {
    this.$refs.modalConfirm.show(
      `¿${this.uploadText}?`,
      this.$t("shared.upload").toString(),
      this.$t("shared.cancel").toString(),
      this.$t("app.tickets.upload.hint").toString()
    );
  }
  async confirm() {
    const errors: string[] = [];

    const self = this;
    this.loadingUploading = true;
    const promises: any[] = [];
    const uploadTicketRequest: UploadTicketsRequest = {
      tickets: this.tickets,
      scan: false,
      waitUntilAdded: true,
      rejectDuplicated: true,
      selectedProjectId: this.selectedProjectId,
      newProjectName: this.newProjectName
    };
    services.tickets
      .uploadTickets(uploadTicketRequest)
      .then(response => {
        this.$refs.modalSuccess.show(
          this.$t("app.tickets.upload.success").toString(),
          this.summary.whoInvoices === WhoInvoices.ADMIN
            ? this.$t("app.tickets.upload.successDescription1").toString()
            : this.$t("app.tickets.upload.successDescription2").toString()
        );
      })
      .catch((error: any) => {
        this.$refs.errorModal.show(this.$t(error).toString());
      })
      .finally(() => {
        self.loadingUploading = false;
      });
  }
  clear() {
    this.tickets = [];
  }
  get currentWorkspace(): WorkspaceDto | null {
    return store.state.tenant.currentWorkspace;
  }
  get canUploadTickets() {
    return this.isAdmin || this.ticketsRemaining - this.tickets.length >= 0;
  }
  get uploadText() {
    if (!this.canUploadTickets) {
      return this.$t("app.tickets.upload.ticketsRemaining", [
        this.ticketsRemaining
      ]);
    }
    if (this.tickets.length === 1) {
      return (
        this.$t("shared.upload") +
        " 1 " +
        this.$t("models.ticket.object")
          .toString()
          .toLowerCase()
      );
    } else if (this.tickets.length > 1) {
      return (
        this.$t("shared.upload") +
        " " +
        this.tickets.length +
        " " +
        this.$t("models.ticket.plural")
          .toString()
          .toLowerCase()
      );
    } else {
      return (
        this.$t("shared.upload") +
        " " +
        this.$t("models.ticket.plural")
          .toString()
          .toLowerCase()
      );
    }
  }
  get whoInvoices(): WhoInvoices | undefined {
    if (this.summary) {
      return this.summary.whoInvoices;
    }
  }
  get ticketsRemaining(): number {
    if (this.summary) {
      return this.summary.ticketsRemaining;
    }
    return 0;
  }
  get anyTickets() {
    return this.tickets && this.tickets.length > 0;
  }
  get anyWorkspaces() {
    this.tryOpenGallery();
    return this.workspaces && this.workspaces.length > 0;
  }
  get anyProjects() {
    return this.projects && this.projects.length > 0;
  }
}
