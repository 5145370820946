



































import Component from "vue-class-component";
import { SubscriptionPriceDto } from "@/application/dtos/master/subscriptions/SubscriptionPriceDto";
import BaseComponent from "../../shared/BaseComponent.vue";
import { WhoInvoices } from "@/application/enums/app/cfdis/WhoInvoices";
import { SubscriptionProductDto } from "@/application/dtos/master/subscriptions/SubscriptionProductDto";

@Component
export default class InvoiceToggle extends BaseComponent {
  mounted() {
    if (!this.$store.state.pricing.mode) {
      this.$store.commit("pricing/mode", 1);
    }
  }
  private changeMode(mode) {
    this.$store.commit("pricing/mode", mode);
    this.$emit("selected");
  }
  private whoInvoicesDiscount(modo: WhoInvoices): string {
    const priceWithDiscount = this.getPriceWithMode(WhoInvoices.TENANT);
    const priceNormal = this.getPriceWithMode(WhoInvoices.ADMIN);
    // console.log("price 1: " + priceWithDiscount);
    // console.log("price normal: " + priceNormal);
    if (priceWithDiscount && priceNormal) {
      const discount =
        100 - (priceWithDiscount.price * 100) / priceNormal.price;
      if (discount !== 0) {
        return "-" + discount.toFixed(0) + "%";
      }
    }

    return "sin descuento";
  }
  private getPriceWithMode(mode: number): SubscriptionPriceDto | undefined {
    if (this.products && this.products.length > 0) {
      return this.products[0].prices.find(
        f =>
          f.billingPeriod === this.$store.state.pricing.billingPeriod &&
          f.currency === this.$store.state.pricing.currency &&
          f.mode === mode
      );
    }
    return undefined;
  }
  get products(): SubscriptionProductDto[] {
    return this.$store.state.pricing.products as SubscriptionProductDto[];
  }
}
