








import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import AppLayout from "@/components/layouts/AppLayout.vue";
import WorkspaceLayout from "@/components/layouts/WorkspaceLayout.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import store from "@/store";

@Component({
  components: {
    AppLayout,
    WorkspaceLayout
  }
})
export default class AppComponent extends Vue {
  get currentWorkspaceId(): string {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
  // @Watch("$store.state.tenant.currentWorkspace")
  // changedWorkspace() {
  //   console.log("app.vue. changed workspace.");
  // }
}
