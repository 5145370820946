import { ProductImageDto } from "@/application/dtos/app/erp/v3/inventory/ProductImageDto";
import { ImageResolution } from "@/application/enums/app/common/ImageResolution";
import store from "@/store";
import B2bService from "../../b2b/B2bService";
import ErpService from "../ErpService";

export class ProductImageService {
  getImage(productId: string, resolution: ImageResolution, thumbnailSize: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line:max-line-length
      // const image = store.state.products.images.find((f) => f.productId === productId && f.resolution === resolution && f.thumbnailSize === thumbnailSize);
      // if (image) {
      //   console.log("loaded from store");
      //   return Promise.resolve(image);
      // }
      return ErpService.inventory.products
        .getImage(productId, resolution, thumbnailSize)
        .then((response) => {
          // console.log({ headers: response.headers });
          let blob: Blob | null = null;
          if (response.data) {
            blob = new Blob([response.data], {
              type: "application/" + response.headers["content-type"],
            });
          }
          if (!blob || blob.size === 0) {
            reject("empty");
          } else {
            const href = window.URL.createObjectURL(blob);
            // const productImage: ProductImageDto = {
            //   productId,
            //   resolution,
            //   thumbnailSize,
            //   image: href,
            // };
            // store.commit("products/addProductImage", productImage);
            resolve(href);
          }
        })
        .catch((error) => {
          Promise.reject(error);
        });
    });
  }
}
