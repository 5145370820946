





























































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ObjectList from "../../../components/shared/forms/ObjectList.vue";
import TableRowSkeleton from "../../../components/shared/skeletons/TableRowSkeleton.vue";
import SvgResultsEmpty from "../../../assets/img/ResultsEmpty.vue";
import SideModal from "../../../components/shared/modals/SideModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import PageTitle from "../../../components/shared/navigation/PageTitle.vue";
import Modal from "../../../components/shared/modals/Modal.vue";
import { mapGetters } from "vuex";
import { TenantFeaturesResponse } from "@/application/contracts/master/tenants/TenantFeaturesResponse";
import WorkspaceItem from "./WorkspaceItem.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import { TicketStatus } from "@/application/enums/app/transactions/TicketStatus";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import ErrorBanner from "@/components/shared/banners/ErrorBanner.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import services from "@/services";

@Component({
  components: {
    Modal,
    SideModal,
    ObjectList,
    TableRowSkeleton,
    SvgResultsEmpty,
    ErrorModal,
    ErrorBanner,
    PageTitle,
    WorkspaceItem,
    EmptyState
    // BeakerIcon,
  },
  computed: {
    ...mapGetters("tenant", {
      features: "features",
      workspaces: "workspaces"
    })
  }
})
export default class WorkspacesList extends BaseComponent {
  $refs!: {
    uploadTicketsModal: Modal;
    errorModal: ErrorModalComponent;
    errorBanner: ErrorBanner;
  };
  private features!: TenantFeaturesResponse;
  private workspaces!: WorkspaceDto[];
  created() {
    const self = this;
    this.eventBus.$on("workspace-deleted", data => self.deleted(data));
    this.eventBus.$on("workspace-added", data => self.added(data));
    this.eventBus.$on("workspace-saved", data => self.reload());
  }
  destroy() {
    this.eventBus.$off("workspace-deleted");
    this.eventBus.$off("workspace-added");
    this.eventBus.$off("workspace-saved");
  }
  mounted() {
    tinyEventBus().emitter.on(`${this.$route.name}-reload`, payload => {
      this.reload();
    });
    tinyEventBus().emitter.on(`${this.$route.name}-add`, payload => {
      this.$router.push({ name: "app.workspaces.new" });
    });
    tinyEventBus().emitter.emit(`settings-init-reload`, true);
    tinyEventBus().emitter.emit(`settings-init-add`, true);
    services.tenants.getFeatures();
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off(`${this.$route.name}-reload`);
    tinyEventBus().emitter.off(`${this.$route.name}-add`);
    tinyEventBus().emitter.emit(`settings-init-reload`, false);
    tinyEventBus().emitter.emit(`settings-init-add`, false);
  }
  async reload(status?: TicketStatus) {
    // this.items = [];
    this.loading = true;
    services.workspaces
      .getAllWorkspaces(true)
      .then((response: WorkspaceDto[]) => {
        // console.log(JSON.stringify(response))
        // this.items = response;
      })
      .catch(error => {
        this.$refs.errorBanner.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deleted(data: WorkspaceDto) {
    this.reload();
  }
  // canceled() {
  // }
  added(data: WorkspaceDto) {
    this.reload();
  }
  saved(data: WorkspaceDto) {
    this.reload();
  }
  get sortedItems(): WorkspaceDto[] {
    return this.workspaces.sort((x, y) => {
      if (x.name && y.name) {
        return (x.name > y.name ? 1 : -1) ?? 1;
      }
      return 1;
    });
  }
  get maxNumberOfWorkspacesReached() {
    if (!this.features) {
      return true;
    } else {
      if (
        this.features.maxNumberOfWorkspaces > 0 &&
        this.workspaces.length >= this.features.maxNumberOfWorkspaces
      ) {
        return true;
      }
      return false;
    }
  }
}
