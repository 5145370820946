




































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class TabsInPillsWithBrandColor extends Vue {
  @Prop({ default: "sm" }) breakpoint!: string;
  @Prop({}) tabs!: any[];
  $refs!: {};
  selectTab(tabName) {
    const tab = this.tabs.find(f => f.name === tabName);
    if (tab) {
      this.$router.push({ name: tab.routeName });
    }
    this.$emit("select", tabName);
  }
  @Watch("$route.path")
  changedPath(val: string, oldVal: string) {
    this.tabs.forEach(element => {
      element.current = this.$route.name === element.routeName;
    });
  }
}
