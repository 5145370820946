import { RouteConfig } from "vue-router";
import { productRoutes } from "./productRoutes";
import { adminRoutes } from "./adminRoutes";
import { appRoutes } from "./appRoutes";
import { ecommerceRoutes } from "./ecommerceRoutes";
import { b2bRoutes } from "./b2b/b2bRoutes";

const routes: RouteConfig[] = [
  {
    path: "*",
    redirect: "/",
  },
  ...productRoutes,
  appRoutes,
  ecommerceRoutes,
  ...b2bRoutes,
  adminRoutes,
  {
    name: "account.forgot",
    path: "/cuenta/olvide",
    alias: "/account/forgot",
    component: require("@/views/account/Forgot.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "account.invitation",
    path: "/invitacion",
    alias: "/invitation",
    component: require("@/views/account/Invitation.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "account.join",
    path: "/unirse/:linkUrl",
    alias: "/join/:linkUrl",
    component: require("@/views/account/InviteURL.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "terms",
    path: "/terminos-y-condiciones",
    alias: "/terms-and-conditions",
    component: require("@/views/marketing/Terms.vue").default,
  },
  {
    name: "privacy",
    path: "/politica-de-privacidad",
    alias: "/privacy-policy",
    component: require("@/views/marketing/Privacy.vue").default,
  },
  {
    name: "logout",
    path: "/salir",
    alias: "/logout",
    component: require("@/views/account/Logout.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "tests",
    path: "/pruebas",
    alias: "/tests",
    component: require("@/views/app/TestsPlayground.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
];
export default routes;
