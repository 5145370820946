








































































































































import store from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor"
    })
  }
})
export default class DashboardQuicklinks extends Vue {
  private phone: string = "";
  private token: string = "";
  private whatsAppLink: string = "";
  mounted() {
    this.phone = process.env.VUE_APP_PHONE?.toString() ?? "";
    this.token = store.state.tenant.current?.currentUser?.chatbotToken ?? "";
    this.whatsAppLink = "";
    if (this.phone && this.token) {
      this.whatsAppLink += `https://wa.me/${this.phone}?text=${this.token}`;
    }
  }
  get getWhatsAppLink() {
    return this.whatsAppLink ?? "#";
  }
}
