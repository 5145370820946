






































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { SaleLineDto } from "@/application/dtos/app/erp/v3/sales/SaleLineDto";
import { Howl } from "howler";
import NewModal from "@/components/shared/modals/NewModal.vue";
import ErrorBanner from "@/components/shared/banners/ErrorBanner.vue";
import SuccessBanner from "@/components/shared/banners/SuccessBanner.vue";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ModalNumberInput from "@/components/shared/modals/ModalNumberInput.vue";
import ProductUnitSearch from "./ProductUnitSearch.vue";
import ProductSearch from "../search/ProductSearch.vue";
import InventoryDocumentSearch from "../documents/InventoryDocumentSearch.vue";
import _ from "lodash";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
// @ts-ignore
import beep from "@/assets/sounds/pos-beep.mp3";
// @ts-ignore
import beepError from "@/assets/sounds/pos-error.mp3";
// @ts-ignore
import beepScream from "@/assets/sounds/pos-scream.mp3";
import { InventoryHeader } from "@/application/dtos/app/erp/common/InventoryHeader";
import { Prop, Watch } from "vue-property-decorator";
import BarcodeScanner from "./BarcodeScanner.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
import Dropdown from "@/components/shared/dropdowns/Dropdown.vue";
import { filters } from "@/plugins/filters";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import NewSaleFromBarcodeReads from "@/components/app/erp/sales/pos/NewSaleFromBarcodeReads.vue";
import BarcodeReadsTable from "./BarcodeReadsTable.vue";
import BarcodeReadEdit from "./BarcodeReadEdit.vue";
import SaleLinesTable from "@/components/app/erp/sales/details/SaleLinesTable.vue";
import Utils from "@/application/shared/Utils";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import ProductStockUnitSearch from "@/components/app/erp/inventory/search/ProductStockUnitSearch.vue";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { HubConnectionState } from "@aspnet/signalr";
import store from "@/store";
import SignalR from "@/plugins/SignalR";
import { ProductBarcodeReadScanRequest } from "@/application/contracts/app/erp/inventory/productBarcodeReads/ProductBarcodeReadScanRequest";
import { IN_Articulo } from "@/application/dtos/app/erp/v2/inventory/IN_Articulo";
import { BarcodeReadsSummaryDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadsSummaryDto";
import { BarcodeReadLineDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadLineDto";
import { TransferDto } from "@/application/dtos/app/erp/v3/inventory/documents/TransferDto";
import { ProductStockUnitDto } from "@/services/api/app/erp/inventory/ProductStockUnitDto";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";

@Component({
  components: {
    NewModal,
    NewErrorModal,
    ProductUnitSearch,
    ProductSearch,
    InventoryDocumentSearch,
    ErrorBanner,
    SuccessBanner,
    BarcodeScanner,
    StreamBarcodeReader,
    ProductStockUnitSearch,
    Dropdown,
    ConfirmModal,
    ModalNumberInput,
    NewSaleFromBarcodeReads,
    BarcodeReadsTable,
    SaleLinesTable,
    BarcodeReadEdit,
    EmptyState
  }
})
export default class BarcodeReads extends Vue {
  @Prop({ default: "" }) headerType!: string;
  @Prop({ default: 0 }) headerFolio!: number;
  @Prop({ default: 0 }) headerWarehouseId!: number;
  @Prop({ default: true }) isSale!: boolean;
  @Prop({ default: "units" }) searchBy!: string;
  $refs!: {
    sku: HTMLInputElement;
    modalProductUnitSearch: NewModalComponent;
    productUnitSearch: ProductUnitSearch;
    modalProducts: NewModalComponent;
    modalSearchProductUnitStock: NewModalComponent;
    modalDocuments: NewModalComponent;
    myGrid: HTMLElement;
    bannerError: ErrorBanner;
    bannerSuccess: SuccessBanner;
    modalError: ErrorModalComponent;
    modalErrorInvalidQuote: ErrorModalComponent;
    modalScanner: NewModalComponent;
    barcodeScanner: BarcodeScanner;
    modalConfirmRemove: ConfirmModalComponent;
    modalConfirmRemoveAll: ConfirmModalComponent;
    modalConfirmDeleteQuoteLines: ConfirmModalComponent;
    modalConfirmApplyDiscount: ConfirmModalComponent;
    modalNewSale: NewModal;
    modalEditBarcodeRead: NewModal;
    dropdownOptions: Dropdown;
    container: HTMLTableElement;
    modalInputDiscount: ModalNumberInput;
  };
  private signalService!: SignalR;
  private connectionState: HubConnectionState = HubConnectionState.Disconnected;
  private checkedAll: boolean = false;
  private loading: boolean = false;
  private loadingUpdating: boolean = false;
  private searchingProducts: boolean = false;
  private searchingProductUnitStock: boolean = false;
  private searchingProductUnits: boolean = false;
  private searchingDocuments: boolean = false;
  private creatingNewSale: boolean = false;
  private scanning: boolean = false;
  private code: string = "";
  private quote: SaleDto | null = null;
  private customer: CustomerDto | null = null;
  private reads: BarcodeReadLineDto[] = [];
  private selectionText: string = "";
  private selectedItem: BarcodeReadLineDto | null = null;
  private header: InventoryHeader = {
    type: this.headerType,
    folio: this.headerFolio,
    wharehouseId: this.headerWarehouseId
  };
  private withBarcode: boolean = true;
  private summary: BarcodeReadsSummaryDto | null = {
    type: "",
    folio: 0,
    // paquetesCompletos: 0,
    // piezasSueltas: 0,
    totalUnits: 0,
    quantity: 0,
    amount: 0,
    discount: 0,
    subtotal: 0,
    taxes: 0,
    total: 0,
    suggestedDiscount: 0,
    reads: []
  };
  private beepSound: any;
  private errorSound: any;
  private screamSound: any;
  private editProductUnitMode: string = "";
  // private collapsed: boolean = false;
  created() {
    window.addEventListener("keyup", event => {
      if (event.ctrlKey && event.keyCode) {
        if (event.keyCode === 70) {
          this.clickedOption(this.completeText);
        }
        if (event.keyCode === 68) {
          this.applyDiscount();
        }
      }
    });
    this.signalService = new SignalR(
      "sales-pos-hub",
      store.state.tenant.current?.id,
      store.state.tenant.currentWorkspace?.id,
      this.header.type,
      this.header.folio
    );
    this.signalService.connection.onclose(() => {
      this.connectionState = 0;
    });
  }
  mounted() {
    this.editProductUnitMode = "net";
    if (this.headerType === "IF") {
      store.commit("layout/posTableCollapsed", true);
    }
    this.configureSignalR();
    this.signalService
      .startConnection()
      .then(() => {
        this.connectionState = 1;
      })
      .catch(error => {
        this.connectionState = 0;
      });
    this.beepSound = new Howl({
      src: beep,
      volume: 0.5
    });
    this.errorSound = new Howl({
      src: beepError,
      volume: 1
    });
    this.screamSound = new Howl({
      src: beepScream,
      volume: 0.5
    });
    this.reads = [];
    this.reload();
  }
  reload() {
    if (this.headerType === "PR") {
      this.loading = true;
      ErpService.sales.sales
        .getSale(this.header.type, this.header.folio)
        .then((response: SaleDto) => {
          this.quote = response;
          if (response && response.details && response.details.length > 0) {
            let details = `${this.quote.details?.length} partidas`;
            if (this.quote.details?.length === 1) {
              details = "1 partida";
            }
            this.$refs.modalConfirmDeleteQuoteLines.show(
              "¿Editar cotización?",
              "Editar",
              "Cancelar",
              `La cotización tiene ${details}. Se eliminarán para su edición y se regresará la existencia de la mercancía para habilitar la edición.`
            );
          } else {
            if (this.quote.customerId > 0) {
              this.loadCustomer(this.quote.customerId);
            }
          }
        })
        .catch(error => {
          console.log("Not a quote");
          // this.$refs.modalErrorInvalidQuote.show(
          //   this.$t("Cotización inexistente")
          // );
        })
        .finally(() => {
          this.loading = false;
          if (this.quote) {
            this.load(this.header).finally(() => (this.loading = false));
          }
        });
    } else {
      this.load(this.header).finally(() => (this.loading = false));
    }
  }
  beforeDestroy() {
    this.signalService.closeConnection();
  }
  loadCustomer(id) {
    ErpService.customers.customers
      .get(id)
      .then(response => {
        this.customer = response;
      })
      .catch(error => {
        console.error("Could not load customer: " + error);
      });
  }
  scrollToBottom() {
    this.$refs.container.scrollTop = this.$refs.container.scrollHeight;
  }
  configureSignalR() {
    this.signalService.on("JoinedGroup", (user: string) => {
      console.log({ method: "[SalesPosHub] JoinedGroup", user });
    });
    this.signalService.on("LeavedGroup", (user: string) => {
      console.log({ method: "[SalesPosHub] LeavedGroup", user });
    });
    this.signalService.on("Updated", (user, id?: string) => {
      console.log(`User ${user} updated from sockets. ID ${id} `);
      this.load(this.header);
    });
  }
  // async reconnectSignalR() {
  //   if (
  //     !this.signalService.connection ||
  //     this.signalService.connection.state !== HubConnectionState.Connected
  //   ) {
  //     await this.signalService.startConnection();
  //   }
  // }
  reconnect() {
    this.signalService
      .startConnection()
      .then(() => {
        this.connectionState = 1;
      })
      .catch(() => {
        this.connectionState = 0;
      });
    // if (this.signalService.connection.state !== HubConnectionState.Connected) {
    //   await this.signalService.connection
    //     .start()
    //     .then(() => {
    //       this.connectionState = 1;
    //     })
    //     .catch(() => {
    //       this.connectionState = 0;
    //     });
    // }
  }
  async updatedBarcodeReads(id: number | null = null) {
    // await this.reconnectSignalR();
    await this.reconnect();
    this.signalService
      .invoke("Update", id)
      .then(response => {
        console.log("Sent Updated to SignalR");
        // // this.message = "";
        // // @ts-ignore
        // this.$refs.message.focus();
      })
      .catch(err => {
        console.log("[ERROR] Sent Updated to SignalR");
        this.load(this.header);
        return console.error(err.toString());
      });
  }
  onReady(params) {
    params.api.sizeColumnsToFit();
  }
  emptySummary() {
    this.summary = {
      type: "",
      folio: 0,
      // paquetesCompletos: 0,
      // piezasSueltas: 0,
      totalUnits: 0,
      quantity: 0,
      amount: 0,
      discount: 0,
      subtotal: 0,
      taxes: 0,
      total: 0,
      suggestedDiscount: 0,
      reads: []
    };
  }
  load(header: InventoryHeader): Promise<void> {
    // this.reconnectSignalR();
    const self = this;
    return new Promise(resolve => {
      this.emptySummary();
      // self.reads = [];
      self.header = header;
      // this.loading = true;
      self.loadingUpdating = true;
      ErpService.inventory.productBarcodeReads
        .getAllReads(self.header.type, self.header.folio)
        .then((response: BarcodeReadsSummaryDto) => {
          self.summary = response;
          self.reads = response.reads;
        })
        .catch(error => {
          if (self.$refs.bannerError) {
            self.$refs.bannerError.show(this.$t(error).toString());
          }
        })
        .finally(() => {
          // self.focusSku();
          self.loading = false;
          self.loadingUpdating = false;
          resolve();
        });
    });
  }
  enter() {
    // this.focusSku();
    if (
      this.code
      // !this.reads.find(f => f.sku.toString() === this.code) &&
      // !this.reads.find(f => f.lot + " " + f.lotNumber === this.code.trim())
    ) {
      this.scan();
    }
  }
  search() {
    if (this.searchBy === "products") {
      this.searchProducts();
    } else if (this.searchBy === "units") {
      this.searchProductUnits();
    }
  }
  searchProducts() {
    this.searchingProducts = true;
    this.$refs.modalProducts.show();
  }
  searchProductUnitStock() {
    this.searchingProductUnitStock = true;
    this.$refs.modalSearchProductUnitStock.show();
  }
  searchProductUnits() {
    this.searchingProductUnits = true;
    this.$refs.modalProductUnitSearch.show();
  }
  // generate() {
  //   this.loading = true;
  //   ErpService.inventory.productBarcodeReads
  //     .generate(this.header)
  //     .then(() => {
  //       this.load(this.header);
  //     })
  //     .catch(error => {
  //       this.$refs.bannerError.show(this.$t(error).toString());
  //     })
  //     .finally(() => {
  //       this.loading = false;
  //     });
  // }
  removeRead(product: BarcodeReadLineDto) {
    this.$refs.modalConfirmRemove.value = product;
    this.$refs.modalConfirmRemove.show("¿Eliminar lectura?");
  }
  scanWithCamera() {
    this.scanning = true;
    this.$refs.modalScanner.show();
  }
  async scan(): Promise<boolean> {
    const tempCode = this.code;
    this.code = "";
    return new Promise((resolve, reject) => {
      ErpService.inventory.productBarcodeReads
        .scan(
          new ProductBarcodeReadScanRequest(
            this.header.type,
            this.header.folio,
            tempCode,
            this.header.wharehouseId
          )
        )
        .then((response: BarcodeReadLineDto) => {
          // this.code = "";
          if (!response.lot) {
            if (!this.reads.find(f => f.id === response.id)) {
              this.reads.push(response);
            }
            this.editRead(response);
          } else {
            this.$refs.bannerSuccess.show(
              `Se agregó ${response.lot} #${response.lotNumber}`
            );

            this.beepSound.play();
            if (this.headerType !== "IF") {
              this.updatedBarcodeReads(response.id);
            } else {
              if (
                !this.reads.find(
                  f =>
                    f.lot === response.lot && f.lotNumber === response.lotNumber
                )
              ) {
                this.reads.push(response);
              }
            }
          }
          resolve(true);
        })
        .catch(error => {
          // this.errorSound.play();
          if (store.state.account.user?.email.includes("alex.martinez@absys.com.mx")) {
            this.screamSound.play();
          } else {
            this.errorSound.play();
          }
          if (error.toString().length > 100) {
            this.$refs.modalError.showErrors([this.$t(error).toString()]);
          } else {
            this.$refs.bannerError.show(this.$t(error).toString());
          }
          reject(error);
        })
        .finally(() => {
          this.loading = false;
          // this.focusSku();
        });
    });
  }
  focusSku() {
    this.$nextTick(() => {
      if (this.$refs.sku) {
        this.$refs.sku.focus();
        this.$refs.sku.select();
      }
    });
  }
  // closedSearch() {
  //   this.searching = false;
  // }
  selectedProductUnit(item: ProductStockUnitDto) {
    // this.$refs.modalProductUnitSearch.close();
    this.code = item.lot + " " + item.lotNumber;
    this.scan().then(() => {
      // if (this.$refs.productUnitSearch) {
      //   this.$refs.bannerSuccess
      //   this.$refs.productUnitSearch.added(item);
      // }
    });
  }
  selectedProduct(item: IN_Articulo) {
    this.$refs.modalProducts.close();
    this.code = item.codigo;
    this.scan();
  }
  selectedDocument(item: TransferDto) {
    this.$refs.modalDocuments.close();
    this.loading = true;
    ErpService.inventory.productBarcodeReads
      .loadDocument({
        from: {
          type: item.type,
          wharehouseId: item.toWarehouseId,
          folio: item.folio
        },
        to: this.header
      })
      .then(response => {
        this.summary = response;
        this.reads = response.reads;
        this.updatedBarcodeReads();
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loading = false;
      });
  }
  // closedScanner(){
  //   this.scanning = false;
  // }
  scanned(response: BarcodeReadLineDto) {
    // this.beepSound.play();
    if (!response.lot) {
      if (!this.reads.find(f => f.id === response.id)) {
        this.reads.push(response);
      }
      this.editRead(response);
    } else {
      if (this.headerType !== "IF") {
        this.updatedBarcodeReads(response.id);
      } else {
        if (!this.reads.find(f => f.id === response.id)) {
          this.reads.push(response);
        }
      }
    }

    // console.log("scanned: " + code);
    // this.code = code;
    // this.scan().then(() => {
    //   this.scanWithCamera();
    // });
  }
  // onDecode(result: string) {
  //   console.log("onDecode: " + result);
  //   this.scanned(result);
  // }
  // checkAll() {
  //   this.reads?.forEach(element => {
  //     element.checked = this.checkedAll;
  //   });
  // }
  // selectionChanged() {
  //   this.selectionText = `${this.selectedRows.length} seleccionados`;
  // }
  applyDiscount() {
    this.yesApplyDiscount(this.suggestedDiscount);
    // this.$refs.modalInputDiscount.show(this.suggestedDiscount ?? 0);
  }
  async yesApplyDiscount(value) {
    return new Promise((resolve, reject) => {
      this.loadingUpdating = true;
      ErpService.inventory.productBarcodeReads
        .applyDiscount({
          ids: this.items.map(f => f.id),
          quantity: value
        })
        .then(() => {
          // this.summary = response;
          // this.reads = response.reads;
          this.updatedBarcodeReads();
          resolve();
        })
        .catch(error => {
          this.$refs.bannerError.show(this.$t(error).toString());
          reject();
        })
        .finally(() => {
          this.loadingUpdating = false;
        });
    });
  }
  addQuotedUnits(items: ProductStockUnitDto[]) {
    this.$refs.modalSearchProductUnitStock.close();
    this.searchingProductUnitStock = false;
    items.forEach(element => {
      this.code = element.lot + " " + element.lotNumber;
      Promise.resolve(this.scan());
    });
  }
  showError(error) {
    this.$refs.modalError.showErrors([error]);
  }
  // get descuentoPromedioAplicado() {
  //   return this.reads.reduce(function(m, d) {
  //     return m.descuento + d.de
  //   });
  //   // return this.reads.reduce()
  // }
  noConfirmApplyDiscount() {
    this.$refs.modalNewSale.show();
  }
  yesConfirmApplyDiscount() {
    const self = this;
    if (!this.summary) {
      return;
    }
    this.yesApplyDiscount(this.suggestedDiscount)
      .then(() => {
        self.$refs.modalNewSale.show();
      })
      .catch(() => {
        //
      });
  }
  yesConfirmDeleteQuoteLines() {
    if (!this.quote) {
      return;
    }
    this.loading = true;
    ErpService.sales.quotes
      .deleteLines(this.quote.folio)
      .then(() => {
        this.load(this.header).finally(() => (this.loading = false));
      })
      .catch(error => {
        this.loading = false;
        this.$refs.modalError.show(this.$t(error));
      });
  }
  yesConfirmRemoveAll() {
    this.loading = true;
    ErpService.inventory.productBarcodeReads
      .deleteAll(this.header.type, this.header.folio)
      .then(() => {
        this.load(this.header);
        this.updatedBarcodeReads();
      })
      .catch(error => {
        this.$refs.modalError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loading = false;
      });
    // const removingAll: any[] = [];
    // this.items.forEach(element => {
    //   removingAll.push(this.yesConfirmRemove(element, false));
    // });
    // Promise.all(removingAll).finally(() => {
    //   this.updatedBarcodeReads();
    //   this.load(this.header);
    //   this.loading = false;
    // });
  }
  yesConfirmRemove(
    product: BarcodeReadLineDto,
    loadHeader: boolean = true
  ): Promise<any> {
    // this.reads = this.reads.filter(
    //   f => f.consecutivo_origen !== product.consecutivo_origen
    // );
    // return;
    return new Promise((resolve, reject) => {
      ErpService.inventory.productBarcodeReads
        .delete(product.id.toString())
        .then(() => {
          if (loadHeader) {
            this.load(this.header);
            this.updatedBarcodeReads();
          }
          // this.reads = this.reads.filter(
          //   (f) => f.consecutivo_origen !== product.consecutivo_origen
          // );
          resolve();
        })
        .catch(error => {
          this.$refs.bannerError.show(this.$t(error).toString());
          reject(error);
        });
    });
  }
  clickedOption(option) {
    this.$refs.dropdownOptions.close();
    switch (option) {
      case this.completeText:
        if (this.loading || this.loadingUpdating || !this.isSale) {
          return;
        } else if (!this.summary || this.summary.total === 0) {
          this.$refs.modalError.show("No hay nada para facturar");
          return;
        }
        this.selectedItem = null;
        this.$refs.modalEditBarcodeRead.close();
        this.$refs.modalProductUnitSearch.close();
        this.$refs.modalDocuments.close();

        const itemsWithoutSuggestedDiscount: BarcodeReadLineDto[] = [];
        this.items.forEach(item => {
          if (this.suggestedDiscount !== item.discount) {
            itemsWithoutSuggestedDiscount.push(item);
          }
        });
        if (
          this.summary.discount > 0 ||
          itemsWithoutSuggestedDiscount.length === 0 ||
          this.suggestedDiscount === 0
        ) {
          this.$refs.modalNewSale.show();
        } else {
          this.$refs.modalConfirmApplyDiscount.show(
            "Descuento sugerido",
            "Aplicar " + filters.percentageFormat(this.suggestedDiscount),
            "No aplicar sugerido",
            `Existen (${itemsWithoutSuggestedDiscount.length}/${this.items.length
            }) renglones sin el descuento sugerido de ${filters.percentageFormat(
              this.suggestedDiscount
            )}. ¿Aplicar descuento sugerido?`
          );
        }
        break;
      case "Escanear":
        this.scanWithCamera();
        break;
      case "Descuento sugerido":
        this.applyDiscount();
        break;
      case "Cargar Entrada de Almacén":
        this.loadPendingDocument();
        break;
      case "Colapsar / Expandir":
        this.$store.commit("layout/togglePosTableCollapsed");
        break;
      case "Buscar productos":
        this.searchProducts();
        break;
      case "Cotizar producto":
        this.searchProductUnitStock();
        break;
      case "Buscar paquetes":
        this.searchProductUnits();
        break;
      case "Recargar":
        this.reload();
        break;
      case "Eliminar todo":
        this.$refs.modalConfirmRemoveAll.show(
          "¿Eliminar todos los renglones?",
          "Eliminar todo",
          "Cancelar",
          "ADVERTENCIA: Esta acción es irreversible."
        );
        break;
    }
  }
  loadPendingDocument() {
    this.searchingDocuments = true;
    this.$refs.modalDocuments.show();
  }
  createdSale(response: SaleDto, createQuoteLines: boolean) {
    this.updatedBarcodeReads();
    if (!createQuoteLines) {
      this.$router.push({ name: "erp.sales.quotes" });
    } else {
      this.$router.push({
        name: "erp.sales.sale",
        params: { type: response.type, folio: response.folio.toString() }
      });
    }
  }
  errorCreatingSale(error) {
    this.$refs.modalNewSale.close();
    this.creatingNewSale = false;
    this.$refs.modalError.show(this.$t(error));
    this.load(this.header);
  }
  deleteRead(item: BarcodeReadLineDto) {
    this.selectedItem = null;
    this.$refs.modalEditBarcodeRead.close();

    this.loadingUpdating = true;
    ErpService.inventory.productBarcodeReads
      .delete(item.id.toString())
      .then(() => {
        // this.load(this.header);
        this.updatedBarcodeReads();
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loadingUpdating = false;
      });
  }
  errorModalFromScanner(error) {
    this.$refs.modalScanner.close();
    this.scanning = false;
    this.$refs.modalError.show(this.$t(error));
  }
  editReadFromScanner(item: BarcodeReadLineDto) {
    this.$refs.modalScanner.close();
    this.scanning = false;
    this.editRead(item);
  }
  editRead(item: BarcodeReadLineDto) {
    this.selectedItem = item;
    this.$refs.modalEditBarcodeRead.show();
  }
  editedBarcodeRead(
    item: BarcodeReadLineDto,
    quantity: number,
    units: number,
    discount: number,
    mode: string
  ) {
    if (mode === "tare") {
      this.updateRawQuantity(item, quantity);
    } else if (mode === "net") {
      this.updateQuantity(item, quantity);
    }
    if (units !== item.units) {
      this.updateUnits(item, units);
    }
    if (discount !== item.discount) {
      this.updateDiscount(item, discount);
    }
    this.selectedItem = null;
    this.$refs.modalEditBarcodeRead.close();
  }
  updateQuantity(item: BarcodeReadLineDto, quantity: number) {
    this.loadingUpdating = true;
    ErpService.inventory.productBarcodeReads
      .updateQuantity({
        ids: [item.id],
        quantity
      })
      .then(response => {
        // this.summary = response;
        // this.reads = response.reads;
        this.updatedBarcodeReads();
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loadingUpdating = false;
      });
  }
  updateRawQuantity(item: BarcodeReadLineDto, quantity: number) {
    this.loadingUpdating = true;
    ErpService.inventory.productBarcodeReads
      .updateRawQuantity({
        ids: [item.id],
        quantity
      })
      .then(response => {
        // this.summary = response;
        // this.reads = response.reads;
        this.updatedBarcodeReads();
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loadingUpdating = false;
      });
  }
  updateUnits(item: BarcodeReadLineDto, quantity: number) {
    this.loadingUpdating = true;
    ErpService.inventory.productBarcodeReads
      .updateUnits({
        ids: [item.id],
        quantity
      })
      .then(response => {
        // this.summary = response;
        // this.reads = response.reads;
        this.updatedBarcodeReads();
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loadingUpdating = false;
      });
  }
  updateDiscount(item: BarcodeReadLineDto, quantity: number) {
    this.loadingUpdating = true;
    ErpService.inventory.productBarcodeReads
      .applyDiscount({
        ids: [item.id],
        quantity
      })
      .then(response => {
        this.updatedBarcodeReads();
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loadingUpdating = false;
      });
  }
  get suggestedDiscount() {
    if (this.customer && this.customer.fixedPercentageDiscount > 0) {
      return this.customer.fixedPercentageDiscount;
    }
    return this.summary?.suggestedDiscount ?? 0;
  }
  get items() {
    return this.reads;
  }
  get saleLines(): SaleLineDto[] {
    const lines: SaleLineDto[] = [];
    const readsByProduct = Utils.groupByRecords(this.reads, f => f.productId);
    const readLines = Object.keys(readsByProduct);
    readLines.forEach(productId => {
      const reads = readsByProduct[productId];
      const discounts: number[] = [];
      reads.forEach(element => {
        if (!discounts.includes(element.discount)) {
          discounts.push(element.discount);
        }
      });

      let amount = 0;
      let quantity = 0;
      let units = 0;
      let subtotal = 0;
      let taxes = 0;
      let total = 0;
      reads.forEach(element => {
        amount += element.amount;
        quantity += element.quantity;
        units += element.units;
        subtotal += element.subtotal;
        taxes += element.taxes;
        total += element.total;
      });

      const line: SaleLineDto = {
        type: this.header.type,
        folio: this.header.folio,
        productId,
        productName: reads.length > 0 ? reads[0].productName : "",
        quantity,
        amount,
        units,
        subtotal,
        taxes,
        total,
        product: reads.length > 0 ? reads[0].product : undefined,
        unitOfMeasure:
          reads.length > 0 ? reads[0].product?.unitOfMeasure ?? "" : "",
        price: reads.length > 0 ? reads[0].price : 0,
        discount: discounts.length === 1 ? discounts[0] : 0,
        reads
      };
      lines.push(line);
    });
    return lines;
  }
  get completeText() {
    if (this.loadingUpdating || this.loading) {
      return "Cargando...";
    }
    if (this.isSale) {
      if (this.summary && this.summary.total) {
        return `${filters.moneyFormat(this.summary?.total ?? 0)}`;
      } else {
        return "$0.00";
      }
    } else {
      return `${filters.decimalFormat(this.itemsSummary.quantity ?? 0)} kg`;
    }
  }
  get itemsSummary() {
    let quantity = 0;
    let units = 0;
    let subtotal = 0;
    let taxes = 0;
    let total = 0;
    let anyLots = false;
    this.items.forEach(element => {
      if (element.lot) {
        anyLots = true;
      }
      quantity += element.quantity;
      units += element.units;
      subtotal += element.subtotal;
      taxes += element.taxes;
      total += element.total;
    });
    return {
      quantity,
      units,
      subtotal,
      taxes,
      total,
      anyLots
    };
  }
}
