










































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { filters } from "@/plugins/filters";
import Dropdown from "@/components/shared/dropdowns/Dropdown.vue";
import DropdownSimple from "@/components/shared/dropdowns/DropdownSimple.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import { SaleReportFormat } from "@/application/enums/app/erp/sales/SaleReportFormat";
import { CategoryDto } from "@/application/dtos/app/erp/v3/inventory/CategoryDto";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";

@Component({
  components: {
    Dropdown,
    DropdownSimple
  }
})
export default class QuoteTableRow extends Vue {
  $refs!: {
    dropdownOptions: DropdownSimple;
  };
  @Prop({ default: -1 }) idx!: number;
  @Prop({ default: undefined }) item!: SaleDto;
  @Prop({ default: null }) value!: any;
  @Prop({ default: false }) allowRemove!: boolean;
  itemDetailsRoute(item: SaleDto) {
    return `erp.sales.quotes.details`;
  }
  action(name, item) {
    this.$refs.dropdownOptions.close();
    this.$emit(name, item);
  }
  dateAgo(value: string) {
    return filters.dateFormat(value, "MMM DD");
  }
  quantity(item: SaleDto, categoryId?: number) {
    let total = 0;
    item.details?.forEach(element => {
      if (!categoryId || element.product?.category?.id === categoryId) {
        total += element.quantity;
      }
    });
    item.reads?.forEach(element => {
      if (!categoryId || element.product?.category?.id === categoryId) {
        total += element.quantity;
      }
    });
    return total;
  }
  subtotal(item: SaleDto) {
    let total = 0;
    item.details?.forEach(element => {
      total += element.amount;
    });
    item.reads?.forEach(element => {
      total += element.subtotal;
    });
    return total;
  }
  total(item: SaleDto) {
    let total = 0;
    item.details?.forEach(element => {
      let totalAmount = element.amount;
      totalAmount *= 1.16;
      // if (element._Articulo?._Linea?.impuesto === 16) {
      //   importeTotal *= 1.16;
      // }
      total += totalAmount;
    });
    item.reads?.forEach(element => {
      total += element.total;
    });
    return total;
  }
  // subtotal(item: SaleDto) {
  //   let total = 0;
  //   item.detalles?.forEach(element => {
  //     total += element.importe;
  //   });
  //   item._Lecturas?.forEach(element => {
  //     total += element._Total;
  //   });
  //   return total;
  // }
  download(item: SaleDto) {
    ErpService.sales.sales.download({
      sale: {
        type: item.type,
        folio: item.folio
      },
      format: SaleReportFormat.Quote
    });
  }
  // saleDetailsTotal(item: SaleDto) {
  //   let total = 0;
  //   item.detalles?.forEach(element => {
  //     total += element.importe;
  //   });
  //   return total;
  // }
  // barcodeReadsTotal(item: SaleDto) {
  //   let total = 0;
  //   item._Lecturas?.forEach(element => {
  //     total += element._Total;
  //   });
  //   return total;
  // }
  get categories(): CategoryDto[] {
    if (!this.value) {
      return [];
    }
    return this.value.categories;
  }
  set categories(value) {
    const payload = this.value || {};
    this.$emit("input", { ...payload, categories: value });
  }
}
