








































































import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/marketing/Header.vue";
import { blogService } from "../../services/marketing/BlogService";

@Component({ components: { Header } })
export default class BlogPostComponent extends Vue {
  post: any = {};
  loading: boolean = false;
  mounted() {
    this.getPost();
  }
  getPost() {
    this.loading = true;
    blogService
      .getPost(this.$route.params.slug)
      .then(response => {
        this.post = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
