import { ApiService, ApiServiceType } from "../../ApiService";
import { GetTransactionsOrTicketsRequest } from "@/application/contracts/app/tickets/GetTransactionsOrTicketsRequest";
// tslint:disable-next-line: max-line-length
import { TransactionOrTicketQueryResult } from "@/application/contracts/app/transactions/TransactionOrTicketQueryResult";

export class TransactionOrTicketService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("TransactionOrTicket", ApiServiceType.TenantDatabase);
  }
  adminGetAllTransactionsOrTickets(request: GetTransactionsOrTicketsRequest): Promise<TransactionOrTicketQueryResult> {
    return this.api.getAll(`Admin/GetAll?` + this.api.toQueryString(request));
  }
  // tslint:disable-next-line: max-line-length
  getAllTransactionsOrTickets(request: GetTransactionsOrTicketsRequest, admin: boolean): Promise<TransactionOrTicketQueryResult> {
    if (admin) {
      return this.api.getAll(`Admin/GetAll?` + this.api.toQueryString(request));
    }
    return this.api.getAll(`GetAll?` + this.api.toQueryString(request));
  }
}
