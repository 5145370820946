































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters } from "vuex";
import TenantNew from "@/components/app/settings/tenant/TenantNew.vue";
import TenantSubscriptionStatus from "@/components/app/settings/tenant/subscription/TenantSubscriptionStatus.vue";
import Breadcrumb from "./Breadcrumb.vue";

import { Prop, Watch } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import { SideBarItem } from "@/application/sidebar/SidebarItem";
import { SidebarGroup } from "@/application/sidebar/SidebarGroup";
import { AdminSidebar } from "@/application/sidebar/AdminSidebar";
import { AppSidebar } from "@/application/sidebar/AppSidebar";
import services from "../../services";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { SubscriptionProductDto } from "@/application/dtos/master/subscriptions/SubscriptionProductDto";
import { UserType } from "@/application/enums/master/users/UserType";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import WorkspaceSelector from "@/components/app/workspaces/WorkspaceSelector.vue";
import TenantSelector from "@/components/app/selectors/TenantSelector.vue";
import DarkModeToggle from "@/components/marketing/toggles/DarkModeToggle.vue";
import store from "@/store";
import SignalR from "@/plugins/SignalR";
import { HubConnectionState } from "@aspnet/signalr";
import NewCustomer from "@/views/app/erp/contacts/customers/forms/NewCustomer.vue";
import NewQuote from "@/components/app/erp/sales/pos/NewQuote.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";

@Component({
  components: {
    TenantNew,
    TenantSubscriptionStatus,
    Breadcrumb,
    WorkspaceSelector,
    TenantSelector,
    DarkModeToggle,
    NewCustomer,
    NewQuote,
    NewModalComponent
  },
  computed: {
    ...mapGetters("account", {
      profileName: "profileName",
      email: "email",
      avatarText: "avatarText",
      avatar: "avatar",
      isAdmin: "isAdmin"
    }),
    ...mapGetters("theme", {
      appTheme: "appTheme",
      appColor: "appColor"
    }),
    ...mapGetters("tenant", {
      activeProduct: "activeProduct",
      roleName: "roleName",
      currentRole: "currentRole",
      isOwnerOrAdmin: "isOwnerOrAdmin"
    })
  }
})
export default class AppLayout extends Vue {
  $refs!: {
    mainElement: HTMLElement;
    newCustomer: NewCustomer;
    modalNewQuote: NewModalComponent;
  };
  @Prop()
  public layout!: string;
  private signalService!: SignalR;
  private dropDownTenantMobile: boolean = false;
  private dropDownTenantDesktop: boolean = false;
  private dropDownUser: boolean = false;
  private menu: SideBarItem[] = [];
  private expanded: number[] = [];
  private addingTenant: boolean = false;
  private appTheme!: string;
  private appColor!: string;
  private currentRole!: TenantUserRole;
  private showQuickActions: boolean = false;
  private isOwnerOrAdmin!: boolean;
  private profileName!: string;
  private avatar!: string;
  private avatarText!: string;
  private whatsAppLink: string = "";
  private phone: string = "";
  private token: string = "";
  private appVersion: string = "";
  created() {
    this.appVersion = process.env.VUE_APP_VERSION ?? "";
    this.signalService = new SignalR(
      "tenant-hub",
      store.state.tenant.current?.id + store.state.tenant.currentWorkspace?.id
    );
    // @ts-ignore
    $crisp.push(["set", "user:email", [store.state.account.user?.email]]);
    // @ts-ignore
    $crisp.push([
      "set",
      "user:nickname",
      [
        store.state.account.user?.firstName +
          " " +
          store.state.account.user?.lastName
      ]
    ]);
  }
  beforeDestroy() {
    this.signalService.closeConnection();
    tinyEventBus().emitter.off("customers-add");
  }
  mounted() {
    const self = this;
    tinyEventBus().emitter.on("customers-add", (payload: any) => {
      self.$refs.newCustomer.add();
    });
    // @ts-ignore
    $crisp.push(["do", "chat:hide"]);
    // this.setTheme();
    this.$store.commit(
      "theme/appTheme",
      this.$store.state.tenant.current.appTheme ?? "theme-bg-dark"
    );
    this.$store.commit(
      "theme/appColor",
      this.$store.state.tenant.current.appColor
    );

    if (this.layout === "admin") {
      this.menu = AdminSidebar;
      // this.$store.commit("theme/appTheme", "theme-bg-light");
      // this.$store.commit("theme/appColor", "theme-indigo");
    } else {
      this.configureSignalR();
      this.startConnection();
      this.menu = AppSidebar;
      this.$store.commit(
        "theme/appTheme",
        this.$store.state.tenant.current.appTheme ?? "theme-bg-dark"
      );
      this.$store.commit(
        "theme/appColor",
        this.$store.state.tenant.current.appColor
      );
      this.whatsAppLink = "";
      this.phone = process.env.VUE_APP_PHONE?.toString() ?? "";
      if (this.phone) {
        this.whatsAppLink += `https://wa.me/${this.phone}`;
      }
      if (this.token) {
        this.whatsAppLink += `?text=${this.token}`;
      }
    }
    this.menu.forEach((group, idxGroup) => {
      // if (group.open) {
      //   this.expanded.push(idxGroup);
      // }
      group.items?.forEach((element, index) => {
        if (element.open) {
          this.expanded.push(index);
        }
        //  else {
        //   this.expanded = this.expanded.filter(f => f !== index);
        // }
      });
    });
    this.showConversations();

    services.tenants.getAll();
    services.workspaces.getAllWorkspaces(true);

    // SignalService.on("globalHub", "UserConnected", message => {
    //   console.log("[Message received] " + message);
    //   // alert(message);
    // });
  }
  showChat() {
    // @ts-ignore
    $crisp.push(["do", "chat:show"]);
    // @ts-ignore
    $crisp.push(["do", "chat:open"]);
  }
  configureSignalR() {
    this.signalService.on("JoinedGroup", (user: string) => {
      console.log({ method: "[TenantHub] JoinedGroup", user });
    });
    this.signalService.on("LeavedGroup", (user: string) => {
      console.log({ method: "[TenantHub] LeavedGroup", user });
    });
    this.signalService.on("ReceiveMessage", (user, message: string) => {
      console.log({
        method: "[WorkspaceHub] ReceiveMessage",
        user,
        message
      });
    });
    this.signalService.on("ObjectAdded", (user: string, obj: any) => {
      console.log({ method: "[WorkspaceHub] AddedObject", user, obj });
    });
  }
  startConnection() {
    this.signalService.startConnection();
  }
  setTheme() {
    const htmlClasses = document.querySelector("html")?.classList;
    if (this.currentTheme === "theme-bg-light") {
      this.$store.commit("theme/appTheme", "theme-bg-light");
      htmlClasses?.remove("dark");
    } else {
      this.$store.commit("theme/appTheme", "theme-bg-dark");
      htmlClasses?.add("dark");
    }
  }
  showConversations() {
    // @ts-ignore
    if (this.$intercom) {
      // @ts-ignore
      // this.$intercom.boot({
      //   user_id: this.$store.state.account.user.id,
      //   name: this.$store.state.account.user.firstName,
      //   email: this.$store.state.account.user.email,
      // });
      // this.$intercom.show();
    }
    // @ts-ignore
    if (this.$drift) {
      // @ts-ignore
      // this.$drift.identify(this.$store.state.account.user.id, {
      //   name: this.$store.state.account.user.firstName,
      //   email: this.$store.state.account.user.email,
      // });
      // this.$drift.show();
    }
  }
  logout() {
    this.$store.dispatch("auth/logout");
  }
  closeShowUploadTickets() {
    this.showQuickActions = false;
  }
  closeDropdownUser() {
    this.dropDownUser = false;
  }
  closeSidebar() {
    this.$store.commit("layout/sidebarOpen", false);
  }
  closeDropdownTenant() {
    this.dropDownTenantMobile = false;
  }
  toggleSidebar() {
    this.$store.commit("layout/toggleSidebar");
  }
  toggleMenuItem(index) {
    if (this.expanded.includes(index)) {
      this.expanded = this.expanded.filter(item => item !== index);
    } else {
      this.expanded.push(index);
    }
  }
  menuItemIsExpanded(index: number) {
    return this.expanded.includes(index);
  }
  translate(title) {
    return this.$t(title.trim());
  }
  createdTenant() {
    this.addingTenant = false;
  }
  // addPricesToAdminSidebar(prices: SideBarItem) {
  //   prices.items = [];
  //   if (prices && this.products) {
  //     this.products.forEach((element: SubscriptionProductDto) => {
  //       const price: SideBarItem = {
  //         title: element.title,
  //         pathName: "/admin/products/" + element.id,
  //         items: []
  //       };
  //       if (element.id && price) {
  //         prices.items?.push(price);
  //       }
  //     });
  //     if (prices.items.length > 0) {
  //       prices.items.unshift({
  //         title: this.$t("shared.all"),
  //         name: "admin.products",
  //         items: []
  //       });
  //       // prices.open = true;
  //     }
  //   }
  // }
  addToBreadcrumb(items: any, route: RouteConfig) {
    if (route.path !== this.$route.path) {
      if (!items.some(f => f.link === route.path)) {
        items.push({
          name: route.meta?.title ?? "Untitled",
          link: route.path
        });
      }
    }
  }
  clickUploadTickets() {
    this.closeShowUploadTickets();
    this.$router.push({ name: "app.tickets.upload" });
  }
  createdCustomer() {
    tinyEventBus().emitter.emit("customers-reload");
  }
  addQuote() {
    this.showQuickActions = false;
    this.$refs.modalNewQuote.show();
  }
  get currentTheme() {
    return this.$store.state.theme.appTheme;
  }
  get products(): SubscriptionProductDto[] {
    return this.$store.state.pricing.products as SubscriptionProductDto[];
  }
  get currentTenant(): TenantDto {
    return this.$store.state.tenant.current ?? { name: "Undefinded" };
  }
  get currentUserType(): UserType {
    return this.$store.state.account.user.type as UserType;
  }
  get bgPrimary() {
    if (this.$store.state.theme.appTheme === "theme-bg-dynamic") {
      return "bg-theme-900 text-blueGray-100";
    } else {
      return "bg-coolGray-900";
    }
  }
  get getMenu(): SidebarGroup[] {
    // if (this.layout === "admin" && this.menu.length > 0 && this.menu[0].items) {
    //   const prices: SideBarItem | undefined = this.menu[0].items.find(
    //     f => f.pathName === "admin.pricing"
    //   );
    //   if (prices) {
    //     prices.items = [];
    //     this.addPricesToAdminSidebar(prices);
    //   }
    // }
    const menu: SidebarGroup[] = [];
    this.menu.forEach(({ title, items, userTypes, userRoles }) => {
      if (
        (!userTypes || userTypes.includes(this.currentUserType)) &&
        (!userRoles || userRoles.includes(this.currentRole))
      ) {
        const validItems: SideBarItem[] = [];
        items.forEach(f => {
          if (
            (!f.userTypes || f.userTypes.includes(this.currentUserType)) &&
            (!f.userRoles || f.userRoles.includes(this.currentRole))
          ) {
            validItems.push(f);
          }
        });
        const addGroup: SidebarGroup = {
          title: title.toString(),
          items: validItems
        };
        if (addGroup.items.length > 0) {
          menu.push(addGroup);
        }
      }
    });
    return menu;
  }
  get currentWorkspaceId(): string {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
  @Watch("$route.path")
  scroll() {
    this.$refs.mainElement.scrollTo(0, 0);
  }
  // @Watch("$store.state.tenant.currentWorkspace")
  // changedWorkspace() {
  //   console.log("app.vue. changed workspace.");
  // }
}
