export enum CustomFieldType {
  STRING,
  INTEGER,
  DECIMAL,
  DATETIME,
  DATE,
  HOUR,
  IMAGE,
  VIDEO,
  AUDIO,
  DOCUMENT,
  SELECT_MULTIPLE,
  SELECT_SINGLE,
  PHONE,
  EMAIL,
  URL,
  CURRENCY,
  PERCENTAGE,
  STATUS,
  RATING,
  FORMULA,
  RECORD,
  CREATED_BY_USERNAME,
  CREATED_BY_USER_EMAIL,
  CREATED_TIME,
  LAST_MODIFIED_TIME,
  LAST_MODIFIED_BY,
  SERIAL,
  BARCODE,
  BUTTON,
  USER,
  PROJECT,
  WORKSPACE,
  TRANSACTION,
  TICKET,
  CFDI,
}
