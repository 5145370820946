



















































































































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import TenantSubscriptionPlan from "@/components/app/settings/tenant/subscription/TenantSubscriptionPlan.vue";
import TenantSubscriptionProducts from "@/components/app/settings/tenant/subscription/TenantSubscriptionProducts.vue";
import TenantSubscriptionPaymentDetails from "@/components/app/settings/tenant/subscription/TenantSubscriptionPaymentDetails.vue";
import TenantSubscriptionInvoices from "@/components/app/settings/tenant/subscription/TenantSubscriptionInvoices.vue";
import { mapGetters } from "vuex";
import { SubscriptionPriceDto } from "@/application/dtos/master/subscriptions/SubscriptionPriceDto";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import services from "@/services";

@Component({
  components: {
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    TenantSubscriptionPlan,
    TenantSubscriptionProducts,
    TenantSubscriptionPaymentDetails,
    TenantSubscriptionInvoices
  },
  computed: {
    ...mapGetters("tenant", {
      activeProduct: "activeProduct"
    })
  }
})
export default class TenantSubscription extends BaseComponent {
  $refs!: {
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
    confirmModal: ConfirmModalComponent;
  };
  mounted() {
    services.subscriptionProducts.getProducts(true, true);
  }
  cancel() {
    this.$refs.confirmModal.show(
      this.$t("settings.tenant.subscription.confirmCancel")
    );
  }
  confirmCancel() {
    return services.subscriptionManager
      .cancelSubscription()
      .then(response => {
        this.$refs.successModal.show(
          this.$t("settings.tenant.subscription.canceled")
        );
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  get products(): TenantProductDto[] | undefined {
    return (
      (this.$store.state.tenant?.subscription
        ?.myProducts as TenantProductDto[]) ?? []
    );
  }
  get subscription(): SubscriptionPriceDto | undefined {
    const mySubscriptions = this.products?.filter(
      f => f.subscriptionPrice.billingPeriod !== SubscriptionBillingPeriod.ONCE
    );
    if (mySubscriptions && mySubscriptions.length > 0) {
      return mySubscriptions[0].subscriptionPrice;
    } else {
      return undefined;
    }
  }
}
