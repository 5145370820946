



















































































































































































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import { Card, createToken } from "vue-stripe-elements-plus";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import NewModal from "@/components/shared/modals/NewModal.vue";
import BillingPeriodToggle from "../../components/marketing/toggles/BillingPeriodToggle.vue";
import Plans from "@/components/marketing/Plans.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import Stripe from "stripe";
import { SubscriptionCouponDto } from "../../application/dtos/master/subscriptions/SubscriptionCouponDto";
import { UserLoginType } from "../../application/enums/master/users/UserLoginType";
import { UserLoggedResponse } from "../../application/contracts/master/users/UserLoggedResponse";
import { SubscriptionBillingPeriod } from "../../application/enums/master/subscriptions/SubscriptionBillingPeriod";
import { SubscriptionProductDto } from "../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { UserRegisterRequest } from "../../application/contracts/master/users/UserRegisterRequest";
import LoadingButtonComponent from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import ModalComponent from "@/components/shared/modals/Modal.vue";
import Warning from "@/components/shared/banners/Warning.vue";
import services from "@/services";

@Component({
  components: {
    BillingPeriodToggle,
    ErrorModal,
    NewModal,
    Card,
    Plans,
    MarketingLogo,
    LoadingButton,
    Warning
  },
  directives: {
    GoogleSignInButton
  }
})
export default class RegisterComponent extends BaseComponent {
  $refs!: {
    loadingButton: LoadingButtonComponent;
    errorModal: ErrorModalComponent;
    cardModal: ModalComponent;
  };
  public requireName: boolean = true;
  public requirePhone: boolean = true;
  public requirePassword: boolean = false;
  public droppedDown: boolean = false;
  public googleClientId: any = "";
  public user = {} as UserRegisterRequest;
  private email: string = "";
  // private verifyToken: string = "";
  private stripeKey: string = "";
  private stripeOptions: any = {};
  private cardCompleted: boolean = false;
  private registered: boolean = false;
  private haveCoupon: boolean = false;
  private couponCode: string = "";
  private couponDescription: string = "";
  private coupon: SubscriptionCouponDto | null = null;

  created() {
    if (this.$route.query.e) {
      this.user.email = this.$route.query.e.toString();
    }
    if (this.products.length === 0) {
      services.subscriptionProducts.getProducts(false, true);
    }
    if (!this.selectedProduct) {
      this.$store.commit("pricing/select", {
        product: this.products[0],
        billingPeriod: this.$store.state.pricing.billingPeriod
      });
    }
    this.googleClientId =
      process.env.VUE_APP_GOOGLE_CLIENT_ID_OAUTH2?.toString() ?? "";
    this.stripeKey =
      process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY?.toString() ?? "";
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: this.$i18n.locale
      }
    };
  }
  private mounted() {
    if (this.$route.params.coupon) {
      this.haveCoupon = true;
      this.couponCode = this.$route.params.coupon;
      this.searchCoupon(false);
    } else if (this.$route.query.coupon) {
      this.haveCoupon = true;
      this.couponCode = this.$route.query.coupon.toString();
      this.searchCoupon(false);
    }
  }
  private searchCoupon(showError) {
    if (!this.couponCode) {
      return;
    }
    services.subscriptionManager
      .getCoupon(this.couponCode, this.selectedPrice.currency)
      .then((response: SubscriptionCouponDto) => {
        this.coupon = response;
        if (this.coupon && this.coupon.name) {
          this.couponDescription = this.coupon.name.toString();
        }
      })
      .catch(error => {
        this.couponDescription = this.$t(
          "account.register.invalidCoupon"
        ).toString();
        if (showError) {
          this.$refs.errorModal.show(this.$t(error));
        }
      });
  }
  // private OnGoogleAuthSuccess(idToken) {
  //   axios
  //     .get("https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" + idToken)
  //     .then((response) => {
  //       this.user.firstName = response.data.given_name;
  //       this.user.lastName = response.data.family_name;
  //       this.user.email = response.data.email;
  //       if (!this.user.organization || this.user.organization.trim() === "") {
  //         this.$refs.errorModal.show(this.$t("account.register.setTenantName"));
  //       } else {
  //         this.tryRegister(UserLoginType.Gmail);
  //       }
  //     })
  //     .catch((error) => {
  //       this.$refs.errorModal.show(error);
  //     });
  // }
  private OnGoogleAuthFail(error) {
    this.$refs.errorModal.show(error);
  }
  private tryRegisterWithPassword() {
    this.tryRegister(UserLoginType.PASSWORD);
  }
  private tryRegister(loginType: UserLoginType = UserLoginType.PASSWORD) {
    this.email = this.user.email;
    this.user.loginType = loginType;
    if (!this.selectedPrice) {
      this.$refs.errorModal.show("Selecciona un plan");
      return;
    }
    if (this.selectedPrice.trialDays === 0 && this.selectedPrice.price > 0) {
      this.$refs.cardModal.show(
        this.$t("account.register.providePaymentDetails"),
        this.$t("shared.cancel")
      );
    } else {
      this.register();
    }
  }
  private pay() {
    createToken()
      .then(data => {
        if (data.error) {
          this.$refs.errorModal.show(data.error.message);
        } else {
          this.register(data.token.id);
        }
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.$refs.cardModal.close();
      });
  }
  private register(cardToken: string = "") {
    if (!this.selectedPrice) {
      this.$refs.errorModal.show(
        this.$t("account.register.errors.priceNotSelected")
      );
      return;
    } else if (this.selectedPrice && !this.selectedPrice.id) {
      this.$refs.errorModal.show(
        this.$t("account.register.errors.priceNotInDatabase")
      );
      return;
    }
    this.user.selectedSubscription = {
      subscriptionPriceId: this.selectedPrice.id ?? "",
      subscriptionCardToken: cardToken,
      subscriptionCoupon: this.couponCode
    };
    this.$refs.loadingButton.start();
    services.authentication
      .register(this.user)
      .then((response: UserLoggedResponse) => {
        this.registered = true;
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  // private changeProduct(product) : void {
  //   this.closeDropdown();
  //   this.$store.commit("pricing/select", product);
  // }
  private closeDropdown() {
    this.droppedDown = false;
  }
  private selectedPriceIsOneTimePrice(): boolean {
    return (
      !this.selectedPrice ||
      this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE
    );
  }
  get planSupportsPhone() {
    return this.selectedProduct && this.selectedProduct.tier >= 3;
  }
  get products(): SubscriptionProductDto[] {
    return this.$store.state.pricing.products;
  }
  // get verifyURL(): string {
  //   if (this.verifyToken && this.verifyToken !== "") {
  //     return "/cuenta/verificar?e=" + this.email + "&t=" + this.verifyToken;
  //   }
  //   return "";
  // }
  get getButtonText(): string {
    if (!this.selectedPrice) {
      return "";
    }
    return (
      (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE
        ? this.$t("marketing.pricing.pay")
        : this.$t("marketing.pricing.subscribe")) +
      " " +
      this.priceDescription
    );
  }
  get selectedPrice(): SubscriptionPriceDto {
    return (
      this.selectedProduct.prices.find(
        f =>
          f.billingPeriod === this.$store.state.pricing.billingPeriod &&
          f.currency === this.$store.state.pricing.currency &&
          f.mode === this.$store.state.pricing.mode
      ) ??
      this.selectedProduct?.prices.filter(
        f =>
          f.currency === this.$store.state.pricing.currency &&
          f.mode === this.$store.state.pricing.mode
      )[0]
    );
  }
  get billingPeriod(): string {
    if (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE) {
      return this.$t("marketing.pricing.once").toString();
    } else {
      return (
        "/ " +
        this.$t(
          "marketing.pricing." +
            SubscriptionBillingPeriod[this.selectedPrice.billingPeriod] +
            "Short"
        )
      );
    }
  }
  get discountedPrice(): number {
    let price = this.selectedPrice.price;
    let couponApplied = "";
    if (this.coupon) {
      if (
        this.coupon.amountOff &&
        this.selectedPrice.currency === this.coupon.currency &&
        this.selectedPrice.mode === this.$store.state.pricing.mode
      ) {
        couponApplied =
          " -$" +
          this.coupon.amountOff / 100 +
          " " +
          this.coupon.currency.toUpperCase();
        price = price - this.coupon.amountOff / 100;
      } else if (this.coupon.percentOff) {
        couponApplied = " -" + this.coupon.percentOff + "%";
        price = Number(
          (price * ((100 - this.coupon.percentOff) / 100)).toFixed(2)
        );
      }
    }
    return price;
  }

  get priceDescription(): string {
    return (
      "$" +
      this.discountedPrice +
      " " +
      this.selectedPrice.currency +
      " " +
      this.billingPeriod
    );
  }
  get selectedProduct(): SubscriptionProductDto {
    return this.$store.state.pricing.selectedProduct;
  }
  get isDarkMode() {
    return this.$store.state.theme.marketingTheme === "theme-bg-dark";
  }
}
