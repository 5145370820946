

















































































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { ProjectDto } from "../../../application/dtos/app/projects/ProjectDto";
import { Prop } from "vue-property-decorator";
import * as projectHelpers from "@/application/modules/projects/ProjectHelpers";
import { ProjectStatus } from "@/application/enums/app/projects/ProjectStatus";
import { Colors } from "@/application/enums/app/common/Colors";
import { ProjectMemberDto } from "@/application/dtos/app/projects/ProjectMemberDto";
import { Period } from "@/application/enums/app/common/Period";
import { SummaryCountTotalResponse } from "@/application/contracts/app/summaries/SummaryCountTotalResponse";
import { mapGetters } from "vuex";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { Role } from "@/application/enums/app/common/Role";
import DropdownOptions from "@/components/shared/dropdowns/DropdownOptions.vue";
import ColorSelect from "@/components/shared/selectors/ColorSelect.vue";
import UserMultiselect from "@/components/shared/selectors/UserMultiselect.vue";
import BadgeCheck from "@/components/app/badges/BadgeCheck.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { SummaryCountTotalType } from "@/application/enums/app/transactions/SummaryCountTotalType";
import services from "@/services";

@Component({
  components: {
    DropdownOptions,
    ColorSelect,
    UserMultiselect,
    BadgeCheck,
    ConfirmModal
  },
  computed: {
    ...mapGetters("account", {
      user: "user"
    }),
    ...mapGetters("tenant", {
      isOwnerOrAdmin: "isOwnerOrAdmin"
    })
  }
})
export default class ProjectItem extends BaseComponent {
  $refs!: {
    options: DropdownOptions;
    addModal: ConfirmModalComponent;
    removeModal: ConfirmModalComponent;
  };
  @Prop({ default: "" }) id!: string;
  @Prop({ default: Colors.UNDEFINED }) color!: Colors;
  @Prop({ default: "" }) name!: string;
  @Prop() status!: ProjectStatus;
  @Prop({ default: [] }) members!: ProjectMemberDto[];
  @Prop({ default: "" }) createdByUserId!: string;
  @Prop({ default: false }) default!: boolean;
  @Prop({ default: 0 }) budget!: number;
  @Prop({ default: Period.ALL }) initialPeriod!: Period;
  private period: Period = Period.ALL;
  private projectStatus: ProjectStatus = ProjectStatus.ACTIVE;
  private currentProjectColor: Colors = Colors.UNDEFINED;
  private user!: UserDto;
  private loadingSummary: boolean = false;
  private userId: string = "";
  private isOwnerOrAdmin!: boolean;
  private summary: SummaryCountTotalResponse = {
    type: SummaryCountTotalType.PROJECT,
    name: "",
    description: "",
    ticketsTotal: 0,
    ticketsCount: 0,
    expenseTotal: 0,
    expenseCount: 0,
    incomeTotal: 0,
    incomeCount: 0,
    incomePercentageTotal: 0,
    incomePercentageCount: 0,
    expensePercentageTotal: 0,
    expensePercentageCount: 0
  };
  private arrStatus = [
    {
      name: this.$t("app.projects.status.ACTIVE"),
      value: 0,
      description: this.$t("app.projects.statusPlural.ACTIVE"),
      class: "bg-teal-300 border border-teal-500",
      action: this.$t("app.projects.actions.activate")
    },
    {
      name: this.$t("app.projects.status.PENDING"),
      value: 1,
      description: this.$t("app.projects.statusPlural.PENDING"),
      class: "bg-yellow-300 border border-yellow-500",
      action: this.$t("app.projects.actions.pending")
    },
    {
      name: this.$t("app.projects.status.ARCHIVED"),
      value: 2,
      description: this.$t("app.projects.statusPlural.ARCHIVED"),
      class: "bg-gray-300 border border-gray-500",
      action: this.$t("app.projects.actions.activate")
    }
  ];
  mounted() {
    this.members = this.members;
    this.projectStatus = this.status;
    this.currentProjectColor = this.color;
    this.cargarResumen(this.initialPeriod);
  }
  cargarResumen(period: Period) {
    this.period = period;
    this.loadingSummary = true;
    services.dashboard
      .getSummaryCountTotal(
        SummaryCountTotalType.PROJECT,
        { period, withDetails: false },
        this.id
      )
      .then((summary: SummaryCountTotalResponse[]) => {
        if (summary && summary.length > 0) {
          this.summary = summary[0];
        }
      })
      .finally(() => {
        this.loadingSummary = false;
      });
  }
  selectedColor(value) {
    this.changeColor(value);
  }
  changeColor(color: Colors) {
    this.currentProjectColor = color;
    services.projects.updateStatusAndColor(this.id, this.projectStatus, color);
  }
  closeOptions() {
    this.$refs.options.close();
  }
  cambiarEstatus(status: ProjectStatus) {
    this.projectStatus = status;
    this.$emit("updatedEstatus", this.id, status);
    services.projects.updateStatusAndColor(
      this.id,
      status,
      this.currentProjectColor
    );
  }
  projectInitials(): string {
    if (!this.name) {
      return "";
    }
    const words = this.name.split(" ");
    const initials: string[] = [];
    words.forEach(word => {
      const cleanWord = word.replace(/\W/g, " ").trim();
      if (cleanWord !== "" && initials.length < 2) {
        initials.push(cleanWord[0].toUpperCase());
      }
    });
    if (initials.length >= 2) {
      return initials.join("");
    } else if (this.name.length > 1) {
      return this.name.substring(0, 2).toUpperCase();
    } else {
      return this.name.substring(0, 1).toUpperCase();
    }
  }
  yesAdd() {
    services.projects
      .addMember(this.id, {
        userId: this.userId,
        proyectoId: this.id,
        rol: Role.MEMBER
      })
      .then((response: ProjectMemberDto) => {
        this.members.push(response);
      });
  }
  yesRemove() {
    const removeUserId = this.userId;
    const usuario = this.members.find(f => f.userId === removeUserId);
    if (usuario) {
      services.projects.removeMember(usuario.id).then(() => {
        this.members = this.members.filter(f => f.userId !== removeUserId);
      });
    }
  }
  addedUser(
    userId: string,
    email: string,
    firstName: string,
    lastName: string
  ) {
    this.userId = userId;
    let userAdded = firstName;
    if (!userAdded || userAdded.toString().trim() === "") {
      userAdded = email;
    }
    this.$refs.addModal.show(
      `¿Agregar a ${userAdded} a ${this.name}?`,
      "Agregar",
      "No",
      "Como miembro, no podrá editar el proyecto, solamente subir tickets y facturas."
    );
  }
  removedUser(
    userId: string,
    email: string,
    firstName: string,
    lastName: string
  ) {
    this.userId = userId;
    let userRemoved = firstName;
    if (!userRemoved || userRemoved.toString().trim() === "") {
      userRemoved = email;
    }
    this.$refs.removeModal.show(
      `¿Remover de ${userRemoved} de ${this.name}?`,
      "Agregar",
      "No",
      "Ya no podrá subir tickets ni facturas al proyecto."
    );
  }
  //   updatedMembers(selected: string[]){
  //       this.members.forEach(usuario => {
  //           if(usuario.rol !== Rol.Administrador){
  //               if(selected.includes(f=>f.))
  //           }
  //       });
  //   }

  get isProyectOwnerOrAdmin() {
    if (this.isOwnerOrAdmin) {
      return true;
    }
    if (this.createdByUserId === this.user.id) {
      return true;
    }
    const usuario = this.members.find(f => f.userId === this.user.id);
    return usuario && usuario.role === Role.ADMINISTRATOR;
  }
  get isProyectOwnerOrAdmins() {
    const ids: string[] = [];
    ids.push(this.createdByUserId);
    const admins = this.members.filter(f => f.role === Role.ADMINISTRATOR);
    admins.forEach(element => {
      ids.push(element.userId);
    });
    return ids;
  }
  get idsUsuarios(): string[] {
    return this.members.map(f => f.userId);
  }
  get remaining() {
    return this.budget - this.total;
  }
  get total() {
    if (this.summary) {
      return this.summary.ticketsTotal + this.summary.expenseTotal;
    }
    return 0;
  }
  get getProjectColor() {
    return projectHelpers.getProjectColor(
      this.currentProjectColor,
      600,
      100,
      300
    );
  }
}
