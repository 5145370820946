












































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Selector from "@/components/shared/forms/Selector.vue";
import { mapGetters } from "vuex";
import ColorSwitcher from "./selectors/ColorSwitcher.vue";
import DarkModeToggle from "./toggles/DarkModeToggle.vue";
import FlagSelector from "./selectors/FlagSelector.vue";

@Component({
  components: {
    Selector,
    DarkModeToggle,
    ColorSwitcher,
    FlagSelector
  },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor"
    })
  }
})
export default class HeaderComponent extends Vue {
  droppedDown: boolean = false;
  currentMarketingTheme: string = "";
  currentMarketingColor: string = "";
  links: any[] = [
    { name: "product", title: this.$t("marketing.navbar.product") },
    { name: "prices", title: this.$t("marketing.navbar.pricing") },
    { name: "contact", title: this.$t("marketing.navbar.contact") },
    // { name: "blog", title: this.$t("marketing.navbar.blog") }
  ];
  mounted() {
    this.currentMarketingTheme = this.$store.state.theme.marketingTheme;
    this.currentMarketingColor = this.$store.state.theme.marketingColor;
  }
  isCurrent(pathName: string): boolean {
    return (
      this.$route.name?.includes(pathName) ||
      this.$route.path.includes(pathName)
    );
  }
  get headerColor(): string {
    const color =
      this.$store.state.theme.marketingTheme === "theme-bg-dark"
        ? "bg-secondary"
        : "bg-secondary";
    return color;
  }
  get headerColorMobile() {
    const color =
      this.$store.state.theme.marketingTheme === "theme-bg-dark"
        ? "bg-secondary"
        : "bg-primary";
    return color;
  }
  get isDarkMode() {
    return this.$store.state.theme.marketingTheme === "theme-bg-dark";
  }
}
