














































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import { mapGetters } from "vuex";
import ProgressBar from "@/components/shared/progress/ProgressBar.vue";
import { PeriodRequest } from "@/application/contracts/app/shared/PeriodRequest";
import { Period } from "@/application/enums/app/common/Period";
import Loader from "../../../components/shared/progress/Loader.vue";
import GeneralSummary from "@/components/app/widgets/GeneralSummary.vue";
import DashboardQuicklinks from "@/components/app/widgets/DashboardQuicklinks.vue";
import SummaryFromType from "@/components/app/widgets/SummaryFromType.vue";
import ActiveProjects from "../../../components/app/widgets/projects/ActiveProjects.vue";
import WorkspacesWidget from "../../../components/app/widgets/workspaces/WorkspacesWidget.vue";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    ProgressBar,
    Loader,
    GeneralSummary,
    DashboardQuicklinks,
    SummaryFromType,
    ActiveProjects,
    WorkspacesWidget
  },
  computed: {
    ...mapGetters("account", {
      firstName: "firstName",
      email: "email",
      phone: "phone",
      avatarText: "avatarText",
      avatar: "avatar",
      isAdmin: "isAdmin"
    }),
    ...mapGetters("tenant", {
      isOwnerOrAdmin: "isOwnerOrAdmin"
    })
  }
})
export default class Dashboard extends BaseComponent {
  $refs!: {
    general: GeneralSummary;
    members: SummaryFromType;
    workspaces: SummaryFromType;
    projects: SummaryFromType;
    categories: SummaryFromType;
    suppliers: SummaryFromType;
    activeProjects: ActiveProjects;
  };
  private isOwnerOrAdmin!: boolean;
  private firstName!: string;
  private phone!: string;
  private email!: string;
  private avatar!: string;
  private avatarText!: string;
  mounted() {
    this.load();
  }
  load() {
    this.$refs.general.reload({
      period: Period.LAST_30_DAYS,
      withDetails: true
    });
  }
  reloaded(period: PeriodRequest) {
    if (this.$refs.members) {
      this.$refs.members.reload(period);
    }
    if (this.$refs.projects) {
      this.$refs.projects.reload(period);
    }
    if (this.$refs.workspaces) {
      this.$refs.workspaces.reload(period);
    }
    if (this.$refs.categories) {
      this.$refs.categories.reload(period);
    }
    if (this.$refs.suppliers) {
      this.$refs.suppliers.reload(period);
    }
    if (this.$refs.activeProjects) {
      this.$refs.activeProjects.reloadPeriod(period.period);
    }
  }
}
