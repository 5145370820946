
















































































import Vue from "vue";
import Component from "vue-class-component";
import B2bSaleDetails from "@/components/app/b2b/billing/B2bSaleDetails.vue";

@Component({
  components: {
    B2bSaleDetails
  }
})
export default class SaleFullScreenDetails extends Vue {
  private workspaceId: string = "";
  private taxId: string = "";
  private folio: string = "";
  created() {
    this.workspaceId = this.$route.params.workspaceId;
    this.taxId = this.$route.params.taxId;
    this.folio = this.$route.params.folio;
  }
}
