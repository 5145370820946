






























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({})
export default class DropImage extends Vue {
  @Prop({ default: 0, type: Number })
  index!: number;
  @Prop({ default: "", type: String })
  image!: string;

  public isDragging: boolean = false;
  public imageSource: string = "";
  mounted() {
    if (this.image && this.image !== "") {
      this.imageSource = this.image;
    }
  }
  dragOver() {
    this.isDragging = true;
  }
  dragLeave() {
    this.isDragging = false;
  }
  async drop(e) {
    const self = this;
    const files = [...e.dataTransfer.files];
    const images = files.filter(file => file.type.indexOf("image/") >= 0);
    images.forEach(file => {
      self.getBase64(file).then((response: any) => {
        self.imageSource = response;
        self.$emit("dropped", this.imageSource, this.index);
      });
    });
    self.isDragging = false;
  }
  remove() {
    this.image = "";
  }
  requestUploadFile() {
    const src = this.$el.querySelector("#uploadmyfile");
    this.drop({ dataTransfer: src });
  }
  getBase64(file) {
    const reader = new FileReader();
    return new Promise(resolve => {
      reader.onload = ev => {
        resolve(ev?.target?.result);
      };
      reader.readAsDataURL(file);
    });
  }
  get getClasses() {
    return this.isDragging
      ? "bg-theme-200 border-2 border-dashed border-theme-800"
      : "";
  }
  get getGridCols() {
    return "grid-cols-1";
  }
}
