

















































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { CfdiDto } from "@/application/dtos/app/cfdis/CfdiDto";
import TicketItemDetails from "./TicketItemDetails.vue";
import services from "@/services";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import BadgeExpense from "@/components/app/badges/BadgeExpense.vue";
import BadgeIncome from "@/components/app/badges/BadgeIncome.vue";
import BadgeTicket from "@/components/app/badges/BadgeTicket.vue";
import { SupplierBranchDto } from "@/application/dtos/app/contacts/SupplierBranchDto";
import { CustomerBranchDto } from "@/application/dtos/app/contacts/CustomerBranchDto";
import { ProjectDto } from "@/application/dtos/app/projects/ProjectDto";
import { TransactionDetailDto } from "@/application/dtos/app/transactions/TransactionDetailDto";
import { TransactionType } from "@/application/enums/app/transactions/TransactionType";
import { TicketDto } from "@/application/dtos/app/transactions/TicketDto";
import * as projectHelpers from "@/application/modules/projects/ProjectHelpers";

@Component({
  components: {
    TicketItemDetails,
    NewErrorModal,
    BadgeExpense,
    BadgeIncome,
    BadgeTicket
  }
})
export default class TransactionTableItem extends Vue {
  $refs!: {
    errorModal: NewErrorModal;
  };
  @Prop({ default: false }) admin!: boolean;
  @Prop() id!: string;
  @Prop() createdAt!: Date;
  @Prop() createdByUser!: UserDto;
  @Prop() ticketId!: string;
  @Prop() ticket!: TicketDto;
  @Prop() type!: TransactionType;
  @Prop() currency!: string;
  @Prop() date!: Date;
  @Prop() category!: string;
  @Prop() subtotal!: number;
  @Prop() discount!: number;
  @Prop() taxes!: number;
  @Prop() total!: number;
  @Prop() cfdi!: CfdiDto;
  @Prop() details!: TransactionDetailDto[];
  @Prop({ default: false }) allowShowDetails!: boolean;
  @Prop() workspace!: WorkspaceDto;
  @Prop() customerBranch!: CustomerBranchDto;
  @Prop() supplierBranch!: SupplierBranchDto;
  @Prop() categories!: string[];
  @Prop() createdByUserName!: string;
  @Prop() project!: ProjectDto;
  @Prop() tenant!: TenantDto;

  private showDetails: boolean = false;

  // mounted() {
  //   console.log("transaction-item: " + this.id);
  // }
  avatarText() {
    if (this.getCreatedByUser) {
      if (this.getCreatedByUser.firstName && this.getCreatedByUser.lastName) {
        return (
          this.getCreatedByUser.firstName[0] + this.getCreatedByUser.lastName[0]
        );
      } else if (this.getCreatedByUser.firstName) {
        this.getCreatedByUser.firstName.substring(0, 1);
      } else if (this.getCreatedByUser.firstName) {
        this.getCreatedByUser.email.substring(0, 1);
      }
    }
    return "?";
  }
  projectColor() {
    return projectHelpers.getProjectColor(this.project?.color);
  }
  typeDeTransaction() {
    return TransactionType[this.type];
  }
  downloadCfdi(type: string) {
    if (!this.cfdi || !this.cfdi.id) {
      this.$refs.errorModal.show("No tiene CFDi");
    } else {
      services.cfdis.download(this.cfdi.id, type).then((response: any) => {
        const contentType: string = response.headers["content-type"];

        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", this.cfdi.id);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }
  }
  get getCreatedByUser() {
    if (this.ticket) {
      return this.ticket.createdByUser;
    } else {
      return this.createdByUser;
    }
  }
}
