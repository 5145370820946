





















































































































































































































































































































































































































































































































































































































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import DropdownSimple from "@/components/shared/dropdowns/DropdownSimple.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { SaleReportFormat } from "@/application/enums/app/erp/sales/SaleReportFormat";
import { filters } from "@/plugins/filters";
import { Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import NewPayment from "@/views/app/erp/contacts/customers/forms/NewPayment.vue";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { CfdiStatus } from "@/application/enums/app/erp/sales/CfdiStatus";
import { PaymentForm } from "@/application/enums/app/erp/contacts/customers/PaymentForm";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    DropdownSimple,
    NewPayment
  },
  computed: {
    ...mapGetters("tenant", {
      isOwnerOrAdmin: "isOwnerOrAdmin"
    })
  }
})
export default class InvoiceDetails extends BaseComponent {
  @Prop({ default: "" }) type!: string;
  @Prop({ default: 0 }) folio!: number;
  $refs!: {
    errorModal: ErrorModalComponent;
    successModalCreated: SuccessModalComponent;
    successModalSent: SuccessModalComponent;
    successModalPaid: SuccessModalComponent;
    successModalDeletedPayments: SuccessModalComponent;
    dropdownOptions: DropdownSimple;
    confirmDeletePaymentsModal: ConfirmModalComponent;
    confirmDeleteModal: ConfirmModalComponent;
    confirmCreateInvoice: ConfirmModalComponent;
    confirmSend: ConfirmModalComponent;
    modalNewPayment: NewPayment;
  };
  private isOwnerOrAdmin!: boolean;
  private sale: SaleDto | null = null;
  private createdInvoice: SaleDto | null = null;
  private createdPaymentsFolios: number[] = [];
  mounted() {
    this.reload();
  }
  reload() {
    this.sale = null;
    this.loading = true;
    ErpService.sales.sales
      .getSale(this.type, this.folio)
      .then(response => {
        this.sale = response;
      })
      .catch(error => {
        // this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deletePayments() {
    this.$refs.dropdownOptions.close();
    this.$refs.confirmDeletePaymentsModal.show(
      `¿Eliminar pagos?`,
      "Eliminar",
      "Cancelar",
      "ADVERTENCIA: Esta acción es irreversible."
    );
  }
  deleteSale() {
    this.$refs.dropdownOptions.close();
    this.$refs.confirmDeleteModal.show(
      `¿Eliminar factura?`,
      "Eliminar",
      "Cancelar",
      "ADVERTENCIA: Esta acción es irreversible."
    );
  }
  confirmDeletePayments() {
    if (!this.sale) {
      return;
    }
    const paymentFolio = 0;
    this.loading = true;
    ErpService.sales.sales
      .deletePayments(this.sale.type, this.sale.folio, paymentFolio)
      .then(() => {
        this.$refs.successModalDeletedPayments.show("Pagos eliminados");
        this.$emit("payment-deleted");
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  confirmDelete() {
    this.loading = true;
    ErpService.sales.sales
      .deleteSale(this.sale?.type ?? "", this.sale?.folio ?? 0)
      .then(() => {
        this.$emit("deleted", this.sale?.id, this.sale?.type, this.sale?.folio);
        // this.$router.push({ name: "erp.sales.invoices" });
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  download() {
    if (!this.sale) {
      this.$refs.errorModal.show("Venta inválida");
      return;
    }
    const format = this.getDefaultFormat(this.sale);
    this.downloadFormat(this.sale.type, this.sale.folio, format);
    if (format === SaleReportFormat.Xml) {
      this.downloadFormat(
        this.sale.type,
        this.sale.folio,
        SaleReportFormat.Cfdi
      );
    }
  }
  downloadQuoteFormat() {
    if (!this.sale) {
      this.$refs.errorModal.show("Venta inválida");
      return;
    }
    this.downloadFormat(
      this.sale.type,
      this.sale.folio,
      SaleReportFormat.Quote
    );
  }
  getFormatName(format: SaleReportFormat) {
    if (format === SaleReportFormat.Quote) {
      return "Cotización ";
    } else if (format === SaleReportFormat.Cfdi) {
      let prefix = "";
      if (this.sale?.cfdi?.status === CfdiStatus.Issued) {
        prefix = "CFDi ";
      }
      if (this.sale?.type === "FR") {
        return prefix + "Venta de Crédito ";
      }
      return prefix + "Venta de Contado ";
    } else if (format === SaleReportFormat.SummaryByProduct) {
      return "Resumen por Artículo ";
    } else if (format === SaleReportFormat.SummaryByCategory) {
      return "Resumen por Clasificación ";
    } else if (format === SaleReportFormat.Recibo) {
      return "Recibo ";
    } else if (format === SaleReportFormat.Xml) {
      return "XML ";
    }
    return "";
  }
  downloadFormat(type, folio, format) {
    ErpService.sales.sales
      .download({
        sale: {
          type,
          folio
        },
        format
      })
      .then((response: any) => {
        const contentType: string = response.headers["content-type"];
        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute(
          "download",
          this.getFormatName(format) + this.sale?.type + "-" + this.sale?.folio
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      });
  }
  getDefaultFormat(sale: SaleDto): SaleReportFormat {
    if (sale.type === "PR") {
      return SaleReportFormat.Quote;
    } else {
      if (sale.cfdi) {
        if (sale.cfdi.status === CfdiStatus.Issued) {
          return SaleReportFormat.Xml;
        } else {
          return SaleReportFormat.Cfdi;
        }
      }
    }
    return SaleReportFormat.Quote;
  }
  send() {
    this.$refs.dropdownOptions.close();
    this.$refs.confirmSend.show(
      `¿Enviar ${this.saleName}?`,
      "Enviar",
      "Cancelar",
      "Se enviarán los detalles de la venta, en el formato correspondiente. Escribe los correos separados por coma:"
    );
  }
  yesSend(input: string) {
    if (!this.sale) {
      this.$refs.errorModal.show("Venta inválida");
      return;
    }
    const type = this.sale.type;
    const folio = this.sale.folio;
    const emails = input.split(",");
    // const email = prompt("Correo electrónico", store.state.account.user?.email);
    if (!emails || emails.length === 0) {
      return;
    }
    ErpService.sales.sales
      .send({
        sale: {
          type,
          folio
        },
        format: this.getDefaultFormat(this.sale),
        emails
      })
      .then(() => {
        this.$refs.successModalSent.show("Correo enviado");
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      });
  }
  pay() {
    if (!this.sale) {
      return;
    }
    this.$refs.dropdownOptions.close();
    if (this.sale?.due === 0) {
      this.$refs.errorModal.show("No tiene saldo pendiente");
    } else {
      this.$refs.modalNewPayment.add(this.sale.type, this.sale.folio);
    }
  }
  createdPayment() {
    this.$refs.modalNewPayment.close();
    this.reload();
  }
  generateInvoice() {
    if (this.sale?.type === "PR") {
      this.$refs.confirmCreateInvoice.show("¿Crear factura?");
    } else {
      this.$refs.errorModal.show("Ya está facturada");
    }
  }
  confirmCreateInvoice() {
    if (this.sale?.type === "PR") {
      const pay = this.sale.creditDays <= 1;
      let toPayDocument = 0;
      if (!pay) {
        const toPayDocumentString = window.prompt("Pagaré");
        toPayDocument = Number(toPayDocumentString);
        if (toPayDocument <= 0) {
          this.$refs.errorModal.show(
            "El pagaré no puede ser cero en una venta de crédito"
          );
          return;
        }
      }
      this.createInvoice(pay, toPayDocument);
    }
  }
  createInvoice(pay, toPayDocument) {
    if (this.sale?.folio) {
      this.loading = true;
      ErpService.sales.sales
        .createInvoice({
          folio: this.sale?.folio,
          incomeBankId: 1,
          pay,
          toPayDocument,
          receiptNumber: ""
        })
        .then((response: SaleDto) => {
          this.createdInvoice = response;
          this.$refs.successModalCreated.show(
            "Venta creada",
            `Folio ${response.type}-${response.folio}`,
            undefined,
            "Recargar"
          );
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.$refs.errorModal.show("Folio inválido: " + this.sale?.folio);
    }
  }
  confirmCreated() {
    if (this.createdInvoice) {
      this.type = this.createdInvoice.type;
      this.folio = this.createdInvoice.folio;
      this.$router.push({
        name: "erp.sales.sale",
        params: {
          type: this.createdInvoice.type,
          folio: this.createdInvoice.folio.toString()
        }
      });
      // this.reload();
    }
  }
  confirmPaid() {
    this.reload();
  }
  paymentFormDescription(paymentForm: PaymentForm) {
    switch (paymentForm) {
      case PaymentForm.Cash:
        return "Efectivo";
      case PaymentForm.Check:
        return "Cheque";
      case PaymentForm.Transfer:
        return "Transferencia";
      case PaymentForm.DebitCard:
        return "Tarjeta de Débito";
      case PaymentForm.CreditCard:
        return "Tarjeta de Crédito";
      case PaymentForm.Condonation:
        return "???";
      case PaymentForm.Compensation:
        return "???";
      case PaymentForm.Novation:
        return "???";
      case PaymentForm.Confusion:
        return "???";
      case PaymentForm.DebtRemittance:
        return "???";
      case PaymentForm.PrescriptionOrExpiration:
        return "???";
      case PaymentForm.ToTheSatisfactionOfTheCreditor:
        return "???";
      case PaymentForm.ServicesCard:
        return "???";
      case PaymentForm.ApplicationOfAdvances:
        return "???";
      case PaymentForm.PaymentIntermediary:
        return "???";
      case PaymentForm.ElectronicPurse:
        return "???";
      case PaymentForm.ElectronicMoney:
        return "???";
      case PaymentForm.Voucher:
        return "???";
      case PaymentForm.DationToPayment:
        return "???";
      case PaymentForm.SurrogacyPayment:
        return "???";
      case PaymentForm.PaymentIntermediary:
        return "???";
      case PaymentForm.ToDefine:
        return "???";
      default:
        return "???";
    }
  }
  get isQuote() {
    return this.type === "PR";
  }
  get isDue() {
    return this.sale && this.sale.due > 0;
  }
  get saleName() {
    if (this.sale) {
      if (this.sale.type === "PR") {
        return `Pedido ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "FO") {
        return `Venta de Contado ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "FR") {
        return `Venta de Crédito ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "FE") {
        return `Venta de Exportación ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "X1") {
        return `Intereses ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "X2") {
        return `Abono ${this.sale.type}-${this.sale.folio}`;
      } else {
        return `${this.sale.type}-${this.sale.folio}`;
      }
    }
    return this.type + "-" + this.folio;
  }
  get hasCfdi() {
    return this.sale && this.sale.cfdi?.type;
  }
}
