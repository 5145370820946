import { ApiService, ApiServiceType } from "../ApiService";

export class EStockService extends ApiService {
  constructor() {
    super("Ecommerce/Stock", ApiServiceType.MasterDatabase);
  }
  getAllStock(branchIds: number[]): Promise<any[]> {
    return super.getAll("GetAll?" + super.toQueryString(branchIds));
  }
  getProductStock(id: string, branchIds: number[]): Promise<any> {
    return super.get("Get?" + super.toQueryString(branchIds), id);
  }
}
