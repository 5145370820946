




import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {}
})
export default class Store extends Vue {
  private store: string = "";
  created() {
    this.store = this.$route.params.store;
  }
}
