











































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ErrorModalComponent extends Vue {
  $refs!: {
    buttonClose: HTMLButtonElement;
  };
  private title!: string;
  private closeText!: any;
  private showing: boolean = false;
  private errorDescription!: string;
  mounted() {
    this.title = this.$t("shared.error").toString();
    this.closeText = this.$t("shared.close").toString();
  }
  public show(error: any, closeText?: any) {
    if (error.toString().includes("Maximum call stack size exceeded")) {
      this.close();
      return;
    }
    if (error.length > 300 && process.env.NODE_ENV !== "production") {
      error = error.substring(0, 300);
    }
    if (
      error
        .toString()
        .trim()
        .toLowerCase() === "Network Error".toLowerCase()
    ) {
      error = this.$i18n.t("shared.networkError").toString();
    }
    if (
      error
        .toString()
        .trim()
        .toLowerCase() === "System.TimeoutException".toLowerCase()
    ) {
      error = this.$i18n.t("shared.timeout").toString();
    }

    if (closeText) {
      this.closeText = closeText;
    }
    if (error.toString().includes("<!DOCTYPE")) {
      error = this.$t("shared.timeout");
    }
    this.errorDescription = error;
    this.showing = true;

    setTimeout(() => {
      window.addEventListener("keyup", this.keyEvent);
      this.$nextTick(() => {
        this.$refs.buttonClose.focus();
      });
    }, 1000);
  }

  public close() {
    window.removeEventListener("keyup", this.keyEvent);
    this.$emit("closed");
    this.showing = false;
  }
  keyEvent(event) {
    if (event.ctrlKey && event.keyCode) {
      if (event.keyCode === 13) {
        this.close();
      }
    }
  }
}
