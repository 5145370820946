import { ApiService, ApiServiceType } from "@/services/api/ApiService";

export class AdminService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("Admin", ApiServiceType.MasterDatabase);
  }
  scanTickets() {
    return this.api.post(null, "ScanTickets");
  }
  scanTenantTickets(tenantId: string) {
    return this.api.post(null, "ScanTenantTickets/" + tenantId);
  }
}
