import { CreateCategoryRequest } from "@/application/contracts/app/erp/inventory/categories/CreateCategoryRequest";
import { UpdateCategoryRequest } from "@/application/contracts/app/erp/inventory/categories/UpdateCategoryRequest";
import { CategoryDto } from "@/application/dtos/app/erp/v3/inventory/CategoryDto";
import { ApiService, ApiServiceType } from "../../../ApiService";
import fakeCategories from "./fakeData/FakeDataCategories";

export class CategoryService extends ApiService {
  constructor() {
    super("Inventory/Category", ApiServiceType.TenantDatabase);
  }
  getAll(): Promise<CategoryDto[]> {
    // return Promise.resolve(fakeCategories);
    return super.getAll();
  }
  get(id: string): Promise<CategoryDto> {
    return super.get(id);
  }
  create(request: CreateCategoryRequest): Promise<CategoryDto> {
    return super.post(request);
  }
  update(id: string, request: UpdateCategoryRequest): Promise<CategoryDto> {
    return super.put(id, request);
  }
  delete(id: string): Promise<void> {
    return super.delete(id);
  }
}
