
























































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { TicketDto } from "@/application/dtos/app/transactions/TicketDto";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import DropImage from "@/components/app/uploads/DropImage.vue";
import DropImages from "@/components/app/uploads/DropImages.vue";
import { FileBase64 } from "@/application/shared/files/FileBase64";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import { Prop } from "vue-property-decorator";
import services from "@/services";

@Component({
  components: {
    DropImages,
    DropImage,
    TableRowSkeleton,
    NewErrorModal,
    BaseComponent
  }
})
export default class TicketFullDetails extends BaseComponent {
  @Prop({ default: false }) admin!: boolean;
  $refs!: {
    errorModal: NewErrorModal;
  };
  private ticket = {} as TicketDto;
  mounted() {
    if (!this.$route.params.id) {
      if (this.admin) {
        this.$router.push({ name: "admin.tickets" });
      } else {
        this.$router.push({ name: "app.tickets" });
      }
    }
    this.reload();
  }
  reload() {
    this.loading = true;
    if (this.admin) {
      services.tickets
        .adminGet(this.$route.params.id)
        .then((response: TicketDto) => {
          this.ticket = response;
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      services.tickets
        .getTicket(this.$route.params.id, this.admin)
        .then((response: TicketDto) => {
          this.ticket = response;
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  avatarText(item: TicketDto) {
    if (item.createdByUser?.firstName && item.createdByUser?.lastName) {
      return item.createdByUser?.firstName[0] + item.createdByUser?.lastName[0];
    } else if (item.createdByUser?.firstName) {
      return item.createdByUser?.firstName[0];
    } else {
      return "?";
    }
  }
  droppedFiles(files: FileBase64[]) {
    console.log("droppedFiles: " + files.length);
    if (files.length > 0) {
      const formData: FormData = new FormData();
      files.forEach(fileBase64 => {
        formData.append(
          fileBase64.file.name,
          fileBase64.file,
          fileBase64.file.name
        );
      });
      services.tickets
        .uploadXmlAndPdf(formData, this.ticket)
        .then((response: TicketDto) => {
          this.ticket = response;
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        });
    }
  }
  scan() {
    this.loading = true;
    services.tickets
      .adminReadTicketLines(this.ticket.id)
      .then((response: TicketDto) => {
        this.ticket = response;
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
