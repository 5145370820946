import { PeriodRequest } from "@/application/contracts/app/shared/PeriodRequest";
import { SupplierBranchDto } from "@/application/dtos/app/contacts/SupplierBranchDto";
import { ApiService, ApiServiceType } from "../../ApiService";

export class ProveedorService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("Proveedor", ApiServiceType.TenantDatabase);
  }
  adminGetAll(request: PeriodRequest): Promise<SupplierBranchDto[]> {
    return this.api.getAll(`Admin/GetAll?` + this.api.toQueryString(request));
  }
  getAllProveedores(request: PeriodRequest): Promise<SupplierBranchDto[]> {
    return this.api.getAll(`GetAll?` + this.api.toQueryString(request));
  }
  get(id: string): Promise<SupplierBranchDto> {
    return this.api.get("Get", id);
  }
  create(data: SupplierBranchDto): Promise<SupplierBranchDto> {
    return this.api.post(data);
  }
  update(id: string, data: SupplierBranchDto): Promise<SupplierBranchDto> {
    return this.api.put(id, data);
  }
  delete(id: string): Promise<any> {
    return this.api.delete(id);
  }
  adminDelete(id: string): Promise<any> {
    return this.api.delete(id, "Admin/Delete");
  }
}
