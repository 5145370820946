







































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { TicketStatus } from "../../../application/enums/app/transactions/TicketStatus";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { TransactionDto } from "@/application/dtos/app/transactions/TransactionDto";
import TicketItemDetails from "./TicketItemDetails.vue";
import BadgeTicket from "@/components/app/badges/BadgeTicket.vue";

@Component({
  components: {
    TicketItemDetails,
    BadgeTicket
  }
})
export default class TicketItem extends Vue {
  @Prop() id!: string;
  @Prop() createdSince!: string;
  @Prop() createdAt!: Date;
  @Prop() createdByUser!: UserDto;
  @Prop() description!: string;
  @Prop() error!: string;
  @Prop() number!: number;
  @Prop() status!: TicketStatus;
  @Prop() lineasEscaneadas!: string[];
  @Prop() taxId!: string;
  @Prop() legalName!: string;
  @Prop() cfdiUse!: string;
  @Prop() tenant!: TenantDto;
  @Prop() transactions!: TransactionDto[];
  @Prop() imagen!: string;
  @Prop({ default: false }) allowShowDetails!: boolean;
  private showDetails: boolean = false;
  // mounted() {
  //   console.log("item id mounted: " + this.id);
  // }
  avatarText() {
    if (this.createdByUser?.firstName && this.createdByUser?.lastName) {
      return this.createdByUser?.firstName[0] + this.createdByUser?.lastName[0];
    } else if (this.createdByUser?.firstName) {
      return this.createdByUser?.firstName[0];
    } else {
      return "?";
    }
  }
  get uso() {
    if (this.cfdiUse === "P01") {
      return "P01 - Por definir";
    } else if (this.cfdiUse === "G01") {
      return "G01 - Adquisición de mercancias";
    } else if (this.cfdiUse === "G02") {
      return "G02 - Devoluciones, descuentos o bonificaciones";
    } else if (this.cfdiUse === "G03") {
      return "G03 - Gastos en general";
    }
    return "";
  }
}
