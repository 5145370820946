






import Vue from "vue";
import { Component } from "vue-property-decorator";
import CustomersList from "@/components/app/contacts/CustomersList.vue";

@Component({
  components: {
    CustomersList
  }
})
export default class Customers extends Vue {}
