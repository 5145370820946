





















































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import { Prop, Watch } from "vue-property-decorator";
import { CustomFieldType } from "@/application/enums/app/projects/CustomFieldType";
import { filters } from "@/plugins/filters";

@Component({
  components: {}
})
export default class TableWithSearch extends BaseComponent {
  $refs!: {
    inputSearch: HTMLInputElement;
  };
  @Prop({ required: true }) columns!: any[];
  @Prop({ required: true }) items!: any[];
  @Prop({ required: false }) filterFields!: string[];
  @Prop({ default: false }) instantSearch!: boolean;
  @Prop({ default: false }) withSearch!: boolean;
  @Prop({ default: false }) withReload!: boolean;
  public searchInput: string = "";
  private searchInputTemp: string = "";
  mounted() {
    if (this.withSearch) {
      this.focusSearchInput();
    }
  }
  focusSearchInput() {
    const self = this;
    this.$nextTick(() => {
      if (self.$refs.inputSearch) {
        self.$refs.inputSearch.focus();
        self.$refs.inputSearch.select();
      }
    });
  }
  itemPropertyValues(item: any, column: any) {
    if (!column.name.includes("+")) {
      return this.itemPropertyValue(item, column.name, column.type);
    }
    const columns = column.name.split("+");
    const values = columns.map(f => this.itemPropertyValue(item, f.trim()));
    return values.join(column.separator ?? " ");
  }
  itemPropertyValue(item: any, columnName: string, type?: CustomFieldType) {
    if (!columnName.includes(".")) {
      if (type === CustomFieldType.DATE) {
        return filters.dateYMD(item[columnName]);
      } else if (type === CustomFieldType.DECIMAL) {
        return filters.decimalFormat(item[columnName]);
      } else if (type === CustomFieldType.INTEGER) {
        return filters.intFormat(item[columnName]);
      } else {
        return item[columnName];
      }
    }
    const properties = columnName.split(".");
    let currentObj: any = item[properties[0]];
    for (let index = 1; index <= properties.length; index++) {
      if (currentObj) {
        const tempObj = currentObj[properties[index]];
        if (tempObj) {
          currentObj = tempObj;
        }
      } else {
        return null;
      }
    }
    // console.log({ currentObj });
    return currentObj;
  }
  filterByValue(array: any[], value: string) {
    console.log({ array, value });
    return array.filter(o => Object.keys(o).some(k => o[k].includes(value)));
  }
  get values() {
    // return this.items;
    if (this.filterFields && this.filterFields.length > 0) {
      const filtered: any[] = [];
      this.items.forEach(item => {
        // const found = this.filterFields.find(f =>
        //   item[f]
        //     .toString()
        //     .toLowerCase()
        //     .includes(this.searchInput)
        // );
        // return found ? 1 : -1;
        let found = false;
        this.filterFields.forEach(field => {
          if (
            item[field] &&
            item[field]
              .toString()
              .toLowerCase()
              .includes(this.searchInput.toLowerCase())
          ) {
            // console.log({ field, item });
            found = true;
            return;
          }
        });
        if (found) {
          filtered.push(item);
        }
      });
      return filtered;
    } else {
      return this.filterByValue(this.items, this.searchInput);
      // return this.items.filter(item => {
      //   const found = this.columns.find(f =>
      //     item[f]?.toLowerCase().includes(this.searchInput)
      //   );
      //   return found ? 1 : -1;
      // });
    }
  }
  @Watch("searchInputTemp")
  changedSearch() {
    if (this.instantSearch) {
      this.searchInput = this.searchInputTemp;
    }
  }
}
