






































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import BarcodeReads from "@/components/app/erp/inventory/barcode/BarcodeReads.vue";
import PointOfSaleSummary from "@/components/app/erp/sales/pos/PointOfSalesSummary.vue";
import Breadcrumb from "../../shared/Breadcrumb.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import { InventoryHeader } from "@/application/dtos/app/erp/common/InventoryHeader";

@Component({
  components: {
    BarcodeReads,
    PointOfSaleSummary,
    Breadcrumb,
    NewErrorModal
  }
})
export default class PhysicalDocument extends BaseComponent {
  $refs!: {
    modalError: ErrorModalComponent;
    inputWarehouse: HTMLInputElement;
  };
  type: string = "IF";
  folio: number = 0;
  warehouse: number = 0;
  header: InventoryHeader | null = {
    type: "",
    folio: 0,
    wharehouseId: 0
  };
  created() {
    this.type = this.$route.query.type?.toString() ?? "IF";
    this.folio = Number(this.$route.query.folio ?? 0);
    this.warehouse = Number(this.$route.query.warehouse ?? 0);
    this.header = {
      type: this.type,
      wharehouseId: this.warehouse,
      folio: this.folio
    };
    // try {
    //   if (
    //     !this.$route.query ||
    //     Number(this.$route.query?.folio) !== this.folio
    //   ) {
    //     this.$router.push({
    //       path: this.$route.path,
    //       query: {
    //         type: this.type,
    //         folio: this.folio.toString(),
    //         warehouse: this.warehouse.toString()
    //       }
    //     });
    //   }
    // } catch (e) {
    //   //
    // }
  }
  mounted() {
    // @ts-ignore
    $crisp.push(["do", "chat:hide"]);
    this.$nextTick(() => {
      this.$refs.inputWarehouse.focus();
      this.$refs.inputWarehouse.select();
    });
  }
  unmounted() {
    // @ts-ignore
    // $crisp.push(["do", "chat:show"]);
  }
  accept() {
    if (!this.type) {
      this.$refs.modalError.show("Ingresa un tipo válido");
      return;
    }
    if (!this.warehouse) {
      this.$refs.modalError.show("Ingresa un almacén válido");
      return;
    }
    if (!this.folio) {
      this.$refs.modalError.show("Ingresa un folio válido");
      return;
    }
    this.header = {
      type: this.type,
      wharehouseId: this.warehouse,
      folio: this.folio
    };
    this.$router.push({
      name: "erp.inventory.physical.new",
      query: {
        type: this.type,
        warehouse: this.warehouse.toString(),
        folio: this.folio.toString()
      }
    });
  }
  get folioName() {
    if (this.folio) {
      return "IF-" + this.folio;
    }
  }
}
