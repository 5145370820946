export const accountRoutes = {
  name: "account",
  path: "/cuenta",
  alias: "/account",
  redirect: "/cuenta/entrar",
  component: require("@/views/account/Index.vue").default,
  meta: {
    requiresLoggedOut: true,
  },
  children: [
    {
      name: "account.login",
      path: "entrar",
      alias: "login",
      component: require("@/views/account/Login.vue").default,
    },
    {
      name: "account.register",
      path: "crear",
      alias: "register",
      component: require("@/views/account/Register.vue").default,
    },
    {
      name: "account.verify",
      path: "verificar",
      alias: "verify",
      component: require("@/views/account/Verify.vue").default,
    },
    {
      name: "account.reset",
      path: "restablecer",
      alias: "reset",
      component: require("@/views/account/Reset.vue").default,
    },
  ],
};
