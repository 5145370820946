





























import Vue from "vue";
import DropdownWithSearch from "@/components/shared/dropdowns/DropdownWithSearch.vue";
import Component from "vue-class-component";
import CustomerForm from "../forms/CustomerForm.vue";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";
import tinyEventBus from "@/plugins/tiny-eventbus";
import ErpService from "@/services/api/app/erp/ErpService";
import store from "@/store";

@Component({
  components: {
    DropdownWithSearch,
    CustomerForm
  }
})
export default class CustomerSelect extends Vue {
  $refs!: {
    dropdown: DropdownWithSearch;
    formCustomer: CustomerForm;
  };
  mounted() {
    console.log("customer select mounted");
    tinyEventBus().emitter.on("customer-added", (payload: any) => {
      this.$refs.dropdown.selectItem(payload);
      this.$refs.dropdown.reload();
    });
    // console.log("customers length: " + store.state.customers.customers.length);
    // if (store.state.customers.customers.length === 0) {
    //   this.reload();
    // } else {
    //   this.$refs.dropdown.setItems(store.state.customers.customers);
    // }
  }
  reload() {
    // ErpService.customers.customers
    //   .getAllCustomers({ fullDetails: false })
    //   .then(response => {
    //     this.$refs.dropdown.setItems(response);
    //   });
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("customer-added");
  }
  selected(value, item) {
    this.$emit("selected", value, item);
  }
  addCustomer() {
    tinyEventBus().emitter.emit("customers-add");
    // this.$refs.dropdown.close();
    // this.$refs.formCustomer.add();
  }
  createdCustomer(item: CustomerDto) {
    this.$refs.dropdown.selectItem(item);
    this.$refs.dropdown.reload();
  }
  // loadCustomerFull(value: number) {
  //   this.loading = true;
  //   ErpService.customers.customers
  //     .get(value.toString())
  //     .then(response => {
  //       this.$emit("selected", value, response);
  //       // if (data._Rfcs.length > 1) {
  //       //   this.$refs.taxIdSelect.open();
  //       // }
  //     })
  //     .catch(error => {
  //       this.error = this.$t(error).toString();
  //     })
  //     .finally(() => {
  //       this.loading = false;
  //     });
  // }
}
