import { CreateInvoiceFromBarcodeReadsRequest } from "@/application/contracts/app/erp/sales/CreateInvoiceFromBarcodeReadsRequest";
import { CreateInvoiceFromQuoteRequest } from "@/application/contracts/app/erp/sales/CreateInvoiceFromQuoteRequest";
import { DownloadSaleRequest } from "@/application/contracts/app/erp/sales/DownloadSaleRequest";
import { GetSalesRequest } from "@/application/contracts/app/erp/sales/GetSalesRequest";
import { PaySaleRequest } from "@/application/contracts/app/erp/sales/PaySaleRequest";
import { SendSaleRequest } from "@/application/contracts/app/erp/sales/SendSaleRequest";
import { BalanceDueDto } from "@/application/dtos/app/erp/v3/contacts/customers/BalanceDueDto";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { ApiService, ApiServiceType } from "../../../ApiService";

export class SaleService extends ApiService {
  constructor() {
    super("Sales/Sale", ApiServiceType.TenantDatabase);
  }
  getAllSales(request: GetSalesRequest): Promise<SaleDto[]> {
    // return Promise.resolve(fakeDataSales);
    return super.getAll("GetAll?" + super.toQueryString(request));
  }
  getAllOverdue(): Promise<SaleDto[]> {
    return super.getAll("GetAllOverdue");
  }
  getSale(type: string, folio: number): Promise<SaleDto> {
    return super.get(`Get/${type}/${folio}`);
  }
  getBalance(type: string, folio: number): Promise<BalanceDueDto> {
    return super.get(`GetBalance/${type}/${folio}`);
  }
  createInvoice(data: CreateInvoiceFromQuoteRequest): Promise<SaleDto> {
    return super.post(data, "CreateInvoice");
  }
  createFromBarcodeReads(data: CreateInvoiceFromBarcodeReadsRequest): Promise<SaleDto> {
    return super.post(data, "CreateFromBarcodeReads");
  }
  pay(data: PaySaleRequest): Promise<BalanceDueDto> {
    return super.post(data, "Pay");
  }
  download(request: DownloadSaleRequest): Promise<any> {
    return super.download(request, `Download`);
  }
  send(request: SendSaleRequest): Promise<any> {
    return super.post(request, `Send`);
  }
  deletePayments(type: string, folio: number, paymentFolio: number = 0): Promise<SaleDto> {
    return super.delete("", `DeletePayments/${type}/${folio}?paymentFolio=` + paymentFolio);
  }
  deleteSale(type: string, folio: number): Promise<SaleDto> {
    return super.delete("", `Delete/${type}/${folio}`);
  }
}
