


































































































































import Vue from "vue";
import Component from "vue-class-component";
import { SummaryResponse } from "@/application/contracts/app/summaries/SummaryResponse";
import { Period } from "@/application/enums/app/common/Period";
import { PeriodRequest } from "@/application/contracts/app/shared/PeriodRequest";
import services from "@/services";
import { TicketStatus } from "../../../application/enums/app/transactions/TicketStatus";
import PeriodSelect from "@/components/shared/selectors/PeriodSelect.vue";
import { WhoInvoices } from "@/application/enums/app/cfdis/WhoInvoices";
import Tickets from "../tickets/Tickets.vue";

@Component({
  components: {
    PeriodSelect
  }
})
export default class GeneralSummary extends Vue {
  private period: PeriodRequest = {
    period: Period.LAST_30_DAYS,
    withDetails: true
  };
  private summary: SummaryResponse = {
    whoInvoices: WhoInvoices.ADMIN,
    monthlyTickets: 0,
    ticketsRemaining: 0,
    ticketsByStatus: [],
    incomeCount: 0,
    expenseCount: 0,
    incomeTotal: 0,
    expenseTotal: 0
  };
  private loadingSummary: boolean = false;
  reload(period?: PeriodRequest) {
    this.summary = {
      whoInvoices: WhoInvoices.ADMIN,
      monthlyTickets: 0,
      ticketsRemaining: 0,
      ticketsByStatus: [],
      incomeCount: 0,
      expenseCount: 0,
      incomeTotal: 0,
      expenseTotal: 0
    };
    if (period) {
      this.period = period;
    }
    this.loadingSummary = true;
    services.dashboard
      .getSummary(this.period)
      .then((response: SummaryResponse) => {
        this.summary = response;
      })
      .catch(() => {
        // ignore
      })
      .finally(() => {
        this.loadingSummary = false;
      });
    this.$emit("reloaded", this.period);
  }
  calcularPorcentaje(valor, total) {
    return (valor * 100) / total;
  }
  selectPeriodo(period: Period) {
    this.reload({
      period,
      withDetails: false
    });
  }

  get ticketsTotal() {
    if (!this.summary) {
      return 0;
    } else {
      let total: number = 0;
      this.summary.ticketsByStatus.forEach(currentStatus => {
        if (
          currentStatus.status === TicketStatus.PENDING ||
          currentStatus.status === TicketStatus.REQUESTED
        ) {
          total += currentStatus.total;
        }
      });
      return total;
    }
  }
  get ticketsCount() {
    if (!this.summary) {
      return "";
    } else {
      const statusArr: string[] = [];
      let pendingCount = 0;
      this.summary.ticketsByStatus
        .filter(f => f.status !== TicketStatus.INVOICED)
        .forEach(status => {
          if (
            status.status === TicketStatus.PENDING ||
            status.status === TicketStatus.REQUESTED
          ) {
            pendingCount += status.count;
          }
          if (status.count > 0) {
            statusArr.push(
              `${status.count} (${this.$t(
                "app.tickets.status." + TicketStatus[status.status]
              )
                .toString()
                .toLowerCase()})`
            );
          }
        });
      return (
        pendingCount +
        " " +
        this.$t(
          "app.tickets.statusPlural." + TicketStatus[TicketStatus.PENDING]
        )
          .toString()
          .toLowerCase()
      );
    }
  }
}
