import { QuoteService } from "./QuoteService";
import { SaleService } from "./SaleService";

export class SalesServices {
  sales: SaleService;
  quotes: QuoteService;

  constructor() {
    this.sales = new SaleService();
    this.quotes = new QuoteService();
  }
}
