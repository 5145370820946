
































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import OverdueSaleTableRow from "./OverdueSaleTableRow.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import Utils from "@/application/shared/Utils";
import PeriodSelect from "@/components/shared/selectors/PeriodSelect.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import { Period } from "@/application/enums/app/common/Period";
import ErrorBanner from "@/components/shared/banners/ErrorBanner.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import SvgResultsEmpty from "@/assets/img/ResultsEmpty.vue";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";

@Component({
  components: {
    ConfirmModal,
    OverdueSaleTableRow,
    PeriodSelect,
    ErrorBanner,
    NewErrorModal,
    SvgResultsEmpty,
    NewModalComponent,
    EmptyState
  }
})
export default class OverdueSalesTable extends Vue {
  $refs!: {
    modalConfirm: ConfirmModalComponent;
    bannerError: ErrorBanner;
    inputSearch: HTMLInputElement;
    modalError: ErrorModalComponent;
  };
  items: SaleDto[] = [];
  sortDirection: number = 1;
  searchInput: string = "";
  loading: boolean = false;
  period: Period = Period.DAY;
  // created() {
  //   if (this.$route.query.periodo) {
  //     switch (this.$route.query.periodo) {
  //       case "hoy":
  //         this.period = Period.DAY;
  //         break;
  //       case "ayer":
  //         this.period = Period.LAST_DAY;
  //         break;
  //       case "semana":
  //         this.period = Period.WEEK;
  //         break;
  //       case "semana-pasada":
  //         this.period = Period.LAST_WEEK;
  //         break;
  //       case "mes":
  //         this.period = Period.MONTH;
  //         break;
  //       case "mes-pasado":
  //         this.period = Period.LAST_MONTH;
  //         break;
  //       case "año":
  //         this.period = Period.YEAR;
  //         break;
  //       case "año-pasado":
  //         this.period = Period.LAST_YEAR;
  //         break;
  //     }
  //   }
  // }
  mounted() {
    tinyEventBus().emitter.on("sales-reload", (payload: any) => {
      this.reload(this.period);
    });
    tinyEventBus().emitter.on("sales-deleted", (id: number) => {
      this.items = this.items.filter(f => f.id !== id);
    });
    this.reload(this.period);
    // this.$nextTick(() => {
    //   this.$refs.inputSearch.focus();
    //   this.$refs.inputSearch.select();
    // });
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("sales-reload");
    tinyEventBus().emitter.off("sales-deleted");
  }
  // refreshPeriodQuery() {
  //   let periodName = "";
  //   switch (this.period) {
  //     case Period.DAY:
  //       periodName = "hoy";
  //       break;
  //     case Period.LAST_DAY:
  //       periodName = "ayer";
  //       break;
  //     case Period.WEEK:
  //       periodName = "semana";
  //       break;
  //     case Period.LAST_WEEK:
  //       periodName = "semana-pasada";
  //       break;
  //     case Period.MONTH:
  //       periodName = "mes";
  //       break;
  //     case Period.LAST_MONTH:
  //       periodName = "mes-pasado";
  //       break;
  //     case Period.YEAR:
  //       periodName = "año";
  //       break;
  //     case Period.LAST_YEAR:
  //       periodName = "año-pasado";
  //       break;
  //   }
  //   if (periodName) {
  //     try {
  //       if (!this.$route.query || this.$route.query?.periodo !== periodName) {
  //         this.$router.push({
  //           path: this.$route.path,
  //           query: { periodo: periodName }
  //         });
  //       }
  //     } catch (e) {
  //       //
  //     }
  //   }
  // }
  reload(period: Period) {
    this.period = period;
    // this.refreshPeriodQuery();
    this.loading = true;
    ErpService.sales.sales
      .getAllOverdue()
      .then((response: SaleDto[]) => {
        this.items = response;
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loading = false;
      });
  }
  toggleSort() {
    if (this.sortDirection === -1) {
      this.sortDirection = 1;
    } else {
      this.sortDirection = -1;
    }
  }
  selectPeriodo(period: Period) {
    this.reload(period);
  }
  // newQuote() {
  //   this.loading = true;
  //   ErpService.sales.quotes
  //     .create()
  //     .then((response: SaleDto) => {
  //       this.$router.push({
  //         name: "erp.sales.pos",
  //         params: { type: response.cve_movto, folio: response.folio.toString() }
  //       });
  //     })
  //     .catch(error => {
  //       this.$refs.modalError.show(this.$t(error).toString());
  //     })
  //     .finally(() => {
  //       this.loading = false;
  //     });
  // }
  get pagos() {
    let x = 0;
    this.sortedItems.forEach(element => {
      if (element.payments) {
        element.payments.forEach(pago => {
          x += pago.amount;
        });
      }
    });
    return x;
  }
  get saldo() {
    let x = 0;
    this.sortedItems.forEach(element => {
      x += element.due;
    });
    return x;
  }
  get total() {
    return Utils.sum(this.sortedItems, "total");
  }
  get filteredItems(): SaleDto[] {
    if (!this.items) {
      return [];
    }
    return this.items.filter(
      f =>
        f.customer?.name
          ?.toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.folio
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.quoteFolio
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.customerId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.date
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.taxName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.cfdi?.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.cfdi?.taxName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.paymentMethod
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.paymentForm
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.cfdi?.paymentMethod
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.cfdi?.paymentForm
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.paymentCustomerLast4Digits
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.paymentIncomeBankName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );
  }
  get sortedItems(): SaleDto[] {
    return this.filteredItems.sort((x, y) => {
      if (x.id && y.id) {
        if (this.sortDirection === -1) {
          return (x.id > y.id ? 1 : -1) ?? 1;
        } else {
          return (x.id < y.id ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
