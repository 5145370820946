















import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "../../shared/Breadcrumb.vue";
import SaleDetails from "@/components/app/erp/sales/details/SaleDetails.vue";
import SideModal from "@/components/shared/modals/SideModal.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { getNamedParents } from "@/application/shared/router/router-utils";

@Component({
  components: {
    Breadcrumb,
    SaleDetails,
    SideModal
  }
})
export default class SaleSidebarDetails extends Vue {
  $refs!: {
    sideModal: SideModal;
  };
  type: string | null = null;
  folio: number | null = null;
  created() {
    this.type = this.$route.params.type;
    this.folio = Number(this.$route.params.folio);
  }
  goBack() {
    const parents = getNamedParents(
      this.$router.options.routes,
      this.$route.matched
    );
    if (parents.length) {
      this.$router.push({
        name: parents[parents.length - 1].name
      });
    }
    this.$router.back();
  }
  deleted(id: number) {
    tinyEventBus().emitter.emit("sales-deleted", id);
    this.$refs.sideModal.close();
  }
}
