import { i18n } from "@/plugins/vue-i18n";

export const ecommerceRoutes = {
  name: "ecommerce",
  path: "/tienda",
  alias: "/store",
  component: require("@/views/ecommerce/Index.vue").default,
  meta: {
    requiresAuth: false,
  },
  children: [
    {
      name: "ecommerce.store",
      path: ":store",
      alias: ":tienda",
      component: require("@/views/ecommerce/Store.vue").default,
    },
  ],
};
