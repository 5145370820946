


























































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import { mapGetters } from "vuex";
import { defaultState } from "../../store/modules/theme";
import { UserVerifyRequest } from "../../application/contracts/master/users/UserVerifyRequest";
import { TenantDto } from "../../application/dtos/master/tenants/TenantDto";
import { TenantUserDto } from "../../application/dtos/master/tenants/TenantUserDto";
import LoadingButtonComponent from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import services from "@/services";

@Component({
  components: { ErrorModal, MarketingLogo, LoadingButton },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor"
    }),
    ...mapGetters("account", {
      email: "email"
    })
  }
})
export default class InviteURLComponent extends BaseComponent {
  $refs!: {
    loadingButton: LoadingButtonComponent;
    errorModal: ErrorModalComponent;
  };
  public requirePhone: boolean = true;
  public user = {} as UserVerifyRequest;
  public emailDisabled: boolean = false;
  public tenant = {} as TenantDto;
  public email!: string;
  private requested: boolean = false;
  private accepted: boolean = false;
  private created() {
    this.user.token = this.$route.params.linkUrl ?? "";
    this.user.email = this.email;
    if (!this.email) {
      this.user.email = this.$route.query.e?.toString() ?? "";
      this.accepted = this.$route.query.a === "true";
      if (this.user.email && this.user.email !== "") {
        this.emailDisabled = true;
      }
    } else {
      this.emailDisabled = true;
    }
  }
  private mounted() {
    if (this.$route.params.linkUrl) {
      this.loading = true;
      this.$store.commit("theme/marketingTheme", "theme-bg-light");
      this.$store.commit("theme/marketingColor", defaultState.marketingColor);
      services.tenantUserInvitations
        .getInviteURL(this.$route.params.linkUrl)
        .then((response: TenantDto) => {
          this.tenant = response;
        })
        .catch(error => {
          if (error.status === 404) {
            this.$refs.errorModal.show(this.$t("shared.invalidInvitation"));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  private accept() {
    if (
      !this.emailDisabled &&
      this.user.password !== this.user.passwordConfirm
    ) {
      this.$refs.errorModal.show(
        this.$t("account.login.errors.passwordMissmatch")
      );
      return;
    }

    this.$refs.loadingButton.start();
    services.tenantUserInvitations
      .requestAccess(this.$route.params.linkUrl, this.user)
      .then((response: TenantUserDto) => {
        this.requested = true;
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  private logout() {
    this.$store.commit("auth/logout");
    this.emailDisabled = false;
    this.email = "";
    this.user.email = "";
  }
  // get tenantTheme() {
  //   if (this.tenant) {
  //     return this.tenant.appTheme + " " + this.tenant.appColor;
  //   }
  // }
}
