










































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import { UserVerifyRequest } from "../../application/contracts/master/users/UserVerifyRequest";
import LoadingButtonComponent from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import services from "@/services";

@Component({
  components: { MarketingLogo },
  directives: {
    GoogleSignInButton
  }
})
export default class VerifyComponent extends BaseComponent {
  $refs!: {
    loadingButton: LoadingButtonComponent;
    errorModal: ErrorModalComponent;
  };
  public requireName: boolean = false;
  public requirePassword: boolean = true;
  public droppedDown: boolean = false;
  public user = {} as UserVerifyRequest;
  private created() {
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
    this.user.token = this.$route.query.t ? this.$route.query.t.toString() : "";
    // console.log(this.user);
  }
  private verify() {
    if (this.user.password !== this.user.passwordConfirm) {
      this.$refs.errorModal.show(
        this.$t("account.login.errors.passwordMissmatch")
      );
      return;
    }

    this.$refs.loadingButton.start();
    services.authentication
      .verify(this.user)
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  private closeDropdown() {
    this.droppedDown = false;
  }
  get isDarkMode() {
    return this.$store.state.theme.marketingTheme === "theme-bg-dark";
  }
}
