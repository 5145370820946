



















































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ProductTableRow from "./ProductTableRow.vue";
import ProductItemWithImage from "./ProductItemWithImage.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import PeriodSelect from "@/components/shared/selectors/PeriodSelect.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorBanner from "@/components/shared/banners/ErrorBanner.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import SvgResultsEmpty from "@/assets/img/ResultsEmpty.vue";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { ProductDto } from "@/application/dtos/app/erp/v3/inventory/ProductDto";
import { Prop } from "vue-property-decorator";
import { CategoryDto } from "@/application/dtos/app/erp/v3/inventory/CategoryDto";
import { WarehouseDto } from "@/application/dtos/app/erp/v3/inventory/WarehouseDto";
import CheckboxSelect from "@/components/shared/checkboxes/CheckboxSelect.vue";
import SimpleSelect from "@/components/shared/selectors/SimpleSelect.vue";
import { ImageResolution } from "@/application/enums/app/common/ImageResolution";

@Component({
  components: {
    ConfirmModal,
    ProductTableRow,
    ProductItemWithImage,
    PeriodSelect,
    ErrorBanner,
    SvgResultsEmpty,
    NewModalComponent,
    EmptyState,
    NewErrorModal,
    CheckboxSelect,
    SimpleSelect
  }
})
export default class ProductsTable extends Vue {
  @Prop({}) categoryIds!: number[];
  @Prop({}) warehouseIds!: number[];
  @Prop({ default: -1 }) withImageResolution!: ImageResolution;
  $refs!: {
    modalConfirm: ConfirmModalComponent;
    bannerError: ErrorBanner;
    inputSearch: HTMLInputElement;
    modalError: ErrorModalComponent;
  };
  items: ProductDto[] = [];
  sortDirection: number = -1;
  searchInput: string = "";
  sortBy: string = "totalStockQuantity";
  filters: string[] = ["onlyActive", "onlyWithStock"];
  loading: boolean = false;
  includeStock: boolean = true;

  categories: CategoryDto[] = [];
  warehouses: WarehouseDto[] = [];
  selectedCategories: number[] = [];
  selectedWarehouses: number[] = [];

  error: string = "";
  viewStyle: number = 0;
  async mounted() {
    tinyEventBus().emitter.on("products-reload", (payload: any) => {
      this.reload();
    });
    tinyEventBus().emitter.on("products-deleted", (id: string) => {
      this.items = this.items.filter(f => f.id !== id);
    });
    this.loading = true;
    await ErpService.inventory.categories
      .getAll()
      .then(response => {
        this.categories = response;
      })
      .catch(error => {
        this.error = this.$t(error).toString();
      });
    await ErpService.inventory.warehouses
      .getAllWarehouses(true)
      .then(response => {
        this.warehouses = response;
      })
      .catch(error => {
        this.error = this.$t(error).toString();
      });
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("products-reload");
    tinyEventBus().emitter.off("products-deleted");
  }
  reload() {
    this.loading = true;
    ErpService.inventory.products
      .getAllProducts(
        {
          categoryIds: this.selectedCategories,
          productIds: [],
          warehouseIds: this.selectedWarehouses,
          includeStock: this.includeStock
        },
        this.filters
      )
      .then((response: ProductDto[]) => {
        this.items = response;
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loading = false;
      });
  }
  loadImages() {
    this.items.forEach(element => {
      if (!element.images || element.images.length === 0) {
        element.images = ["", "", ""];
      }
      this.loadImageResolution(element, ImageResolution.Low);
    });
  }
  loadImageResolution(item: ProductDto, resolution: ImageResolution) {
    ErpService.inventory.products
      .getImage(item.id, resolution, 50)
      .then(response => {
        const blob = new Blob([response.data], {
          type: "application/" + response.headers["content-type"]
        });
        const href = window.URL.createObjectURL(blob);
        if (!item.images) {
          item.images = ["", "", ""];
        }
        item.images[resolution] = href;
      })
      .catch(() => {
        // console.error("ERROR: " + error);
      });
  }
  toggleSort() {
    if (this.sortDirection === -1) {
      this.sortDirection = 1;
    } else {
      this.sortDirection = -1;
    }
  }
  toggleViewStyle() {
    if (this.viewStyle === 0) {
      this.viewStyle = 1;
    } else {
      this.viewStyle = 0;
    }
  }
  setSortBy(by, direction) {
    this.sortBy = by;
    this.sortDirection = direction;
  }
  get summary() {
    let totalStock = 0;
    let totalAmount = 0;
    const unitOfMeasures: string[] = [];
    this.sortedItems.forEach(element => {
      if (!unitOfMeasures.includes(element.unitOfMeasure)) {
        unitOfMeasures.push(element.unitOfMeasure);
      }
      element.stock.forEach(stock => {
        totalStock += stock.quantity;
        totalAmount += stock.quantity * element.unitPrice;
      });
    });
    return {
      totalStock,
      totalAmount,
      unitOfMeasures
    };
  }
  get filteredCategories(): CategoryDto[] {
    return this.filteredItems.map(f => f.category);
  }
  get filteredItems(): ProductDto[] {
    if (!this.items) {
      return [];
    }
    let items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.category?.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.unitOfMeasure
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    if (this.filters?.includes("onlyWithStock")) {
      items = items.filter(f => f.totalStockQuantity > 0);
    }
    if (this.filters?.includes("onlyWithUnitPrice")) {
      items = items.filter(f => f.unitPrice > 0);
    }
    if (this.filters?.includes("onlyActive")) {
      items = items.filter(f => f.active);
    }

    return items;
  }
  get sortedItems(): ProductDto[] {
    this.$forceUpdate();
    if (this.sortBy.includes(".")) {
      const objectProperty = this.sortBy.split(".")[0];
      const valueProperty = this.sortBy.split(".")[1];

      return this.filteredItems.sort((x, y) => {
        if (x[objectProperty] && y[objectProperty]) {
          if (
            x[objectProperty][valueProperty] &&
            y[objectProperty][valueProperty]
          ) {
            return (
              (x[objectProperty][valueProperty] >
              y[objectProperty][valueProperty]
                ? this.sortDirection
                : this.sortDirection * -1) ?? this.sortDirection
            );
          }
        }
        return 1;
      });
    }
    return this.filteredItems.sort((x, y) => {
      if (x[this.sortBy] && y[this.sortBy]) {
        return (
          (x[this.sortBy] > y[this.sortBy]
            ? this.sortDirection
            : this.sortDirection * -1) ?? this.sortDirection
        );
      }
      return 1;
    });
  }
}
