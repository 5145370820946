


























































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import B2bService from "@/services/api/app/b2b/B2bService";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ErrorModalComponent from "@/components/shared/modals/ErrorModal.vue";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";

@Component({
  components: {
    SuccessModal,
    ErrorModal
  }
})
export default class BillingIndex extends Vue {
  $refs!: {
    successModal: SuccessModalComponent;
    errorModal: ErrorModalComponent;
    taxIdInput: HTMLInputElement;
  };
  taxId: string = "";
  folio: string = "";
  total: number = 0;
  loading = false;
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.taxIdInput) {
        this.$refs.taxIdInput.focus();
        this.$refs.taxIdInput.select();
      }
    });
  }
  search() {
    if (!this.taxId) {
      this.$refs.errorModal.show("Ingresa tu RFC");
      return;
    }
    if (!this.folio) {
      this.$refs.errorModal.show("Ingresa la remisión");
      return;
    }
    if (!this.total || Number(this.total) === 0) {
      this.$refs.errorModal.show("Ingresa el total de tu factura");
      return;
    }
    this.loading = true;
    B2bService.billing
      .searchInvoice(this.taxId, this.folio, this.total.toString())
      .then((response: SaleDto[]) => {
        if (response.length > 0) {
          this.$router.push({
            name: "billing.invoice",
            params: {
              workspaceId: response[0].workspaceId?.toString() ?? "0",
              taxId: this.taxId,
              folio: this.folio
            }
          });
        } else {
          const invoices = JSON.stringify(response);
          console.log({ invoices });
          this.$refs.successModal.show(
            "Hay más de una factura encontrada: " + invoices
          );
        }
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
