import { CategoryService } from "./CategoryService";
import { TransferService } from "./TransferService";
import { ProductBarcodeReadService } from "./ProductBarcodeReadService";
import { ProductService } from "./ProductService";
import { StockService } from "./StockService";
import { UnitOfMeasureService } from "./UnitOfMeasureService";
import { WarehouseService } from "./WarehouseService";
import { ProductImageService } from "./ProductImageService";

export class InventoryServices {
  products: ProductService;
  productImages: ProductImageService;
  unitsOfMeasure: UnitOfMeasureService;
  documents: TransferService;
  productBarcodeReads: ProductBarcodeReadService;
  categories: CategoryService;
  warehouses: WarehouseService;
  stock: StockService;
  constructor() {
    this.products = new ProductService();
    this.productImages = new ProductImageService();
    this.unitsOfMeasure = new UnitOfMeasureService();
    this.documents = new TransferService();
    this.productBarcodeReads = new ProductBarcodeReadService();
    this.categories = new CategoryService();
    this.warehouses = new WarehouseService();
    this.stock = new StockService();
  }
}
