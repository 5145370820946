

































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/views/app/erp/shared/Breadcrumb.vue";
import Tabs from "@/components/shared/tabs/Tabs.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import NewQuote from "@/components/app/erp/sales/pos/NewQuote.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";

@Component({
  components: {
    Breadcrumb,
    Tabs,
    NewErrorModal,
    ConfirmModal,
    NewModalComponent,
    NewQuote
  }
})
export default class SalesTable extends Vue {
  $refs!: {
    modalError: ErrorModalComponent;
    modalNew: NewModalComponent;
  };
  tabs: any[] = [];
  mounted() {
    this.tabs = [
      {
        name: this.$t("erp.sales.quotes.title"),
        routeName: "erp.sales.quotes",
        current: false
      },
      {
        name: this.$t("erp.sales.invoices.title"),
        routeName: "erp.sales.invoices",
        current: false
      },
      {
        name: this.$t("erp.sales.overdue.title"),
        routeName: "erp.sales.overdue",
        current: false
      }
    ];
    this.tabs.forEach(tab => {
      tab.current = tab.routeName === this.$route.name;
    });
  }
  reload() {
    tinyEventBus().emitter.emit("sales-reload");
  }
  add() {
    this.$refs.modalNew.show();
  }
}
