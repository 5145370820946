











































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class ButtonSort extends Vue {
  public sortBy: string = "";
  public sortDirection: number = 1;
  private dropDownSort: boolean = false;
  mounted() {
    this.sortBy = "Fecha subido";
    this.sortDirection = -1;
  }
  sortByColumn(column: string) {
    this.closeDropDownSort();
    this.$emit("sort", column);
  }
  sortDirectionToggle() {
    this.$emit("toggle");
    // console.log("[toggle] sortDirection: " + this.sortDirection);
  }
  closeDropDownSort() {
    this.dropDownSort = false;
  }
}
