






























































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ObjectList from "../../../components/shared/forms/ObjectList.vue";
import TableRowSkeleton from "../../../components/shared/skeletons/TableRowSkeleton.vue";
import SvgResultsEmpty from "../../../assets/img/ResultsEmpty.vue";
import { TicketStatus } from "../../../application/enums/app/transactions/TicketStatus";
import SideModal from "../../../components/shared/modals/SideModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import PageTitle from "../../../components/shared/navigation/PageTitle.vue";
import Modal from "../../../components/shared/modals/Modal.vue";
import { ProjectDto } from "../../../application/dtos/app/projects/ProjectDto";
import { mapGetters } from "vuex";
import { TenantFeaturesResponse } from "@/application/contracts/master/tenants/TenantFeaturesResponse";
import ProyectoMembers from "./ProjectMembers.vue";
import { ProjectStatus } from "@/application/enums/app/projects/ProjectStatus";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import { ProjectMemberDto } from "@/application/dtos/app/projects/ProjectMemberDto";
import ProjectItem from "./ProjectItem.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import { Watch } from "vue-property-decorator";
import services from "@/services";

@Component({
  components: {
    Modal,
    SideModal,
    ObjectList,
    TableRowSkeleton,
    SvgResultsEmpty,
    ErrorModal,
    PageTitle,
    ProyectoMembers,
    ProjectItem
  },
  computed: {
    ...mapGetters("tenant", {
      features: "features"
    })
  }
})
export default class ProjectsList extends BaseComponent {
  $refs!: {
    uploadTicketsModal: Modal;
    errorModal: ErrorModalComponent;
  };
  // private items = [] as ProyectoDto[];
  private adding: boolean = false;
  private showArchived: boolean = false;
  private showPending: boolean = false;
  private features!: TenantFeaturesResponse;
  private items: ProjectDto[] = [];
  private projectId: string = "";
  private users: TenantUserDto[] = [];
  created() {
    const self = this;
    this.eventBus.$on("project-canceled", () => self.canceled());
    this.eventBus.$on("project-deleted", data => self.deleted(data));
    this.eventBus.$on("project-added", data => self.added(data));
    this.eventBus.$on("project-saved", data => self.reload());
  }
  destroy() {
    this.eventBus.$off("project-canceled");
    this.eventBus.$off("project-deleted");
    this.eventBus.$off("project-added");
    this.eventBus.$off("project-saved");
  }
  mounted() {
    // services.tenantUsers.getAll().then((response: TenantUserDto[]) => {
    //   this.users = response;
    // });
    this.reload();
  }
  async reload(status?: TicketStatus) {
    // this.items = [];
    this.loading = true;
    this.items = [];
    services.projects
      .getAllProjects(false)
      .then((response: ProjectDto[]) => {
        // console.log(JSON.stringify(response))
        this.items = response;
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deleted(data: ProjectDto) {
    // this.reload();
    this.items = this.items.filter(f => f.id !== data.id);
  }
  canceled() {
    // console.log("canceled");
  }
  added(data: ProjectDto) {
    // this.reload();
    // console.log("added data:" + JSON.stringify(data));
    // this.items.push(data);
    this.reload();
  }
  saved(data: ProjectDto) {
    // this.reload();
    // const idx = this.items.findIndex((f) => f.id === data.id);
    // this.items[idx] = data;
    this.reload();
  }
  projectsByStatus(status: ProjectStatus) {
    return this.items.filter(f => f.status === status);
  }
  avatarText(item: ProjectMemberDto) {
    if (item.user.firstName && item.user.lastName) {
      return item.user.firstName[0] + item.user.lastName[0];
    } else if (item.user.firstName) {
      return item.user.firstName[0];
    } else {
      return item.user.email.substring(0, 2).toUpperCase();
    }
  }
  userAvatar(user: ProjectMemberDto) {
    if (this.users) {
      const tenantUser = this.users.find(f => f.userId === user.id);
      if (tenantUser) {
        return tenantUser.avatar;
      }
    }
    return "";
  }
  updatedEstatus(id, status) {
    const item = this.items.find(f => f.id === id);
    if (item) {
      item.status = status;
    }
  }
  get activeProjects() {
    return this.projects.filter(f => f.status === ProjectStatus.ACTIVE);
  }
  get pending() {
    return this.projects.filter(f => f.status === ProjectStatus.PENDING);
  }
  get archived() {
    return this.projects.filter(f => f.status === ProjectStatus.ARCHIVED);
  }
  get projects() {
    if (!this.items) {
      return [];
    }
    return this.items;
  }
  @Watch("$store.state.tenant.currentWorkspace")
  changedWorkspace() {
    console.log("changed workspace");
    // this.reload();
  }
  // get maxNumberOfWorkspacesReached() {
  //   if (!this.features) {
  //     return true;
  //   } else {
  //     if (
  //       this.features.maxNumberOfWorkspaces > 0 &&
  //       this.projects.length >= this.features.maxNumberOfWorkspaces
  //     ) {
  //       return true;
  //     }
  //     return false;
  //   }
  // }
}
