













































































import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/marketing/Header.vue";
import Warning from "@/components/shared/banners/Warning.vue";
import { blogService } from "../../services/marketing/BlogService";

@Component({ components: { Header, Warning } })
export default class BlogComponent extends Vue {
  posts: any[] = [];
  async mounted() {
    this.posts = await blogService.getPosts();
  }
  debug() {
    return process.env.NODE_ENV !== "production";
  }
}
