var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title)?_c('label',{staticClass:"block text-xs font-medium text-gray-400",attrs:{"for":"customer"}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{staticClass:"flex items-center space-x-2 w-full",class:{
      'mt-1': _vm.title
    }},[_c('span',{staticClass:"relative inline-flex shadow-sm rounded-md w-full space-x-0"},_vm._l((_vm.options),function(option,idx){return _c('div',{key:idx,staticClass:"w-full"},[_c('button',{staticClass:" w-full text-center relative inline-flex items-center justify-center px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:z-10 focus:outline-none truncate",class:[
            'border-t border-b border-r',
            _vm.optionClass(option),
            {
              'rounded-l-md border-l': idx === 0,
              'rounded-r-md': idx === _vm.options.length - 1
            }
          ],attrs:{"type":"button"},on:{"click":function($event){return _vm.select(option.value)}}},[_c('span',{staticClass:"hidden sm:block"},[_vm._v(" "+_vm._s(option.name)+" ")]),_c('span',{staticClass:"sm:hidden"},[(!option.nameSm)?_c('span',[_vm._v(" "+_vm._s(option.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(option.nameSm)+" ")])])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }