

































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import AllCustomerTableRow from "./AllCustomerTableRow.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import PeriodSelect from "@/components/shared/selectors/PeriodSelect.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorBanner from "@/components/shared/banners/ErrorBanner.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import SvgResultsEmpty from "@/assets/img/ResultsEmpty.vue";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";

@Component({
  components: {
    ConfirmModal,
    AllCustomerTableRow,
    PeriodSelect,
    ErrorBanner,
    SvgResultsEmpty,
    NewModalComponent,
    EmptyState,
    NewErrorModal
  }
})
export default class AllCustomersTable extends Vue {
  $refs!: {
    modalConfirm: ConfirmModalComponent;
    bannerError: ErrorBanner;
    inputSearch: HTMLInputElement;
    modalQuotes: NewModalComponent;
    modalError: ErrorModalComponent;
  };
  items: CustomerDto[] = [];
  sortDirection: number = -1;
  searchInput: string = "";
  loading: boolean = false;
  mounted() {
    tinyEventBus().emitter.on("customers-reload", (payload: any) => {
      this.reload();
    });
    tinyEventBus().emitter.on("customers-deleted", (id: number) => {
      this.items = this.items.filter(f => f.id !== id);
    });
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("customers-reload");
    tinyEventBus().emitter.off("customers-deleted");
  }
  reload() {
    this.loading = true;
    ErpService.customers.customers
      .getAllCustomers({ fullDetails: false })
      .then((response: CustomerDto[]) => {
        this.items = response;
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loading = false;
      });
  }
  toggleSort() {
    if (this.sortDirection === -1) {
      this.sortDirection = 1;
    } else {
      this.sortDirection = -1;
    }
  }
  get amountDue() {
    let x = 0;
    this.sortedItems.forEach(element => {
      if (element.amountDue) {
        x += element.amountDue;
      }
    });
    return x;
  }
  get filteredItems(): CustomerDto[] {
    if (!this.items) {
      return [];
    }
    return this.items.filter(
      f =>
        f.id.toString().includes(this.searchInput.toUpperCase()) ||
        f.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.company
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.taxIds?.find(
          f => f.taxId.toUpperCase() === this.searchInput.toUpperCase()
        ) ||
        f.taxIds?.find(
          f => f.name.toUpperCase() === this.searchInput.toUpperCase()
        )
    );
  }
  get sortedItems(): CustomerDto[] {
    return this.filteredItems.sort((x, y) => {
      if (x.amountDue && y.amountDue) {
        if (this.sortDirection === -1) {
          return (x.amountDue > y.amountDue ? 1 : -1) ?? 1;
        } else {
          return (x.amountDue < y.amountDue ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
