

















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ProgressBar extends Vue {
  @Prop({ default: 0 }) percentage!: number;

  get widthStyle() {
    return `style='width: ${this.percentage}%'`;
  }
}
