



























































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import Selector from "@/components/shared/forms/Selector.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import supportedLocales from "../../../locale/supportedLocales";
import UploadImage from "@/components/shared/forms/UploadImage.vue";
import { UserUpdatePasswordRequest } from "../../../application/contracts/master/users/UserUpdatePasswordRequest";
import { UserUpdateAvatarRequest } from "../../../application/contracts/master/users/UserUpdateAvatarRequest";
import { UserDto } from "../../../application/dtos/master/users/UserDto";
import { UserUpdateRequest } from "../../../application/contracts/master/users/UserUpdateRequest";
import timezones from "../../../application/shared/timezones/Timezones";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { UserLoginType } from "@/application/enums/master/users/UserLoginType";
import services from "@/services";

@Component({
  components: {
    Selector,
    SuccessModal,
    ErrorModal,
    UploadImage,
    ConfirmModal
  }
})
export default class ProfileComponent extends BaseComponent {
  $refs!: {
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
    confirmModal: ConfirmModalComponent;
  };
  private profile = {} as UserDto;
  private updatePasswordRequest = {} as UserUpdatePasswordRequest;
  private selectedLocale: string = "";
  private locales: string[] = [];
  private showUploadImage = false;
  private timezonesOptions: any[] = [];
  private uploadingImage: boolean = false;
  created() {
    this.timezonesOptions = timezones;
    this.profile.id = this.$store.state.account.user.id;
    this.profile.email = this.$store.state.account.user.email;
    this.profile.firstName = this.$store.state.account.user.firstName;
    this.profile.lastName = this.$store.state.account.user.lastName;
    this.profile.phone = this.$store.state.account.user.phone;
    this.profile.loginType = this.$store.state.account.user.loginType;
    this.selectedLocale = this.$i18n.locale;
  }
  mounted() {
    supportedLocales.forEach(f => {
      this.locales.push(f.lang);
    });
  }
  canChangePassword() {
    if (this.profile.loginType === UserLoginType.PASSWORD) {
      return true;
    }
    return false;
  }
  changedLocale(locale: string) {
    services.users
      .updateLocale({ locale })
      .then(() => {
        // this.$router.go(0);
      })
      .catch(error => {
        // this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.$store.commit("locale/updateLocale", locale);
        this.$i18n.locale = locale;
      });
  }
  updateProfile() {
    const updateRequest: UserUpdateRequest = {
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      phone: this.profile.phone
    };
    services.users
      .update(this.profile.id, updateRequest)
      .then(response => {
        this.$refs.successModal.show(
          this.$t("settings.profile.profileUpdated")
        );
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  logout() {
    this.$store.dispatch("auth/logout");
  }
  updatePassword() {
    services.users
      .updatePassword(this.updatePasswordRequest)
      .then(() => {
        this.$refs.successModal.show(
          this.$t("settings.profile.passwordUpdated")
        );
        this.updatePasswordRequest.passwordCurrent = "";
        this.updatePasswordRequest.passwordNew = "";
        this.updatePasswordRequest.passwordConfirm = "";
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  deleteAccount() {
    this.$refs.confirmModal.show(this.$t("settings.danger.confirmDelete"));
  }
  confirmDelete() {
    services.users
      .deleteMe()
      .then(response => {
        // ignore
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  loadedImage(image) {
    const updateAvatar: UserUpdateAvatarRequest = {
      avatar: image
    };
    this.uploadingImage = true;
    services.users
      .updateAvatar(updateAvatar)
      .then(response => {
        this.showUploadImage = false;
      })
      .catch(error => {
        console.error("Error: " + JSON.stringify(error));
      })
      .finally(() => {
        this.uploadingImage = false;
      });
  }

  get avatar() {
    return this.$store.state.account.user.avatar;
  }
}
