import { ApiService, ApiServiceType } from "../../../ApiService";
import { UpdateStockRequest } from "@/application/contracts/app/erp/inventory/stock/UpdateStockRequest";
import { ProductStockDto } from "@/application/dtos/app/erp/v3/inventory/stock/ProductStockDto";
import { ProductStockUnitDto } from "./ProductStockUnitDto";

export class StockService extends ApiService {
  constructor() {
    super("Inventory/Stock", ApiServiceType.TenantDatabase);
  }
  getAllStock(warehouseIds: any[], productIds: any[]): Promise<ProductStockDto[]> {
    // return Promise.resolve(fakeDataProductBarcodeReads);
    return super.getAll(`GetAll?warehouseIds=${super.toQueryString(warehouseIds)}&productIds=${super.toQueryString(productIds)}`);
  }
  getStock(warehouseId: any, productId: any): Promise<ProductStockDto[]> {
    // return Promise.resolve(fakeDataProductBarcodeReads);
    return super.getAll(`Get/${warehouseId}/${productId}`);
  }
  getUnitsNeeded(productId: any, quantity: number): Promise<ProductStockUnitDto[]> {
    // return Promise.resolve(fakeDataProductBarcodeReads);
    const request = {
      productId,
      quantity,
    };
    return super.getAll(`GetUnitsNeeded?${super.toQueryString(request)}`);
  }
  updateStock(warehouseId: any, productId: any, request: UpdateStockRequest): Promise<ProductStockDto[]> {
    // return Promise.resolve(fakeDataProductBarcodeReads);
    return super.post(request, `UpdateStock/${warehouseId}/${productId}`);
  }
  downloadPdf(withStockOnly: boolean): Promise<any> {
    return super.download(null, `DownloadPdf/${withStockOnly}`);
  }
}
