

































































import Vue from "vue";
import Component from "vue-class-component";
import CountryFlag from "vue-country-flag";
import supportedLocales from "../../../locale/supportedLocales";
import { Prop } from "vue-property-decorator";
import { faLevelDownAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
  components: {
    CountryFlag
  }
})
export default class FlagSelector extends Vue {
  @Prop() open!: boolean;
  @Prop({ default: true }) showFlagInsteadOfLocale;
  dropDownFlags: boolean = false;
  supportedLocales = supportedLocales;
  mounted() {
    this.dropDownFlags = this.open;
  }
  closeDropDownFlags() {
    this.dropDownFlags = false;
  }
  changedLocale(value) {
    this.closeDropDownFlags();
    this.$store.commit("locale/updateLocale", value);
    this.$i18n.locale = value;
  }
  getCurrentLocale(flag: boolean) {
    const current = this.supportedLocales.find(
      f => f.lang === this.$i18n.locale
    );
    if (current) {
      return flag ? current.flag : current.lang_short;
    }
    return flag ? "us" : "en";
  }
}
