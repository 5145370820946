





























































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ObjectList from "../../../components/shared/forms/ObjectList.vue";
import TableRowSkeleton from "../../../components/shared/skeletons/TableRowSkeleton.vue";
import SvgResultsEmpty from "../../../assets/img/ResultsEmpty.vue";
import SideModal from "../../../components/shared/modals/SideModal.vue";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import PageTitle from "../../../components/shared/navigation/PageTitle.vue";
import Modal from "../../../components/shared/modals/Modal.vue";
import { PeriodRequest } from "@/application/contracts/app/shared/PeriodRequest";
import { CustomerBranchDto } from "@/application/dtos/app/contacts/CustomerBranchDto";
import { Period } from "@/application/enums/app/common/Period";
import services from "@/services";

@Component({
  components: {
    Modal,
    SideModal,
    ObjectList,
    TableRowSkeleton,
    SvgResultsEmpty,
    ErrorModal,
    PageTitle
  }
})
export default class CustomersList extends BaseComponent {
  $refs!: {
    uploadTicketsModal: Modal;
    errorModal: ErrorModal;
  };
  private items = [] as CustomerBranchDto[];
  private adding: boolean = false;
  private periodRequest: PeriodRequest = {
    period: Period.ALL,
    withDetails: true
  };
  mounted() {
    this.reload(this.periodRequest);
  }
  async reload(periodRequest?: PeriodRequest) {
    if (periodRequest) {
      this.periodRequest = periodRequest;
    }
    this.items = [];
    this.loading = true;
    services.customers
      .getAllClientes(this.periodRequest)
      .then((response: CustomerBranchDto[]) => {
        // console.log(JSON.stringify(response))
        this.items = response;
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deleted(data: CustomerBranchDto) {
    this.items = this.items.filter(f => f.id !== data.id);
  }
  canceled() {
    // console.log("canceled");
  }
  added(data: CustomerBranchDto) {
    // console.log("added data:" + JSON.stringify(data));
    this.items.push(data);
  }
  saved(data: CustomerBranchDto) {
    const idx = this.items.findIndex(f => f.id === data.id);
    this.items[idx] = data;
  }
  get orderedItems(): CustomerBranchDto[] {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      if (x.taxId && y.taxId) {
        return (x.taxId > y.taxId ? 1 : -1) ?? 1;
      }
      return 1;
    });
  }
}
