export enum Period {
  ALL, // 0
  YEAR, // 1
  MONTH, // 2
  WEEK, // 3
  DAY, // 4
  LAST_YEAR, // 5
  LAST_MONTH, // 6
  LAST_WEEK, // 7
  LAST_DAY, // 8
  LAST_30_DAYS, // 9
  LAST_7_DAYS, // 10
}
