














































































import Vue from "vue";
import Component from "vue-class-component";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { Prop } from "vue-property-decorator";
import { filters } from "@/plugins/filters";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { PaymentForm } from "@/application/enums/app/erp/contacts/customers/PaymentForm";
import { ProductDto } from "@/application/dtos/app/erp/v3/inventory/ProductDto";
import ErpService from "@/services/api/app/erp/ErpService";
import { ImageResolution } from "@/application/enums/app/common/ImageResolution";

@Component({})
export default class ProductItemWithImage extends Vue {
  @Prop({ default: -1 }) idx!: number;
  @Prop({ default: undefined }) item!: ProductDto;
  @Prop({ default: false }) allowRemove!: boolean;
  @Prop({ default: -1 }) withImageResolution!: ImageResolution;
  image: string = "";
  loading: boolean = false;
  mounted() {
    if (this.withImageResolution >= 0) {
      this.loading = true;
      ErpService.inventory.productImages
        .getImage(this.item.id, this.withImageResolution, 300)
        .then(response => {
          this.image = response;
          this.$forceUpdate();
        })
        .catch(() => {
          // console.error("ERROR: " + error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  moneyFormat(value) {
    return filters.moneyFormat(value);
  }
  totalStock(item: ProductDto) {
    let total = 0;
    item.stock.forEach(element => {
      total += element.quantity;
    });
    return filters.decimalFormat(total);
  }
  totalAmount(item: ProductDto) {
    let total = 0;
    item.stock.forEach(element => {
      total += element.quantity * item.unitPrice;
    });
    return filters.moneyFormat(total);
  }
  getRgbColor(productColor) {
    const color = productColor.color?.split(",") ?? [];
    if (color && color.length === 3) {
      return `background-color:rgb(${color[0]},${color[1]},${color[2]})`;
    } else {
      return "";
    }
  }
}
