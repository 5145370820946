









































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import QuoteTableRow from "./QuoteTableRow.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import Utils from "@/application/shared/Utils";
import PeriodSelect from "@/components/shared/selectors/PeriodSelect.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import { Period } from "@/application/enums/app/common/Period";
import ErrorBanner from "@/components/shared/banners/ErrorBanner.vue";
import SvgResultsEmpty from "@/assets/img/ResultsEmpty.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import SaleActionsModal from "@/components/app/erp/sales/SaleActionsModal.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { CategoryDto } from "@/application/dtos/app/erp/v3/inventory/CategoryDto";
import CategorySelect from "@/components/app/selectors/CategorySelect.vue";
import { resolve } from "dns";
import { rejects } from "assert";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";

@Component({
  components: {
    ConfirmModal,
    QuoteTableRow,
    PeriodSelect,
    ErrorBanner,
    SvgResultsEmpty,
    NewErrorModal,
    EmptyState,
    SaleActionsModal
  }
})
export default class QuotesTable extends Vue {
  $refs!: {
    modalConfirm: ConfirmModalComponent;
    modalError: ErrorModalComponent;
    bannerError: ErrorBanner;
    inputSearch: HTMLInputElement;
    saleActionsModal: SaleActionsModal;
  };
  items: SaleDto[] = [];
  categories: CategoryDto[] = [];
  sortDirection: number = 1;
  searchInput: string = "";
  loading: boolean = false;
  mounted() {
    tinyEventBus().emitter.on("sales-reload", (payload: any) => {
      this.reload();
    });
    tinyEventBus().emitter.on("sales-deleted", (id: number) => {
      this.items = this.items.filter(f => f.id !== id);
    });
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("sales-reload");
    tinyEventBus().emitter.off("sales-deleted");
  }
  newQuote() {
    this.loading = true;
    ErpService.sales.quotes
      .create({
        cliente: 0
      })
      .then((response: SaleDto) => {
        this.$router.push({
          name: "erp.sales.pos",
          query: { type: response.type, folio: response.folio.toString() }
        });
      })
      .catch(error => {
        this.$refs.modalError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loading = false;
      });
  }
  remove(item: SaleDto) {
    this.items = this.items.filter(f => f.id !== item.id);
  }
  removeItem(item: SaleDto) {
    this.$refs.saleActionsModal.close();
    this.$refs.modalConfirm.value = item;
    this.$refs.modalConfirm.show(
      `¿Eliminar ${item.type}-${item.folio}?`,
      "Eliminar",
      "Cancelar",
      "Se eliminará el pedido con sus productos apartados."
    );
  }
  editItem(item: SaleDto) {
    this.$refs.saleActionsModal.close();
    this.$router.push({
      name: "erp.sales.pos",
      query: { type: item.type, folio: item.folio.toString() }
    });
  }
  yesRemove(item: SaleDto) {
    // this.loading = true;
    ErpService.sales.sales
      .deleteSale(item.type, item.folio)
      .then(() => {
        this.items = this.items.filter(f => f.id !== item.id);
      })
      .catch(error => {
        this.$refs.modalError.show(this.$t(error));
      });
  }
  showActions(item: SaleDto) {
    this.$refs.saleActionsModal.show(item);
  }
  loadCategories() {
    return new Promise((resolve, reject) => {
      ErpService.inventory.categories
        .getAll()
        .then((response: CategoryDto[]) => {
          this.categories = response;
          resolve();
        })
        .catch(error => {
          this.$refs.bannerError.show(this.$t(error).toString());
          reject();
        });
    });
  }
  reload() {
    this.searchInput = "";
    this.loading = true;
    ErpService.sales.quotes
      .getAll()
      .then((response: SaleDto[]) => {
        this.items = response;
      })
      .catch(error => {
        this.$refs.bannerError.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loading = false;
      });
  }
  toggleSort() {
    if (this.sortDirection === -1) {
      this.sortDirection = 1;
    } else {
      this.sortDirection = -1;
    }
  }
  quantity(item: SaleDto) {
    let total = 0;
    item.details?.forEach(element => {
      total += element.quantity;
    });
    item.reads?.forEach(element => {
      total += element.quantity;
    });
    return total;
  }
  subtotal(item: SaleDto) {
    let total = 0;
    item.details?.forEach(element => {
      total += element.amount;
    });
    item.reads?.forEach(element => {
      total += element.subtotal;
    });
    return total;
  }
  get allQuantity() {
    let total = 0;
    this.sortedItems?.forEach(element => {
      total += this.quantity(element);
    });
    return total;
  }
  get categoriesForItems() {
    return {
      categories: this.categoriesInItems
    };
  }
  get categoriesInItems(): CategoryDto[] {
    if (this.filteredItems.length === 0) {
      return [];
    }
    const categoryIds: number[] = [];
    this.filteredItems.forEach(item => {
      item.details?.forEach(element => {
        if (
          element.product?.category?.id &&
          !categoryIds.includes(element.product?.category.id)
        ) {
          categoryIds.push(element.product?.category.id);
        }
      });
      item.reads?.forEach(element => {
        if (
          element.product?.category?.id &&
          !categoryIds.includes(element.product?.category.id)
        ) {
          categoryIds.push(element.product?.category.id);
        }
      });
    });
    if (categoryIds.length === 0) {
      return [];
    }
    return this.categories
      .filter(f => categoryIds.includes(f.id))
      .sort((x, y) => {
        return x.id > y.id ? -1 : 1;
      });
  }
  get total() {
    return Utils.sum(this.sortedItems, "total");
  }
  get allSubtotal() {
    return Utils.sum(this.sortedItems, "subtotal");
  }
  get totalSaleDetails() {
    let subtotal = 0;
    this.sortedItems.forEach(item => {
      subtotal += Utils.sum(item.details, "amount");
    });
    return subtotal;
  }
  get totalSales() {
    let subtotal = 0;
    this.sortedItems.forEach(item => {
      subtotal += this.itemTotal(item);
    });
    return subtotal;
  }
  itemTotal(item: SaleDto) {
    let total = 0;
    item.details?.forEach(element => {
      let totalAmount = element.amount;
      if (element.product?.category?.tax1 === 16) {
        totalAmount *= 1.16;
      }
      total += totalAmount;
    });
    item.reads?.forEach(element => {
      total += element.total;
    });
    return total;
  }
  get totalBarcodeReads() {
    let subtotal = 0;
    this.sortedItems.forEach(item => {
      subtotal += Utils.sum(item.reads, "amount");
    });
    return subtotal;
  }
  get filteredItems(): SaleDto[] {
    if (!this.items) {
      return [];
    }
    try {
      return this.items.filter(
        f =>
          f.customer?.name
            ?.toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.folio
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.quoteFolio
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.customerId
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.date
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.taxId
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.taxName
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.cfdi?.taxId
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.cfdi?.customerName
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.paymentMethod
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.paymentForm
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.cfdi?.paymentMethod
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          f.cfdi?.paymentForm
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase())
      );
    } catch {
      return this.items;
    }
  }
  get sortedItems(): SaleDto[] {
    return this.filteredItems.sort((x, y) => {
      if (x.id && y.id) {
        if (this.sortDirection === -1) {
          return (x.id > y.id ? 1 : -1) ?? 1;
        } else {
          return (x.id < y.id ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
