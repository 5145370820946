







































import Component from "vue-class-component";
import { SubscriptionPriceDto } from "@/application/dtos/master/subscriptions/SubscriptionPriceDto";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import {SubscriptionBillingPeriod} from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";

@Component({
  components: {},
})
export default class TenantSubscriptionPlan extends BaseComponent {
  get myProducts(): SubscriptionPriceDto[] {
    const products = this.$store.state.tenant
      .myProducts as SubscriptionPriceDto[];
    if (products && products.length > 0) {
      return products.filter(
        (f) => f.billingPeriod === SubscriptionBillingPeriod.ONCE
      );
    }
    return [];
  }
}
