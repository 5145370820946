
































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";
import Breadcrumb from "@/views/app/erp/shared/Breadcrumb.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import { CustomerTaxIdDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerTaxIdDto";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { ProductDto } from "@/application/dtos/app/erp/v3/inventory/ProductDto";
import { CategoryDto } from "@/application/dtos/app/erp/v3/inventory/CategoryDto";
import { WarehouseDto } from "@/application/dtos/app/erp/v3/inventory/WarehouseDto";
import { StockDto } from "@/application/dtos/app/erp/v3/inventory/StockDto";
import UploadImage from "@/components/shared/forms/UploadImage.vue";
// tslint:disable-next-line:max-line-length
import { UpdateProductImageRequest } from "@/application/contracts/app/erp/inventory/products/UpdateProductImageRequest";
import { ImageResolution } from "@/application/enums/app/common/ImageResolution";
import { UnitOfMeasureDto } from "@/application/dtos/app/erp/v3/inventory/uom/UnitOfMeasureDto";
import { filters } from "@/plugins/filters";

@Component({
  components: {
    NewModalComponent,
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    Breadcrumb,
    UploadImage
  }
})
export default class ProductForm extends Vue {
  $refs!: {
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
    nameInput: HTMLInputElement;
    taxIdInput: HTMLInputElement;
  };
  private id: string = "";
  private item: ProductDto | null = null;
  private unitsOfMeasure: UnitOfMeasureDto[] = [];
  private categories: CategoryDto[] = [];
  private warehouses: WarehouseDto[] = [];
  private loading: boolean = false;
  private error: string = "";
  private images: string[] = ["", "", ""];
  private showUploadImage = false;
  private currentResolution = -1;
  private uploadingImage: boolean = false;
  private resolutions: ImageResolution[] = [0, 1, 2];
  created() {
    this.id = this.$route.params.id.toString();
  }
  async mounted() {
    this.loading = true;
    await ErpService.inventory.categories.getAll().then(response => {
      this.categories = response;
    });
    await ErpService.inventory.unitsOfMeasure.getAll().then(response => {
      this.unitsOfMeasure = response;
    });
    await ErpService.inventory.warehouses
      .getAllWarehouses(true)
      .then(response => {
        this.warehouses = response;
      });
    this.reload();
  }
  reload() {
    this.loading = true;
    ErpService.inventory.products
      .get(this.$route.params.id)
      .then(response => {
        this.item = response;
        this.loadImages();
      })
      .catch(error => {
        this.error = this.$t(error).toString();
      })
      .finally(() => {
        this.loading = false;
      });
  }
  loadImages() {
    this.images = ["", "", ""];
    this.$forceUpdate();
    this.resolutions.forEach(resolution => {
      this.loadImageResolution(resolution).then(image => {
        this.images[resolution] = image;
        this.$forceUpdate();
      });
    });
  }
  loadImageResolution(resolution: ImageResolution): Promise<string> {
    return new Promise((resolve, reject) => {
      ErpService.inventory.productImages
        .getImage(this.id, resolution, 20)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  clear() {
    this.item = null;
  }
  add() {
    this.$nextTick(() => {
      this.$refs.nameInput.focus();
      this.$refs.nameInput.select();
    });
    // this.$refs.modal.show();
  }
  edit(item) {
    this.item = item;
  }
  update(id: number) {
    this.loading = true;
    ErpService.inventory.products
      .get(id.toString())
      .then((response: ProductDto) => {
        this.item = response;
        this.$nextTick(() => {
          this.$refs.nameInput.focus();
          this.$refs.nameInput.select();
        });
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  save() {
    if (!this.item) {
      this.$refs.errorModal.show(this.$t("Cliente inválido"));
      return;
    }
    if (!this.item.name) {
      this.$refs.errorModal.show(this.$t("Nombre inválido"));
      return;
    }

    // this.loading = true;
    if (!this.item.id) {
      // ErpService.inventory.products
      //   .create(this.item)
      //   .then((response: CustomerDto) => {
      //     this.item = response;
      //     tinyEventBus().emitter.emit("customer-added", response);
      //     this.$emit("created", response);
      //     this.$refs.successModal.show(
      //       this.$t("Cliente creado"),
      //       "Se le asignó el número " + response.id
      //     );
      //   })
      //   .catch(error => {
      //     this.$refs.errorModal.show(this.$t(error));
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    } else {
      // ErpService.inventory.products
      //   .update(this.item.id.toString(), this.item)
      //   .then((response: CustomerDto) => {
      //     this.item = response;
      //     this.$emit("updated", response);
      //     this.$refs.successModal.show(this.$t("Cliente actualizado"));
      //   })
      //   .catch(error => {
      //     this.$refs.errorModal.show(this.$t(error));
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    }
  }
  // yesDelete() {
  //   if (!this.item || this.item.id <= 0) {
  //     return;
  //   }
  //   this.loading = true;
  //   ErpService.customers.customers
  //     .delete(this.item.id.toString())
  //     .then(() => {
  //       this.item = null;
  //       this.taxId = null;
  //       this.$refs.successModal.show(this.$t("Cliente eliminado"));
  //     })
  //     .catch(error => {
  //       this.$refs.errorModal.show(this.$t(error));
  //     })
  //     .finally(() => {
  //       this.loading = false;
  //     });
  // }
  // showTaxInfoDetails() {
  //   if (!this.showTaxInfo) {
  //     this.showTaxInfo = true;
  //     this.$nextTick(() => {
  //       this.$refs.taxIdInput.focus();
  //       this.$refs.taxIdInput.select();
  //     });
  //   } else {
  //     this.showTaxInfo = false;
  //   }
  // }
  close() {
    this.$emit("close");
  }
  closed() {
    //   this.
  }
  getReferences() {
    const selectedCategory = this.categories.find(
      f => f.id === this.item?.category.id
    );
    if (selectedCategory) {
      return selectedCategory.getReferences();
    }
    return [];
  }
  getWarehouseName(id) {
    return this.warehouses.find(f => f.id === id)?.name ?? "";
  }
  loadedImage(image) {
    const resolution = this.currentResolution;
    if (!this.item) {
      return;
    }
    const payload: UpdateProductImageRequest = {
      image,
      resolution
    };
    this.uploadingImage = true;
    ErpService.inventory.products
      .updateImage(this.item.id, payload)
      .then(() => {
        this.loadImageResolution(resolution).then(image => {
          this.images[resolution] = image;
          this.$forceUpdate();
        });
        this.showUploadImage = false;
        this.currentResolution = -1;
      })
      .catch(error => {
        console.error("Error: " + error);
      })
      .finally(() => {
        this.uploadingImage = false;
      });
  }
  selectImage(resolution: ImageResolution) {
    this.currentResolution = resolution;
    this.showUploadImage = true;
  }
  nombreDeResolucion(resolution) {
    if (resolution === 0) {
      return "Baja";
    } else if (resolution === 1) {
      return "Media";
    } else if (resolution === 2) {
      return "Alta";
    }
    return "";
  }
  get sortedResolutions() {
    return this.resolutions.sort((x, y) => {
      return x > y ? -1 : 1;
    });
  }
  get itemStock(): StockDto[] {
    if (this.item) {
      return this.item.stock.filter(f =>
        this.warehouses.find(x => x.id === f.warehouseId)
      );
    }
    return [];
  }
}
