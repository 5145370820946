import { PeriodRequest } from "@/application/contracts/app/shared/PeriodRequest";
import { CustomerBranchDto } from "@/application/dtos/app/contacts/CustomerBranchDto";
import { ApiService, ApiServiceType } from "../../ApiService";

export class ClienteService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("Cliente", ApiServiceType.TenantDatabase);
  }
  adminGetAll(request: PeriodRequest): Promise<CustomerBranchDto[]> {
    return this.api.getAll(`Admin/GetAll?` + this.api.toQueryString(request));
  }
  getAllClientes(request: PeriodRequest): Promise<CustomerBranchDto[]> {
    return this.api.getAll(`GetAll?` + this.api.toQueryString(request));
  }
  get(id: string): Promise<CustomerBranchDto> {
    return this.api.get("Get", id);
  }
  create(data: CustomerBranchDto): Promise<CustomerBranchDto> {
    return this.api.post(data);
  }
  update(id: string, data: CustomerBranchDto): Promise<CustomerBranchDto> {
    return this.api.put(id, data);
  }
  delete(id: string): Promise<any> {
    return this.api.delete(id);
  }
  adminDelete(id: string): Promise<any> {
    return this.api.delete(id, "Admin/Delete");
  }
}
