import store from "@/store";
import { ApiService, ApiServiceType } from "@/services/api/ApiService";
import { ResourceDto } from "@/application/dtos/master/common/ResourceDto";

export class ResourceService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("Resource", ApiServiceType.MasterDatabase);
  }
  getAll(): Promise<ResourceDto[]> {
    return this.api.getAll();
  }
  getMyResources(): Promise<ResourceDto[]> {
    return this.api.getAll("GetMyResources");
  }
  download(id: string): Promise<any> {
    return this.api.download(null, `Download/${id}`);
  }
  create(data: ResourceDto): Promise<ResourceDto> {
    return this.api.post(data);
  }
  upload(fileData: FormData, id: string): Promise<any> {
    return this.api.upload(fileData, `Upload/${id}`);
  }
  update(id: string, data: ResourceDto): Promise<ResourceDto> {
    return this.api.put(id, data);
  }
  delete(id: string) {
    return this.api.delete(id);
  }
}
