


















import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor"
    })
  }
})
export default class ColorSwitcherComponent extends Vue {
  colors = [
    "red",
    "orange",
    "amber",
    // "yellow",
    // "lime",
    "green",
    "emerald",
    "teal",
    "cyan",
    "lightBlue",
    "blue",
    "indigo",
    "violet",
    "purple",
    "fuchsia",
    // "pink",
    "rose",
    // "gray",
    "blueGray",
    "coolGray"
    // "trueGray",
    // "warmGray"
  ];
  setColor(color) {
    this.$store.commit("theme/marketingColor", "theme-" + color);
  }
}
