































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/views/app/erp/shared/Breadcrumb.vue";
import Tabs from "@/components/shared/tabs/Tabs.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import CustomerForm from "@/components/app/erp/contacts/customers/forms/CustomerForm.vue";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";
import { TinyEmitter } from "tiny-emitter";

@Component({
  components: {
    Breadcrumb,
    Tabs,
    NewErrorModal,
    ConfirmModal,
    NewModalComponent,
    CustomerForm
  }
})
export default class CustomersTables extends Vue {
  $refs!: {
    modalError: ErrorModalComponent;
    modalNewCustomer: NewModalComponent;
    formCustomer: CustomerForm;
  };
  tabs: any[] = [];
  mounted() {
    tinyEventBus().emitter.on("customers-deleted", (id: number) => {
      this.reload();
    });
    this.tabs = [
      {
        name: this.$t("shared.all"),
        routeName: "erp.contacts.customers.all",
        current: false
      }
    ];
    this.tabs.forEach(tab => {
      tab.current = tab.routeName === this.$route.name;
    });
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("customers-deleted");
  }
  reload() {
    tinyEventBus().emitter.emit("customers-reload");
  }
  add() {
    tinyEventBus().emitter.emit("customers-add");
    // this.$refs.modalNewCustomer.show();
    // this.$refs.formCustomer.add();
  }
  createdCustomer(item: CustomerDto) {
    this.reload();
  }
}
