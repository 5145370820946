
































































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import { mapGetters } from "vuex";
import { SubscriptionCardDto } from "../../../../../application/dtos/master/subscriptions/SubscriptionCardDto";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import { Card, createToken } from "vue-stripe-elements-plus";
import services from "@/services";

@Component({
  components: { LoadingButton, SuccessModal, ErrorModal, Card },
  computed: {
    ...mapGetters("tenant", {
      subscriptionCard: "defaultSubscriptionCard"
    })
  }
})
export default class TenantSubscriptionPaymentDetails extends BaseComponent {
  $refs!: {
    successModal: SuccessModal;
    errorModal: ErrorModal;
    loadingButton: LoadingButton;
  };
  private editing: boolean = false;
  private stripeKey!: string;
  private stripeOptions!: any;
  private complete: boolean = false;
  private subscriptionCard!: SubscriptionCardDto;
  created() {
    this.stripeKey = process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY ?? "";
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: this.$i18n.locale
      }
    };
  }
  updatePaymentDetails() {
    this.$refs.loadingButton.start();
    createToken()
      .then(data => {
        if (data.error) {
          this.$refs.errorModal.show(data.error.message);
          if (this.$refs.loadingButton) {
            this.$refs.loadingButton.stop();
          }
        } else {
          if (data.token.id && data.token.id !== "") {
            this.updateCardToken(data.token.id);
          }
        }
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
        this.editing = false;
      });
  }
  updateCardToken(token: string) {
    services.subscriptionManager
      .updateCardToken(token)
      .then(response => {
        services.subscriptionManager.getCurrentSubscription();
        this.$refs.successModal.show(
          this.$t("settings.tenant.payment.updated")
        );
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
        this.editing = false;
      });
  }

  get subscriptionCardLast4() {
    return this.subscriptionCard?.lastFourDigits;
  }
  get subscriptionCardHolder() {
    return this.subscriptionCard?.brand;
  }
  get subscriptionCardExpDesc() {
    return (
      ("0" + this.subscriptionCard?.expiryMonth).slice(-2) +
      " / " +
      this.subscriptionCard?.expiryYear
    );
  }
}
