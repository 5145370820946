










































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class NewModalComponent extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  @Prop({ default: "overflow-y-scroll" }) overflowContent!: string;
  public title!: string;
  public closeText!: any;
  public showing: boolean = false;
  private errorDescription!: string;
  mounted() {
    this.closeText = this.$t("shared.close").toString();
  }
  public show(title: string = "", closeText: string = "") {
    this.title = title;
    if (closeText !== "") {
      this.closeText = closeText;
    }
    this.showing = true;
  }
  public close() {
    this.$emit("closed");
    this.showing = false;
  }
}
