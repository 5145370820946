





















import Vue from "vue";
import DropdownWithSearch from "@/components/shared/dropdowns/DropdownWithSearch.vue";
import Component from "vue-class-component";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { ProductDto } from "@/application/dtos/app/erp/v3/inventory/ProductDto";

@Component({
  components: {
    DropdownWithSearch
  }
})
export default class ProductSelect extends Vue {
  $refs!: {
    dropdown: DropdownWithSearch;
  };
  mounted() {
    // tinyEventBus().emitter.on("product-added", (payload: any) => {
    //   this.$refs.dropdown.selectItem(payload);
    //   this.$refs.dropdown.reload();
    // });
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("customer-added");
  }
  selected(value, item) {
    this.$emit("selected", value, item);
  }
  add() {
    tinyEventBus().emitter.emit("products-add");
  }
  // created(item: ProductDto) {
  //   this.$refs.dropdown.selectItem(item);
  //   this.$refs.dropdown.reload();
  // }
}
