import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { SaleReportFormat } from "@/application/enums/app/erp/sales/SaleReportFormat";
import { ApiService, ApiServiceType } from "../../../ApiService";

export class BillingService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("b2b/Billing", ApiServiceType.TenantDatabase);
  }
  searchInvoice(taxId: string, folio: string, total: string): Promise<SaleDto[]> {
    return this.api.getAll(
      `SearchInvoice?${this.api.toQueryString({
        taxId,
        folio,
        total,
      })}`
    );
  }
  getInvoice(workspaceId: string, taxId: string, folio: string): Promise<SaleDto> {
    return this.api.getAll(`GetInvoice/${workspaceId}/${taxId}/${folio}`);
  }
  downloadInvoice(workspaceId: string, taxId: string, folio: string, format: SaleReportFormat): Promise<SaleDto> {
    return this.api.download(null, `DownloadInvoice/${workspaceId}/${taxId}/${folio}/${format}`);
  }
}
