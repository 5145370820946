var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumb',{attrs:{"menu":[
      { title: _vm.$t('erp.inventory.title'), routeName: '' },
      { title: 'Documentos', routeName: '' },
      {
        title: _vm.folioName,
        routeName: _vm.$route.name,
        query: _vm.$route.query
      }
    ]}}),_c('new-error-modal',{ref:"modalError"}),(!_vm.header || _vm.header.wharehouseId === 0 || _vm.header.folio === 0)?_c('div',{staticClass:"p-4"},[_c('form',{staticClass:"h-auto",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"space-y-2"},[_vm._m(0),_c('div',{staticClass:"grid grid-cols-3 gap-2"},[_c('div',[_c('label',{staticClass:"block text-xs font-medium text-gray-400",attrs:{"for":"type"}},[_vm._v("Tipo")]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"inline-block relative w-full "},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.type),expression:"type"}],ref:"inputType",staticClass:"w-full flex text-left justify-between items-center px-3 py-1 text-black transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",class:{
                      'bg-gray-50 text-gray-400 cursor-not-allowed': true
                    },attrs:{"disabled":true,"id":"type","type":"text","placeholder":"Tipo","max":"2","step":"any"},domProps:{"value":(_vm.type)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.type=$event.target.value},function($event){_vm.type = $event.target.value.toUppercase()}]}})])])])]),_c('div',[_c('label',{staticClass:"block text-xs font-medium text-gray-400",attrs:{"for":"warehouse"}},[_vm._v("Almacén")]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"inline-block relative w-full "},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.warehouse),expression:"warehouse"}],ref:"inputWarehouse",staticClass:"w-full flex text-left justify-between items-center px-3 py-1 text-black transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",class:{
                      'bg-gray-50 text-gray-200 cursor-not-allowed': false
                    },attrs:{"id":"warehouse","min":1,"max":99999,"type":"number","placeholder":"Almacén","step":"1"},domProps:{"value":(_vm.warehouse)},on:{"input":function($event){if($event.target.composing){ return; }_vm.warehouse=$event.target.value}}})])])])]),_c('div',[_c('label',{staticClass:"block text-xs font-medium text-gray-400",attrs:{"for":"folio"}},[_vm._v("Folio")]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"inline-block relative w-full "},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.folio),expression:"folio"}],ref:"inputFolio",staticClass:"w-full flex text-left justify-between items-center px-3 py-1 text-black transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",class:{
                      'bg-gray-50 text-gray-200 cursor-not-allowed': false
                    },attrs:{"id":"folio","min":1,"type":"number","placeholder":"Folio","step":"1"},domProps:{"value":(_vm.folio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.folio=$event.target.value}}})])])])])])]),_c('div',{staticClass:"pt-5"},[_c('div',{staticClass:"flex justify-end space-x-1"},[_c('loading-button',{ref:"loadingButton",attrs:{"buttonType":"submit"},on:{"click":_vm.accept}},[_vm._v(" Aceptar ")])],1)])])]):_c('BarcodeReads',{attrs:{"headerType":_vm.header.type,"headerFolio":_vm.header.folio,"headerWarehouseId":_vm.header.wharehouseId,"isSale":false}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between space-x-2"},[_c('h2',{staticClass:"font-bold text-lg"},[_vm._v(" Inventarios Físicos ")])])}]

export { render, staticRenderFns }