















































import Vue from "vue";
import Component from "vue-class-component";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import { Prop } from "vue-property-decorator";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";

@Component({
  components: {
    NewModalComponent
  }
})
export default class SaleActionsModal extends Vue {
  $refs!: {
    modal: NewModalComponent;
  };
  private item: SaleDto | null = null;
  show(item: SaleDto) {
    this.item = item;
    this.$refs.modal.show();
  }
  close() {
    this.$refs.modal.close();
  }
  closed() {
    //   this.
  }
}
