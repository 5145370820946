
















































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import { mapGetters } from "vuex";
import { defaultState } from "../../store/modules/theme";
import { UserVerifyRequest } from "../../application/contracts/master/users/UserVerifyRequest";
import { TenantInvitationResponse } from "../../application/contracts/master/tenants/TenantInvitationResponse";
import { TenantDto } from "../../application/dtos/master/tenants/TenantDto";
import LoadingButtonComponent from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import services from "@/services";

@Component({
  components: { ErrorModal, MarketingLogo },
  directives: {
    GoogleSignInButton
  },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor"
    })
  }
})
export default class InvitationComponent extends BaseComponent {
  $refs!: {
    loadingButton: LoadingButtonComponent;
    errorModal: ErrorModalComponent;
  };
  public requirePassword: boolean = true;
  public user = {} as UserVerifyRequest;
  public invitation = {} as any;
  public tenant = {} as TenantDto;
  private created() {
    this.user.token = this.$route.query.i ? this.$route.query.i.toString() : "";
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
  }
  private mounted() {
    if (this.$route.query.i) {
      this.loading = true;
      this.$store.commit("theme/marketingTheme", "theme-bg-light");
      this.$store.commit("theme/marketingColor", defaultState.marketingColor);
      services.tenantUserInvitations
        .getInvitation(this.$route.query.i.toString())
        .then((response: TenantInvitationResponse) => {
          this.invitation = response.invitation;
          this.tenant = response.tenant;
          this.requirePassword = response.requiresVerify;

          // if (this.tenant.appTheme) {
          //   this.$store.commit("theme/marketingTheme", this.tenant.appTheme);
          // }
          // if (this.tenant.appColor) {
          //   this.$store.commit("theme/marketingColor", this.tenant.appColor);
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  private accept() {
    if (this.user.password !== this.user.passwordConfirm) {
      return;
    }

    this.$refs.loadingButton.start();
    services.tenantUserInvitations
      .acceptInvitation(this.$route.query.i.toString(), this.user)
      .catch(error => {
        this.$refs.errorModal.show(error);
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  get tenantTheme() {
    if (this.tenant) {
      return this.tenant.appTheme + " " + this.tenant.appColor;
    }
  }
}
