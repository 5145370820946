import { TenantSessionService } from "./tenant/TenantSessionService";
import { BillingService } from "./sales/BillingService";
import { B2BProductService } from "./inventory/B2BProductService";

class B2bService {
  tenantSession: TenantSessionService;
  billing: BillingService;
  product: B2BProductService;
  constructor() {
    this.tenantSession = new TenantSessionService();
    this.billing = new BillingService();
    this.product = new B2BProductService();
  }
}
export default new B2bService();
