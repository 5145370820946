




























































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class SimpleSelect extends Vue {
  @Prop({}) title!: string;
  @Prop({}) value!: any;
  @Prop({}) options!: any[];
  @Prop({ default: "" }) buttonClass!: string;
  private open: boolean = false;
  private direction: number = 1;
  close() {
    this.open = false;
  }
  select(option) {
    if (this.model === option.id) {
      this.direction = this.direction * -1;
    } else {
      this.direction = 1;
    }
    this.model = option.id;
    this.close();
  }
  get model() {
    return this.value;
  }
  set model(value) {
    this.$emit("input", value, this.direction);
  }
}
