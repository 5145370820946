

























import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "../../shared/Breadcrumb.vue";
import SaleDetails from "@/components/app/erp/sales/details/SaleDetails.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";

@Component({
  components: {
    Breadcrumb,
    SaleDetails
  }
})
export default class SaleFullScreenDetails extends Vue {
  private type: string = "";
  private folio: number = 0;
  created() {
    this.type = this.$route.params.type;
    this.folio = Number(this.$route.params.folio);
  }
  deleted(id: number, type: string, folio: number) {
    tinyEventBus().emitter.emit("sales-deleted", id);
    if (type === "PR") {
      this.$router.push({ name: "erp.sales.quotes" });
    } else {
      this.$router.push({ name: "erp.sales.invoices" });
    }
  }
  get saleName() {
    if (this.type === "PR") {
      return `Pedido ${this.type}-${this.folio}`;
    } else if (this.type === "FO") {
      return `Venta de Contado ${this.type}-${this.folio}`;
    } else if (this.type === "FR") {
      return `Venta de Crédito ${this.type}-${this.folio}`;
    } else if (this.type === "FE") {
      return `Venta de Exportación ${this.type}-${this.folio}`;
    } else if (this.type === "X1") {
      return `Intereses ${this.type}-${this.folio}`;
    } else if (this.type === "X2") {
      return `Abono ${this.type}-${this.folio}`;
    } else {
      return `${this.type}-${this.folio}`;
    }
    return this.type + "-" + this.folio;
  }
}
