import { CustomerServices } from "./contacts/customers";
import { InventoryServices } from "./inventory";
import { SalesServices } from "./sales";

class ErpService {
  inventory: InventoryServices;
  sales: SalesServices;
  customers: CustomerServices;
  constructor() {
    this.inventory = new InventoryServices();
    this.sales = new SalesServices();
    this.customers = new CustomerServices();
  }
}
export default new ErpService();
