






































import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class DarkModeToggleComponent extends Vue {
  toggle() {
    if (this.currentTheme === "theme-bg-dark") {
      this.$store.commit("theme/appTheme", "theme-bg-light");
      this.$store.commit("theme/marketingTheme", "theme-bg-light");
    } else {
      this.$store.commit("theme/appTheme", "theme-bg-dark");
      this.$store.commit("theme/marketingTheme", "theme-bg-dark");
    }
    const htmlClasses = document.querySelector("html")?.classList;
    if (this.currentTheme === "theme-bg-light") {
      htmlClasses?.remove("dark");
    } else {
      htmlClasses?.add("dark");
    }
  }
  get currentTheme() {
    return this.$store.state.theme.marketingTheme;
  }
}
