import { ApiService, ApiServiceType } from "../../ApiService";
import { TeamDto } from "@/application/dtos/app/projects/TeamDto";

export class TeamService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("Team", ApiServiceType.TenantDatabase);
  }
  getAllTeams(active: boolean): Promise<TeamDto[]> {
    return new Promise((resolve, reject) => {
      this.api
        .getAll("GetAll/" + active)
        .then((response: TeamDto[]) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  get(id: string): Promise<TeamDto> {
    return this.api.get("Get", id);
  }
  create(data: TeamDto): Promise<TeamDto> {
    return this.api.post(data, "Create");
  }
  update(id: string, data: TeamDto): Promise<TeamDto> {
    return this.api.put(id, data, "Update");
  }
  delete(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .delete(id, "Delete")
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  adminDelete(id: string): Promise<any> {
    return this.api.delete(id, "Admin/Delete");
  }
}
