








































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { Prop } from "vue-property-decorator";
import { filters } from "@/plugins/filters";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { PaymentForm } from "@/application/enums/app/erp/contacts/customers/PaymentForm";

@Component({})
export default class InvoiceTableRow extends Vue {
  @Prop({ default: -1 }) idx!: number;
  @Prop({ default: undefined }) item!: SaleDto;
  @Prop({ default: false }) allowRemove!: boolean;
  dateAgo(value: string) {
    return filters.dateFormat(value, "MMM DD");
  }
  paymentsTotal(item: SaleDto) {
    let total = 0;
    item.payments?.forEach(element => {
      total += element.amount;
    });
    return total;
  }
  paymentFormDescription(paymentForm: PaymentForm) {
    switch (paymentForm) {
      case PaymentForm.Cash:
        return "Efectivo";
      case PaymentForm.Check:
        return "Cheque";
      case PaymentForm.Transfer:
        return "Transferencia";
      case PaymentForm.DebitCard:
        return "Tarjeta de Débito";
      case PaymentForm.CreditCard:
        return "Tarjeta de Crédito";
      case PaymentForm.Condonation:
        return "???";
      case PaymentForm.Compensation:
        return "???";
      case PaymentForm.Novation:
        return "???";
      case PaymentForm.Confusion:
        return "???";
      case PaymentForm.DebtRemittance:
        return "???";
      case PaymentForm.PrescriptionOrExpiration:
        return "???";
      case PaymentForm.ToTheSatisfactionOfTheCreditor:
        return "???";
      case PaymentForm.ServicesCard:
        return "???";
      case PaymentForm.ApplicationOfAdvances:
        return "???";
      case PaymentForm.PaymentIntermediary:
        return "???";
      case PaymentForm.ElectronicPurse:
        return "???";
      case PaymentForm.ElectronicMoney:
        return "???";
      case PaymentForm.Voucher:
        return "???";
      case PaymentForm.DationToPayment:
        return "???";
      case PaymentForm.SurrogacyPayment:
        return "???";
      case PaymentForm.PaymentIntermediary:
        return "???";
      case PaymentForm.ToDefine:
        return "???";
      default:
        return "???";
    }
  }
}
