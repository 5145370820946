























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { mapGetters } from "vuex";
import { CfdiDto } from "@/application/dtos/app/cfdis/CfdiDto";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import DropdownSimple from "@/components/shared/dropdowns/DropdownSimple.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import DropImages from "@/components/app/uploads/DropImages.vue";
import { FileBase64 } from "@/application/shared/files/FileBase64";
import NewModal from "../../../components/shared/modals/NewModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import SideModal from "../../../components/shared/modals/SideModal.vue";
import { TicketStatus } from "@/application/enums/app/transactions/TicketStatus";
import { TicketDto } from "@/application/dtos/app/transactions/TicketDto";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { ProjectDto } from "@/application/dtos/app/projects/ProjectDto";
import CfdiUses from "@/application/shared/CfdiUses";
import * as projectHelpers from "@/application/modules/projects/ProjectHelpers";
import { TransactionDto } from "@/application/dtos/app/transactions/TransactionDto";
import { TransactionType } from "@/application/enums/app/transactions/TransactionType";
import { filters } from "../../../plugins/filters";
import services from "@/services";

@Component({
  components: {
    TableRowSkeleton,
    NewErrorModal,
    DropdownSimple,
    ConfirmModal,
    DropImages,
    NewModal,
    SideModal
  },
  computed: {
    ...mapGetters("account", {
      isAdmin: "isAdmin"
    })
  }
})
export default class TicketItemDetails extends BaseComponent {
  $refs!: {
    errorModal: NewErrorModal;
    confirmDelete: ConfirmModalComponent;
    confirmDeleteTransaction: ConfirmModalComponent;
    confirmReportErrorModal: ConfirmModalComponent;
    dropdownOptions: DropdownSimple;
    reportErrorModal: NewModal;
    uploadXmlAndPdfModal: NewModal;
    successUploadCfdiModal: SuccessModalComponent;
    uploadPdfModal: NewModal;
    editModal: NewModal;
  };
  private ticket = {} as TicketDto;
  private seeMoreTransactions: boolean = false;
  private showTransactions: number = 8;
  private imageFullSize: boolean = false;
  private isAdmin!: boolean;
  private fields: any[] = [
    "Website",
    "TaxId",
    "Date",
    "Total",
    "Folio",
    "Id",
    "Email"
  ];
  private errorType: string = "";
  private errorDescription: string = "";
  private errorFiles: FormData | undefined = undefined;
  private xmlsAndPdfs: FormData | undefined = undefined;
  private invoicedWithWebsite: string = "";
  private invoicedWithEmail: string = "";
  private invoicedWithPhone: string = "";
  private invoicedComments: string = "";
  private errorTypes: string[] = [
    this.$t("app.tickets.uploadErrors.sameDayOnly").toString(),
    this.$t("app.tickets.uploadErrors.expired").toString(),
    this.$t("app.tickets.uploadErrors.pictureIncomplete").toString(),
    this.$t("app.tickets.uploadErrors.pictureBlurred").toString(),
    this.$t("app.tickets.uploadErrors.duplicated").toString(),
    this.$t("app.tickets.uploadErrors.websiteWithoutInvoicing").toString(),
    this.$t("app.tickets.uploadErrors.websiteNotWorking").toString(),
    this.$t("app.tickets.uploadErrors.websiteAndPhoneNotFound").toString(),
    this.$t("app.tickets.uploadErrors.websiteNotFoundNotPickingUp").toString(),
    this.$t("app.tickets.uploadErrors.other").toString(),
    this.$t("app.tickets.uploadErrors.noXmlNorPdf").toString()
  ];
  private workspaces: WorkspaceDto[] = [];
  private projects: ProjectDto[] = [];
  private loadingRfcs: boolean = false;
  private loadingEdit: boolean = false;
  private showDropImagesPdf: boolean = false;
  private loadingProjects: boolean = false;
  private cfdiUses = CfdiUses;
  private arrStatus: any[] = [
    { key: 0, name: this.$t("app.tickets.status.PENDING") },
    { key: 1, name: this.$t("app.tickets.status.REQUESTED") },
    { key: 2, name: this.$t("app.tickets.status.INVOICED") },
    { key: 3, name: this.$t("app.tickets.status.DUPLICATED") },
    { key: 4, name: this.$t("app.tickets.status.UNREADABLE") },
    { key: 5, name: this.$t("app.tickets.status.EXPIRED") },
    { key: 6, name: this.$t("app.tickets.status.INVALID") }
  ];
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.tickets
      .getTicket(this.$route.params.id, this.isAdmin)
      .then((response: TicketDto) => {
        this.ticket = response;
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  close(deleted: boolean = false) {
    this.$emit("closed", 0, this.$route.params.id, deleted);
    if (this.$route.fullPath.includes("/admin")) {
      this.$router.push({ name: "admin.tickets" });
    } else if (this.$route.fullPath.includes("/app")) {
      this.$router.push({ name: "app.tickets" });
    }
  }
  loadWorkspaces() {
    this.workspaces = [];
    this.loadingRfcs = true;
    services.workspaces
      .getAllWorkspaces()
      .then((response: WorkspaceDto[]) => {
        this.workspaces = response;
      })
      .catch(error => {
        // ignore
      })
      .finally(() => {
        this.loadingRfcs = false;
      });
  }
  loadProjects() {
    this.projects = [];
    this.loadingProjects = true;
    services.projects
      .getAllProjects(false)
      .then((response: ProjectDto[]) => {
        response.forEach(element => {
          this.projects.push(element);
        });
      })
      .catch(error => {
        // ignore
      })
      .finally(() => {
        this.loadingProjects = false;
      });
  }
  createdByUserName() {
    if (this.ticket.createdByUser) {
      return (
        this.ticket.createdByUser.firstName ?? this.ticket.createdByUser.email
      );
    } else {
      return "?";
    }
  }
  avatarText() {
    if (this.ticket && this.ticket.createdByUser) {
      if (
        this.ticket.createdByUser?.firstName &&
        this.ticket.createdByUser?.lastName
      ) {
        return (
          this.ticket.createdByUser?.firstName[0] +
          this.ticket.createdByUser?.lastName[0]
        );
      } else if (this.ticket.createdByUser?.firstName) {
        return this.ticket.createdByUser?.firstName[0];
      }
    } else {
      return "?";
    }
  }
  hasFile(type: any): boolean {
    if (this.ticket?.transactions && this.ticket?.transactions.length > 0) {
      if (this.ticket?.transactions[0].cfdi) {
        if (type === "xml") {
          return this.ticket?.transactions[0].cfdi.tieneXml;
        } else if (type === "pdf") {
          return this.ticket?.transactions[0].cfdi.tienePdf;
        }
      }
    }
    return false;
  }
  projectColor() {
    return projectHelpers.getProjectColor(this.ticket?.project?.color);
  }
  downloadCfdi(type: string) {
    if (!this.cfdi || !this.cfdi.id) {
      this.$refs.errorModal.show("No tiene CFDi");
    } else {
      const cfdiId = this.cfdi.id;
      if (cfdiId) {
        services.cfdis
          .download(this.cfdi.id, type)
          .then((response: any) => {
            const contentType: string = response.headers["content-type"];

            const fileUrl = window.URL.createObjectURL(
              new Blob([response.data], { type: contentType })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", cfdiId);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch(error => {
            this.$refs.errorModal.show(this.$t(error));
          });
      }
    }
  }
  deleteTicket() {
    this.$refs.dropdownOptions.close();
    if (this.ticket.status === 2) {
      this.$refs.confirmDelete.show(
        `El ticket #${this.ticket.number} ya fue facturado, ¿delete de todas formas?`
      );
    } else {
      this.$refs.confirmDelete.show(`¿Eliminar ticket #${this.ticket.number}?`);
    }
  }
  confirmDelete() {
    if (this.isAdmin) {
      services.tickets
        .adminDelete(this.ticket.id)
        .then(() => {
          this.$emit("deleted");
          this.close(true);
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        });
    } else {
      services.tickets
        .delete(this.ticket.id)
        .then(() => {
          this.$emit("deleted");
          this.close(true);
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        });
    }
  }
  deleteTransaction() {
    this.$refs.dropdownOptions.close();
    this.$refs.confirmDeleteTransaction.show(
      `El ticket #${this.ticket.number} ya fue facturado, ¿delete de todas formas?`
    );
  }
  confirmDeleteTransaction() {
    if (this.isAdmin) {
      services.transactions
        .adminDelete(this.transactions[0].id)
        .then((response: any) => {
          this.reload();
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        });
    } else {
      services.transactions
        .delete(this.transactions[0].id)
        .then((response: any) => {
          this.reload();
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        });
    }
  }
  reportError() {
    this.$refs.reportErrorModal.show();
  }
  showEdit() {
    this.loadWorkspaces();
    this.loadProjects();
    this.$refs.editModal.show();
  }
  edit() {
    this.loadingEdit = true;
    services.tickets
      .update(this.ticket.id, this.ticket)
      .then(() => {
        this.$emit("ticket-saved", this.ticket.id);
        this.reload();
      })
      .catch((error: any) => {
        this.$refs.errorModal.show(this.$t(error).toString());
      })
      .finally(() => {
        this.$refs.editModal.close();
        this.loadingEdit = false;
      });
  }
  downloadImage() {
    window.location.href = this.ticket.image;
  }
  updatePdf() {
    this.showDropImagesPdf = true;
    this.$refs.uploadPdfModal.show();
  }
  droppedPdf(files: FileBase64[]) {
    this.showDropImagesPdf = false;
    this.$refs.uploadPdfModal.close();
    this.loading = true;
    if (files.length > 0) {
      const formData: FormData = new FormData();
      files.forEach(fileBase64 => {
        // const blob = this.blobCreationFromURL(fileBase64.base64);
        formData.append(
          fileBase64.file.name,
          fileBase64.file,
          fileBase64.file.name
        );
      });

      services.tickets
        .uploadNewPdf(formData, this.ticket)
        .then((response: any) => {
          this.reload();
          this.$refs.successUploadCfdiModal.show("PDF actualizado");
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        });
    }
  }
  scan() {
    this.loading = true;
    services.tickets
      .adminReadTicketLines(this.ticket.id)
      .then(() => {
        this.reload();
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  uploadError() {
    if (
      !this.errorType ||
      (this.errorType ===
        this.$t("app.tickets.uploadErrors.other").toString() &&
        !this.errorDescription)
    ) {
      this.$refs.errorModal.show("Define la descripción del error");
      return;
    }
    this.$refs.confirmReportErrorModal.show(
      this.$t("app.tickets.errors.reportError?").toString(),
      this.$t("shared.report").toString(),
      this.$t("shared.cancel").toString(),
      "El ticket será inválido hasta que el cliente actualice la foto del ticket."
    );
  }
  confirmReportError() {
    this.$refs.reportErrorModal.close();
    if (
      this.errorType !== this.$t("app.tickets.uploadErrors.other").toString()
    ) {
      this.errorDescription = this.errorType;
    }
    services.tickets
      .uploadErrorImages(this.ticket, this.errorDescription, this.errorFiles)
      .then(() => {
        this.reload();
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      });
  }
  droppedNewImage(files: FileBase64[]) {
    if (files.length > 0) {
      const formData: FormData = new FormData();
      files.forEach(fileBase64 => {
        formData.append(
          fileBase64.file.name,
          fileBase64.file,
          fileBase64.file.name
        );
      });
      this.errorFiles = formData;

      services.tickets
        .uploadNewImage(formData, this.ticket)
        .then(() => {
          this.reload();
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        });
    }
  }
  droppedErrors(files: FileBase64[]) {
    if (files.length > 0) {
      const formData: FormData = new FormData();
      files.forEach(fileBase64 => {
        formData.append(
          fileBase64.file.name,
          fileBase64.file,
          fileBase64.file.name
        );
      });
      this.errorFiles = formData;
    }
  }
  confirmUploadXmlAndPdf() {
    if (!this.xmlsAndPdfs) {
      this.$refs.errorModal.show(
        this.$t("app.tickets.uploadErrors.noXmlNorPdf").toString()
      );
      return;
    }
    this.$refs.uploadXmlAndPdfModal.close();
    services.tickets
      .uploadXmlAndPdf(this.xmlsAndPdfs, this.ticket)
      .then(() => {
        services.tickets.updateInvoiced(this.ticket.id, {
          status: TicketStatus.INVOICED,
          website: this.invoicedWithWebsite,
          email: this.invoicedWithEmail,
          phone: this.invoicedWithPhone,
          comments: this.invoicedComments
        });
        this.reload();
        this.$refs.successUploadCfdiModal.show(
          this.$t("app.cfdis.uploadSuccess").toString()
        );
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      });
  }
  droppedFiles(files: FileBase64[]) {
    if (files.length > 0) {
      const formData: FormData = new FormData();
      files.forEach(fileBase64 => {
        formData.append(
          fileBase64.file.name,
          fileBase64.file,
          fileBase64.file.name
        );
      });
      this.xmlsAndPdfs = formData;
      if (this.isAdmin) {
        this.$refs.uploadXmlAndPdfModal.show();
      } else {
        this.confirmUploadXmlAndPdf();
      }
    }
  }
  getTicketFileName(): string {
    return `Ticket #${this.ticket.number} - ${
      this.ticket.taxId
    } - ${filters.dateYMDHMS(this.ticket.createdAt)}.png`;
  }
  isWebsite(valor: string) {
    return (
      valor &&
      (valor.includes("http") ||
        valor.includes(".ai") ||
        valor.includes(".com") ||
        valor.includes(".mx") ||
        valor.includes(".org") ||
        (valor.includes(".") && valor.includes("/")))
    );
  }
  get address() {
    if (this.ticket.workspace) {
      return `${this.ticket.workspace.addressStreet} ${this.ticket.workspace
        .addressExterior ?? ""} ${this.ticket.workspace.addressInterior ?? ""}`;
    }
  }
  get canDelete() {
    return this.isAdmin || this.ticket.status === TicketStatus.PENDING;
  }
  get canEdit() {
    return this.ticket.status === TicketStatus.PENDING || this.isAdmin;
  }
  get transactionType() {
    if (this.transactions && this.transactions.length > 0) {
      return this.transactions[0].type;
    } else {
      return -1;
    }
  }
  get type() {
    if (this.transactions && this.transactions.length > 0) {
      return TransactionType[this.transactions[0].type];
    } else {
      return "Ticket;";
    }
  }
  get transactions(): TransactionDto[] {
    if (
      this.ticket &&
      this.ticket.transactions &&
      this.ticket.transactions.length > 0
    ) {
      return this.ticket.transactions;
    }
    return [];
  }
  get cfdi(): CfdiDto | undefined {
    if (
      this.transactions &&
      this.transactions.length > 0 &&
      this.transactions[0].cfdi
    ) {
      return this.transactions[0].cfdi;
    }
    return undefined;
  }
  get anyProjects() {
    return this.projects && this.projects.length > 0;
  }
}
