



























import Component from "vue-class-component";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import BaseComponent from "../../../components/shared/BaseComponent.vue";

@Component
export default class BillingPeriodToggleComponent extends BaseComponent {
  private changeCurrency(currency) {
    this.$store.commit("pricing/currency", currency);
    this.$emit("selected");
  }
  get products(): SubscriptionProductDto[] {
    return this.$store.state.pricing.products as SubscriptionProductDto[];
  }
  get currencies() {
    const allCurrencies: string[] = [];
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    this.products.forEach(element => {
      element.prices.forEach(price => {
        if (
          this.$store.state.pricing.billingPeriod === price.billingPeriod ||
          true
        ) {
          allCurrencies.push(price.currency.toLowerCase());
        }
      });
    });
    return allCurrencies.filter(onlyUnique);
  }
}
