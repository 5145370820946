






























































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import * as projectHelpers from "@/application/modules/projects/ProjectHelpers";
import { ProjectDto } from "@/application/dtos/app/projects/ProjectDto";

@Component({
  components: {}
})
export default class ProjectSelect extends Vue {
  @Prop() tenantId!: string;
  @Prop({ default: true }) selectAll!: boolean;
  @Prop({ default: "" }) preSelect!: string;
  public selected: number = -1;
  private dropDown: boolean = false;
  private items: ProjectDto[] = [];
  private loading: boolean = false;
  mounted() {
    this.loading = true;
    services.projects
      .getAllProjects(false)
      .then((response: ProjectDto[]) => {
        this.items = response;
        if (this.selectAll) {
          this.selected = 0;
        }
        if (this.preSelect) {
          this.selectId(this.preSelect);
        }
      })
      .catch(error => {
        console.error("[ERROR LOADING]: " + JSON.stringify(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  select(idx) {
    this.selected = idx;
    this.dropDown = false;
    if (this.selectedProject) {
      this.$emit("selectProjectId", this.selectedProject.id);
    } else {
      this.$emit("selectAllProjects");
    }
  }
  selectId(id: string) {
    const idx = this.items.findIndex(f => f.id === id);
    if (idx !== -1) {
      this.selected = idx + 1;
    }
  }
  closeDropDown() {
    this.dropDown = false;
  }
  projectColor(item) {
    return projectHelpers.getProjectColor(item.color);
  }
  get selectedProject(): ProjectDto | undefined {
    if (this.selected > 0) {
      return this.items[this.selected - 1];
    }
  }
}
