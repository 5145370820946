


































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import { mapGetters } from "vuex";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import { SubscriptionCardDto } from "@/application/dtos/master/subscriptions/SubscriptionCardDto";
import services from "@/services";

@Component({
  components: {},
  computed: {
    ...mapGetters("tenant", {
      activeProduct: "activeProduct",
      defaultSubscriptionCard: "defaultSubscriptionCard"
    })
  }
})
export default class TenantSubscriptionStatus extends BaseComponent {
  private activeProduct!: TenantProductDto;
  private defaultSubscriptionCard!: SubscriptionCardDto | undefined;
  mounted() {
    services.subscriptionManager.getCurrentSubscription();
  }

  get getEndOfTrial() {
    if (this.activeProduct && this.activeProduct.trialEnds) {
      try {
        // @ts-ignore
        const date = new Date(this.activeProduct.trialEnds * 1000);

        // const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        // const diffDays = Math.round(
        //   Math.abs((date.getTime() - Date.now()) / oneDay)
        // );
        return this.$d(date, "short", this.$store.state.locale.locale);
      } catch (ex) {
        // ignore
      }
    }

    return "";
  }
  get getEndsIn() {
    if (this.activeProduct && this.activeProduct.trialEnds) {
      try {
        // @ts-ignore
        const date = new Date(this.activeProduct.trialEnds * 1000);

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = Math.round(
          Math.abs((date.getTime() - Date.now()) / oneDay)
        );
        if (!diffDays) {
          return "";
        }
        let untilDate: string =
          this.$t("shared.ends") +
          " " +
          this.$t("shared.in") +
          " " +
          diffDays +
          " " +
          this.$t("shared.days");
        if (diffDays === 0) {
          untilDate = this.$t("shared.today").toString();
        } else if (diffDays === 1) {
          untilDate = this.$t("shared.tomorrow").toString();
        }
        return untilDate;
      } catch (ex) {
        // ignore
      }
    }

    return "";
  }
}
