import { ApiService, ApiServiceType } from "../ApiService";

export class EStoreService extends ApiService {
  constructor() {
    super("Ecommerce/Store", ApiServiceType.MasterDatabase);
  }
  getAllStores(): Promise<any[]> {
    return super.getAll("GetAll");
  }
  getProductStock(id: string): Promise<any> {
    return super.get("Get", id);
  }
}
