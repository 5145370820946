

























































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/views/app/erp/shared/Breadcrumb.vue";
import Tabs from "@/components/shared/tabs/Tabs.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ModalComponent from "@/components/shared/modals/Modal.vue";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import NewQuote from "@/components/app/erp/sales/pos/NewQuote.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import store from "@/store";

@Component({
  components: {
    Breadcrumb,
    Tabs,
    NewErrorModal,
    ConfirmModal,
    NewModalComponent,
    NewQuote
  }
})
export default class SalesTable extends BaseComponent {
  $refs!: {
    modalError: ErrorModalComponent;
    // modalConfirm: ModalComponent;
  };
  tabs: any[] = [];
  reloadButtonActive: boolean = false;
  addButtonActive: boolean = false;
  mounted() {
    tinyEventBus().emitter.on(
      `settings-init-reload`,
      active => (this.reloadButtonActive = active)
    );
    tinyEventBus().emitter.on(
      `settings-init-add`,
      active => (this.addButtonActive = active)
    );
    this.tabs = [
      // {
      //   name: this.$t("settings.profile.title"),
      //   routeName: "settings.profile",
      //   current: false
      // },
      {
        name: this.$t("settings.tenant.members.title"),
        routeName: "settings.tenant.members",
        current: false,
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN]
      },
      {
        name: this.$t("models.workspace.plural"),
        routeName: "app.workspaces",
        current: false,
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN]
      },
      {
        name: this.$t("settings.tenant.title"),
        routeName: "settings.tenant.general",
        current: false,
        userRoles: [TenantUserRole.OWNER]
      },
      {
        name: this.$t("settings.tenant.subscription.title"),
        routeName: "settings.tenant.subscription",
        current: false,
        userRoles: [TenantUserRole.OWNER]
      }
    ];
    this.tabs.forEach(tab => {
      tab.current = tab.routeName === this.$route.name;
    });
  }
  beforeDestroy() {
    tinyEventBus().emitter.off(`settings-init-reload`);
    tinyEventBus().emitter.off(`settings-init-add`);
  }
  reload() {
    tinyEventBus().emitter.emit(`${this.$route.name}-reload`);
  }
  add() {
    tinyEventBus().emitter.emit(`${this.$route.name}-add`);
  }
  get myTabs() {
    return this.tabs.filter(
      f =>
        !f.userRoles ||
        f.userRoles.includes(store.state.tenant.current?.currentUser.role)
    );
  }
}
