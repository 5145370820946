











































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { TicketStatus } from "../../../application/enums/app/transactions/TicketStatus";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { TransactionDto } from "@/application/dtos/app/transactions/TransactionDto";
import TicketItemDetails from "./TicketItemDetails.vue";
import BadgeTicket from "@/components/app/badges/BadgeTicket.vue";
import BadgeExpense from "@/components/app/badges/BadgeExpense.vue";
import BadgeCheck from "@/components/app/badges/BadgeCheck.vue";
import { ProjectDto } from "../../../application/dtos/app/projects/ProjectDto";
import * as projectHelpers from "@/application/modules/projects/ProjectHelpers";
import { WhoInvoices } from "@/application/enums/app/cfdis/WhoInvoices";

@Component({
  components: {
    TicketItemDetails,
    BadgeTicket,
    BadgeExpense,
    BadgeCheck
  }
})
export default class TicketTableItem extends Vue {
  @Prop({ default: false }) admin!: boolean;
  @Prop() id!: string;
  @Prop() createdSince!: string;
  @Prop() createdAt!: Date;
  @Prop() createdByUser!: UserDto;
  @Prop() description!: string;
  @Prop() error!: string;
  @Prop() number!: number;
  @Prop() status!: TicketStatus;
  @Prop() scannedLines!: string[];
  @Prop() taxId!: string;
  @Prop() legalName!: string;
  @Prop() cfdiUse!: string;
  @Prop() tenant!: TenantDto;
  @Prop() transactions!: TransactionDto[];
  @Prop() imagen!: string;
  @Prop({ default: false }) allowShowDetails!: boolean;
  @Prop() possibleTotal!: number;
  @Prop() possibleWebsite!: string;
  @Prop() possibleDate!: number;
  @Prop() possibleFolio!: number;
  @Prop() errorDescription!: string;
  @Prop() project!: ProjectDto;
  @Prop() comments!: string;
  @Prop() whoInvoices!: WhoInvoices;
  @Prop() scanned!: boolean;
  private showDetails: boolean = false;
  avatarText() {
    if (this.createdByUser?.firstName && this.createdByUser?.lastName) {
      return this.createdByUser?.firstName[0] + this.createdByUser?.lastName[0];
    } else if (this.createdByUser?.firstName) {
      return this.createdByUser?.firstName[0];
    } else {
      return "?";
    }
  }
  projectColor() {
    return projectHelpers.getProjectColor(this.project?.color);
  }
  get whoInvoicesDescription() {
    return this.$t("app.tickets.whoInvoices." + this.whoInvoices);
  }
  get isExpense() {
    return this.status === 2;
  }
}
