import { GetAllCustomersRequest } from "@/application/contracts/app/erp/contacts/customers/GetAllCustomersRequest";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";
import { ApiService, ApiServiceType } from "@/services/api/ApiService";
import store from "@/store";

export class CustomerService extends ApiService {
  constructor() {
    super("Contact/Customer", ApiServiceType.TenantDatabase);
  }
  getAllCustomers(request: GetAllCustomersRequest): Promise<CustomerDto[]> {
    return new Promise((resolve, reject) => {
      return super
        .getAll("GetAll?" + super.toQueryString(request))
        .then((response: CustomerDto[]) => {
          resolve(JSON.parse(JSON.stringify(response)));
          store.commit("customers/customers", response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  get(id: string): Promise<CustomerDto> {
    return super.get("Get", id);
  }
  create(data: CustomerDto): Promise<CustomerDto> {
    return super.post(data);
  }
  update(id: string, data: CustomerDto): Promise<CustomerDto> {
    return super.put(id, data);
  }
  delete(id: string): Promise<any> {
    return super.delete(id);
  }
}
