export enum PaymentForm {
  Cash,
  Check,
  Transfer,
  CreditCard,
  ElectronicPurse,
  ElectronicMoney,
  Voucher,
  DationToPayment,
  SurrogacyPayment,
  PaymentByConsignment,
  Condonation,
  Compensation,
  Novation,
  Confusion,
  DebtRemittance,
  PrescriptionOrExpiration,
  ToTheSatisfactionOfTheCreditor,
  DebitCard,
  ServicesCard,
  ApplicationOfAdvances,
  PaymentIntermediary,
  ToDefine,
}
