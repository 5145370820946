


































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({})
export default class DropdownSimple extends Vue {
  @Prop() title!: string;
  @Prop({ default: "lg" }) size!: string;
  @Prop({ default: true }) border!: boolean;
  private show: boolean = false;
  close() {
    this.show = false;
  }
}
