

























































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { JsonHubProtocol } from "@aspnet/signalr";
import { nextTick } from "process";

@Component
export default class ErrorModalComponent extends Vue {
  $refs!: {
    closeButton: HTMLElement;
  };
  private title!: string;
  private closeText!: any;
  private showing: boolean = false;
  private errorDescription!: string;
  private errors: string[] = [];
  mounted() {
    this.title = this.$t("shared.error").toString();
    this.closeText = this.$t("shared.accept").toString();
  }
  public show(error: any, closeText?: any) {
    if (error.length > 300 && process.env.NODE_ENV !== "production") {
      error = error.substring(0, 300);
    }
    if (
      error
        .toString()
        .trim()
        .toLowerCase() === "Network Error".toLowerCase()
    ) {
      error = this.$i18n.t("shared.networkError").toString();
    }
    if (
      error
        .toString()
        .trim()
        .toLowerCase() === "System.TimeoutException".toLowerCase()
    ) {
      error = this.$i18n.t("shared.timeout").toString();
    }

    if (closeText) {
      this.closeText = closeText;
    }
    if (error.toString().includes("<!DOCTYPE")) {
      error = "Tiempo de espera agotado";
    }
    this.errorDescription = error;
    this.showing = true;
    nextTick(() => {
      if (this.$refs.closeButton) {
        this.$refs.closeButton.focus();
      }
    });
    setTimeout(() => {
      window.addEventListener("keyup", this.keyup);
    }, 1000);
  }
  public close() {
    window.removeEventListener("keyup", this.keyup);
    this.showing = false;
    this.$emit("closed");
  }
  public showErrors(errors: string[]) {
    if (errors.length > 1) {
      this.title = this.$t("shared.errors").toString();
    } else {
      this.title = this.$t("shared.error").toString();
    }
    this.errors = errors;
    this.showing = true;
  }
  private keyup(event: KeyboardEvent) {
    // console.log({ event, showing: this.showing });
    if (event.keyCode === 13) {
      this.close();
    }
  }
}
