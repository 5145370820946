import { appSettingsRoutes } from "./appSettingsRoutes";
import { i18n } from "@/plugins/vue-i18n";
import { defaultProducts } from "@/store/modules/pricing/default-pricing";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import erpRoutes from "./erp/erpRoutes";
const products = defaultProducts;

export const appRoutes = {
  name: "app",
  path: "/",
  alias: "/app",
  component: require("@/views/app/Index.vue").default,
  meta: {
    requiresAuth: true,
  },
  redirect: "/erp",
  children: [
    {
      name: "app.dashboard",
      path: "portal",
      alias: "dashboard",
      component: require("@/views/app/dashboard/Dashboard.vue").default,
      meta: {
        title: i18n.t("shared.dashboard"),
      },
    },
    ...erpRoutes,
    // {
    //   name: "app.transactions",
    //   alias: "transactions",
    //   path: "transacciones",
    //   component: require("@/views/app/transactions/Transactions.vue").default,
    //   meta: {
    //     requiresAuth: true,
    //     title: i18n.t("models.transaction.plural"),
    //     subscriptions: [products[1], products[2]],
    //   },
    //   children: [
    //     {
    //       name: "app.transactions.new",
    //       alias: "new",
    //       path: "nueva",
    //       component: require("@/views/app/transactions/TransactionsForm.vue").default,
    //     },
    //     {
    //       name: "app.transactions.new.manual",
    //       path: "manual",
    //       component: require("@/views/app/transactions/TransactionsForm.vue").default,
    //     },
    //     {
    //       name: "app.transactions.edit",
    //       path: ":id/editar",
    //       alias: ":id/edit",
    //       component: require("@/views/app/transactions/TransactionsForm.vue").default,
    //     },
    //   ],
    // },
    // {
    //   name: "app.tickets",
    //   alias: "receipts",
    //   path: "tickets",
    //   component: require("@/components/app/tickets/Tickets.vue").default,
    //   meta: {
    //     requiresAuth: true,
    //     title: i18n.t("models.ticket.plural"),
    //   },
    //   children: [
    //     {
    //       name: "app.tickets.details",
    //       path: ":id",
    //       component: require("@/views/app/tickets/TicketItemDetails.vue").default,
    //     },
    //   ],
    // },
    // {
    //   name: "app.invoices",
    //   alias: "invoices",
    //   path: "facturas",
    //   component: require("@/components/app/transactions/Transactions.vue").default,
    //   meta: {
    //     requiresAuth: true,
    //     title: i18n.t("models.invoice.plural"),
    //   },
    //   children: [
    //     {
    //       name: "app.invoices.details",
    //       alias: "receipt/:id",
    //       path: "ticket/:id",
    //       component: require("@/views/app/tickets/TicketItemDetails.vue").default,
    //     },
    //     {
    //       name: "app.invoices.edit",
    //       alias: ":id/edit",
    //       path: ":id/editar",
    //       component: require("@/views/app/tickets/TransactionItemDetails.vue").default,
    //     },
    //     {
    //       name: "app.invoices.details.ticket",
    //       alias: ":id/receipt",
    //       path: ":id/ticket",
    //       component: require("@/views/app/tickets/TicketItemDetails.vue").default,
    //     },
    //   ],
    // },
    // {
    //   name: "app.tickets.details",
    //   alias: "ticket/:id",
    //   component: require("@/components/app/tickets/TicketFullDetails.vue")
    //     .default,
    //   meta: {
    //     requiresAuth: true,
    //     title: "Ticket",
    //   },
    // },
    {
      name: "app.tickets.upload",
      alias: "upload/receipts/:action?",
      path: "subir/tickets/:action?",
      component: require("@/views/app/tickets/TicketsUpload.vue").default,
      meta: {
        requiresAuth: true,
        title: i18n.t("app.tickets.uploadTickets"),
      },
    },
    // {
    //   name: "app.cfdis.upload",
    //   alias: "upload/cfdis",
    //   path: "subir/cfdis",
    //   component: require("@/views/app/cfdis/CfdisUpload.vue").default,
    //   meta: {
    //     requiresAuth: true,
    //     title: i18n.t("app.cfdis.uploadCfdis"),
    //   },
    // },
    {
      name: "app.reports",
      alias: "reports",
      path: "reportes",
      component: require("@/views/app/reports/Reports.vue").default,
      meta: {
        requiresAuth: true,
        title: i18n.t("app.reports.title"),
      },
    },
    {
      name: "app.suppliers",
      alias: "suppliers",
      path: "proveedores",
      component: require("@/views/app/contacts/Suppliers.vue").default,
      meta: {
        requiresAuth: true,
        title: i18n.t("models.supplier.plural"),
      },
    },
    {
      name: "app.customers",
      alias: "customers",
      path: "clientes",
      component: require("@/views/app/contacts/Customers.vue").default,
      meta: {
        requiresAuth: true,
        title: i18n.t("models.customer.plural"),
      },
    },
    {
      name: "app.projects",
      alias: "projects",
      path: "proyectos",
      component: require("@/views/app/projects/ProjectsList.vue").default,
      meta: {
        requiresAuth: true,
        title: i18n.t("models.project.plural"),
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
      children: [
        {
          name: "app.projects.new",
          alias: "new",
          path: "nuevo",
          component: require("@/views/app/projects/ProjectsForm.vue").default,
          meta: {
            roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
          },
        },
        {
          name: "app.projects.edit",
          alias: "edit/:id",
          path: "editar/:id",
          component: require("@/views/app/projects/ProjectsForm.vue").default,
          meta: {
            roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
          },
        },
      ],
    },
    // {
    //   name: "app.resources",
    //   alias: "resources",
    //   component: require("@/views/app/resources/MyResources.vue").default,
    //   meta: {
    //     title: i18n.t("models.resource.plural"),
    //   },
    // },
    {
      name: "app.unauthorized",
      path: "no-autorizado",
      alias: "unauthorized",
      component: require("@/views/app/common/Unauthorized.vue").default,
      meta: {
        title: i18n.t("shared.unauthorized"),
      },
    },
    appSettingsRoutes,
  ],
};
