import { Module } from "vuex";
import { RootState } from "@/types/stateTypes";
import { ProductsState } from "@/types/erpStateTypes";
import { ImageResolution } from "@/application/enums/app/common/ImageResolution";
import { ProductImageDto } from "@/application/dtos/app/erp/v3/inventory/ProductImageDto";

export const state: ProductsState = {
  images: [],
};

export const products: Module<ProductsState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    reset(state) {
      state.images = [];
    },
    images(state, payload) {
      state.images = payload;
    },
    addProductImage(state, payload: ProductImageDto) {
      state.images.push(payload);
    },
    removeProductImage(state, id) {
      state.images = state.images.filter((f) => f.productId !== id);
    },
  },
};
