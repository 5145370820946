















































































import Component from "vue-class-component";
import BaseComponent from "../../../shared/BaseComponent.vue";
import PageTitle from "../../../shared/navigation/PageTitle.vue";
import Modal from "../../../shared/modals/Modal.vue";
import { ProjectDto } from "../../../../application/dtos/app/projects/ProjectDto";
import { ProjectStatus } from "@/application/enums/app/projects/ProjectStatus";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import ProjectItem from "@/views/app/projects/ProjectItem.vue";
import { Prop } from "vue-property-decorator";
import { Period } from "@/application/enums/app/common/Period";
import services from "@/services";

@Component({
  components: {
    Modal,
    PageTitle,
    ProjectItem
  }
})
export default class ProyectosActivosComponent extends BaseComponent {
  @Prop({ default: false }) summary!: boolean;
  @Prop({ default: Period.ALL }) initialPeriod!: Period;
  $refs!: {
    uploadTicketsModal: Modal;
    projectItem: ProjectItem[];
  };
  private items: ProjectDto[] = [];
  private projectId: string = "";
  private users: TenantUserDto[] = [];
  private period: Period = Period.ALL;
  mounted() {
    this.period = this.initialPeriod;
    this.reload();
  }
  reloadPeriod(period: Period) {
    this.period = period;
    for (let index = 0; index < this.activos.length; index++) {
      this.$refs.projectItem[index].cargarResumen(period);
      //   this.items.forEach(project => {
    }
    //   });
  }
  async reload() {
    this.loading = true;
    this.items = [];
    services.projects
      .getAllProjects(true)
      .then((response: ProjectDto[]) => {
        this.items = response;
      })
      .catch(error => {
        console.error(
          "[ERROR LOADING ACTIVE PROJECTS] " + JSON.stringify(error)
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }
  projectsByStatus(status: ProjectStatus) {
    return this.items.filter(f => f.status === status);
  }
  updatedEstatus(id, status) {
    const item = this.items.find(f => f.id === id);
    if (item) {
      item.status = status;
    }
  }
  get activos() {
    return this.projects.filter(f => f.status === ProjectStatus.ACTIVE);
  }
  get projects() {
    if (!this.items) {
      return [];
    }
    return this.items;
  }
}
