
















































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import { Prop, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

@Component({
  components: {},
})
export default class NavigationTabsComponent extends BaseComponent {
  @Prop()
  route: any;
  onChange(event) {
    this.$router.push({ name: event.target.value });
  }
}
