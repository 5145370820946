























import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import Header from "@/components/marketing/Header.vue";
import Products from "../../components/marketing/pricing/Products.vue";
import TableRowSkeleton from "../../components/shared/skeletons/TableRowSkeleton.vue";
import Faq from "../../components/marketing/Faq.vue";
import Footer from "../../components/marketing/Footer.vue";
import { SubscriptionBillingPeriod } from "../../application/enums/master/subscriptions/SubscriptionBillingPeriod";
import { WhoInvoices } from "../../application/enums/app/cfdis/WhoInvoices";

@Component({
  components: {
    Header,
    Products,
    TableRowSkeleton,
    Faq,
    Footer
  }
})
export default class PricingComponent extends BaseComponent {
  mounted() {
    this.$store.commit(
      "pricing/billingPeriod",
      SubscriptionBillingPeriod.MONTHLY
    );
    this.$store.commit("pricing/mode", WhoInvoices.ADMIN);
  }
}
