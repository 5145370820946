import { UploadCfdisRequest } from "@/application/contracts/app/cfdis/UploadCfdisRequest";
import { CfdiDto } from "@/application/dtos/app/cfdis/CfdiDto";
import { CfdiXmlPdfDto } from "@/application/dtos/app/cfdis/CfdiXmlPdfDto";
import { ApiService, ApiServiceType } from "../../ApiService";

export class CfdiService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("Cfdi", ApiServiceType.TenantDatabase);
  }
  adminGetAll(): Promise<CfdiDto[]> {
    return this.api.getAll("Admin/GetAll");
  }
  getAll(): Promise<CfdiDto[]> {
    return this.api.getAll();
  }
  get(id: string): Promise<CfdiDto> {
    return this.api.get("Get", id);
  }
  create(data: CfdiDto): Promise<CfdiDto> {
    return this.api.post(data);
  }
  readCfdis(data: UploadCfdisRequest): Promise<CfdiXmlPdfDto[]> {
    return this.api.post(data, "ReadCfdis");
  }
  uploadCfdis(data: UploadCfdisRequest): Promise<any> {
    return this.api.post(data, "UploadCfdis");
  }
  download(id: string, fileType: string): Promise<CfdiDto> {
    return this.api.download(undefined, `Download/${id}/${fileType}`);
  }
  update(id: string, data: CfdiDto): Promise<CfdiDto> {
    return this.api.put(id, data);
  }
  delete(id: string): Promise<any> {
    return this.api.delete(id);
  }
  adminDelete(id: string): Promise<any> {
    return this.api.delete(id, "Admin/Delete");
  }
}
