




























































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";

@Component({})
export default class CustomerTableRow extends Vue {
  @Prop({ default: -1 }) idx!: number;
  @Prop({ default: undefined }) item!: CustomerDto;
  @Prop({ default: false }) allowRemove!: boolean;
  emails(item: CustomerDto) {
    const emails: string[] = [];
    item.taxIds?.forEach(taxId => {
      if (
        taxId.email1 &&
        taxId.email1.trim() !== "" &&
        emails.includes(taxId.email1.toLowerCase().trim()) === false
      ) {
        emails.push(taxId.email1.toLowerCase().trim());
      }
      if (
        taxId.email2 &&
        taxId.email2.trim() !== "" &&
        emails.includes(taxId.email2.toLowerCase().trim()) === false
      ) {
        emails.push(taxId.email2.toLowerCase().trim());
      }
    });
    return emails;
  }
  phoneNumbers(item: CustomerDto) {
    const numbers: string[] = [];
    item.taxIds?.forEach(taxId => {
      if (
        taxId.phone &&
        taxId.phone.trim() !== "" &&
        numbers.includes(taxId.phone.toLowerCase().trim()) === false
      ) {
        numbers.push(taxId.phone.toLowerCase().trim());
      }
    });
    return numbers;
  }
}
