















































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ButtonGroup extends BaseComponent {
  $refs!: {};
  @Prop({}) value!: any;
  @Prop({ default: "blue" }) color!: string;
  @Prop({ default: undefined }) title!: string;
  @Prop({}) options!: any[];
  select(value) {
    this.model = value;
  }
  optionClass(option) {
    if (option.value === this.value) {
      return `bg-${this.color}-100 border-${this.color}-200`;
    }
    return `z-0 hover:bg-${this.color}-50  border-${this.color}-200`;
  }
  get model() {
    return this.value;
  }
  set model(value) {
    this.$emit("input", value);
  }
}
