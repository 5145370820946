import { ApiService, ApiServiceType } from "../../ApiService";
import { SummaryResponse } from "@/application/contracts/app/summaries/SummaryResponse";
import { PeriodRequest } from "@/application/contracts/app/shared/PeriodRequest";
import { SummaryCountTotalResponse } from "@/application/contracts/app/summaries/SummaryCountTotalResponse";
import { SummaryCountTotalType } from "@/application/enums/app/transactions/SummaryCountTotalType";

export class DashboardService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("Dashboard", ApiServiceType.TenantDatabase);
  }
  // tslint:disable-next-line: max-line-length
  adminGetSummaryCountTotal(type: SummaryCountTotalType, tenantId: string, periodRequest: PeriodRequest): Promise<SummaryCountTotalResponse[]> {
    return this.api.get(`/Admin/GetSummaryCountTotal/${type}/${tenantId}?` + this.api.toQueryString(periodRequest));
  }
  getSummary(periodRequest: PeriodRequest): Promise<SummaryResponse> {
    return this.api.get(`GetSummary?` + this.api.toQueryString(periodRequest));
  }
  // tslint:disable-next-line: max-line-length
  getSummaryCountTotal(type: SummaryCountTotalType, periodRequest?: PeriodRequest, id?: string): Promise<SummaryCountTotalResponse[]> {
    let query = this.api.toQueryString(periodRequest);
    if (id) {
      query += "&id=" + id;
    }
    return this.api.get(`GetSummaryCountTotal/${type}?` + query);
  }
}
