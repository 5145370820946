








































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { Prop } from "vue-property-decorator";
import { Period } from "@/application/enums/app/common/Period";
import { SummaryCountTotalResponse } from "@/application/contracts/app/summaries/SummaryCountTotalResponse";
import { mapGetters } from "vuex";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import DropdownOptions from "@/components/shared/dropdowns/DropdownOptions.vue";
import UserMultiselect from "@/components/shared/selectors/UserMultiselect.vue";
import BadgeCheck from "@/components/app/badges/BadgeCheck.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { WorkspaceUserDto } from "@/application/dtos/app/workspaces/WorkspaceUserDto";
import { Colors } from "@/application/enums/app/common/Colors";
import { Role } from "@/application/enums/app/common/Role";
import * as ProjectHelpers from "@/application/modules/projects/ProjectHelpers";
import { SummaryCountTotalType } from "@/application/enums/app/transactions/SummaryCountTotalType";
import services from "@/services";

@Component({
  components: {
    DropdownOptions,
    // ColorSelect,
    UserMultiselect,
    BadgeCheck,
    ConfirmModal
  },
  computed: {
    ...mapGetters("account", {
      user: "user"
    }),
    ...mapGetters("tenant", {
      isOwnerOrAdmin: "isOwnerOrAdmin"
    })
  }
})
export default class WorkspaceItem extends BaseComponent {
  $refs!: {
    options: DropdownOptions;
    addModal: ConfirmModalComponent;
    removeModal: ConfirmModalComponent;
  };

  @Prop({ default: false }) showSummary!: boolean;
  @Prop({ default: "" }) id!: string;
  @Prop({ default: Colors.UNDEFINED }) color!: Colors;
  @Prop({ default: "" }) name!: string;
  @Prop({ default: "" }) taxId!: string;
  @Prop({ default: "" }) legalName!: string;
  @Prop({ default: "" }) cfdiUse!: string;
  @Prop({ default: "" }) addressStreet!: string;
  @Prop({ default: "" }) addressExterior!: string;
  @Prop({ default: "" }) addressInterior!: string;
  @Prop({ default: "" }) addressNeighborhood!: string;
  @Prop({ default: "" }) addressCity!: string;
  @Prop({ default: "" }) addressZip!: string;
  @Prop({ default: "" }) addressState!: string;
  @Prop({ default: "" }) addressCountry!: string;
  @Prop({ default: false }) default!: boolean;
  @Prop({ default: [] }) users!: WorkspaceUserDto[];
  @Prop({ default: "" }) createdByUserId!: string;
  @Prop({ default: 0 }) budget!: number;
  @Prop({ default: Period.ALL }) initialPeriod!: Period;
  private period: Period = Period.ALL;
  private user!: UserDto;
  private loadingSummary: boolean = false;
  private userId: string = "";
  private isOwnerOrAdmin!: boolean;
  private summary: SummaryCountTotalResponse = {
    type: SummaryCountTotalType.WORKSPACE,
    name: "",
    description: "",
    ticketsTotal: 0,
    ticketsCount: 0,
    expenseTotal: 0,
    expenseCount: 0,
    incomeTotal: 0,
    incomeCount: 0,
    incomePercentageTotal: 0,
    incomePercentageCount: 0,
    expensePercentageTotal: 0,
    expensePercentageCount: 0
  };
  mounted() {
    // this.loadSummary(this.initialPeriod);
  }
  loadSummary(period: Period) {
    this.period = period;
    this.loadingSummary = true;
    services.dashboard
      .getSummaryCountTotal(
        SummaryCountTotalType.WORKSPACE,
        { period, withDetails: false },
        this.id
      )
      .then((summary: SummaryCountTotalResponse[]) => {
        if (summary && summary.length > 0) {
          this.summary = summary[0];
        }
      })
      .finally(() => {
        this.loadingSummary = false;
      });
  }
  closeOptions() {
    this.$refs.options.close();
  }
  yesAdd() {
    services.workspaces
      .addUser(this.id, {
        id: undefined,
        userId: this.userId,
        workspaceId: this.id,
        role: Role.MEMBER
      })
      .then((response: WorkspaceUserDto) => {
        this.users.push(response);
      });
  }
  yesRemove() {
    const removeUserId = this.userId;
    const user = this.users.find(f => f.userId === removeUserId);
    if (user) {
      services.workspaces.removeUser(user.id).then(() => {
        this.users = this.users.filter(f => f.userId !== removeUserId);
      });
    }
  }
  addedUser(
    userId: string,
    email: string,
    firstName: string,
    lastName: string
  ) {
    this.userId = userId;
    let userAdded = firstName;
    if (!userAdded || userAdded.toString().trim() === "") {
      userAdded = email;
    }
    this.$refs.addModal.show(
      this.$t("shared.addTo?", [email, this.name]).toString(),
      this.$t("shared.add").toString(),
      this.$t("shared.no").toString()
    );
  }
  removedUser(
    userId: string,
    email: string,
    firstName: string,
    lastName: string
  ) {
    this.userId = userId;
    let userRemoved = firstName;
    if (!userRemoved || userRemoved.toString().trim() === "") {
      userRemoved = email;
    }
    this.$refs.removeModal.show(
      this.$t("shared.removeFrom?", [userRemoved, this.taxId]).toString(),
      this.$t("shared.add").toString(),
      this.$t("shared.no").toString(),
      this.$t("app.tickets.warnings.removingUser").toString()
    );
  }
  get userEmails() {
    return this.users.map(f => f.user?.email).join(", ");
  }
  get isProyectOwnerOrAdmin() {
    if (this.isOwnerOrAdmin) {
      return true;
    }
    if (this.createdByUserId === this.user.id) {
      return true;
    }
    const usuario = this.users.find(f => f.userId === this.user.id);
    return usuario && usuario.role === Role.ADMINISTRATOR;
  }
  get isProyectOwnerOrAdmins() {
    const ids: string[] = [];
    ids.push(this.createdByUserId);
    const admins = this.users.filter(f => f.role === Role.ADMINISTRATOR);
    admins.forEach(element => {
      ids.push(element.userId);
    });
    return ids;
  }
  get idsUsers(): string[] {
    return this.users.map(f => f.userId);
  }
  get remaining() {
    return this.budget - this.total;
  }
  get total() {
    if (this.summary) {
      return this.summary.ticketsTotal + this.summary.expenseTotal;
    }
    return 0;
  }
  get companyColor() {
    return ProjectHelpers.getProjectColor(Colors.COOL_GRAY, 600, 100, 300);
  }
}
