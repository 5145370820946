


































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import TableWithSearch from "@/components/shared/tables/TableWithSearch.vue";
import { PagedModel } from "@/application/contracts/PagedModel";
import ErpService from "@/services/api/app/erp/ErpService";
import { ProductStockUnitDto } from "@/services/api/app/erp/inventory/ProductStockUnitDto";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";

@Component({
  components: {
    TableWithSearch,
    NewErrorModal
  }
})
export default class ProductUnitSearch extends BaseComponent {
  $refs!: {
    table: TableWithSearch;
    loadingButton: LoadingButton;
    inputLot: HTMLInputElement;
    modalError: ErrorModalComponent;
  };
  private itemsPagination: PagedModel<ProductStockUnitDto> = {
    pagination: {
      maxPageSize: 0,
      pageSize: 0,
      currentPage: 0,
      totalItems: 0,
      totalPages: 0
    },
    items: []
  };
  private error: string = "";
  private lot: string = "";
  private columns: any[] = [
    { name: "wharehouseId", title: "Almacén", thClass: "", trClass: "" },
    { name: "lot", title: "Teñida", thClass: "", trClass: "" },
    { name: "lotNumber", title: "Caja", thClass: "", trClass: "" },
    {
      name: "productId + product.name",
      title: "Artículo",
      separator: " - ",
      thClass: "w-10 truncate ",
      trClass: "w-10 truncate"
    },
    { name: "quantity", title: "Cantidad", thClass: "", trClass: "text-right" },
    {
      name: "availableUnits",
      title: "Piezas",
      thClass: "",
      trClass: "text-right"
    }
  ];
  private currentPage: number = 1;
  mounted() {
    this.focusInput();
  }
  focusInput() {
    this.$nextTick(() => {
      this.$refs.inputLot.focus();
      this.$refs.inputLot.select();
    });
  }
  nextPage(addPages) {
    this.currentPage += addPages;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    } else if (this.currentPage > this.itemsPagination.pagination.totalPages) {
      this.currentPage = this.itemsPagination.pagination.totalPages;
    }
    this.reload();
  }
  reload() {
    this.$refs.loadingButton.start();
    this.loading = true;
    this.error = "";
    this.itemsPagination.items = [];
    ErpService.inventory.productBarcodeReads
      .search({
        currentPage: 1,
        pageSize: 5000,
        lot: this.lot,
        search: this.$refs.table?.searchInput ?? "",
        onlyAvailable: true
      })
      .then(response => {
        this.itemsPagination = response;
        this.itemsPagination.items = this.itemsPagination.items
          .sort((x, y) => {
            if (x.lot && y.lot) {
              return (x.lot > y.lot ? 1 : -1) ?? 1;
            }
            return 1;
          })
          .sort((x, y) => {
            if (x.lotNumber && y.lotNumber) {
              return (x.lotNumber > y.lotNumber ? -1 : 1) ?? -1;
            }
            return -1;
          });
        if (this.itemsPagination.items.length === 0) {
          // this.$refs.modalError.show("No hay paquetes con lote: " + this.lot);
          this.$emit("error", "No hay paquetes con lote: " + this.lot);
        }
      })
      .catch(error => {
        console.error(this.$t(error));
        this.error = this.$t(error).toString();
      })
      .finally(() => {
        this.loading = false;
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  selected(item) {
    this.$emit("selected", item);
  }
  // added(item: ProductStockUnitDto) {
  //   this.$refs
  // }
}
