
























































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import TableWithSearch from "@/components/shared/tables/TableWithSearch.vue";
import ProductSelect from "@/components/app/erp/inventory/selectors/ProductSelect.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import { ProductStockUnitDto } from "@/services/api/app/erp/inventory/ProductStockUnitDto";
import { ProductDto } from "@/application/dtos/app/erp/v3/inventory/ProductDto";
import NewErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import { nextTick } from "process";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { Prop } from "vue-property-decorator";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { filters } from "@/plugins/filters";

@Component({
  components: {
    TableWithSearch,
    ProductSelect,
    NewErrorModal,
    ConfirmModal,
    LoadingButton
  }
})
export default class ProductStockUnitSearch extends BaseComponent {
  $refs!: {
    table: TableWithSearch;
    modalError: ErrorModalComponent;
    modalConfirm: ConfirmModalComponent;
    inputQuantity: HTMLInputElement;
    loadingButton: LoadingButton;
  };
  @Prop({ default: undefined }) quote!: SaleDto;
  private items: ProductStockUnitDto[] = [];
  private error: string = "";
  private loaded: boolean = false;
  private productId: string = "";
  private quantity: number = 0;
  search() {
    this.error = "";
    if (!this.productId) {
      this.error = "Selecciona artículo";
      return;
    }
    if (this.quantity === 0) {
      this.error = "Ingresa una cantidad para cotizar";
      return;
    }
    this.$refs.loadingButton.start();
    // this.loading = true;
    this.error = "";
    this.items = [];
    ErpService.inventory.stock
      .getUnitsNeeded(this.productId, this.quantity)
      .then(response => {
        this.items = response;
        if (response.length === 0) {
          this.error = "No hay existencia disponible";
        } else if (response.length === 1) {
          this.$refs.modalConfirm.show(
            `¿Tomar ${this.items.length} paquete: ${this.quantityFound} kg's, ${this.unitsFound} pz's?`,
            "Aceptar",
            "Cancelar",
            this.quote
              ? `Se agregará a cotización ${this.quote.type}-${this.quote.folio}.`
              : "Se creará cotización con este paquete."
          );
        } else {
          this.$refs.modalConfirm.show(
            `¿Tomar ${this.items.length} paquete(s): ${this.quantityFound} kg's, ${this.unitsFound} pz's?`,
            "Aceptar",
            "Cancelar",
            this.quote
              ? `Se agregarán a cotización ${this.quote.type}-${this.quote.folio}.`
              : "Se creará cotización con estos paquetes."
          );
        }
      })
      .catch(error => {
        console.error(this.$t(error));
        this.error = this.$t(error).toString();
        // this.$refs.errorModal
      })
      .finally(() => {
        // this.loading = false;
        this.$refs.loadingButton.stop();
        this.loaded = true;
      });
  }
  selected(item) {
    this.$emit("selected", item);
  }
  selectedProduct(value: string, data: ProductDto) {
    this.productId = value;
    nextTick(() => {
      this.$refs.inputQuantity.focus();
      this.$refs.inputQuantity.select();
    });
  }
  confirm() {
    if (this.quote) {
      this.$emit("add", this.items);
    } else {
      // TODO: CREATE QUOTE WITH ITEMS
    }
  }
  get quantityFound() {
    let total = 0;
    this.items.forEach(element => {
      total += element.quantity;
    });
    return filters.decimalFormat(total.toFixed(2));
  }
  get unitsFound() {
    let total = 0;
    this.items.forEach(element => {
      total += element.availableUnits;
    });
    return filters.intFormat(total.toFixed(2));
  }
}
