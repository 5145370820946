import { WarehouseDto } from "@/application/dtos/app/erp/v3/inventory/WarehouseDto";
import { ApiService, ApiServiceType } from "../../../ApiService";

export class WarehouseService extends ApiService {
  constructor() {
    super("Inventory/Warehouse", ApiServiceType.TenantDatabase);
  }
  getAllWarehouses(manageStock: boolean): Promise<WarehouseDto[]> {
    return super.getAll(`GetAll?manageStock=${manageStock}`);
  }
  get(id: string): Promise<WarehouseDto> {
    return super.get(id);
  }
  // create(request: CreateCategoryRequest): Promise<WarehouseDto> {
  //   return super.post(request);
  // }
  // update(id: string, request: UpdateCategoryRequest): Promise<WarehouseDto> {
  //   return super.put(id, request);
  // }
  delete(id: string): Promise<void> {
    return super.delete(id);
  }
}
