

































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ObjectList from "../../../components/shared/forms/ObjectList.vue";
import { report } from "process";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import Modal from "../../../components/shared/modals/Modal.vue";
import Products from "../../../components/marketing/pricing/Products.vue";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import services from "@/services";

@Component({
  components: { Products, ObjectList, TableRowSkeleton, Modal }
})
export default class SubscriptionProductComponent extends BaseComponent {
  $refs!: {
    errorModal: ErrorModalComponent;
  };
  private items = [] as SubscriptionProductDto[];
  private fromServer: boolean = true;
  mounted() {
    this.reload(true);
  }
  async reload(fromServer: boolean) {
    this.fromServer = fromServer;
    this.items = [];
    this.loading = true;
    services.subscriptionProducts
      .getProducts(fromServer, false)
      .then((response: SubscriptionProductDto[]) => {
        this.items = [];
        response.forEach(product => {
          this.items.push(product);
        });
        if (response.length === 0 && this.fromServer) {
          this.reload(false);
        }
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  added(data: SubscriptionProductDto) {
    this.items.push(data);
  }
  saved(data: SubscriptionProductDto) {
    const idx = this.items.findIndex(f => f.id === data.id);
    this.items[idx] = data;
  }
  deleted(data: SubscriptionProductDto) {
    this.items = this.items.filter(f => f.id !== data.id);
  }
  async generateFromFiles() {
    await this.items.forEach(product => {
      services.subscriptionProducts
        .createProduct(product)
        .then(response => {
          this.reload(true);
        })
        .catch(error => {
          this.$refs.errorModal.show(error);
        });
    });
  }
  get pricingFile() {
    return "ClientApp/src/store/modules/pricing/default-pricing.ts";
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      return x?.tier > y?.tier ? 1 : -1;
    });
  }
}
