






import Component from "vue-class-component";
import Vue from "vue";
// import SalesTables from "@/components/app/erp/sales/tables/SalesTables.vue";

@Component({
  components: {
    // SalesTables
  }
})
export default class SalesIndex extends Vue {
  mounted() {
    // @ts-ignore
    // $crisp.push(["do", "chat:show"]);
  }
}
