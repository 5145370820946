import store from "@/store";
import { defaultState as defaultTheme } from "@/store/modules/theme";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { ApiService, ApiServiceType } from "@/services/api/ApiService";
import { TenantCreateRequest } from "@/application/contracts/master/tenants/TenantCreateRequest";
import { UserLoggedResponse } from "@/application/contracts/master/users/UserLoggedResponse";
import { TenantUpdateImageRequest } from "@/application/contracts/master/tenants/TenantUpdateImageRequest";
import { TenantFeaturesResponse } from "@/application/contracts/master/tenants/TenantFeaturesResponse";

export class TenantService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("Tenant", ApiServiceType.MasterDatabase);
  }
  adminGetAll(): Promise<TenantDto[]> {
    return this.api.getAll("Admin/GetAll");
  }
  getAll(): Promise<TenantDto[]> {
    return new Promise((resolve, reject) => {
      return this.api
        .getAll()
        .then((response: TenantDto[]) => {
          store.commit("tenant/myTenants", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  get(id: string): Promise<TenantDto[]> {
    return this.api.get("Get", id);
  }
  getFeatures(): Promise<TenantFeaturesResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .get("GetFeatures")
        .then((response: TenantFeaturesResponse) => {
          store.commit("tenant/features", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  adminGetFeatures(id: string): Promise<TenantFeaturesResponse> {
    return this.api.get("GetFeatures/" + id);
  }
  getCurrent(): Promise<TenantDto[]> {
    return this.api.get("GetCurrent");
  }
  create(payload: TenantCreateRequest): Promise<UserLoggedResponse> {
    payload.appTheme = defaultTheme.appTheme;
    payload.appColor = defaultTheme.appColor;
    console.log("createTenant: " + JSON.stringify(payload));
    return new Promise((resolve, reject) => {
      this.api
        .post(payload)
        .then((response: UserLoggedResponse) => {
          store.commit("auth/login", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  update(payload: TenantDto): Promise<TenantDto> {
    const tenantId = store.state.tenant?.current?.id ?? "";
    return new Promise((resolve, reject) => {
      this.api
        .put(tenantId, payload)
        .then((response: TenantDto) => {
          store.commit("tenant/settings", payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  updateImage(payload: TenantUpdateImageRequest): Promise<TenantDto> {
    const tenantId = store.state.tenant?.current?.id ?? "";
    return this.api.put(tenantId, payload, "UpdateImage");
  }
  delete() {
    const tenantId = store.state.tenant?.current?.id ?? "";
    return this.api.delete(tenantId);
  }
  adminDelete(id: string) {
    return this.api.delete(id, "Admin/Delete");
  }
}
