import { ApiService, ApiServiceType } from "@/services/api/ApiService";
import store from "@/store";
import { SubscriptionProductDto } from "@/application/dtos/master/subscriptions/SubscriptionProductDto";
import { defaultProducts } from "@/store/modules/pricing/default-pricing";
import { SubscriptionPriceDto } from "@/application/dtos/master/subscriptions/SubscriptionPriceDto";
import { SubscriptionFeatureDto } from "@/application/dtos/master/subscriptions/SubscriptionFeatureDto";
import { SubscriptionUpdatePriceRequest } from "@/application/contracts/master/subscriptions/SubscriptionUpdatePriceRequest";
import { SubscriptionUpdateProductRequest } from "@/application/contracts/master/subscriptions/SubscriptionUpdateProductRequest";

const debug = process.env.NODE_ENV !== "production";

export class SubscriptionProductService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("SubscriptionProduct");
  }
  getProducts(fromServer: boolean, onlyActive: boolean): Promise<SubscriptionProductDto[]> {
    // console.log("fromServer: " + fromServer);
    return new Promise((resolve, reject) => {
      if (fromServer) {
        return this.api
          .getAll()
          .then((response: SubscriptionProductDto[]) => {
            response.forEach((product) => {
              if (product.tier === 4) {
                product.custom = true;
              }
            });
            store.commit("pricing/products", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        let products: SubscriptionProductDto[] = defaultProducts;
        if (onlyActive) {
          products = products.filter((f) => f.active && f.prices.find((f) => f.active));
        }
        store.commit("pricing/products", products);
        resolve(products);
      }
    });
  }
  getProduct(id: string): Promise<SubscriptionProductDto> {
    return this.api.get("GetProduct", id);
  }
  getPrice(id: string): Promise<SubscriptionPriceDto> {
    return this.api.get("GetPrice", id);
  }
  getFeature(id: string): Promise<SubscriptionFeatureDto> {
    return this.api.get("GetFeature", id);
  }
  createProduct(product: SubscriptionProductDto): Promise<SubscriptionProductDto> {
    return this.api.post(product, `CreateProduct`);
  }
  createPrice(price: SubscriptionPriceDto): Promise<SubscriptionPriceDto> {
    return this.api.post(price, `CreatePrice`);
  }
  createFeature(feature: SubscriptionFeatureDto): Promise<SubscriptionFeatureDto> {
    return this.api.post(feature, `CreateFeature`);
  }
  updateProduct(id: string, product: SubscriptionUpdateProductRequest): Promise<SubscriptionProductDto> {
    return this.api.put(id, product, `UpdateProduct`);
  }
  updatePrice(id: string, price: SubscriptionUpdatePriceRequest): Promise<SubscriptionPriceDto> {
    return this.api.put(id, price, `UpdatePrice`);
  }
  updateFeature(id: string, feature: SubscriptionFeatureDto): Promise<SubscriptionFeatureDto> {
    return this.api.put(id, feature, `UpdateFeature`);
  }
  deleteProduct(id: string): Promise<any> {
    return this.api.delete(id, "DeleteProduct");
  }
  deletePrice(id: string): Promise<any> {
    return this.api.delete(id, "DeletePrice");
  }
  deleteFeature(id: string): Promise<any> {
    return this.api.delete(id, "DeleteFeature");
  }
}
