






















































































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ErrorBanner from "@/components/shared/banners/ErrorBanner.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ObjectList from "../../../../components/shared/forms/ObjectList.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import { TenantJoinSettingsDto } from "@/application/dtos/master/tenants/TenantJoinSettingsDto";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import { TenantUserStatus } from "@/application/enums/master/tenants/TenantUserStatus";
import { TenantUserJoined } from "@/application/enums/master/tenants/TenantUserJoined";
import tinyEventBus from "@/plugins/tiny-eventbus";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import services from "@/services";

@Component({
  components: {
    ObjectList,
    TableRowSkeleton,
    ConfirmModal,
    ErrorModal,
    ErrorBanner,
    EmptyState
  },
  computed: {
    ...mapGetters("tenant", {
      activeProduct: "activeProduct",
      memberCount: "memberCount",
      isOwnerOrAdmin: "isOwnerOrAdmin"
    }),
    ...mapGetters("account", {
      isAdmin: "isAdmin",
      email: "email"
    })
  }
})
export default class TenantMembersComponent extends BaseComponent {
  $refs!: {
    confirmarAcceptUser: ConfirmModal;
    errorModal: ErrorModalComponent;
    errorBanner: ErrorBanner;
    confirmUpgrade: ConfirmModalComponent;
  };
  @Prop({ default: "Miembros" }) readonly title!: string;

  public items = [] as TenantUserDto[];
  private tenantJoinSettings: any = {};
  private enableLink: boolean = false;
  private enablePublicUrl: boolean = false;
  private requireAcceptance: boolean = false;
  private copiedUrlLink: boolean = false;
  private activeProduct!: TenantProductDto;
  private memberCount!: number;
  private isOwnerOrAdmin!: boolean;
  private role!: TenantUserRole;
  private acceptedUser!: TenantUserDto;
  private isAdmin!: boolean;
  private acceptUserEmail: string = "";
  private email!: string;
  created() {
    const self = this;
    this.eventBus.$on("user-canceled", () => self.canceled());
    this.eventBus.$on("user-added", data => self.added(data));
    this.eventBus.$on("user-saved", data => self.saved(data));
    this.eventBus.$on("user-deleted", data => self.deleted(data));

    this.acceptUserEmail = this.$route.query.au?.toString() ?? "";
  }
  destroy() {
    this.eventBus.$off("user-canceled");
    this.eventBus.$off("user-deleted");
    this.eventBus.$off("user-added");
    this.eventBus.$off("user-saved");
  }
  mounted() {
    // SignalService.on("UserAdded", (user: User) => {
    //   this.items.push(user);
    // });
    tinyEventBus().emitter.on(`${this.$route.name}-reload`, payload => {
      this.reload();
    });
    tinyEventBus().emitter.on(`${this.$route.name}-add`, payload => {
      this.$router.push({ name: "settings.tenant.members.new" });
    });
    tinyEventBus().emitter.emit(`settings-init-reload`, true);
    tinyEventBus().emitter.emit(`settings-init-add`, true);

    services.tenantUserInvitations
      .getInvitationSettings()
      .then((response: TenantJoinSettingsDto) => {
        this.tenantJoinSettings = response;
        this.enableLink = this.tenantJoinSettings.linkActive;
        this.enablePublicUrl = this.tenantJoinSettings.publicUrl;
        this.requireAcceptance = this.tenantJoinSettings.requireAcceptance;
      });
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off(`${this.$route.name}-reload`);
    tinyEventBus().emitter.off(`${this.$route.name}-add`);
    tinyEventBus().emitter.emit(`settings-init-reload`, false);
    tinyEventBus().emitter.emit(`settings-init-add`, false);
  }
  avatarText(user) {
    if (user) {
      if (user.firstName && user.lastName) {
        if (user.firstName.length > 0 && user.lastName.length > 0) {
          return (user.firstName[0] + user.lastName[0]).toUpperCase();
        } else if (user.firstName.length > 1) {
          return user.firstName.substring(0, 2).toUpperCase();
        } else if (user.email.length > 1) {
          return user.email.substring(0, 2).toUpperCase();
        }
      } else {
        return user.email.substring(0, 2).toUpperCase();
      }
    }
  }
  async reload() {
    this.loading = true;
    services.tenantUsers
      .getAll()
      .then((response: TenantUserDto[]) => {
        this.items = [];
        response.forEach(element => {
          this.items.push(element);
        });

        if (this.acceptUserEmail) {
          const user = this.items.find(f => f.email === this.acceptUserEmail);
          if (user && user.status === TenantUserStatus.PENDING_ACCEPTANCE) {
            this.acceptUser(user, true);
          }
        }
      })
      .catch(error => {
        this.$refs.errorBanner.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  canceled() {
    console.log("canceled");
  }
  added(data: TenantUserDto) {
    this.reload();
    // console.log("added data:" + JSON.stringify(data));
    // this.items.push(data);
    // // SignalService.invoke(
    //   "AddUser",
    //   this.$store.state.tenant.current.id,
    //   data
    // );
  }
  saved(data) {
    const idx = this.items.findIndex(f => f.id === data.id);
    // console.log("found: " + JSON.stringify(idx));
    this.items[idx] = data;
  }
  deleted(data) {
    this.items = this.items.filter(f => f.id !== data.id);
  }
  updateTenantJoinSettings(reset = false) {
    services.tenantUserInvitations
      .updateInvitationSettings({
        enableLink: this.enableLink,
        resetLink: reset,
        enablePublicUrl: this.enablePublicUrl,
        requireAcceptance: this.requireAcceptance
      })
      .then((response: TenantJoinSettingsDto) => {
        this.tenantJoinSettings = response;
        this.enableLink = this.tenantJoinSettings.linkActive;
        this.enablePublicUrl = this.tenantJoinSettings.publicUrl;
        this.requireAcceptance = this.tenantJoinSettings.requireAcceptance;
      });
  }

  changeLinkSettings(e) {
    this.updateTenantJoinSettings();
  }
  copyInviteLink() {
    if (this.maxNumberOfUsersReached) {
      this.$refs.confirmUpgrade.show(
        this.$t("settings.tenant.members.maxNumberOfUsers") +
          " (" +
          this.maxNumberOfUsers +
          "). " +
          this.$t("shared.upgrade?")
      );
    } else {
      // @ts-ignore
      this.$clipboard(this.urlLink);
      this.copiedUrlLink = true;
    }
  }
  yesUpdateSubscription() {
    this.$router.push({ name: "settings.tenant.subscription" });
  }
  getUserRole(item: TenantUserDto) {
    return this.$t("settings.profile.roles." + TenantUserRole[item.role]);
  }
  getUserJoined(item: TenantUserDto) {
    if (
      item.status === TenantUserStatus.ACTIVE &&
      item.joined !== TenantUserJoined.CREATOR
    ) {
      return this.$t(
        "settings.profile.joined." + TenantUserJoined[item.joined]
      );
    }
  }
  getUserStatus(item: TenantUserDto) {
    return this.$t("settings.profile.status." + TenantUserStatus[item.status]);
  }
  getUserRoleClass(item: TenantUserDto) {
    switch (item.role as TenantUserRole) {
      case TenantUserRole.OWNER:
        return "bg-blueGray-50 text-gray-800 border border-blueGray-300";
      case TenantUserRole.ADMIN:
        return "bg-rose-50 border border-rose-200";
      case TenantUserRole.MEMBER:
        return "bg-blue-50 border border-blue-200";
      case TenantUserRole.GUEST:
        return "bg-gray-50 border border-gray-200";
    }
  }
  getUserJoinedClass(item: TenantUserDto) {
    if (item.status === TenantUserStatus.ACTIVE) {
      switch (item.joined as TenantUserJoined) {
        case TenantUserJoined.CREATOR:
          return "bg-gray-800 text-gray-100";
        case TenantUserJoined.JOINED_BY_INVITATION:
          return "bg-blue-50 border border-blue-200";
        case TenantUserJoined.JOINED_BY_LINK:
          return "bg-teal-50 border border-teal-200";
        case TenantUserJoined.JOINED_BY_PUBLIC_URL:
          return "bg-orange-50 border border-orange-200";
      }
    }
  }
  getUserStatusClass(item: TenantUserDto) {
    switch (item.status as TenantUserStatus) {
      case TenantUserStatus.PENDING_INVITATION:
        return "bg-yellow-50 border border-yellow-300";
      case TenantUserStatus.PENDING_ACCEPTANCE:
        return "bg-yellow-50 border border-yellow-300";
      case TenantUserStatus.ACTIVE:
        return "bg-teal-50 border border-teal-200";
      case TenantUserStatus.INACTIVE:
        return "bg-red-50 border border-red-300";
    }
  }
  pendingAcceptance(item: TenantUserDto) {
    return item.status === TenantUserStatus.PENDING_ACCEPTANCE;
  }
  yesAcceptUser() {
    if (this.isOwnerOrAdmin && this.acceptedUser) {
      services.tenantUserInvitations
        .acceptUser(this.acceptedUser)
        .then(() => {
          this.reload();
        })
        .catch(error => {
          this.$refs.errorModal.show(error);
        });
    }
  }
  acceptUser(item: TenantUserDto, accept: boolean) {
    item.accepted = accept;
    this.acceptedUser = item;
    this.$refs.confirmarAcceptUser.show(
      this.$t("shared.accept?", [item.email]).toString(),
      this.$t("shared.accept").toString(),
      this.$t("shared.cancel").toString()
    );
  }
  get maxNumberOfUsers(): number {
    if (this.isAdmin) {
      return 0;
    }
    return this.activeProduct &&
      this.activeProduct.subscriptionProduct &&
      this.activeProduct.subscriptionProduct.maxUsers
      ? this.activeProduct.subscriptionProduct.maxUsers
      : 1;
  }
  get maxNumberOfUsersReached() {
    if (
      this.maxNumberOfUsers > 0 &&
      this.memberCount >= this.maxNumberOfUsers
    ) {
      return true;
    }
    return false;
  }

  get urlLink() {
    if (this.tenantJoinSettings) {
      return location.origin + "/join/" + this.tenantJoinSettings.link;
    }
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    const items = this.items.sort((x, y) => {
      return x.role > y.role ? -1 : 1;
    });
    return items.sort((x, y) => {
      return x.status > y.status ? -1 : 1;
    });
  }
}
