





















import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import PageTitle from "../../../components/shared/navigation/PageTitle.vue";
import UploadTicketsDragAndDrop from "./UploadTicketsDragAndDrop.vue";

@Component({
  components: {
    UploadTicketsDragAndDrop,
    PageTitle
  }
})
export default class TicketsUpload extends BaseComponent {
  $refs!: {
    uploadTicketsDragAndDrop: UploadTicketsDragAndDrop;
  };
  mounted() {
    if (this.$route.params.action === "gallery") {
      this.$refs.uploadTicketsDragAndDrop.openGalleryWhenReady = true;
    }
  }
  uploadedTickets() {
    window.scrollTo(0, 0);
    this.$router.push({ name: "app.tickets" });
  }
}
