































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { SummaryCountTotalResponse } from "@/application/contracts/app/summaries/SummaryCountTotalResponse";
import services from "@/services";
import { SummaryCountTotalType } from "@/application/enums/app/transactions/SummaryCountTotalType";

@Component({
  components: {}
})
export default class CategorySelect extends Vue {
  @Prop() tenantId!: string;
  @Prop({ default: true }) selectAll!: boolean;
  @Prop({ default: "" }) preSelect!: string;
  public selected: number = -1;
  private dropDown: boolean = false;
  private items: SummaryCountTotalResponse[] = [];
  private loading: boolean = false;
  mounted() {
    this.loading = true;
    services.dashboard
      .getSummaryCountTotal(SummaryCountTotalType.CATEGORY)
      .then((response: SummaryCountTotalResponse[]) => {
        this.items = response;
        if (this.selectAll) {
          this.selected = 0;
        }
        if (this.preSelect) {
          this.preSelectValue(this.preSelect);
        }
      })
      .catch(() => {
        // ignore
      })
      .finally(() => {
        this.loading = false;
      });
  }
  preSelectValue(value: string) {
    const idx = this.items.findIndex(f => f.name === value);
    if (idx !== -1) {
      this.selected = idx + 1;
    }
  }
  select(idx) {
    this.selected = idx;
    this.dropDown = false;
    if (this.selectedItem) {
      this.$emit("selectItem", this.selectedItem.name);
    } else {
      this.$emit("selectAllItems");
    }
  }
  closeDropDown() {
    this.dropDown = false;
  }
  get selectedItem(): SummaryCountTotalResponse | undefined {
    if (this.selected > 0) {
      return this.items[this.selected - 1];
    }
  }
}
