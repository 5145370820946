






import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import EcommerceLayout from "@/components/layouts/EcommerceLayout.vue";
import store from "@/store";

@Component({
  components: {
    EcommerceLayout
  }
})
export default class EcommerceIndex extends Vue {
  // get currentWorkspaceId(): string {
  //   return store.state.tenant.currentWorkspace?.id ?? "";
  // }
}
