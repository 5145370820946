




































import Vue from "vue";
import DropdownWithSearch from "@/components/shared/dropdowns/DropdownWithSearch.vue";
import Component from "vue-class-component";
import CfdiUses from "@/application/shared/CfdiUses";
import { CustomerTaxIdDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerTaxIdDto";

@Component({
  components: {
    DropdownWithSearch
  }
})
export default class TaxIdSelect extends Vue {
  $refs!: {
    dropdown: DropdownWithSearch;
  };
  private cfdiUses = CfdiUses;
  public setItems(items: CustomerTaxIdDto[], selectFirst: boolean) {
    this.$refs.dropdown.setItems(items);
    if (items.length === 1 || (selectFirst && items.length > 0)) {
      this.$refs.dropdown.selectItem(items[0]);
    }
    // if (items.length > 1) {
    //   this.$refs.dropdown.open();
    // }
  }
  // public open() {
  //   this.$refs.dropdown.open();
  // }
  private selected(value, item) {
    this.$emit("selected", value, item);
  }
}
