











import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SideModal from "@/components/shared/modals/SideModal.vue";
import CustomerForm from "@/components/app/erp/contacts/customers/forms/CustomerForm.vue";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    SideModal,
    CustomerForm,
    NewModalComponent
  }
})
export default class NewCustomer extends Vue {
  $refs!: {
    modalNewCustomer: NewModalComponent;
    formCustomer: CustomerForm;
  };
  add() {
    this.$refs.modalNewCustomer.show();
    this.$refs.formCustomer.add();
  }
  createdCustomer(item: CustomerDto) {
    if (this.$refs.modalNewCustomer) {
      this.$refs.modalNewCustomer.close();
    }
    this.$emit("created", item);
  }
  close() {
    if (this.$refs.modalNewCustomer) {
      this.$refs.modalNewCustomer.close();
    }
  }
}
