import { Module } from "vuex";
import { RootState } from "@/types/stateTypes";
import { CustomersState } from "@/types/erpStateTypes";

export const state: CustomersState = {
  customers: [],
};

export const customers: Module<CustomersState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    reset(state) {
      state.customers = [];
    },
    customers(state, payload) {
      state.customers = payload;
    },
  },
};
