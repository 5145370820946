




























































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BarcodeReadRow from "./BarcodeReadRow.vue";
import Utils from "@/application/shared/Utils";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { BarcodeReadLineDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadLineDto";

@Component({
  components: {
    ConfirmModal,
    BarcodeReadRow
  }
})
export default class BarcodeReadsTable extends Vue {
  @Prop({ default: [] }) items!: BarcodeReadLineDto[];
  get summary() {
    let quantity = 0;
    let units = 0;
    let subtotal = 0;
    let taxes = 0;
    let total = 0;
    let anyLots = false;
    let isSale = false;
    this.items.forEach(element => {
      if (element.lot) {
        anyLots = true;
      }
      if (element.type === "PR" || element.type === "PT") {
        isSale = true;
      }
      quantity += element.quantity;
      units += element.units;
      subtotal += element.subtotal;
      taxes += element.taxes;
      total += element.total;
    });
    return {
      quantity,
      units,
      subtotal,
      taxes,
      total,
      anyLots,
      isSale
    };
  }
}
