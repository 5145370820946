import { TransferDto } from "@/application/dtos/app/erp/v3/inventory/documents/TransferDto";
import { ApiService, ApiServiceType } from "../../../ApiService";

export class TransferService extends ApiService {
  constructor() {
    super("Inventory/Transfer", ApiServiceType.TenantDatabase);
  }
  getAllWithPendingUnits(): Promise<TransferDto[]> {
    return super.getAll(`GetAllWithPendingUnits`);
  }
}
