



































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class DropdownOptions extends Vue {
  @Prop({ default: "" }) mainOption!: string;
  @Prop({ default: "" }) mainOptionSm!: string;
  @Prop({}) mainOptionRoute!: any;
  @Prop({ default: [] }) options!: string[];
  private mostrar: boolean = false;
  close() {
    this.mostrar = false;
  }
  clicked(option, idx) {
    this.$emit("click", option, idx);
    this.close();
  }
}
