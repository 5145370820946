







































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters } from "vuex";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import NewQuote from "@/components/app/erp/sales/pos/NewQuote.vue";

@Component({
  components: {
    NewModalComponent,
    NewQuote
  },
  computed: {
    ...mapGetters("account", {
      firstName: "firstName",
      email: "email",
      phone: "phone",
      avatarText: "avatarText",
      avatar: "avatar",
      isAdmin: "isAdmin"
    })
  }
})
export default class Menu extends Vue {
  $refs!: {
    modalNewQuote: NewModalComponent;
  };
  private isOwnerOrAdmin!: boolean;
  private firstName!: string;
  private phone!: string;
  private email!: string;
  private avatar!: string;
  private avatarText!: string;
  private modules: any[] = [];
  private loading: boolean = false;
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.modules = [
        {
          title: this.$t("erp.sales.title"),
          routes: [
            {
              name: "erp.sales.quotes",
              title: this.$t("erp.sales.description"),
              description: this.$t("erp.sales.description")
            },
            {
              action: this.newQuote,
              // name: "erp.sales.pos",
              // query: { folio: 1 },
              title: this.$t("erp.sales.pos.title"),
              description: this.$t("erp.sales.pos.description")
            }
          ]
        },
        {
          title: this.$t("erp.inventory.title"),
          routes: [
            {
              name: "erp.inventory.products",
              title: this.$t("erp.inventory.products.title"),
              description: this.$t("erp.inventory.products.description")
            }
            // {
            //   name: "erp.inventory.physical",
            //   title: this.$t("erp.inventory.physical.title"),
            //   description: this.$t("erp.inventory.physical.description")
            // }
          ]
        }
      ];
      this.loading = false;
    }, 0);
  }
  newQuote() {
    this.$refs.modalNewQuote.show();
  }
}
