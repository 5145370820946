






































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import { CustomerTaxIdDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerTaxIdDto";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";

@Component({
  components: {
    NewModalComponent,
    ErrorModal,
    SuccessModal,
    ConfirmModal
  }
})
export default class CustomerForm extends Vue {
  $refs!: {
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
    confirmModal: ConfirmModalComponent;
    nameInput: HTMLInputElement;
    taxIdInput: HTMLInputElement;
  };
  private item: CustomerDto | null = null;
  private taxId: CustomerTaxIdDto | null = null;
  private loading: boolean = false;
  private showTaxInfo: boolean = false;
  clear() {
    this.item = null;
    this.taxId = null;
  }
  add() {
    this.taxId = {
      customerId: 0,
      taxId: "XAXX010101000",
      name: "VENTAS DE MOSTRADOR",
      phone: "",
      postalCode: "",
      country: "MEX",
      email1: "",
      email2: "",
      paymentsEmail: ""
    };
    this.item = {
      id: 0,
      name: "",
      company: "",
      creditDays: 1,
      fixedPercentageDiscount: 0,
      taxIds: [this.taxId]
    };
    this.$nextTick(() => {
      this.$refs.nameInput.focus();
      this.$refs.nameInput.select();
    });
    // this.$refs.modal.show();
  }
  update(id: number) {
    this.loading = true;
    ErpService.customers.customers
      .get(id.toString())
      .then((response: CustomerDto) => {
        this.item = response;
        const taxIds = response.taxIds.filter(f => f.customerId !== 777);
        if (taxIds && taxIds.length > 0) {
          this.taxId = taxIds[0];
        } else {
          this.taxId = {
            customerId: 0,
            taxId: "XAXX010101000",
            name: "VENTAS DE MOSTRADOR",
            phone: "",
            postalCode: "",
            country: "MEX",
            email1: "",
            email2: "",
            paymentsEmail: ""
          };
        }
        this.$nextTick(() => {
          this.$refs.nameInput.focus();
          this.$refs.nameInput.select();
        });
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  save() {
    if (!this.item) {
      this.$refs.errorModal.show(this.$t("Cliente inválido"));
      return;
    }
    if (!this.item.name) {
      this.$refs.errorModal.show(this.$t("Nombre inválido"));
      return;
    }
    if (!this.taxId?.taxId) {
      this.$refs.errorModal.show(this.$t("RFC inválido"));
      return;
    }
    this.item.company = this.taxId?.name ?? "";
    this.item.taxIds = [this.taxId];

    this.loading = true;
    if (this.item.id === 0) {
      ErpService.customers.customers
        .create(this.item)
        .then((response: CustomerDto) => {
          this.item = response;
          tinyEventBus().emitter.emit("customer-added", response);
          this.$emit("created", response);
          this.$refs.successModal.show(
            this.$t("Cliente creado"),
            "Se le asignó el número " + response.id
          );
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      ErpService.customers.customers
        .update(this.item.id.toString(), this.item)
        .then((response: CustomerDto) => {
          this.item = response;
          this.$emit("updated", response);
          this.$refs.successModal.show(this.$t("Cliente actualizado"));
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  deleteCustomer() {
    this.$refs.confirmModal.show(
      `¿Eliminar cliente #${this.item?.id}`,
      "Eliminar",
      "Cancelar",
      "ADVERTENCIA: Esta acción es irreversible."
    );
  }
  yesDelete() {
    if (!this.item || this.item.id <= 0) {
      return;
    }
    this.loading = true;
    ErpService.customers.customers
      .delete(this.item.id.toString())
      .then(() => {
        this.item = null;
        this.taxId = null;
        this.$refs.successModal.show(this.$t("Cliente eliminado"));
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  showTaxInfoDetails() {
    if (!this.showTaxInfo) {
      this.showTaxInfo = true;
      this.$nextTick(() => {
        this.$refs.taxIdInput.focus();
        this.$refs.taxIdInput.select();
      });
    } else {
      this.showTaxInfo = false;
    }
  }
  close() {
    this.$emit("close");
  }
  closed() {
    //   this.
  }
}
