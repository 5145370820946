
















































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BadgeTicket from "../badges/BadgeTicket.vue";
import BadgeIncome from "../badges/BadgeIncome.vue";
import BadgeExpense from "../badges/BadgeExpense.vue";
import { GetTransactionOrTicketFilter } from "@/application/enums/app/transactions/GetTransactionOrTicketFilter";

@Component({
  components: {
    BadgeTicket,
    BadgeIncome,
    BadgeExpense
  }
})
export default class TransactionTypeSelect extends Vue {
  @Prop() tenantId!: string;
  @Prop({ default: true }) selectAll!: boolean;
  public selected: number = 0;
  private dropDown: boolean = false;
  private items: GetTransactionOrTicketFilter[] = [];
  mounted() {
    this.items = [
      GetTransactionOrTicketFilter.TICKETS_AND_TRANSACTIONS,
      GetTransactionOrTicketFilter.TICKETS,
      GetTransactionOrTicketFilter.INCOME,
      GetTransactionOrTicketFilter.EXPENSE
    ];
  }
  select(idx) {
    this.selected = idx;
    this.dropDown = false;
    this.$emit("selectType", this.items[this.selected]);
  }
  closeDropDown() {
    this.dropDown = false;
  }
  name(type: GetTransactionOrTicketFilter) {
    return GetTransactionOrTicketFilter[type];
  }
  get selectedType(): GetTransactionOrTicketFilter {
    return this.items[this.selected];
  }
}
