















































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import LoadingButton from "../../components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import LoadingButtonComponent from "../../components/shared/buttons/LoadingButton.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import services from "@/services";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    MarketingLogo,
    LoadingButton
  }
})
export default class ForgotComponent extends BaseComponent {
  $refs!: {
    loadingButton: LoadingButtonComponent;
    errorModal: ErrorModalComponent;
  };
  private emailSent: boolean = false;
  private email: string = "";
  private verifyToken: string = "";
  created() {
    this.email = this.$route.query.e ? this.$route.query.e.toString() : "";
  }
  reset() {
    this.$refs.loadingButton.start();
    services.authentication
      .reset(this.email)
      .then((response: any) => {
        this.emailSent = true;
        this.verifyToken = response.verifyToken;
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  get isDarkMode() {
    return this.$store.state.theme.marketingTheme === "theme-bg-dark";
  }
}
