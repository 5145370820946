






import Component from "vue-class-component";
import Vue from "vue";

@Component({
  components: {}
})
export default class CustomersIndex extends Vue {
  mounted() {
    // @ts-ignore
    // $crisp.push(["do", "chat:show"]);
  }
}
