export function getNamedParents(routes: any, matched: any) {
  let parent: any = null;
  const parents: any[] = [];
  const parentPath: any[] = [];
  for (const record of matched) {
    let path = record.path;
    if (!path) {
      path = "/";
    }
    if (parent && parent.path) {
      parentPath.push(parent.path);
    }
    if (parentPath.length) {
      path = path.substr(parentPath.join("/").length + 1);
    }
    let next = routes.find((r) => r.path === path);
    if (next && next.path) {
      parent = next;
      routes = next.children;
      if (!next.name && next.children) {
        next = next.children.find((r) => !r.path);
      }
      parents.push(next);
    } else {
      break;
    }
  }
  return parents.slice(0, parents.length - 1);
}
