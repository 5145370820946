const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByRecords = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) {
      previous[group] = [];
    }
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

const sum = (arr, key) => {
  return arr.reduce((a, b) => a + (b[key] || 0), 0);
};

function isLastDays(from: Date, days: number) {
  var lastDayOfMonth = new Date(from.getFullYear(), from.getMonth() + 1, 0);
  var lastDaysDate = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate());
  lastDaysDate.setDate(lastDaysDate.getDate() - days);

  console.log({ from });
  console.log({ lastDayOfMonth });
  console.log({ lastDaysDate });
  // const diffTime = Math.abs(today - lastDaysDate);
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // console.log(diffTime + " milliseconds");
  // console.log(diffDays + " days");

  if (from.getDate() >= lastDaysDate.getDate()) {
    return true;
  }
  return false;
}

const Utils = {
  sum,
  groupBy,
  groupByRecords,
  isLastDays,
};
export default Utils;
