var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative inline-block text-left"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}]},[_c('div',[_c('button',{staticClass:"h-full inline-flex justify-center w-full px-3 py-2 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-100 focus:ring-indigo-500 focus:z-10",class:{
          'border border-gray-300 bg-white hover:bg-gray-50 shadow-sm rounded-sm': _vm.border
        },attrs:{"type":"button","id":"options-menu","aria-haspopup":"true","aria-expanded":"true"},on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._t("icon"),_vm._t("title")],2)]),_c('transition',{attrs:{"enter-active-class":"transition ease-out duration-100","enter-class":"transform opacity-0 scale-95","enter-to-class":"transform opacity-100 scale-100","leave-active-class":"transition ease-in duration-75","leave-class":"transform opacity-100 scale-100","leave-to-class":"transform opacity-0 scale-95"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"origin-top-right absolute right-0 mt-2 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50",class:{
          'w-32': _vm.size === 'xs',
          'w-52': _vm.size === 'sm',
          'w-72': _vm.size === 'md',
          'w-96': _vm.size === 'lg'
        }},[_c('div',{staticClass:"py-1",attrs:{"role":"menu","aria-orientation":"vertical","aria-labelledby":"options-menu"}},[_vm._t("items")],2)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }