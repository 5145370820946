export class ProductBarcodeReadScanRequest {
  type: string;
  folio: number;
  sku: string;
  warehouseId: number = 0;
  date?: Date = new Date();
  constructor(type: string, folio: number, sku: string, warehouseId?: number, date?: Date) {
    this.type = type;
    this.folio = folio;
    this.sku = sku;
    if (warehouseId) {
      this.warehouseId = warehouseId;
    }
    if (date) {
      this.date = date;
    }
  }
}
