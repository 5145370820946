import { GetProductsRequest } from "@/application/contracts/app/erp/inventory/products/GetProductsRequest";
// tslint:disable-next-line:max-line-length
import { UpdateProductImageRequest } from "@/application/contracts/app/erp/inventory/products/UpdateProductImageRequest";
import { ProductDto } from "@/application/dtos/app/erp/v3/inventory/ProductDto";
import { ImageResolution } from "@/application/enums/app/common/ImageResolution";
import store from "@/store";
import { products } from "@/store/modules/erp/products";
import { ApiService, ApiServiceType } from "../../../ApiService";
import fakeProducts from "./fakeData/FakeDataProducts";

export class ProductService extends ApiService {
  constructor() {
    super("Inventory/Product", ApiServiceType.TenantDatabase);
  }
  getAllProducts(payload: GetProductsRequest, filters: string[]): Promise<ProductDto[]> {
    // return Promise.resolve(fakeProducts.filter((f) => categoryIds.includes(f.category.id)));
    return super.getAll(`GetAll?${super.toQueryString(payload)}&${super.toFromQueryArray("filters", filters)}`);
  }
  get(id: string): Promise<ProductDto> {
    return super.get(`Get/${id}`);
  }
  getImage(id: string, resolution: ImageResolution, thumbnailSize: number): Promise<any> {
    // tslint:disable-next-line:max-line-length
    // const image = store.state.products.images.find((f) => f.productId === id && f.resolution === resolution && f.thumbnailSize === thumbnailSize);
    // if (image) {
    //   return Promise.resolve(image);
    // }
    return super.download(undefined, `Image/${id}/${resolution}/${thumbnailSize}`).catch(() => {
      // ignore
    });
  }
  updateImage(id: string, request: UpdateProductImageRequest) {
    return super.put(id, request, "UpdateImage");
  }
}
