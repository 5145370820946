































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import Tabs from "@/components/shared/tabs/Tabs.vue";
import CheckboxSelect from "@/components/shared/checkboxes/CheckboxSelect.vue";
import SimpleSelect from "@/components/shared/selectors/SimpleSelect.vue";
import ProductsTable from "@/components/app/erp/inventory/list/products/ProductsTable.vue";
// import ProductForm from "@/views/app/erp/inventory/products/ProductForm.vue";
import Breadcrumb from "@/views/app/erp/shared/Breadcrumb.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import { CategoryDto } from "@/application/dtos/app/erp/v3/inventory/CategoryDto";
import { ProductDto } from "@/application/dtos/app/erp/v3/inventory/ProductDto";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import { WarehouseDto } from "@/application/dtos/app/erp/v3/inventory/WarehouseDto";

@Component({
  components: {
    ProductsTable,
    Breadcrumb,
    Tabs,
    CheckboxSelect,
    SimpleSelect,
    // ProductForm,
    NewModalComponent
  }
})
export default class ProductsList extends BaseComponent {
  $refs!: {
    productsTable: ProductsTable;
    // productForm: ProductForm;
    modalEditProduct: NewModalComponent;
  };
}
