











import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SideModal from "@/components/shared/modals/SideModal.vue";
import NewModalComponent from "@/components/shared/modals/NewModal.vue";
import { PaymentDto } from "@/application/dtos/app/erp/v3/contacts/customers/PaymentDto";
import PaymentForm from "@/components/app/erp/contacts/customers/forms/PaySaleForm.vue";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    SideModal,
    NewModalComponent,
    PaymentForm
  }
})
export default class NewPayment extends Vue {
  $refs!: {
    modalNewPayment: NewModalComponent;
    formPayment: PaymentForm;
  };
  add(type, folio) {
    this.$refs.modalNewPayment.show();
    this.$refs.formPayment.add(type, folio);
  }
  createdPayment(item: PaymentDto) {
    if (this.$refs.modalNewPayment) {
      this.$refs.modalNewPayment.close();
    }
    this.$emit("created", item);
  }
  close() {
    if (this.$refs.modalNewPayment) {
      this.$refs.modalNewPayment.close();
    }
  }
}
