















































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import SaleLineRow from "./SaleLineRow.vue";
import BarcodeReadRow from "@/components/app/erp/inventory/barcode/BarcodeReadRow.vue";
import Utils from "@/application/shared/Utils";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { BarcodeReadLineDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadLineDto";
import { SaleLineDto } from "@/application/dtos/app/erp/v3/sales/SaleLineDto";

@Component({
  components: {
    ConfirmModal,
    SaleLineRow,
    BarcodeReadRow
  }
})
export default class SaleLinesTable extends Vue {
  @Prop({ default: [] }) items!: SaleLineDto[];
  get summary() {
    let quantity = 0;
    let units = 0;
    let subtotal = 0;
    let taxes = 0;
    let total = 0;
    let reads = 0;
    let isSale = false;
    this.items.forEach(element => {
      if (element.type === "PR" || element.type === "PT") {
        isSale = true;
      }
      quantity += element.quantity;
      units += element.units;
      subtotal += element.subtotal;
      taxes += element.taxes;
      total += element.total;
      reads += element.reads.length;
    });
    return {
      quantity,
      units,
      subtotal,
      taxes,
      total,
      isSale,
      reads
    };
  }
}
