






import Vue from "vue";
import { Component } from "vue-property-decorator";
import SignalR from "@/plugins/SignalR";
import store from "@/store";
@Component({})
export default class WorkspaceLayout extends Vue {
  private signalService!: SignalR;
  created() {
    this.signalService = new SignalR(
      "workspace-hub",
      store.state.tenant.current?.id,
      store.state.tenant.currentWorkspace?.id
    );
  }
  beforeDestroy() {
    this.signalService.closeConnection();
  }
  mounted() {
    this.configureSignalR();
    this.startConnection();
  }
  configureSignalR() {
    this.signalService.on("JoinedGroup", (user: string) => {
      console.log({ method: "[WorkspaceHub] JoinedGroup", user });
    });
    this.signalService.on("LeavedGroup", (user: string) => {
      console.log({ method: "[WorkspaceHub] LeavedGroup", user });
    });
    this.signalService.on("ReceiveMessage", (user, message: string) => {
      console.log({
        method: "[WorkspaceHub] ReceiveMessage",
        user,
        message
      });
    });
    this.signalService.on("ObjectAdded", (user: string, obj: any) => {
      console.log({ method: "[WorkspaceHub] AddedObject", user, obj });
    });
  }
  startConnection() {
    this.signalService.startConnection();
  }
}
