













































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class SuccessBanner extends Vue {
  @Prop({ default: 10 }) secondsToClose!: number;
  @Prop({ default: "" }) classes!: number;
  public message: string = "";
  public showing: boolean = false;
  public show(message: string = "", secondsToClose?: number) {
    this.message = message;
    this.showing = true;
    if (
      this.message
        .toString()
        .trim()
        .toLowerCase() === "Network Error".toLowerCase()
    ) {
      this.message = this.$i18n.t("shared.networkError").toString();
    }
    if (secondsToClose && secondsToClose > 0) {
      this.secondsToClose = secondsToClose;
    }
    if (this.secondsToClose > 0) {
      setTimeout(() => {
        this.close();
      }, this.secondsToClose * 1000);
    }
  }
  public close() {
    this.$emit("closed");
    this.showing = false;
  }
}
