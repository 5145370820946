





















































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";

@Component({
  components: {
    LoadingButton
  }
})
export default class ModalNumberInput extends Vue {
  $refs!: {
    input: HTMLInputElement;
  };
  @Prop({}) initial!: number;
  @Prop({ default: 0 }) min!: number;
  @Prop({ default: 9999999999999 }) max!: number;
  @Prop({}) title!: number;
  @Prop({}) acceptText!: number;
  @Prop({ default: "sm:max-w-sm" }) maxSize!: string;
  @Prop({ default: "overflow-y-scroll" }) overflowContent!: string;
  public showing: boolean = false;
  private errorDescription!: string;
  private value: number = 0;
  created() {
    this.value = this.initial;
  }
  public show(initial: number) {
    this.value = initial;
    this.showing = true;
    this.$nextTick(() => {
      this.$refs.input.focus();
      this.$refs.input.select();
    });
  }
  public close() {
    this.$emit("closed");
    this.showing = false;
  }
  public accept() {
    this.$emit("accept", this.value);
    this.showing = false;
  }
  get acceptTextCalculated() {
    return this.acceptText
      .toString()
      .replaceAll("{value}", this.value.toString());
  }
}
