




















































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import CustomerSelect from "@/components/app/erp/contacts/customers/selectors/CustomerSelect.vue";
import TaxIdSelect from "@/components/app/erp/contacts/customers/selectors/TaxIdSelect.vue";
import CreditSelect from "@/components/app/erp/contacts/customers/selectors/CreditSelect.vue";
import ButtonGroup from "@/components/shared/buttons/ButtonGroup.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import LoadingButtonComponent from "@/components/shared/buttons/LoadingButton.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";
import { CreateQuoteRequest } from "@/application/contracts/app/erp/sales/CreateQuoteRequest";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";

@Component({
  components: {
    LoadingButton,
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    CustomerSelect,
    TaxIdSelect,
    CreditSelect,
    ButtonGroup
  }
})
export default class NewQuote extends Vue {
  $refs!: {
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
    customerSelect: CustomerSelect;
    buttonGenerateQuote: LoadingButtonComponent;
    modalConfirmCreate: ConfirmModalComponent;
  };
  customer: CustomerDto | null = null;
  comment1: string = "";
  comment2: string = "";
  loading: boolean = false;
  selectedCustomer(value: number, data: CustomerDto) {
    this.customer = data;
  }
  isNumber(val) {
    if (isNaN(Number(val.key))) {
      return val.preventDefault();
    }
  }
  validate(createQuoteLines: boolean, invoice: boolean, pay: boolean): boolean {
    const errors: string[] = [];
    if (errors.length > 0) {
      this.$refs.errorModal.show(this.$t(errors[0]));
      return false;
    }
    return true;
  }
  createdCustomer(item: CustomerDto) {
    this.$refs.customerSelect.$refs.dropdown.selectItem(item);
    this.$refs.customerSelect.$refs.dropdown.reload();
  }
  newQuote() {
    if (!this.validate(false, false, false)) {
      return;
    }
    this.yesConfirmCreate();
  }
  yesConfirmCreate() {
    const request: CreateQuoteRequest = {
      cliente: this.customer?.id ?? 0,
      observacion_1: this.comment1,
      observacion_2: this.comment2,
      banco_ingreso: 1,
      fecha: null
    };
    this.$refs.buttonGenerateQuote.start();
    ErpService.sales.quotes
      .create(request)
      .then((response: SaleDto) => {
        this.$router.push({
          name: "erp.sales.pos",
          query: { type: response.type, folio: response.folio.toString() }
        });
        this.$emit("created");
      })
      .catch(error => {
        this.$refs.buttonGenerateQuote.stop();
        this.$refs.errorModal.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loading = false;
        this.$refs.buttonGenerateQuote.stop();
      });
  }
}
