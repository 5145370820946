












































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { filters } from "@/plugins/filters";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";

@Component({})
export default class OverdueSaleTableRow extends Vue {
  @Prop({ default: -1 }) idx!: number;
  @Prop({ default: undefined }) item!: SaleDto;
  @Prop({ default: false }) allowRemove!: boolean;
  dateAgo(value: string) {
    return filters.dateFormat(value, "MMM DD");
  }
  totalPayments(item: SaleDto) {
    let total = 0;
    item.payments?.forEach(element => {
      total += element.amount;
    });
    return total;
  }
}
