import { EStockService } from "./EStockService";
import { EStoreService } from "./EStoreService";

class EcommerceService {
  stores: EStoreService;
  stock: EStockService;
  constructor() {
    this.stores = new EStoreService();
    this.stock = new EStockService();
  }
}
export default new EcommerceService();
