


































































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import { SubscriptionInvoiceDto } from "@/application/dtos/master/subscriptions/SubscriptionInvoiceDto";
import { filters } from "../../../../../plugins/filters";
import services from "@/services";

@Component({
  components: {}
})
export default class TenantSubscriptionInvoices extends BaseComponent {
  private upcomingInvoice = {} as SubscriptionInvoiceDto;
  created() {
    const self = this;
    this.eventBus.$on("updated-plan", () => self.getUpcomingInvoice());
  }
  beforeDestroy() {
    this.eventBus.$off("updatedPlan");
  }
  mounted() {
    this.getUpcomingInvoice();
  }
  getUpcomingInvoice() {
    const self = this;
    services.subscriptionManager
      .getUpcomingInvoice()
      .then((response: SubscriptionInvoiceDto) => {
        self.upcomingInvoice = response;
      });
  }
  invoiceDescription(invoice: SubscriptionInvoiceDto) {
    if (invoice && invoice.created) {
      const date = new Date(invoice.created);
      return filters.dateLL("LL");
    }
    return "";
  }
  get invoices(): SubscriptionInvoiceDto[] | undefined {
    return this.$store.state.tenant?.subscription?.invoices ?? [];
  }
}
