
































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import services from "@/services";

@Component({
  components: {}
})
export default class UserSelect extends Vue {
  @Prop() tenantId!: string;
  @Prop({ default: true }) selectAll!: boolean;
  @Prop({ default: "" }) preSelect!: string;
  public selected: number = -1;
  private dropDown: boolean = false;
  private items: TenantUserDto[] = [];
  private loading: boolean = false;
  mounted() {
    this.loading = true;
    services.tenantUsers
      .getAll(this.tenantId)
      .then((response: TenantUserDto[]) => {
        this.items = response;
        if (this.selectAll) {
          this.selected = 0;
        }
        if (this.preSelect) {
          this.selectEmail(this.preSelect);
        }
      })
      .catch(error => {
        console.error("[ERROR USERS]: " + JSON.stringify(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  name(item: TenantUserDto) {
    if (item.user.firstName && item.user.lastName) {
      return item.user.firstName + " " + item.user.lastName;
    }
    return item.user.firstName;
  }
  selectEmail(email: string) {
    const idx = this.items.findIndex(f => f.email === email);
    if (idx !== -1) {
      this.selected = idx + 1;
    }
  }
  avatarText(item: TenantUserDto) {
    if (item.user.firstName && item.user.lastName) {
      return item.user.firstName[0] + item.user.lastName[0];
    } else if (item.user.firstName) {
      return item.user.firstName[0];
    } else {
      return "?";
    }
  }
  select(idx) {
    this.selected = idx;
    this.dropDown = false;
    if (this.selectedTenantUser) {
      this.$emit("selectUser", this.selectedTenantUser.id);
    } else {
      this.$emit("selectAllUsers");
    }
  }
  closeDropDown() {
    this.dropDown = false;
  }
  get selectedTenantUser(): TenantUserDto | undefined {
    if (this.selected > 0) {
      return this.items[this.selected - 1];
    }
  }
}
