









































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import TableWithSearch from "@/components/shared/tables/TableWithSearch.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import { CustomFieldType } from "@/application/enums/app/projects/CustomFieldType";
import { TransferDto } from "@/application/dtos/app/erp/v3/inventory/documents/TransferDto";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";

@Component({
  components: {
    TableWithSearch,
    EmptyState
  }
})
export default class InventoryDocumentSearch extends BaseComponent {
  private items: TransferDto[] = [];
  private error: string = "";
  private columns: any[] = [
    { name: "warehouseId", title: "Almacén", thClass: "", trClass: "" },
    { name: "type", title: "Tipo", thClass: "", trClass: "" },
    { name: "folio", title: "Documento", thClass: "", trClass: "" },
    {
      name: "date",
      title: "Fecha",
      type: CustomFieldType.DATE,
      thClass: "",
      trClass: ""
    },
    { name: "reference1", title: "Ref 1", thClass: "", trClass: "" },
    { name: "reference2", title: "Ref 2", thClass: "", trClass: "" },
    { name: "reference3", title: "Ref 3", thClass: "", trClass: "" },
    {
      name: "readsCount",
      title: "Paquetes",
      type: CustomFieldType.INTEGER,
      thClass: "",
      trClass: "text-right"
    },
    {
      name: "readsStock",
      title: "Existencia",
      type: CustomFieldType.DECIMAL,
      thClass: "",
      trClass: "text-right"
    },
    {
      name: "lotAvailableStock",
      title: "Disponible",
      type: CustomFieldType.DECIMAL,
      thClass: "",
      trClass: "text-right"
    }
  ];
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    this.error = "";
    this.items = [];
    ErpService.inventory.documents
      .getAllWithPendingUnits()
      .then(response => {
        this.items = response;
      })
      .catch(error => {
        console.error(this.$t(error));
        this.error = this.$t(error).toString();
      })
      .finally(() => {
        this.loading = false;
      });
  }
  selected(item) {
    this.$emit("selected", item);
  }
}
