


























































































































import Vue from "vue";
import Component from "vue-class-component";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { Prop } from "vue-property-decorator";
import { BarcodeReadLineDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadLineDto";

@Component({})
export default class BarcodeReadRow extends Vue {
  @Prop({ default: -1 }) idx!: number;
  @Prop({ default: undefined }) item!: BarcodeReadLineDto;
  @Prop({ default: false }) disabled!: boolean;
  getRgbColor(item: BarcodeReadLineDto) {
    const color = item.product?.color?.split(",") ?? [];
    if (color && color.length === 3) {
      return `background-color:rgb(${color[0]},${color[1]},${color[2]})`;
    } else {
      return "";
    }
  }
  edit(item) {
    if (!this.disabled) {
      this.$parent.$emit("edit", item);
    }
  }
  get isSale() {
    return this.item.type === "PR";
  }
}
