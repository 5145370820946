













































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import services from "@/services";
import { mapGetters } from "vuex";
import { PeriodRequest } from "@/application/contracts/app/shared/PeriodRequest";
import { Period } from "@/application/enums/app/common/Period";
import { SummaryCountTotalResponse } from "@/application/contracts/app/summaries/SummaryCountTotalResponse";
import * as projectHelpers from "@/application/modules/projects/ProjectHelpers";
import { SummaryCountTotalType } from "@/application/enums/app/transactions/SummaryCountTotalType";

@Component({
  components: {}
})
export default class SummaryFromType extends Vue {
  @Prop({ default: -1 }) type!: SummaryCountTotalType;
  @Prop({ default: false }) details!: boolean;
  @Prop({ default: true }) withExpenses!: boolean;
  @Prop({ default: true }) withIncome!: boolean;
  @Prop({ default: true }) withTickets!: boolean;
  @Prop({ default: "" }) urlDetails!: string;
  @Prop({ default: "" }) urlNew!: string;
  @Prop({ default: false }) onlyInvoices!: boolean;
  private title: string = "";
  private dropDown: boolean = false;
  private items?: SummaryCountTotalResponse[] = [];
  private loading: boolean = false;
  private selected: number = -1;
  private period: PeriodRequest = {
    period: Period.LAST_30_DAYS,
    withDetails: false
  };
  private periodList: any[] = [];
  created() {
    this.periodList = [];
    const keys = Object.keys(Period).filter(
      k => typeof Period[k as any] === "number"
    ); // ["A", "B"]
    const periods = keys.map(k => Period[k as any]); // [0, 1]
    periods.forEach(key => {
      if (this.periodList.includes(f => f.value === Number(key))) {
        return;
      }
      const periodName: string = Period[key];
      this.periodList.push({
        value: Number(key),
        name: this.$t("app.shared.periods." + periodName),
        description: this.$t(
          "app.shared.periods." + periodName + "_Description"
        )
          .toString()
          .toLowerCase()
      });
    });
  }
  mounted() {
    this.title = this.$t(
      "app.dashboard.summaryTypes." + SummaryCountTotalType[this.type]
    ).toString();
  }
  reload(period?: PeriodRequest) {
    // console.log(`[${this.title}] current: ${this.period.period}`);
    // console.log(`[${this.title}] reloading: ${period?.period}`);
    if (this.period.period === period?.period) {
      return;
    }
    if (period) {
      this.period = period;
    }
    this.loading = true;
    this.items = [];
    services.dashboard
      .getSummaryCountTotal(this.type, this.period)
      .then((response: SummaryCountTotalResponse[]) => {
        this.items = response;
      })
      .catch(error => {
        // console.error(
        //   `[ERROR TIPO ${ResumenCuentaTotalTipo[this.tipo]}]: ` +
        //     JSON.stringify(error)
        // );
      })
      .finally(() => {
        this.loading = false;
      });
  }
  projectColor(color) {
    return projectHelpers.getProjectColor(color);
  }
  goTo(id) {
    if (this.urlDetails && id) {
      this.$router.push(this.urlDetails + id);
    }
  }
  texto(value: string): string | undefined {
    if (!value || value.trim() === "") {
      return undefined;
    }
    return value.trim();
  }
  get currentPeriod() {
    const period = this.periodList.find(f => f.value === this.period.period);
    if (period) {
      return period.description;
    }
  }
  get reportsUrl() {
    try {
      return {
        name: "app.reports",
        query: {
          type: SummaryCountTotalType[this.type].toLowerCase(),
          period: Period[this.period.period].toLowerCase()
        }
      };
    } catch {
      return {};
    }
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items
      .sort((x, y) => {
        return x.expenseTotal > y.expenseTotal ? -1 : 1;
      })
      .slice(0, 5);
  }
}
