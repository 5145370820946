import { UserDto } from "@/application/dtos/master/users/UserDto";
import * as signalR from "@aspnet/signalr";
import { HubConnectionState } from "@aspnet/signalr";
import store from "../store";

export default class SignalR {
  public connection!: signalR.HubConnection;
  private hubName!: string;
  private hubUrl!: string;
  private group!: string;
  private user: UserDto | null = null;
  private tries: number = 0;
  constructor(hubName: string, ...args: any[]) {
    this.hubName = hubName;
    this.group = args.join("-");
    this.user = store.state.account.user;
    this.hubUrl = process.env.VUE_APP_SERVER_URL + hubName;
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl)
      .configureLogging(signalR.LogLevel.Information)
      .build();
    this.connection.serverTimeoutInMilliseconds = 60000;
    this.connection.onclose(() => {
      if (this.tries <= 2) {
        console.log(`[${this.hubName}] Restarting in 30 seconds...`);
        setTimeout(() => {
          this.tries += 1;
          console.log(`[${this.hubName}] Restarting now`);
          this.startConnection();
        }, 30000);
      }
    });
  }
  public startConnection(): Promise<any> {
    const self = this;
    return new Promise((resolve, reject) => {
      if (self.connection.state === HubConnectionState.Connected) {
        return resolve(true);
      }
      try {
        self.connection
          .start()
          .then(() => {
            // console.log(`[${self.hubName}] Started connection`);
            return resolve(true);
          })
          .catch((err) => {
            console.log(`[${self.hubName}] Error: ` + err.toString());
            return reject(err);
          })
          .finally(() => {
            // console.log("Finally joining group with state: " + HubConnectionState[self.connection.state]);
            if (self.connection.state === HubConnectionState.Connected) {
              setTimeout(() => {
                self.joinGroup();
              }, 1000);
            }
          });
      } catch (err) {
        // ignore
      }
    });
  }
  public closeConnection() {
    try {
      if (this.connection && this.connection.state === HubConnectionState.Connected) {
        this.connection.invoke("LeaveGroup", this.group, this.user?.email);
      }
    } catch (error) {
      console.log("signal error: " + error);
    }
  }
  public async on(methodName: string, newMethod: (...args: any[]) => void): Promise<void> {
    this.connection.on(methodName, newMethod);
  }
  public async invoke<T = any>(methodName: string, ...args: any[]): Promise<T> {
    if (this.connection.state !== HubConnectionState.Connected) {
      await this.startConnection();
    }
    return new Promise((resolve, reject) => {
      this.connection
        .invoke(methodName, this.group, this.user?.email, ...args)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public async joinGroup() {
    if (this.connection.state !== HubConnectionState.Connected) {
      await this.startConnection();
    }
    // console.log("Joining group...");
    await this.connection.invoke("JoinGroup", this.group, this.user?.email);
    // const tenant = store.state.tenant?.current;
    // const workspace = store.state.tenant?.currentWorkspace;
    // if (this.connection && tenant && workspace) {

    //   // .then((response) => {
    //   //   console.log("JoinGroup Success:" + JSON.stringify(response));
    //   // })
    //   // .catch((err) => {
    //   //   console.log("JoinGroup Error:" + JSON.stringify(err));
    //   // });
    // }
  }
}
