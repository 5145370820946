























import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import BarcodeReads from "@/components/app/erp/inventory/barcode/BarcodeReads.vue";
import PointOfSaleSummary from "@/components/app/erp/sales/pos/PointOfSalesSummary.vue";
import Breadcrumb from "../../shared/Breadcrumb.vue";

@Component({
  components: {
    BarcodeReads,
    PointOfSaleSummary,
    Breadcrumb
  }
})
export default class PointOfSale extends BaseComponent {
  type: string = "";
  folio: number = 0;
  warehouse: number = 0;
  created() {
    this.type = this.$route.query.type?.toString() ?? "PT";
    this.folio = Number(this.$route.query.folio ?? 1);
    this.warehouse = 0;

    try {
      if (
        !this.$route.query ||
        Number(this.$route.query?.folio) !== this.folio
      ) {
        this.$router.push({
          path: this.$route.path,
          query: { folio: this.folio.toString() }
        });
      }
    } catch (e) {
      //
    }
  }
  mounted() {
    // @ts-ignore
    $crisp.push(["do", "chat:hide"]);
  }
  unmounted() {
    // @ts-ignore
    // $crisp.push(["do", "chat:show"]);
  }
  get folioName() {
    if (this.type === "PT") {
      return this.$t("erp.sales.pos.title") + " #" + this.folio;
    } else {
      return "Editando " + this.folioNameWithRoutePath;
    }
  }
  get folioNameWithRoutePath() {
    return (
      this.$route.query.type?.toString() +
      "-" +
      this.$route.query.folio.toString()
    );
  }
  // @Watch("$route.path")
  // pathChanged() {
  //   this.reload();
  // }
}
