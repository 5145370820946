var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.folioNameWithRoutePath},[_c('breadcrumb',{attrs:{"menu":[
      { title: _vm.$t('erp.sales.title'), routeName: '' },
      { title: _vm.$t('erp.sales.quotes.title'), routeName: 'erp.sales.quotes' },
      {
        title: _vm.folioName,
        routeName: _vm.$route.name,
        query: _vm.$route.query
      }
    ]}}),(_vm.type)?_c('BarcodeReads',{attrs:{"headerType":_vm.type,"headerFolio":_vm.folio,"headerWarehouseId":_vm.warehouse,"isSale":true}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }