









































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import NewModal from "../modals/NewModal.vue";

@Component({
  components: {
    NewModal,
  },
})
export default class DropdownSimpleFullScreen extends Vue {
  $refs!: {
    modalOptions: NewModal;
  };
  @Prop() title!: string;
  @Prop({ default: "lg" }) size!: string;
  close() {
    this.$refs.modalOptions.close();
  }
  show() {
    this.$refs.modalOptions.show();
  }
}
