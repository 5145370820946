






import Vue from "vue";
import { Component } from "vue-property-decorator";
import SuppliersList from "@/components/app/contacts/SuppliersList.vue";

@Component({
  components: {
    SuppliersList
  }
})
export default class Suppliers extends Vue {}
