import { accountRoutes } from "./accountRoutes";

export const productRoutes = [
  {
    path: "/producto",
    alias: "/product",
    name: "product",
    redirect: "/erp",
    component: require("@/views/marketing/Product.vue").default,
  },
  {
    path: "/precios",
    alias: "/prices",
    name: "prices",
    redirect: "/erp",
    component: require("@/views/marketing/Pricing.vue").default,
  },
  {
    alias: "/contact",
    path: "/contacto",
    name: "contact",
    redirect: "/erp",
    component: require("@/views/marketing/Contact.vue").default,
  },
  {
    path: "/blog",
    name: "blog",
    redirect: "/erp",
    component: require("@/views/marketing/Blog.vue").default,
  },
  {
    name: "blogPost",
    path: "/blog/:slug",
    component: require("@/views/marketing/BlogPost.vue").default,
  },
  {
    name: "lang",
    path: "/lang/:lang",
    component: require("@/views/marketing/Lang.vue").default,
  },
  {
    name: "lang.spanish",
    path: "/es",
    redirect: "lang/es-MX",
  },
  {
    name: "lang.english",
    path: "/en",
    redirect: "lang/en-US",
  },
  // {
  //   path: "hi",
  //   redirect: "lang/hi-IN",
  // },
  // {
  //   path: "in",
  //   redirect: "lang/hi-IN",
  // },
  accountRoutes,
];
