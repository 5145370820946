





























































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import PageTitle from "../../../components/shared/navigation/PageTitle.vue";
import { adminRoutes } from "../../../router/adminRoutes";
import { Watch } from "vue-property-decorator";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { ColumnType, ValueType } from "../../../application/dtos/ColumnType";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import services from "@/services";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    PageTitle
  }
})
export default class SubscriptionProductComponent extends BaseComponent {
  $refs!: {
    errorModal: ErrorModalComponent;
  };
  public product = {} as SubscriptionProductDto;
  public hasChanges: boolean = false;
  columns: ColumnType[] = [
    { name: "serviceId" },
    { name: "title" },
    { name: "description" },
    { name: "tier", valueType: ValueType.Number },
    { name: "badge", valueType: ValueType.String },
    { name: "active", valueType: ValueType.Bool },
    { name: "maxUsers", valueType: ValueType.Number },
    { name: "image", valueType: ValueType.Image }
  ];
  created() {
    const self = this;
    services.subscriptionProducts.getProducts(true, false);
  }
  added(data: SubscriptionProductDto) {
    this.reload();
  }
  saved(data: SubscriptionProductDto) {
    this.reload();
  }
  deleted(data: SubscriptionProductDto) {
    this.pushToParent();
  }
  @Watch("$route.params.id")
  productChanged(val: string, oldVal: string) {
    this.reload();
  }
  mounted() {
    this.reload();
    // this.$router.push({ name: "admin.product.overview" });
  }
  async reload() {
    this.loading = true;
    this.hasChanges = false;
    services.subscriptionProducts
      .getProduct(this.$route.params.id)
      .then((response: SubscriptionProductDto) => {
        this.product = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  saveChanges() {
    if (!this.product.id) {
      services.subscriptionProducts
        .createProduct(this.product)
        .then(response => {
          this.reload();
        })
        .catch(error => {
          this.$refs.errorModal.show(error);
        });
    } else {
      services.subscriptionProducts
        .updateProduct(this.product.id, {
          id: this.product.id,
          tier: this.product.tier,
          title: this.product.title,
          description: this.product.description,
          badge: this.product.badge,
          active: this.product.active,
          image: this.product.image,
          maxUsers: this.product.maxUsers
        })
        .then(response => {
          this.reload();
        })
        .catch(error => {
          this.$refs.errorModal.show(error);
        });
    }
  }
  isBool(column: ColumnType) {
    return column.valueType === ValueType.Bool;
  }
  hasImage() {
    return this.columns.includes(
      (element: ColumnType) => element.valueType === ValueType.Image
    );
  }
  getImage() {
    return this.product.image;
  }
  get item() {
    return this.product;
  }
  //   get columns() {
  //     let modelColumns: ColumnType[] = [];
  //     Object.keys(this.product).forEach((key) => {
  //       modelColumns.push({
  //         name: key,
  //       });
  //     });
  //     return modelColumns;
  //   }
}
