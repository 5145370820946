












































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import BillingPeriodToggle from "../../components/marketing/toggles/BillingPeriodToggle.vue";
import { mapGetters } from "vuex";
import { SubscriptionProductDto } from "../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import CurrencyToggle from "@/components/marketing/toggles/CurrencyToggle.vue";
import InvoiceToggle from "@/components/marketing/toggles/InvoiceToggle.vue";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import Vue from "vue";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import { Prop } from "vue-property-decorator";
import services from "@/services";

@Component({
  components: {
    BillingPeriodToggle,
    CurrencyToggle,
    InvoiceToggle
  },
  computed: {
    ...mapGetters("pricing", {
      selectedProductTitle: "selectedProductTitle",
      selectedPrice: "selectedPrice",
      selectedProduct: "selectedProduct",
      selectedBillingPeriod: "selectedBillingPeriod"
    }),
    ...mapGetters("tenant", {
      activePrice: "activePrice",
      activeProduct: "activeProduct"
    })
  }
})
export default class PlansComponent extends BaseComponent {
  @Prop({ default: "theme-bg-light theme-indigo" }) mainTheme!: string;
  public droppedDown: boolean = false;
  showOptions: boolean = false;
  selectedProduct!: SubscriptionProductDto;
  selectedPrice!: SubscriptionPriceDto;
  activePrice!: SubscriptionPriceDto;
  activeProduct!: TenantProductDto;
  selectedProductTitle!: string;
  selectedBillingPeriod!: string;

  created() {
    if (this.$store.state.pricing.products.length === 0) {
      services.subscriptionProducts.getProducts(false, true).then(() => {
        this.loadCurrent();
      });
    }
  }
  mounted() {
    services.subscriptionProducts.getProducts(true, true);
    this.loadCurrent();
    // this.$store.commit("pricing/mode", 0);
    // if (
    //   this.myProducts &&
    //   this.myProducts.length > 0 &&
    //   this.myProducts[0].subscriptionProduct
    // ) {
    //   this.$store.commit(
    //     "pricing/currency",
    //     this.myProducts[0].subscriptionPrice.currency
    //   );
    //   this.$store.commit(
    //     "pricing/billingPeriod",
    //     this.myProducts[0].subscriptionPrice.billingPeriod
    //   );
    //   this.$store.commit(
    //     "pricing/mode",
    //     this.myProducts[0].subscriptionPrice.mode
    //   );
    //   this.changeProduct(this.myProducts[0].subscriptionProduct);
    // }
  }
  loadCurrent() {
    if (this.activeProduct) {
      this.$store.commit("pricing/select", {
        billingPeriod: this.activeProduct.subscriptionPrice.billingPeriod,
        product: this.activeProduct.subscriptionProduct
      });
    }
  }
  // public setFromCurrentSubscription() {
  //   if (this.subscription) {
  //   }
  // }
  open() {
    this.droppedDown = true;
  }
  closeDropdown() {
    this.droppedDown = false;
  }
  changeProduct(product: SubscriptionProductDto) {
    this.closeDropdown();
    this.$store.commit("pricing/select", {
      product,
      billingPeriod: this.$store.state.pricing.billingPeriod
    });
  }
  price(product: SubscriptionProductDto): SubscriptionPriceDto {
    return (
      product.prices.find(
        f =>
          f.billingPeriod === this.$store.state.pricing.billingPeriod &&
          f.currency === this.$store.state.pricing.currency &&
          f.mode === this.$store.state.pricing.mode
      ) ?? product.prices[0]
    );
  }
  billingPeriod(price: SubscriptionPriceDto): string {
    if (price.billingPeriod === SubscriptionBillingPeriod.ONCE) {
      return this.$t("marketing.pricing.once").toString();
    } else {
      return (
        "/" +
        this.$t(
          "marketing.pricing." +
            SubscriptionBillingPeriod[price.billingPeriod] +
            "Short"
        )
      );
    }
  }
  showShowOptions() {
    // if (!this.showOptions) {
    //   this.closeDropdown();
    // }
    this.showOptions = !this.showOptions;
  }
  closeShowOptions() {
    // if (!this.showOptions) {
    //   this.closeDropdown();
    // }
    this.showOptions = false;
  }
  toggleBilling() {
    if (
      this.selectedPrice?.billingPeriod === SubscriptionBillingPeriod.MONTHLY
    ) {
      this.$store.commit(
        "pricing/billingPeriod",
        SubscriptionBillingPeriod.MONTHLY
      );
    } else {
      this.$store.commit(
        "pricing/billingPeriod",
        SubscriptionBillingPeriod.YEARLY
      );
    }
  }
  minPrice(product) {
    const price = Vue.filter("intFormat")(this.price(product));
    const billingPeriod = this.$t(
      "marketing.pricing." + this.selectedBillingPeriod
    );
    return `${price} ${this.price(product).currency} ${billingPeriod}`;
  }
  get selectedMode(): number {
    return this.$store.state.pricing.mode as number;
  }
  get myProducts(): TenantProductDto[] {
    return (this.$store.state.tenant.subscription
      .myProducts as TenantProductDto[])?.filter(
      f => f.subscriptionPrice.billingPeriod !== SubscriptionBillingPeriod.ONCE
    );
  }
  get products() {
    return (this.$store.state.pricing.products as SubscriptionProductDto[])
      ?.filter(f => f.active && !f.custom)
      .sort((x, y) => {
        return x.tier > y.tier ? 1 : -1;
      });
  }
}
