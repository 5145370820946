

















import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import TabsInPills from "./TabsInPills.vue";
import TabsInPillsWithBrandColor from "./TabsInPillsWithBrandColor.vue";

@Component({
  components: {
    TabsInPills,
    TabsInPillsWithBrandColor
  }
})
export default class Tabs extends Vue {
  @Prop({}) tabs!: any[];
  @Prop({ default: "sm" }) breakpoint!: string;
  @Prop({ default: 0 }) type!: number;
  click(idx) {
    this.$emit("click", idx);
  }
}
