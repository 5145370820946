import { CreateQuoteRequest } from "@/application/contracts/app/erp/sales/CreateQuoteRequest";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { ApiService, ApiServiceType } from "../../../ApiService";

export class QuoteService extends ApiService {
  constructor() {
    super("Sales/Quote", ApiServiceType.TenantDatabase);
  }
  getAll(): Promise<SaleDto[]> {
    return super.getAll("GetAll");
  }
  create(request: CreateQuoteRequest): Promise<SaleDto> {
    return super.post(request, "Create");
  }
  deleteLines(folio: number): Promise<any> {
    return super.delete(folio.toString(), "DeleteLines");
  }
}
