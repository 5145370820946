



















































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import server from "@/plugins/axios-helper";
import axios from "axios";

@Component({
  components: {}
})
export default class DropdownWithSearch extends Vue {
  $refs!: {
    inputSearch: HTMLInputElement;
    itemList: HTMLUListElement;
  };
  @Prop({ default: "" }) label!: string;
  @Prop({}) selectPlaceholder!: string;
  @Prop({}) searchPlaceholder!: string;
  @Prop({}) noResultsPlaceholder!: string;
  @Prop({ default: "value" }) valueProp!: string;
  @Prop({ default: "name" }) textProp!: string;
  @Prop({ default: undefined }) selectedTextProp!: string;
  @Prop({ default: true }) reloadOnEnter!: boolean;
  @Prop({ default: false }) searchOnEnter!: boolean;
  @Prop({ default: true }) showValueInText!: boolean;
  @Prop({ default: false }) loadWithSearchOnly!: boolean;
  @Prop({ default: "" }) apiUrl!: string;
  @Prop({ default: false }) loadAll!: boolean;
  @Prop({ default: false }) createButton!: boolean;
  @Prop({}) data!: any[];
  private searching: boolean = false;
  private searchInput: string = "";
  private searchedWithInput: string = "";
  private items: any[] = [];
  private filteredItems: any[] = [];
  private selectedValue: any = null;
  private selectedText: string = "";
  private loading: boolean = false;
  private error: string = "";
  private currentItem = -1;
  public select(selectedValue, selectedText) {
    this.selectedValue = selectedValue;
    this.selectedText = selectedText;
  }
  public setItems(items) {
    this.items = items;
  }
  public selectItem(item) {
    this.close();
    this.selectedValue = item[this.valueProp];
    this.selectedText = item[this.selectedTextProp ?? this.textProp];
    this.$emit("selected", this.selectedValue, item);
  }
  public selectValue(value: any) {
    if (value !== null) {
      const item = this.items.find(
        f => f[this.valueProp].toString() === value.toString()
      );
      if (item) {
        this.selectItem(item);
      }
    }
  }
  public open() {
    this.searching = true;
    if (this.apiUrl && (this.error || !this.items || this.items.length === 0)) {
      this.reload();
    }
    this.focusSearchInput();
  }
  public close() {
    this.searching = false;
  }
  public async reload() {
    if (!this.apiUrl) {
      this.$emit("reload");
      return;
    }
    if (!this.searchInput && this.loadWithSearchOnly) {
      return;
    }
    this.error = "";
    this.loading = true;
    this.items = [];
    this.filteredItems = [];
    this.searchedWithInput = this.searchInput;
    const apiUrl = this.apiUrl.replace("[searchInput]", this.searchInput);
    server
      .get(apiUrl)
      .then((response: any) => {
        if (response?.data && response?.data.length > 0) {
          this.items = response.data;
          this.filteredItems = response.data;
          this.$nextTick(() => {
            // this.$refs.itemList.focus();
          });
          if (this.searchInput.includes("%")) {
            this.searchInput = "";
          }
        }
      })
      .catch(error => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
        this.focusSearchInput();
      });
    // await fetch(apiUrl)
    //   .then(response => response.json())
    //   .then(data => {
    //     this.items = data.items;
    //   })
    //   .finally(() => (this.loading = false));
  }
  private focusSearchInput() {
    if (this.searching) {
      this.$nextTick(() => {
        if (this.$refs.inputSearch) {
          this.$refs.inputSearch.focus();
          this.$refs.inputSearch.select();
        }
      });
    }
  }
  private mounted() {
    // document.addEventListener("keyup", this.nextItem);
    if (this.loadAll) {
      this.reload();
    }
    window.addEventListener("keyup", this.keyup);
  }
  private beforeDestroy() {
    window.removeEventListener("keyup", this.keyup);
  }
  private keyup(event: KeyboardEvent) {
    // console.log({ event, searching: this.searching });
    if (!this.searchOnEnter) {
      if (this.searching && this.filtered?.length === 0) {
        if (event.keyCode === 13) {
          this.selectItem(this.filtered[0]);
        }
      }
    }
  }
  private nextItem(event) {
    if (event.keyCode === 38 && this.currentItem > 1) {
      this.currentItem--;
    } else if (event.keyCode === 40 && this.currentItem < 3) {
      this.currentItem++;
    }
    if (this.currentItem < 0) {
      this.currentItem = 0;
    }
  }
  private toggle() {
    if (!this.searching) {
      this.open();
    } else {
      this.close();
    }
  }
  private reloadWithEnter() {
    if (this.reloadOnEnter) {
      this.reload();
    } else if (this.searchOnEnter) {
      this.filteredItems = this.filter(this.items, this.searchInput);
    }
  }
  private filter(items, searchInput) {
    if (!searchInput || searchInput.trim() === "") {
      return items;
    }
    if (this.reloadOnEnter) {
      return items.filter(
        f =>
          !f[this.valueProp] ||
          f[this.valueProp]
            ?.toString()
            .toUpperCase()
            .includes(
              searchInput
                .toUpperCase()
                .replaceAll("%", "")
                .replaceAll("*", "")
            ) ||
          f[this.textProp]
            .toString()
            .toUpperCase()
            .includes(
              searchInput
                .toUpperCase()
                .replaceAll("%", "")
                .replaceAll("*", "")
            )
      );
    } else {
      return items.filter(
        f =>
          f[this.valueProp]
            .toString()
            .toUpperCase()
            .includes(searchInput.toUpperCase()) ||
          f[this.textProp]
            .toString()
            .toUpperCase()
            .includes(searchInput.toUpperCase())
      );
    }
  }
  get getSearchPlaceholder() {
    return this.searchPlaceholder ?? this.$t("shared.searchDot");
  }
  get filtered() {
    // console.log({ searchInput: this.searchInput, items: this.items });
    return this.filter(this.items, this.searchInput);
  }
}
