import { CreateCategoryRequest } from "@/application/contracts/app/erp/inventory/categories/CreateCategoryRequest";
import { UpdateCategoryRequest } from "@/application/contracts/app/erp/inventory/categories/UpdateCategoryRequest";
import { UnitOfMeasureDto } from "@/application/dtos/app/erp/v3/inventory/uom/UnitOfMeasureDto";
import { ApiService, ApiServiceType } from "../../../ApiService";

export class UnitOfMeasureService extends ApiService {
  constructor() {
    super("Inventory/UnitOfMeasure", ApiServiceType.TenantDatabase);
  }
  getAll(): Promise<UnitOfMeasureDto[]> {
    return super.getAll();
  }
  get(id: string): Promise<UnitOfMeasureDto> {
    return super.get(id);
  }
  create(request: CreateCategoryRequest): Promise<UnitOfMeasureDto> {
    return super.post(request);
  }
  update(id: string, request: UpdateCategoryRequest): Promise<UnitOfMeasureDto> {
    return super.put(id, request);
  }
  delete(id: string): Promise<void> {
    return super.delete(id);
  }
}
