import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import { UserType } from "@/application/enums/master/users/UserType";

const erpRoutes = [
  {
    name: "erp",
    path: "erp",
    component: require("@/views/app/erp/Menu.vue").default,
  },
  {
    name: "erp.sales.sale",
    alias: "sale",
    path: "venta/:type/:folio",
    component: require("@/views/app/erp/sales/details/SaleFullScreenDetails.vue").default,
  },
  {
    name: "erp.sales",
    path: "ventas",
    alias: "sales",
    component: require("@/views/app/erp/sales/Index.vue").default,
    // redirect: "/ventas/cotizaciones",
    children: [
      {
        path: "lista",
        alias: "list",
        component: require("@/components/app/erp/sales/list/SalesTables.vue").default,
        children: [
          {
            name: "erp.sales.quotes",
            path: "cotizaciones",
            alias: "quotes",
            component: require("@/components/app/erp/sales/list/quotes/QuotesTable.vue").default,
            children: [
              {
                name: "erp.sales.quotes.details",
                path: "detalles/:type?/:folio?",
                alias: "details/:type?/:folio?",
                component: require("@/views/app/erp/sales/details/SaleSidebarDetails.vue").default,
              },
            ],
          },
          {
            name: "erp.sales.invoices",
            path: "facturas",
            alias: "invoices",
            component: require("@/components/app/erp/sales/list/invoices/InvoicesTable.vue").default,
          },
          {
            name: "erp.sales.overdue",
            path: "por-cobrar",
            alias: "overdue",
            component: require("@/components/app/erp/sales/list/overdue/OverdueSalesTable.vue").default,
          },
        ],
      },
      {
        name: "erp.sales.pos",
        path: "punto-de-venta",
        alias: "point-of-sale",
        component: require("@/views/app/erp/sales/pos/PointOfSale.vue").default,
      },
    ],
  },
  {
    name: "erp.contacts.customers",
    path: "clientes",
    alias: "customers",
    component: require("@/views/app/erp/contacts/customers/Index.vue").default,
    redirect: "/app/clientes/lista",
    children: [
      {
        path: "lista",
        alias: "list",
        redirect: "/app/clientes/lista/todos",
        component: require("@/components/app/erp/contacts/customers/list/CustomersTables.vue").default,
        children: [
          {
            name: "erp.contacts.customers.all",
            path: "todos",
            alias: "all",
            component: require("@/components/app/erp/contacts/customers/list/all/AllCustomersTable.vue").default,
          },
          {
            name: "erp.contacts.customers.new",
            path: "nuevo",
            alias: "new",
            component: require("@/views/app/erp/contacts/customers/forms/NewCustomer.vue").default,
          },
        ],
      },
    ],
  },
  {
    name: "erp.inventory",
    alias: "inventory",
    path: "inventario",
    component: require("@/views/app/erp/inventory/Index.vue").default,
    redirect: "/app/inventory/products",
    children: [
      {
        name: "erp.inventory.products",
        path: "productos",
        alias: "products",
        component: require("@/views/app/erp/inventory/list/products/ProductsList.vue").default,
      },
      {
        name: "erp.inventory.products.edit",
        path: "productos/:id",
        alias: "products/:id",
        component: require("@/views/app/erp/inventory/products/ProductForm.vue").default,
        meta: {
          userTypes: [UserType.ADMIN],
          userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
        },
      },
      // {
      //   name: "app.inventory.transfers",
      //   path: "transferencias",
      //   alias: "products",
      //   component: require("@/views/app/erp/inventory/TransfersList.vue").default,
      // },
      {
        name: "erp.inventory.physical.new",
        path: "fisicos",
        alias: "physical",
        component: require("@/views/app/erp/inventory/documents/PhysicalDocument.vue").default,
      },
    ],
  },
];

export default erpRoutes;
