import { GetTicketsRequest } from "@/application/contracts/app/tickets/GetTicketsRequest";
import { TicketDto } from "@/application/dtos/app/transactions/TicketDto";
import { UpdateTicketInvoicedRequest } from "@/application/contracts/app/tickets/UpdateTicketInvoicedRequest";
import { UploadTicketsRequest } from "@/application/contracts/app/tickets/UploadTicketsRequest";
import { ApiService, ApiServiceType } from "../../ApiService";

export class TicketService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("Ticket", ApiServiceType.TenantDatabase);
  }
  adminGetAll(request: GetTicketsRequest): Promise<TicketDto[]> {
    return this.api.getAll(`Admin/GetAll?` + this.api.toQueryString(request));
  }
  getAllTickets(request: GetTicketsRequest): Promise<TicketDto[]> {
    return this.api.getAll(`GetAll?` + this.api.toQueryString(request));
  }
  adminGet(id: string): Promise<TicketDto> {
    return this.api.get("Admin/Get", id);
  }
  getTicket(id: string, admin: boolean): Promise<TicketDto> {
    if (admin) {
      return this.api.get("Admin/Get", id);
    }
    return this.api.get("Get", id);
  }
  create(data: TicketDto): Promise<TicketDto> {
    return this.api.post(data);
  }
  uploadTickets(data: UploadTicketsRequest): Promise<any> {
    return this.api.post(data, "UploadTickets");
  }
  adminReadTicketLines(id: string): Promise<TicketDto> {
    return this.api.post(undefined, "Admin/ReadTicketLines/" + id);
  }
  downloadAll(): Promise<any> {
    return this.api.download(null, `DownloadAll`);
  }
  uploadXmlAndPdf(fileData: FormData, ticket: TicketDto): Promise<TicketDto> {
    return this.api.post(fileData, "UploadXmlAndPdf/" + ticket.id);
  }
  uploadNewImage(fileData: FormData, ticket: TicketDto): Promise<TicketDto> {
    return this.api.post(fileData, "UploadNewImage/" + ticket.id);
  }
  uploadNewPdf(fileData: FormData, ticket: TicketDto): Promise<any> {
    return this.api.post(fileData, "UploadNewPdf/" + ticket.id);
  }
  uploadErrorImages(ticket: TicketDto, error: string, fileData?: FormData): Promise<TicketDto> {
    return this.api.post(fileData, "UploadErrorImages/" + ticket.id + "?error=" + error);
  }
  update(id: string, data: TicketDto): Promise<TicketDto> {
    return this.api.put(id, data);
  }
  updateInvoiced(id: string, request: UpdateTicketInvoicedRequest): Promise<TicketDto> {
    return this.api.put(id, request, "UpdateInvoiced");
  }
  delete(id: string): Promise<any> {
    return this.api.delete(id);
  }
  adminDelete(id: string): Promise<any> {
    return this.api.delete(id, "Admin/Delete");
  }
}
