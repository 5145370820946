





































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import CustomerSelect from "@/components/app/erp/contacts/customers/selectors/CustomerSelect.vue";
import TaxIdSelect from "@/components/app/erp/contacts/customers/selectors/TaxIdSelect.vue";
import CreditSelect from "@/components/app/erp/contacts/customers/selectors/CreditSelect.vue";
import ButtonGroup from "@/components/shared/buttons/ButtonGroup.vue";
import { Prop } from "vue-property-decorator";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import LoadingButtonComponent from "@/components/shared/buttons/LoadingButton.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { filters } from "@/plugins/filters";
import { nextTick } from "process";
import { CustomerDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerDto";
import { CustomerTaxIdDto } from "@/application/dtos/app/erp/v3/contacts/customers/CustomerTaxIdDto";
import { CreateInvoiceFromBarcodeReadsRequest } from "@/application/contracts/app/erp/sales/CreateInvoiceFromBarcodeReadsRequest";
import { CreateQuoteRequest } from "@/application/contracts/app/erp/sales/CreateQuoteRequest";
import { BarcodeReadsSummaryDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadsSummaryDto";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import Utils from "@/application/shared/Utils";
import store from "@/store";

@Component({
  components: {
    LoadingButton,
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    CustomerSelect,
    TaxIdSelect,
    CreditSelect,
    ButtonGroup
  }
})
export default class NewSaleFromBarcodeReads extends Vue {
  $refs!: {
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
    customerSelect: CustomerSelect;
    taxIdSelect: TaxIdSelect;
    creditSelect: CreditSelect;
    inputPagare: HTMLInputElement;
    inputMetodoDePago: ButtonGroup;
    inputPaymentForm: ButtonGroup;
    inputUsoDeCfdi: ButtonGroup;
    buttonGenerateQuote: LoadingButtonComponent;
    buttonCreateQuote: LoadingButtonComponent;
    buttonGenerateInvoice: LoadingButtonComponent;
    buttonGenerateInvoiceAndPay: LoadingButtonComponent;
    modalConfirmCreate: ConfirmModalComponent;
  };
  @Prop({}) summary!: BarcodeReadsSummaryDto;
  @Prop({}) quote!: SaleDto;
  customer: CustomerDto | null = null;
  customerTaxId: CustomerTaxIdDto | null = null;
  customerCredit: number | null = null;
  last4Digits: string = "";
  pagare: number = 0;
  paymentMethod: string = "PUE";
  paymentForm: string = "01";
  usoDeCfdi: string = "P01";
  comment1: string = "";
  comment2: string = "";
  createQuoteLines: boolean = false;
  loading: boolean = false;
  created() {
    window.addEventListener("keyup", event => {
      if (event.ctrlKey && event.keyCode) {
        if (event.keyCode === 70) {
          if (this.paymentMethod === "PUE") {
            this.generate(true, true, true);
          } else {
            this.generate(true, true, false);
          }
        }
      }
    });
  }
  mounted() {
    const self = this;
    if (this.quote) {
      if (this.quote.customerId) {
        this.loading = true;
        ErpService.customers.customers
          .get(this.quote.customerId.toString())
          .then((response: CustomerDto) => {
            self.$refs.customerSelect.$refs.dropdown.selectItem(response);
            if (response.creditDays >= 0) {
              self.$refs.creditSelect.select(response.creditDays);
            }
            const taxId = response.taxIds.find(
              f => f.taxId === self.quote.taxId
            );
            if (taxId) {
              self.$refs.taxIdSelect.$refs.dropdown.selectItem(taxId);
            }
            if (self.quote.cfdiUse) {
              self.usoDeCfdi = self.quote.cfdiUse;
            }
            if (self.quote.paymentMethod) {
              self.paymentMethod = self.quote.paymentMethod;
            }
            if (self.quote.paymentForm) {
              self.paymentForm = self.quote.paymentForm;
            }
          })
          .finally(() => {
            self.loading = false;
          });
      }
    }
  }
  selectedCustomer(value: number, data: CustomerDto) {
    this.customer = data;
    this.pagare = 0;
    this.last4Digits = "";
    this.paymentMethod = "PUE";
    this.paymentForm = "01";
    this.usoDeCfdi = "P01";

    this.$refs.creditSelect.select(data.creditDays);
    this.$refs.taxIdSelect.setItems(data.taxIds, true);
    let taxIdDefault = data.taxIds.find(f => f.taxId !== "XAXX010101000");
    if (!taxIdDefault && data.taxIds.length > 0) {
      taxIdDefault = data.taxIds[0];
    }
    if (taxIdDefault) {
      if (taxIdDefault.paymentMethod) {
        this.paymentMethod = taxIdDefault.paymentMethod;
      }
      if (taxIdDefault.paymentForm) {
        this.paymentForm = taxIdDefault.paymentForm;
      }
      if (taxIdDefault.last4Digits) {
        this.last4Digits = taxIdDefault.last4Digits;
      }
      if (taxIdDefault.cfdiUse) {
        this.usoDeCfdi = taxIdDefault.cfdiUse;
      }
    }
    //   if (data._Rfcs.length > 1) {
    //   this.$refs.taxIdSelect.open();
    // }
  }
  selectedTaxId(value: number, data: CustomerTaxIdDto) {
    this.customerTaxId = data;
    // console.log({ data });
    if (data.paymentMethod) {
      this.paymentMethod = data.paymentMethod;
    }
    if (data.paymentForm) {
      this.paymentForm = data.paymentForm;
    }
    if (data.last4Digits) {
      this.last4Digits = data.last4Digits;
    }
    if (data.cfdiUse) {
      this.usoDeCfdi = data.cfdiUse;
    }
    // console.log("selectedTaxId: " + value);
  }
  selectedCredit(value: number) {
    this.customerCredit = value;
    if (this.isCredit) {
      this.$refs.inputMetodoDePago.select("PPD");
      this.$nextTick(() => {
        this.$refs.inputPagare.focus();
        this.$refs.inputPagare.select();
      });
    } else {
      this.$refs.inputMetodoDePago.select("PUE");
    }
  }
  isNumber(val) {
    if (isNaN(Number(val.key))) {
      return val.preventDefault();
    }
  }
  validate(createQuoteLines: boolean, invoice: boolean, pay: boolean): boolean {
    const errors: string[] = [];
    this.createQuoteLines = true;
    if (!this.customer || this.customer.id === 0) {
      errors.push("Selecciona un cliente");
      nextTick(() => {
        // this.$refs.customerSelect.$refs.dropdown.open();
      });
    }
    // if (this.isCredit && !invoice && !pay) {
    //   errors.push("No puedes crear pedidos de facturas a crédito");
    // }
    if (!this.customerTaxId || this.customerTaxId.taxId === "") {
      errors.push("Selecciona un RFC");
      nextTick(() => {
        // this.$refs.taxIdSelect.$refs.dropdown.open();
      });
    }
    if (!this.customerCredit) {
      errors.push("Selecciona los días de crédito");
      nextTick(() => {
        // this.$refs.creditSelect.$refs.dropdown.open();
      });
    }
    if (!this.paymentMethod || this.paymentMethod === "") {
      errors.push("Selecciona el método de pago");
      this.paymentMethod = "PUE";
    }
    if (!this.paymentForm || this.paymentForm === "") {
      errors.push("Selecciona la forma de pago");
      this.paymentMethod = "01";
    }
    if (this.paymentMethod === "PUE" && this.paymentForm === "99") {
      errors.push(
        "Especifica forma de pago Efectivo, Cheque o Transferencia para ventas tipo PUE - Pago en una sola exhibición"
      );
      this.paymentForm = "01";
    }
    if (this.isCredit) {
      this.paymentMethod = "PPD";
      this.paymentForm = "99";
    }
    if (this.paymentMethod === "PPD" && this.paymentForm !== "99") {
      // errors.push(
      //   "Especifica forma de pago '99' para ventas tipo PPD - Pago en parcialidades o diferido"
      // );
      this.paymentForm = "99";
    }
    if (this.isCredit && invoice && this.pagare === 0) {
      errors.push("Especifica el pagaré para ventas de crédito");
      nextTick(() => {
        this.$refs.inputPagare.focus();
        this.$refs.inputPagare.select();
      });
    }
    if (this.isCredit && this.paymentMethod !== "PPD") {
      errors.push(
        "Especifica el método de pago 'PPD' para ventas con días de crédito"
      );
      this.paymentMethod = "PPD";
    }
    if (this.paymentMethod === "PPD" && pay) {
      errors.push(
        "No puedes Facturar y Pagar en ventas tipo PPD - Pago en parcialidades o diferido"
      );
    }
    if (!this.usoDeCfdi || this.usoDeCfdi === "") {
      errors.push("Selecciona el uso de CFDi");
    }
    if (errors.length > 0) {
      this.$refs.errorModal.show(this.$t(errors[0]));
      return false;
    }
    return true;
  }
  generate(createQuoteLines: boolean, invoice: boolean, pay: boolean) {
    if (!this.validate(createQuoteLines, invoice, pay)) {
      return;
    }

    if (!this.canCreateInvoice) {
      return;
    }


    this.createQuoteLines = createQuoteLines;
    this.$refs.modalConfirmCreate.value = { invoice, pay };
    let confirmText = "";
    let descriptionText = "";
    if (invoice && pay) {
      this.createQuoteLines = true;
      confirmText =
        "Facturar y pagar " + filters.moneyFormat(this.summary.total);
      descriptionText = `Se <b>facturará</b> a ${
        this.customer?.name
      } de <b>${filters.moneyFormat(
        this.summary.total
      )}</b> y se pagará en automático como <b>${
        this.paymentFormDescripcion
      }</b>.`;
      // this.$refs.buttonGenerateInvoiceAndPay.start();
    } else if (invoice && !pay) {
      this.createQuoteLines = true;
      confirmText = "Facturar";
      descriptionText = `Se <b>facturará</b> a <b>${
        this.customer?.name
      }</b> de <b>${filters.decimalFormat(
        this.summary.quantity
      )}</b> - <b>${filters.moneyFormat(this.summary.total)}</b>.`;
      // this.$refs.buttonGenerateInvoice.start();
    } else if (!invoice && !pay) {
      if (createQuoteLines) {
        confirmText = "Crear pedido";
        descriptionText = `Se creará el <b>pedido</b> apartando existencias <em>(creando detalles de prefactura)</em> a <b>${
          this.customer?.name
        }</b> de <b>${filters.decimalFormat(
          this.summary.quantity
        )}</b> - <b>${filters.moneyFormat(this.summary.total)}</b>.`;
      } else {
        confirmText = "Guardar para después";
        descriptionText = `Se <b>guardarán</b> las lecturas sin apartar existencias <em>(sin crear detalles de prefactura)</em> a <b>${
          this.customer?.name
        }</b> de <b>${filters.decimalFormat(this.summary.quantity)}</b>.`;
      }
      // this.$refs.buttonGenerateQuote.start();
    }
    this.$refs.modalConfirmCreate.show(
      "¿" + confirmText + "?",
      "Confirmar",
      "Cancelar",
      descriptionText
    );
  }
  yesConfirmCreate(value) {
    const { invoice, pay } = value;
    const request: CreateInvoiceFromBarcodeReadsRequest = {
      type: this.summary.type,
      folio: this.summary.folio,
      customerId: this.customer?.id ?? 0,
      taxId: this.customerTaxId?.taxId ?? "XAXX010101000",
      creditDays: this.customerCredit ?? 0,
      comment1: this.comment1,
      comment2: this.comment2,
      paymentMethod: this.paymentMethod,
      paymentForm: this.paymentForm,
      last4Digits: this.last4Digits,
      cfdiUse: this.usoDeCfdi,
      bankIncomeId: 1,
      date: null,
      invoice,
      pay,
      toPayDocument: this.pagare,
      createSaleLines: this.createQuoteLines
      // unitOfMeasure: "BL"
    };
    this.loading = true;
    ErpService.sales.sales
      .createFromBarcodeReads(request)
      .then((response: SaleDto) => {
        // this.$refs.successModal.show(`Factura creada ${response}`);
        this.$emit("created", response, this.createQuoteLines);
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
        if (error.toString().includes("Tiempo de espera agotado")) {
          this.$router.push({ name: "erp.sales.invoices" });
        } else {
          this.$emit("error", error);
        }
      })
      .finally(() => {
        this.loading = false;
        // if (invoice && pay) {
        //   this.$refs.buttonGenerateInvoiceAndPay.stop();
        // } else if (invoice && !pay) {
        //   this.$refs.buttonGenerateInvoice.stop();
        // } else if (!invoice && !pay) {
        //   this.$refs.buttonGenerateQuote.stop();
        // }
      });
  }
  changedMetodoDePago() {
    if (this.paymentMethod === "PPD") {
      this.paymentForm = "99";
    } else {
      this.paymentForm = "01";
    }
  }
  get canCreateInvoice() {
    // const isLastDays = Utils.isLastDays(new Date(), 3);
    // if (!store.state.account.user?.email.includes("@absys.com.mx")) {
    //   if (isLastDays) {
    //     return false;
    //   }
    // }
    return true;
  }
  get paymentFormDescripcion() {
    if (this.paymentForm === "01") {
      return "Efectivo";
    } else if (this.paymentForm === "02") {
      return "Cheque";
    } else if (this.paymentForm === "03") {
      return "Transferencia";
    } else {
      return "Por definir";
    }
  }
  get canEditFormaDePago() {
    return this.paymentMethod !== "PPD";
  }
  get isBankDeposit() {
    return this.paymentForm === "02" || this.paymentForm === "03";
  }
  // get isCash() {
  //   return this.paymentForm === "01";
  // }
  get isCredit() {
    if (!this.customerCredit) {
      return false;
    }
    const days: number = +this.customerCredit;
    // console.log({ days });
    return days > 1;
  }
}
