


























































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import TableWithSearch from "@/components/shared/tables/TableWithSearch.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import { IN_Articulo } from "@/application/dtos/app/erp/v2/inventory/IN_Articulo";
import { ProductDto } from "@/application/dtos/app/erp/v3/inventory/ProductDto";

@Component({
  components: {
    TableWithSearch
  }
})
export default class ProductSearch extends BaseComponent {
  $refs!: {
    table: TableWithSearch;
  };
  private items: ProductDto[] = [];
  private error: string = "";
  private columns: any[] = [
    { name: "id", title: "Artículo", thClass: "", trClass: "" },
    {
      name: "name",
      title: "Descripción",
      thClass: "w-10 truncate ",
      trClass: "w-10 truncate"
    }
  ];
  private loaded: boolean = false;
  private currentPage: number = 1;
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    this.error = "";
    this.items = [];
    ErpService.inventory.products
      .getAll()
      .then(response => {
        this.items = response;
      })
      .catch(error => {
        console.error(this.$t(error));
        this.error = this.$t(error).toString();
        // this.$refs.errorModal
      })
      .finally(() => {
        this.loading = false;
        this.loaded = true;
      });
  }
  selected(item) {
    this.$emit("selected", item);
  }
}
