import { ImageResolution } from "@/application/enums/app/common/ImageResolution";
import store from "@/store";
import { ApiService, ApiServiceType } from "../../../ApiService";

export class B2BProductService {
  api: ApiService;
  constructor() {
    this.api = new ApiService("b2b/Product", ApiServiceType.TenantDatabase);
  }
  getImage(id: string, resolution: ImageResolution, thumbnailSize: number): Promise<any> {
    // tslint:disable-next-line:max-line-length
    const image = store.state.products.images.find((f) => f.productId === id && f.resolution === resolution && f.thumbnailSize === thumbnailSize);
    if (image) {
      return Promise.resolve(image);
    }
    return this.api.get(`Image/${id}/${resolution}/${thumbnailSize}`).catch(() => {
      // ignore
    });
  }
}
