















































































































import Vue from "vue";
import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import { Period } from "@/application/enums/app/common/Period";
import { Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PeriodSelect extends Vue {
  @Prop({ default: [] }) periods!: string[];
  @Prop({ default: Period.ALL }) initialPeriod!: Period;
  @Prop({ default: true }) withDescription!: Period;
  public period: Period = Period.ALL;
  private periodList: any[] = [];
  private showPeriods: boolean = false;
  created() {
    this.periodList = [];
    const keys = Object.keys(Period)
      .reverse()
      .filter(k => typeof Period[k as any] === "number"); // ["A", "B"]
    let periods = keys.map(k => Period[k as any]); // [0, 1]

    if (this.periods && this.periods.length > 0) {
      periods = this.periods;
    }
    periods.forEach(key => {
      if (this.periodList.includes(f => f.value === Number(key))) {
        return;
      }
      this.periodList.push({
        value: Number(key),
        name: this.$t("app.shared.periods." + Period[key]),
        description: this.$t(
          "app.shared.periods." + Period[key] + "_Description"
        )
          .toString()
          .toLowerCase()
      });
    });
  }
  mounted() {
    this.period = this.initialPeriod;
  }
  closeShowPeriods() {
    this.showPeriods = false;
  }
  selectPeriod(period: Period) {
    this.closeShowPeriods();
    this.period = period;
    this.$emit("selected", period);
  }
  get currentPeriod() {
    const period = this.periodList.find(f => f.value === this.period);
    return period?.name ?? "";
  }
}
