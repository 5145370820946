var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-h-60 min-h-[60px] text-sm"},[(_vm.loading)?_c('div',{staticClass:"flex flex-wrap -m-2 mb-10"},[_vm._m(0)]):(_vm.error)?_c('div',{staticClass:"p-6 flex justify-center mx-auto"},[_c('span',{staticClass:"text-red-600 shadow-lg"},[_vm._v(" Error: "+_vm._s(_vm.error)+" ")])]):(_vm.items)?_c('table-with-search',{attrs:{"columns":_vm.columns,"items":_vm.items,"filterFields":[
      'type',
      'folio',
      'toWarehouseId',
      'date',
      'reference1',
      'reference2',
      'reference3'
    ]},on:{"reload":_vm.reload,"selected":_vm.selected}}):_vm._e(),(!_vm.loading && _vm.items.length === 0)?_c('EmptyState',{attrs:{"type":0,"captions":{
      thereAreNo: 'No hay entradas de almacén pendientes',
      new: 'Recargar'
    },"icon":""},on:{"new":_vm.reload}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-auto w-full flex justify-center py-16"},[_c('div',{staticClass:"loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"})])}]

export { render, staticRenderFns }