export enum Colors {
  UNDEFINED,
  BLUE_GRAY,
  COOL_GRAY,
  GRAY,
  TRUE_GRAY,
  WARM_GRAY,
  RED,
  ORANGE,
  AMBER,
  YELLOW,
  LIME,
  GREEN,
  EMERALD,
  TEAL,
  CYAN,
  LIGHT_BLUE,
  BLUE,
  INDIGO,
  VIOLET,
  PURPLE,
  FUCHSIA,
  PINK,
  ROSE,
}
