












































































































































































































































































































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import ErpService from "@/services/api/app/erp/ErpService";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import DropdownSimple from "@/components/shared/dropdowns/DropdownSimple.vue";
import ConfirmModalComponent from "@/components/shared/modals/ConfirmModal.vue";
import { SaleReportFormat } from "@/application/enums/app/erp/sales/SaleReportFormat";
import { filters } from "@/plugins/filters";
import { Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import NewPayment from "@/views/app/erp/contacts/customers/forms/NewPayment.vue";
import B2bService from "@/services/api/app/b2b/B2bService";
import { SaleDto } from "@/application/dtos/app/erp/v3/sales/SaleDto";
import { CfdiStatus } from "@/application/enums/app/erp/sales/CfdiStatus";
import { PaymentForm } from "@/application/enums/app/erp/contacts/customers/PaymentForm";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    DropdownSimple,
    NewPayment
  }
})
export default class B2bSaleDetails extends BaseComponent {
  @Prop({ default: "" }) workspaceId!: string;
  @Prop({ default: "" }) taxId!: string;
  @Prop({ default: "" }) folio!: string;
  $refs!: {
    errorModal: ErrorModalComponent;
    successModalSent: SuccessModalComponent;
    dropdownOptions: DropdownSimple;
    confirmSend: ConfirmModalComponent;
  };
  private sale: SaleDto | null = null;
  mounted() {
    this.reload();
  }
  reload() {
    this.sale = null;
    this.loading = true;
    B2bService.billing
      .getInvoice(this.workspaceId, this.taxId, this.folio)
      .then(response => {
        this.sale = response;
      })
      .catch(error => {
        // this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  download() {
    if (!this.sale) {
      this.$refs.errorModal.show("Venta inválida");
      return;
    }
    const format = this.getDefaultFormat(this.sale);
    this.downloadFormat(SaleReportFormat.Cfdi);
    this.downloadFormat(SaleReportFormat.Xml);
  }
  getFormatName(format: SaleReportFormat) {
    if (format === SaleReportFormat.Quote) {
      return "Cotización ";
    } else if (format === SaleReportFormat.Cfdi) {
      let prefix = "";
      if (this.sale?.cfdi?.status === CfdiStatus.Issued) {
        prefix = "CFDi ";
      }
      if (this.sale?.type === "FR") {
        return prefix + "Venta de Crédito ";
      }
      return prefix + "Venta de Contado ";
    } else if (format === SaleReportFormat.SummaryByProduct) {
      return "Resumen por Artículo ";
    } else if (format === SaleReportFormat.SummaryByCategory) {
      return "Resumen por Clasificación ";
    } else if (format === SaleReportFormat.Recibo) {
      return "Recibo ";
    } else if (format === SaleReportFormat.Xml) {
      return "XML ";
    }
    return "";
  }
  downloadFormat(format: SaleReportFormat) {
    B2bService.billing
      .downloadInvoice(this.workspaceId, this.taxId, this.folio, format)
      .then((response: any) => {
        const contentType: string = response.headers["content-type"];
        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute(
          "download",
          this.getFormatName(format) + this.sale?.type + "-" + this.sale?.folio
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      });
  }
  getDefaultFormat(sale: SaleDto): SaleReportFormat {
    if (sale.type === "PR") {
      return SaleReportFormat.Quote;
    } else {
      if (sale.cfdi) {
        if (sale.cfdi.status === CfdiStatus.Issued) {
          return SaleReportFormat.Xml;
        } else {
          return SaleReportFormat.Cfdi;
        }
      }
    }
    return SaleReportFormat.Quote;
  }
  send() {
    this.$refs.dropdownOptions.close();
    this.$refs.confirmSend.show(
      `¿Enviar ${this.saleName}?`,
      "Enviar",
      "Cancelar",
      "Se enviarán los detalles de la venta, en el formato correspondiente. Escribe los correos separados por coma:"
    );
  }
  yesSend(input: string) {
    if (!this.sale) {
      this.$refs.errorModal.show("Venta inválida");
      return;
    }
    const type = this.sale.type;
    const folio = this.sale.folio;
    const emails = input.split(",");
    // const email = prompt("Correo electrónico", store.state.account.user?.email);
    if (!emails || emails.length === 0) {
      return;
    }
    ErpService.sales.sales
      .send({
        sale: {
          type,
          folio
        },
        format: this.getDefaultFormat(this.sale),
        emails
      })
      .then(() => {
        this.$refs.successModalSent.show("Correo enviado");
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      });
  }
  paymentFormDescription(paymentForm: PaymentForm) {
    switch (paymentForm) {
      case PaymentForm.Cash:
        return "Efectivo";
      case PaymentForm.Check:
        return "Cheque";
      case PaymentForm.Transfer:
        return "Transferencia";
      case PaymentForm.DebitCard:
        return "Tarjeta de Débito";
      case PaymentForm.CreditCard:
        return "Tarjeta de Crédito";
      case PaymentForm.Condonation:
        return "???";
      case PaymentForm.Compensation:
        return "???";
      case PaymentForm.Novation:
        return "???";
      case PaymentForm.Confusion:
        return "???";
      case PaymentForm.DebtRemittance:
        return "???";
      case PaymentForm.PrescriptionOrExpiration:
        return "???";
      case PaymentForm.ToTheSatisfactionOfTheCreditor:
        return "???";
      case PaymentForm.ServicesCard:
        return "???";
      case PaymentForm.ApplicationOfAdvances:
        return "???";
      case PaymentForm.PaymentIntermediary:
        return "???";
      case PaymentForm.ElectronicPurse:
        return "???";
      case PaymentForm.ElectronicMoney:
        return "???";
      case PaymentForm.Voucher:
        return "???";
      case PaymentForm.DationToPayment:
        return "???";
      case PaymentForm.SurrogacyPayment:
        return "???";
      case PaymentForm.PaymentIntermediary:
        return "???";
      case PaymentForm.ToDefine:
        return "???";
      default:
        return "???";
    }
  }
  get isQuote() {
    return this.sale?.type === "PR";
  }
  get isDue() {
    return this.sale && this.sale.due > 0;
  }
  get saleName() {
    if (this.sale) {
      if (this.sale.type === "PR") {
        return `Pedido ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "FO") {
        return `Venta de Contado ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "FR") {
        return `Venta de Crédito ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "FE") {
        return `Venta de Exportación ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "X1") {
        return `Intereses ${this.sale.type}-${this.sale.folio}`;
      } else if (this.sale.type === "X2") {
        return `Abono ${this.sale.type}-${this.sale.folio}`;
      } else {
        return `${this.sale.type}-${this.sale.folio}`;
      }
      return this.sale?.type + "-" + this.folio;
    }
    return this.taxId + "-" + this.folio;
  }
  get hasCfdi() {
    return this.sale && this.sale.cfdi?.type;
  }
}
