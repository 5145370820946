
















































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import LoadingButton from "../../components/shared/buttons/LoadingButton.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import axios from "axios";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import Warning from "@/components/shared/banners/Warning.vue";
import { UserLoginRequest } from "../../application/contracts/master/users/UserLoginRequest";
import { UserLoginType } from "../../application/enums/master/users/UserLoginType";
import services from "@/services";

@Component({
  components: {
    ErrorModal,
    MarketingLogo,
    LoadingButton,
    Warning
  },
  directives: {
    GoogleSignInButton
  }
})
export default class LoginComponent extends BaseComponent {
  $refs!: {
    errorModal: ErrorModal;
    loadingButton: LoadingButton;
  };
  public googleClientId: any = "";
  public user = {} as UserLoginRequest;
  created() {
    this.googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID_OAUTH2 ?? "";
  }
  OnGoogleAuthSuccess(idToken) {
    axios
      .get("https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" + idToken)
      .then((response: any) => {
        this.user.email = response.data.email;
        this.login(UserLoginType.GMAIL);
      });
  }
  OnGoogleAuthFail(error) {
    console.error("Error: " + error);
  }
  login(type: UserLoginType = UserLoginType.PASSWORD) {
    this.$refs.loadingButton.start();
    this.loading = true;
    this.user.loginType = type;
    services.authentication
      .login(this.user)
      .then(response => {
        // ignore
      })
      .catch(error => {
        this.$refs.loadingButton.stop();
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  get isDarkMode() {
    return this.$store.state.theme.marketingTheme === "theme-bg-dark";
  }
}
