import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { ApiService, ApiServiceType } from "../../../ApiService";

export class TenantSessionService {
  api!: ApiService;
  constructor() {
    this.api = new ApiService("B2b/TenantSession", ApiServiceType.TenantDatabase);
  }
  getCurrent(): Promise<TenantDto> {
    return this.api.get(`GetCurrent`);
  }
}
