import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import { i18n } from "@/plugins/vue-i18n";

export const appSettingsRoutes = {
  name: "settings",
  path: "ajustes",
  alias: "settings",
  component: require("@/views/app/settings/Index.vue").default,
  redirect: "ajustes/perfil",
  children: [
    {
      name: "settings.profile",
      path: "perfil",
      alias: "profile",
      component: require("@/views/app/settings/Profile.vue").default,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: "app.workspaces",
      path: "sucursales",
      alias: "workspaces",
      component: require("@/views/app/workspaces/WorkspacesList.vue").default,
      meta: {
        requiresAuth: true,
        title: i18n.t("models.workspace.plural").toString(),
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
      },
      children: [
        {
          name: "app.workspaces.new",
          alias: "new",
          path: "nueva",
          component: require("@/views/app/workspaces/WorkspacesForm.vue").default,
          meta: {
            roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
          },
        },
        {
          name: "app.workspaces.edit",
          alias: "edit/:id",
          path: "editar/:id",
          component: require("@/views/app/workspaces/WorkspacesForm.vue").default,
          meta: {
            roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
          },
        },
      ],
    },
    {
      name: "settings.tenant",
      path: "organizacion",
      alias: "organization",
      component: require("@/views/app/settings/tenant/TenantCurrent.vue").default,
      meta: {
        title: i18n.t("settings.tenant.title"),
      },
      redirect: "organization/general",
      children: [
        {
          path: "general",
          name: "settings.tenant.general",
          component: require("@/components/app/settings/tenant/TenantSettings.vue").default,
          meta: {
            title: i18n.t("settings.tenant.general"),
            roles: [TenantUserRole.OWNER],
          },
        },
        {
          name: "settings.tenant.subscription",
          path: "subscripcion",
          alias: "subscription",
          component: require("@/components/app/settings/tenant/subscription/TenantSubscription.vue").default,
          meta: {
            title: i18n.t("settings.tenant.subscription.title"),
            roles: [TenantUserRole.OWNER],
          },
        },
        {
          name: "settings.tenant.members",
          path: "miembros",
          alias: "members",
          component: require("@/components/app/settings/tenant/TenantMembers.vue").default,
          meta: {
            title: i18n.t("settings.tenant.members.title"),
            roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
          },
          children: [
            {
              name: "settings.tenant.members.new",
              path: "nuevo",
              alias: "new",
              component: require("@/components/app/settings/members/MembersForm.vue").default,
              meta: {
                roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
              },
            },
            {
              name: "settings.tenant.members.edit",
              path: "editar/:id",
              alias: "edit/:id",
              component: require("@/components/app/settings/members/MembersForm.vue").default,
              meta: {
                roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
              },
            },
          ],
        },
      ],
    },
  ],
};
