












import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Banner from "@/views/marketing/Banner.vue";
import B2bService from "./services/api/app/b2b/B2bService";
import { TenantDto } from "./application/dtos/master/tenants/TenantDto";
// import { TenantSessionService } from "./services/api/app/b2b/tenant/TenantSessionService";

@Component({
  components: {
    Banner
  },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor"
    })
  }
})
export default class App extends Vue {
  marketingTheme!: string;
  marketingColor!: string;
  closed = false;
  tenant: TenantDto | undefined = undefined;
  // created() {
  //   B2bService.tenantSession
  //     .getCurrent()
  //     .then((tenant: TenantDto) => {
  //       this.tenant = tenant;
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // }
  get isDarkMode() {
    return this.$store.state.theme.marketingTheme === "theme-bg-dark";
  }
}
