












































































import Vue from "vue";
import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import { Prop } from "vue-property-decorator";
import * as ProjectHelpers from "@/application/modules/projects/ProjectHelpers";
import {Colors} from "@/application/enums/app/common/Colors";

@Component({
  components: {},
})
export default class ColorSelect extends Vue {
  @Prop({ default: Colors.UNDEFINED }) initial!: Colors;
  @Prop({ default: false }) disabled!: boolean;
  private arr = ProjectHelpers.colors;
  private show: boolean = false;
  private selected: Colors = Colors.UNDEFINED;
  mounted() {
    this.selected = this.initial;
  }
  close() {
    this.show = false;
  }
  select(value) {
    this.close();
    this.selected = value;
    this.$emit("selected", value);
  }
  projectColor(item) {
    return ProjectHelpers.getProjectColor(item);
  }
}
