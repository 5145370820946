






























































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
import ErpService from "@/services/api/app/erp/ErpService";
import { Prop } from "vue-property-decorator";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import { ProductBarcodeReadScanRequest } from "@/application/contracts/app/erp/inventory/productBarcodeReads/ProductBarcodeReadScanRequest";
// @ts-ignore
import beep from "@/assets/sounds/pos-beep.mp3";
// @ts-ignore
import beepError from "@/assets/sounds/pos-error.mp3";
// @ts-ignore
import beepScream from "@/assets/sounds/pos-scream.mp3";
import { Howl, Howler } from "howler";
import store from "@/store";
import { BarcodeReadLineDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadLineDto";

@Component({
  components: {
    StreamBarcodeReader,
    ErrorModalComponent
  }
})
export default class BarcodeScanner extends BaseComponent {
  $refs!: {
    sku: HTMLInputElement;
    // errorModal: ErrorModalComponent;
  };
  @Prop({}) type!: string;
  @Prop({}) folio!: number;
  @Prop({ default: false }) showErrorBanner!: boolean;
  error: string = "";
  item: BarcodeReadLineDto | null = null;
  lastScanned: string = "";
  code: string = "";
  status: number = 0; // 0: Pending, 1: Loading, 2: Success, 3: Error
  beepSound: any;
  errorSound: any;
  screamSound: any;
  mounted() {
    // this.focusSku();
    this.beepSound = new Howl({
      src: beep,
      volume: 0.5
    });
    this.errorSound = new Howl({
      src: beepError,
      volume: 1
    });
    this.screamSound = new Howl({
      src: beepScream,
      volume: 0.5
    });
  }
  onDecode(result: string) {
    this.code = result;
    this.loadCode();
  }
  focusSku() {
    this.$nextTick(() => {
      this.$refs.sku.focus();
      this.$refs.sku.select();
    });
  }
  enter() {
    // this.focusSku();
    this.loadCode();
  }
  loadCode() {
    if (this.lastScanned === this.code) {
      // this.focusSku();
      return;
    }
    this.loading = true;
    this.error = "";
    this.lastScanned = this.code;
    this.status = 1;
    this.code = "";
    ErpService.inventory.productBarcodeReads
      .scan(
        new ProductBarcodeReadScanRequest(
          this.type,
          this.folio,
          this.lastScanned
        )
      )
      .then((response: BarcodeReadLineDto) => {
        // this.code = "";
        // this.lecturas.push(response);
        // this.load(this.header);
        this.$emit("scanned", response);
        this.item = response;
        this.status = 2;
        this.beepSound.play();
      })
      .catch(error => {
        if (store.state.account.user?.email.includes("alex.martinez@absys.com.mx")) {
          this.screamSound.play();
        } else {
          this.errorSound.play();
        }
        this.error = this.$t(error).toString();
        this.status = 3;
        if (this.showErrorBanner) {
          setTimeout(() => {
            this.error = "";
            if (this.status === 3) {
              this.status = 0;
            }
          }, 3000);
        } else {
          this.$emit("error", error);
          // this.$refs.errorModal.show(this.$t(error));
        }
        // reject(false);
      })
      .finally(() => {
        // this.beepSound.play();
        this.loading = false;
      });
  }
  edit(item: BarcodeReadLineDto | null) {
    if (item) {
      this.$emit("edit", item);
    }
  }
}
