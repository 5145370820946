































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class BadgeTicket extends Vue {
  @Prop({ default: "md" }) size!: string;
  @Prop({ default: 0 }) status!: boolean;
  @Prop({ default: "" }) text!: string;
  @Prop({ default: "" }) height!: string;
  @Prop({ default: "" }) width!: string;
}
