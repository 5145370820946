

































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import BaseComponent from "../../shared/BaseComponent.vue";
import ObjectList from "../../shared/forms/ObjectList.vue";
import TableRowSkeleton from "../../shared/skeletons/TableRowSkeleton.vue";
import SvgResultsEmpty from "../../../assets/img/ResultsEmpty.vue";
import SideModal from "../../shared/modals/SideModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import PageTitle from "../../shared/navigation/PageTitle.vue";
import Modal from "../../shared/modals/Modal.vue";
import NewModal from "../../shared/modals/NewModal.vue";
import UploadTicketsDragAndDrop from "../../../views/app/tickets/UploadTicketsDragAndDrop.vue";
import TicketItem from "../../../views/app/tickets/TicketItem.vue";
import TicketTableItem from "../../../views/app/tickets/TicketTableItem.vue";
import TransactionItem from "@/views/app/tickets/TransactionItem.vue";
import TransactionTableItem from "@/views/app/tickets/TransactionTableItem.vue";
import TicketItemDetails from "@/views/app/tickets/TicketItemDetails.vue";
import TransactionItemDetails from "@/views/app/tickets/TransactionItemDetails.vue";
import UserSelect from "@/components/app/selectors/UserSelect.vue";
import WorkspaceSelect from "@/components/app/selectors/WorkspaceSelect.vue";
import CategorySelect from "@/components/app/selectors/CategorySelect.vue";
import TransactionTypeSelect from "@/components/app/selectors/TransactionTypeSelect.vue";
import ButtonSort from "@/components/shared/buttons/ButtonSort.vue";
import DropdownSimpleFullScreen from "@/components/shared/dropdowns/DropdownSimpleFullScreen.vue";
import QuicklinksTransactionsSummary from "@/components/app/widgets/QuicklinksTransactionsSummary.vue";
import { Prop } from "vue-property-decorator";
import ProjectSelect from "../selectors/ProjectSelect.vue";
import { mapGetters } from "vuex";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import Warning from "@/components/shared/banners/Warning.vue";
import { TransactionDto } from "@/application/dtos/app/transactions/TransactionDto";
import { TicketDto } from "@/application/dtos/app/transactions/TicketDto";
import * as projectHelpers from "@/application/modules/projects/ProjectHelpers";
import { GetTransactionOrTicketFilter } from "@/application/enums/app/transactions/GetTransactionOrTicketFilter";
import { TransactionOrTicketDto } from "@/application/dtos/app/transactions/TransactionOrTicketDto";
// tslint:disable-next-line: max-line-length
import { TransactionOrTicketQueryResult } from "@/application/contracts/app/transactions/TransactionOrTicketQueryResult";
import { TicketStatus } from "@/application/enums/app/transactions/TicketStatus";
import { WhoInvoices } from "@/application/enums/app/cfdis/WhoInvoices";
import { GetTransactionsOrTicketsRequest } from "@/application/contracts/app/tickets/GetTransactionsOrTicketsRequest";
import { TicketGeneralStatus } from "@/application/enums/app/transactions/TicketGeneralStatus";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import store from "@/store";
import services from "@/services";

@Component({
  components: {
    QuicklinksTransactionsSummary,
    TransactionItem,
    TransactionTableItem,
    TicketItem,
    TicketTableItem,
    UploadTicketsDragAndDrop,
    Modal,
    SideModal,
    ObjectList,
    TableRowSkeleton,
    SvgResultsEmpty,
    ErrorModal,
    Warning,
    PageTitle,
    TicketItemDetails,
    TransactionItemDetails,
    NewModal,
    UserSelect,
    WorkspaceSelect,
    CategorySelect,
    ProjectSelect,
    TransactionTypeSelect,
    ButtonSort,
    DropdownSimpleFullScreen
  },
  computed: {
    ...mapGetters("account", {
      isAdmin: "isAdmin"
    })
  }
})
export default class TransactionsList extends BaseComponent {
  @Prop({ default: false }) admin!: boolean;
  @Prop({ default: false }) onlyTickets!: boolean;
  @Prop({ default: false }) onlyTransactions!: boolean;
  $refs!: {
    uploadTicketsModal: Modal;
    itemDetails: Modal;
    newOptionsModal: Modal;
    buttonSort: ButtonSort;
    userSelect: UserSelect;
    workspaceSelect: WorkspaceSelect;
    categorySelect: CategorySelect;
    projectSelect: ProjectSelect;
    transactionTypeSelect: TransactionTypeSelect;
    filtersDropdown: DropdownSimpleFullScreen;
    errorModal: ErrorModalComponent;
  };
  public searchText: string = "";
  private isAdmin!: boolean;

  private showSubirTickets: boolean = false;
  private dropDownNew: boolean = false;

  private queryResult: TransactionOrTicketQueryResult = {
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    pageSize: 0,
    from: 0,
    to: 0,
    items: []
  };
  private selected = {} as TransactionOrTicketDto;
  private filterWorkspaces: string = "";
  private filterUserEmail: string = "";
  private filterTenantId?: string = undefined;
  private filterStatus: TicketStatus[] = [];
  private filterTransactionsOrTickets: GetTransactionOrTicketFilter =
    GetTransactionOrTicketFilter.ALL_TRANSACTIONS;
  private filterCustomerTaxId: string = "";
  private filterSupplierTaxId: string = "";
  private filterCategory: string = "";
  private filterProjectId: string = "";
  private request = {} as GetTransactionsOrTicketsRequest;
  private itemStyle: number = 1;
  private selectedStatus: TicketGeneralStatus = TicketGeneralStatus.PENDING;
  private arrTicketGeneralStatus: any[] = [
    { value: 0, name: this.$t("app.tickets.statusPlural.PENDING") },
    { value: 1, name: this.$t("app.tickets.statusPlural.INVALID") },
    { value: 2, name: this.$t("app.tickets.statusPlural.INVOICED") }
  ];
  created() {
    this.resetQueryResults();

    if (this.$route.query.tipo) {
      if (this.$route.query.tipo.includes("todo")) {
        this.filterTransactionsOrTickets =
          GetTransactionOrTicketFilter.TICKETS_AND_TRANSACTIONS;
      } else if (this.$route.query.tipo.includes("ticket")) {
        this.filterTransactionsOrTickets = GetTransactionOrTicketFilter.TICKETS;
      } else if (this.$route.query.tipo.includes("ingreso")) {
        this.filterTransactionsOrTickets = GetTransactionOrTicketFilter.INCOME;
      } else if (this.$route.query.tipo.includes("egreso")) {
        this.filterTransactionsOrTickets = GetTransactionOrTicketFilter.EXPENSE;
      }
      // this.$refs.transactionTypeSelect.selected = this.filterTransactionsOrTickets;
    }

    if (this.$route.query.cliente) {
      this.filterCustomerTaxId = this.$route.query.cliente.toString();
    }

    if (this.$route.query.proveedor) {
      this.filterSupplierTaxId = this.$route.query.proveedor.toString();
    }

    if (this.$route.query.categoria) {
      this.filterCategory = this.$route.query.categoria.toString();
    }

    if (this.$route.query.proyecto) {
      this.filterProjectId = this.$route.query.proyecto.toString();
    }

    if (this.$route.query.estatus) {
      if (this.$route.query.estatus.includes("pendiente")) {
        this.filterStatus = [TicketStatus.PENDING, TicketStatus.REQUESTED];
        this.selectedStatus = TicketGeneralStatus.PENDING;
      } else if (
        this.$route.query.estatus.includes("invalido") ||
        this.$route.query.estatus.includes("inválido") ||
        this.$route.query.estatus.includes("error")
      ) {
        this.filterStatus = [
          TicketStatus.DUPLICATED,
          TicketStatus.UNREADABLE,
          TicketStatus.EXPIRED,
          TicketStatus.INVALID
        ];
        this.selectedStatus = TicketGeneralStatus.INVALID;
      } else if (
        this.$route.query.estatus.includes("completado") ||
        this.$route.query.estatus.includes("facturado")
      ) {
        this.filterStatus = [TicketStatus.INVOICED];
        this.selectedStatus = TicketGeneralStatus.INVOICED;
      }
    }

    if (this.$route.query.usuario) {
      this.filterUserEmail = this.$route.query.usuario.toString();
    }

    if (this.$route.query.empresa) {
      this.filterWorkspaces = this.$route.query.empresa.toString();
    }

    this.queryResult.currentPage = 1;
    this.queryResult.pageSize = 40;
  }
  mounted() {
    window.scrollTo(0, 0);
    this.reload();
  }
  resetPageAndReload() {
    this.queryResult.currentPage = 1;
    this.reload();
  }
  clearSearch() {
    this.request.search = "";
    this.reload();
  }
  clearFilters() {
    this.$refs.transactionTypeSelect.selected = 0;
    // this.$refs.workspaceSelect.selected = 0;
    this.$refs.userSelect.selected = 0;
    this.$refs.categorySelect.selected = 0;
    this.$refs.projectSelect.selected = 0;

    this.filterWorkspaces = "";
    this.filterUserEmail = "";
    this.filterTenantId = undefined;
    this.filterStatus = [];
    this.filterCustomerTaxId = "";
    this.filterSupplierTaxId = "";
    this.filterCategory = "";
    this.filterProjectId = "";
    console.log("clear filters");

    this.filterTransactionsOrTickets =
      GetTransactionOrTicketFilter.ALL_TRANSACTIONS;
    this.resetPageAndReload();
    this.reload();
  }
  updateRequestValues() {
    this.request.tenantId = this.filterTenantId;
    if (!this.admin && this.currentWorkspace) {
      this.request.workspaceId = this.currentWorkspace.id;
    }
    this.request.filter = this.filterTransactionsOrTickets;
    this.request.ticketStatus = this.selectedStatus;
    this.request.createdByUserId = this.filterUserEmail;
    this.request.customerId = this.filterCustomerTaxId;
    this.request.supplierId = this.filterSupplierTaxId;
    this.request.category = this.filterCategory;
    this.request.projectId = this.filterProjectId;
    this.request.whoInvoices = undefined;

    if (this.itemStyle === 0) {
      this.request.withImage = this.itemStyle === 0 && this.onlyTickets;
    } else {
      this.request.ticketStatus = undefined;
    }

    this.request.onlyPendingTickets = false;
    if (
      this.filterTransactionsOrTickets === GetTransactionOrTicketFilter.TICKETS
    ) {
      this.request.onlyPendingTickets = false;
    }

    this.request.sortBy = this.sortBy;
    this.request.isSortAscending = this.sortDirection === 1;

    this.request.pageSize = this.queryResult.pageSize;
    this.request.page = this.queryResult.currentPage;

    this.queryResult.totalItems = 0;
    this.queryResult.totalPages = 0;
    this.queryResult.from = 0;
    this.queryResult.to = 0;
    let tipo = "todos";
    if (this.request.filter === GetTransactionOrTicketFilter.TICKETS) {
      tipo = "tickets";
    } else if (this.request.filter === GetTransactionOrTicketFilter.INCOME) {
      tipo = "ingresos";
    } else if (this.request.filter === GetTransactionOrTicketFilter.EXPENSE) {
      tipo = "egresos";
    }

    if (this.onlyTickets) {
      this.request.filter = GetTransactionOrTicketFilter.TICKETS;
    }
    if (this.onlyTransactions) {
      this.request.filter = GetTransactionOrTicketFilter.ALL_TRANSACTIONS;
    }
  }
  selectStatus(status) {
    this.selectedStatus = Number(status);
    if (this.request.ticketStatus) {
      this.reload();
    }
  }
  countWithStatus(status: number) {
    const arrStatus = this.ticketStatusFromGeneralStatus(status);
    return (
      this.queryResult.items.filter(f => arrStatus.includes(f.ticket?.status))
        ?.length ?? 0
    );
  }
  ticketStatusFromGeneralStatus(status: number) {
    switch (status) {
      case 0:
        return [0, 1];
      case 1:
        return [3, 4, 5, 6];
      case 2:
        return [2];
    }
    return [];
  }
  resetQueryResults() {
    this.queryResult = {
      pageSize: 10,
      currentPage: 1,
      totalItems: 0,
      totalPages: 0,
      from: 0,
      to: 0,
      items: []
    };
  }
  async download() {
    this.updateRequestValues();

    this.$refs.filtersDropdown.close();
    this.selected = {} as TransactionOrTicketDto;
    this.showSubirTickets = false;
    this.loading = true;
    services.tickets
      .downloadAll()
      .then((response: any) => {
        const disposition = response.headers["content-disposition"];
        let fileName = "";

        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
        }
        const type: string = "application/zip";
        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  async reload() {
    this.updateRequestValues();

    this.$refs.filtersDropdown.close();
    this.selected = {} as TransactionOrTicketDto;
    this.showSubirTickets = false;
    // this.resetQueryResults();

    this.loading = true;

    // this.request = {
    //   tenantId: this.filterTenantId,
    //   filtro: this.filterTransactionsOrTickets,
    //   estatusDeTicket: this.filterStatus,
    //   createdByUserEmail: this.filterUserEmail,
    //   empresaRfc: this.filterEmpresa,
    //   search: this.search,
    //   sortBy: this.sortBy,
    //   isSortAscending: this.sortDirection === 1,
    //   page: this.page,
    //   pageSize: this.pageSize
    // };

    services.transactionsOrTickets
      .getAllTransactionsOrTickets(this.request, this.admin)
      .then((response: TransactionOrTicketQueryResult) => {
        this.queryResult = response;
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  showNewOptionsModal() {
    this.$refs.newOptionsModal.show();
  }
  showUploadsTickets() {
    this.$refs.uploadTicketsModal.show();
  }
  // deleted(data: TicketDto) {
  //   this.items = this.items.filter((f) => f.id !== data.id);
  // }
  // canceled() {
  //   // console.log("canceled");
  // }
  // added(data: TicketDto) {
  //   // console.log("added data:" + JSON.stringify(data));
  //   this.items.push(data);
  // }
  // saved(data: TicketDto) {
  //   const idx = this.items.findIndex((f) => f.id === data.id);
  //   this.items[idx] = data;
  // }
  closeDropDownNew() {
    this.dropDownNew = false;
  }
  sortByColumn(column: string) {
    this.$refs.buttonSort.sortBy = column;
  }
  sortDirectionToggle() {
    // this.$refs.buttonSort.sortDirection = column;
    if (this.$refs.buttonSort.sortDirection === 1) {
      this.$refs.buttonSort.sortDirection = -1;
    } else {
      this.$refs.buttonSort.sortDirection = 1;
    }
    // console.log("[toggle] sortDirection: " + this.sortDirection);
  }
  uploadedTickets() {
    this.showSubirTickets = false;
    this.reload();
  }
  itemUrl(item: TransactionOrTicketDto) {
    if (this.onlyTickets) {
      return "tickets/" + item.ticket.id;
    } else {
      if (item.transaction.ticketId) {
        return "facturas/" + item.transaction.ticketId + "/ticket";
      }
      return "facturas/" + item.transaction.id + "/editar";
    }
  }
  select(item: TransactionOrTicketDto) {
    this.selected = {} as TransactionOrTicketDto;
    this.selected = item;
  }
  closedDetails(type: number, id: string, deleted: boolean = false) {
    if (deleted) {
      this.reload();
      return;
    }
    if (type === 0) {
      const idx = this.queryResult.items.findIndex(f => f.ticket?.id === id);
      services.tickets
        .getTicket(id, this.isAdmin)
        .then((response: TicketDto) => {
          this.queryResult.items[idx].ticket = response;
        });
    } else if (type === 1) {
      const idx = this.queryResult.items.findIndex(
        f => f.transaction?.id === id
      );
      services.transactions.get(id).then((response: TransactionDto) => {
        this.queryResult.items[idx].transaction = response;
      });
    }
  }
  deletedItem() {
    this.reload();
  }
  subirTickets() {
    this.dropDownNew = false;
    this.showSubirTickets = true;
  }
  selectUser(userId: string) {
    this.filterUserEmail = userId;
  }
  selectAllUsers() {
    this.filterUserEmail = "";
  }
  selectWorkspace(id: string) {
    this.filterWorkspaces = id;
  }
  selectAllWorkspaces() {
    this.filterWorkspaces = "";
  }
  selectCategoria(value: string) {
    this.filterCategory = value;
  }
  selectAllCategories() {
    this.filterCategory = "";
  }
  selectProjectId(value: string) {
    this.filterProjectId = value;
  }
  selectAllProjects() {
    this.filterProjectId = "";
  }
  selectType(type: GetTransactionOrTicketFilter) {
    this.filterTransactionsOrTickets = type;
  }
  addPage(add) {
    this.queryResult.currentPage += add;
    this.reload();
  }
  goToPage(page) {
    this.queryResult.currentPage = page;
    this.reload();
  }
  changedPage(page) {
    this.queryResult.currentPage = page;
    this.reload();
  }
  toggleItemStyle() {
    if (this.itemStyle === 0) {
      this.itemStyle = 1;
      this.queryResult.pageSize = 40;
    } else {
      this.itemStyle = 0;
      this.queryResult.pageSize = 20;
    }
    this.reload();
  }
  selectGroupByValue(value) {
    //
  }
  projectColor(color) {
    return projectHelpers.getProjectColor(color);
  }
  get workspaces() {
    return store.state.tenant.workspaces;
  }
  get anyWorkspaces() {
    return this.workspaces && this.workspaces.length > 0;
  }
  get currentWorkspace(): WorkspaceDto | null {
    return store.state.tenant.currentWorkspace;
  }
  get countWithStatusSeleccionado() {
    return (
      this.queryResult.items.filter(f =>
        this.selectedStatuss.includes(f.ticket?.status)
      )?.length ?? 0
    );
  }
  get selectedStatusName() {
    return this.$t(
      "app.tickets.statusPlural." + TicketGeneralStatus[this.selectedStatus]
    )
      .toString()
      .toLowerCase();
  }
  get totalItems() {
    let total = 0;
    if (this.onlyTickets) {
      this.items.forEach(item => {
        if (item.ticket?.transactions && item.ticket?.transactions.length > 0) {
          total += item.ticket.transactions[0].total;
        } else if (item.ticket.possibleTotal) {
          total += item.ticket.possibleTotal;
        }
      });
    } else {
      this.items.forEach(item => {
        total += item.transaction.total;
      });
    }
    return total;
  }
  get items() {
    if (this.onlyTickets) {
      return this.queryResult.items
        .filter(
          f =>
            (this.selectedStatuss.length === 0 ||
              this.selectedStatuss.includes(f.ticket?.status)) &&
            ((f.companyTaxId &&
              f.companyTaxId
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
              (f.companyName &&
                f.companyName
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase())) ||
              (f.companyLegalName &&
                f.companyLegalName
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase())) ||
              (f.ticket.createdByUser &&
                f.ticket.createdByUser?.firstName &&
                f.ticket.createdByUser?.firstName
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase())) ||
              (f.ticket.createdByUser &&
                f.ticket.createdByUser?.lastName &&
                f.ticket.createdByUser?.lastName
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase())) ||
              (f.ticket.createdByUser &&
                f.ticket.createdByUser?.email &&
                f.ticket.createdByUser?.email
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase())) ||
              (f.ticket.possibleWebsite &&
                f.ticket.possibleWebsite
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase())) ||
              (f.ticket.legalName &&
                f.ticket.legalName
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase())) ||
              (f.ticket.tenant &&
                f.ticket.tenant.name &&
                f.ticket.tenant.name
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase())) ||
              (f.ticket.project &&
                f.ticket.project.name &&
                f.ticket.project.name
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase())))
        )
        .sort((x, y) => {
          return x.createdAt > y.createdAt ? -1 : 1;
        });
    } else {
      return this.queryResult.items
        .filter(
          f =>
            (f.companyTaxId &&
              f.companyTaxId
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.companyName &&
              f.companyName
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.companyLegalName &&
              f.companyLegalName
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.issuerLegalName &&
              f.issuerLegalName
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.issuerTaxId &&
              f.issuerTaxId
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.receiverLegalName &&
              f.receiverLegalName
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.receiverTaxId &&
              f.receiverTaxId
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.transaction.createdByUser &&
              f.transaction.createdByUser?.firstName &&
              f.transaction.createdByUser?.firstName
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.transaction.createdByUser &&
              f.transaction.createdByUser?.lastName &&
              f.transaction.createdByUser?.lastName
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.transaction.createdByUser &&
              f.transaction.createdByUser?.email &&
              f.transaction.createdByUser?.email
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.transaction.tenant &&
              f.transaction.tenant.name &&
              f.transaction.tenant.name
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.transaction.project &&
              f.transaction.project.name &&
              f.transaction.project.name
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            (f.transaction.category &&
              f.transaction.category
                .toLowerCase()
                .includes(this.searchText.toLowerCase()))
        )
        .sort((x, y) => {
          return x.createdAt > y.createdAt ? -1 : 1;
        });
    }
  }
  get selectedStatuss(): number[] {
    switch (this.selectedStatus) {
      case 0:
        return [0, 1];
      case 1:
        return [3, 4, 5, 6];
      case 2:
        return [2];
    }
    return [];
  }
  get previousPageEnabled() {
    return this.queryResult.currentPage > 1;
  }
  get nextPageEnabled() {
    return this.queryResult.currentPage < this.queryResult.totalPages;
  }
  get filtros(): string[] {
    const arr: string[] = [];
    if (this.filterWorkspaces) {
      arr.push("empresa " + this.filterWorkspaces);
    }
    if (this.filterUserEmail) {
      arr.push("usuario " + this.filterUserEmail);
    }
    if (this.filterCategory) {
      arr.push("categoría " + this.filterCategory);
    }
    // console.log("filtros: " + JSON.stringify(arr));
    return arr;
  }
  get selection(): boolean {
    // return false;
    if (this.selected.ticket) {
      return true;
    } else if (this.selected.transaction) {
      return true;
    } else {
      return false;
    }
  }
  get tickets(): TicketDto[] {
    return this.queryResult.items.filter(f => f.ticket).map(f => f.ticket);
  }
  get transactions(): TransactionDto[] {
    return this.queryResult.items
      .filter(f => f.transaction)
      .map(f => f.transaction);
  }
  // get orderedTransactions(): TransactionDto[] {
  //   if (!this.transactions) {
  //     return [];
  //   }
  //   // console.log("[get] sortDirection: " + this.sortDirection);
  //   // console.log("[get] sortBy: " + this.sortBy);
  //
  //   const direction1 = this.sortDirection;
  //   const direction2 = direction1 === 1 ? -1 : 1;
  //   return this.transactions.sort((x, y) => {
  //     if(!this.sortBy || this.sortBy === 'Fecha subido') {
  //       if (x.createdAt && y.createdAt) {
  //         return (x.createdAt > y.createdAt ? direction1 : direction2) ?? direction2;
  //       }
  //     } else if(this.sortBy === "Usuario"){
  //       if(x.createdByUser && y.createdByUser) {
  //         return (x.createdByUser.firstName > y.createdByUser.firstName ? direction1 : direction2) ?? direction2;
  //       }
  //     }
  //     return direction2;
  //   });
  // }
  get sortDirection() {
    return -1;
    // return this.$refs.buttonSort.sortDirection;
  }
  get sortBy() {
    return "Fecha subido";
    // return this.$refs.buttonSort.sortBy;
  }
  get orderedItems() {
    // return this.queryResult.items
    if (!this.queryResult.items) {
      return [];
    }
    // console.log("[get] sortDirection: " + this.sortDirection);
    // console.log("[get] sortBy: " + this.sortBy);

    const direction1 = this.sortDirection;
    const direction2 = direction1 === 1 ? -1 : 1;
    return this.queryResult.items.sort((x, y) => {
      if (!this.sortBy || this.sortBy === "Fecha modificado") {
        if (x.modifiedAt && y.modifiedAt) {
          return (
            (x.modifiedAt > y.modifiedAt ? direction1 : direction2) ??
            direction2
          );
        }
      } else if (!this.sortBy || this.sortBy === "Fecha subido") {
        if (x.createdAt && y.createdAt) {
          return (
            (x.createdAt > y.createdAt ? direction1 : direction2) ?? direction2
          );
        }
      } else if (this.sortBy === "Empresa") {
        return (
          (x.companyTaxId > y.companyTaxId ? direction1 : direction2) ??
          direction2
        );
      } else if (this.sortBy === "Usuario") {
        if (x.createdByUserName && y.createdByUserName) {
          return (
            (x.createdByUserName > y.createdByUserName
              ? direction1
              : direction2) ?? direction2
          );
        }
      }
      return direction2;
    });
  }
}
