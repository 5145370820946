








































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import EntityTableList from "@/components/shared/entities/EntityTableList.vue";
import EntityForm from "@/components/shared/entities/EntityForm.vue";
import { ColumnType } from "../../../application/dtos/ColumnType";
import {
  columns,
  SubscriptionPriceDto
} from "../../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import services from "@/services";

@Component({
  components: {
    EntityTableList,
    EntityForm
  }
})
export default class PricesComponent extends BaseComponent {
  $refs!: {
    errorModal: ErrorModalComponent;
  };
  modelName: string = "subscriptionPrice";
  columns: ColumnType[] = columns;
  selected = null as any | null;
  adding: boolean = false;
  closeForm(hasChanges = false) {
    this.selected = null;
    this.adding = false;
    // if (hasChanges) {
    //
    //   this.$parent.hasChanges = true;
    // }
  }
  edit(item: any) {
    this.selected = item;
  }

  onAdded(data: SubscriptionPriceDto) {
    data.subscriptionProductId = this.parentProduct.id;
    services.subscriptionProducts
      .createPrice(data)
      .then((response: SubscriptionPriceDto) => {
        this.parentProduct.prices.push(response);
        this.selected = null;
        this.closeForm(true);
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  onSaved(data: SubscriptionPriceDto) {
    services.subscriptionProducts
      .updatePrice(data.id, data)
      .then((response: SubscriptionPriceDto) => {
        const idx = this.items.findIndex(f => f.id === data.id);
        this.items[idx] = response;
        this.closeForm(true);
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  onDeleted(data: SubscriptionPriceDto) {
    services.subscriptionProducts
      .deletePrice(data.id)
      .then((response: SubscriptionPriceDto) => {
        this.parentProduct.prices = this.items.filter(f => f.id !== data.id);
        this.closeForm(true);
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  onCanceled() {
    console.log("canceled");
    this.closeForm();
  }
  get items(): SubscriptionPriceDto[] {
    return this.parentProduct.prices;
  }
  get parentProduct(): SubscriptionProductDto {
    // @ts-ignore
    return this.$parent.item as SubscriptionProductDto;
  }
}
