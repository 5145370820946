import { ProductBarcodeReadLoadDocumentRequest } from "@/application/contracts/app/erp/inventory/productBarcodeReads/ProductBarcodeReadLoadDocumentRequest";
import { ProductBarcodeReadScanRequest } from "@/application/contracts/app/erp/inventory/productBarcodeReads/ProductBarcodeReadScanRequest";
import { ProductBarcodeReadSearchRequest } from "@/application/contracts/app/erp/inventory/productBarcodeReads/ProductBarcodeReadSearchRequest";
import { UpdateProductBarcodeReadRequest } from "@/application/contracts/app/erp/inventory/productBarcodeReads/UpdateProductBarcodeReadRequest";
import { PagedModel } from "@/application/contracts/PagedModel";
import { InventoryHeader } from "@/application/dtos/app/erp/common/InventoryHeader";
import { BarcodeReadLineDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadLineDto";
import { BarcodeReadsSummaryDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadsSummaryDto";
import { ApiService, ApiServiceType } from "../../../ApiService";
import { ProductStockUnitDto } from "./ProductStockUnitDto";

export class ProductBarcodeReadService extends ApiService {
  constructor() {
    super("Inventory/ProductBarcodeRead", ApiServiceType.TenantDatabase);
  }
  getAllReads(type: string, folio: number): Promise<BarcodeReadsSummaryDto> {
    return super.getAll(`GetAll?type=${type}&folio=${folio}`);
  }
  search(request: ProductBarcodeReadSearchRequest): Promise<PagedModel<ProductStockUnitDto>> {
    return super.get(`Search?` + super.toQueryString(request));
  }
  scan(data: ProductBarcodeReadScanRequest): Promise<BarcodeReadLineDto> {
    return super.post(data, `Scan`);
  }
  generate(header: InventoryHeader): Promise<void> {
    return super.post(header, "Generate");
  }
  loadDocument(payload: ProductBarcodeReadLoadDocumentRequest): Promise<BarcodeReadsSummaryDto> {
    return super.post(payload, "LoadDocument");
  }
  applyDiscount(payload: UpdateProductBarcodeReadRequest): Promise<BarcodeReadsSummaryDto> {
    return super.post(payload, "ApplyDiscount");
  }
  updateQuantity(payload: UpdateProductBarcodeReadRequest): Promise<BarcodeReadsSummaryDto> {
    return super.post(payload, "UpdateQuantity");
  }
  updateRawQuantity(payload: UpdateProductBarcodeReadRequest): Promise<BarcodeReadsSummaryDto> {
    return super.post(payload, "UpdateRawQuantity");
  }
  updateUnits(payload: UpdateProductBarcodeReadRequest): Promise<BarcodeReadsSummaryDto> {
    return super.post(payload, "UpdateUnits");
  }
  deleteAll(type: string, folio: number): Promise<void> {
    return super.post(null, `DeleteAll/${type}/${folio}`);
  }
  delete(id: string): Promise<void> {
    return super.delete(id);
  }
}
