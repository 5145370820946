


































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import BillingPeriodToggle from "@/components/marketing/toggles/BillingPeriodToggle.vue";
import CurrencyToggle from "@/components/marketing/toggles/CurrencyToggle.vue";
import InvoiceToggle from "@/components/marketing/toggles/InvoiceToggle.vue";
import Products from "@/components/marketing/pricing/Products.vue";
import Warning from "@/components/shared/banners/Warning.vue";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "../../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { SubscriptionFeatureDto } from "../../../application/dtos/master/subscriptions/SubscriptionFeatureDto";
import { mapGetters } from "vuex";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import services from "@/services";

@Component({
  components: {
    BillingPeriodToggle,
    CurrencyToggle,
    InvoiceToggle,
    Products,
    Warning,
    ErrorModal
  },
  computed: {
    ...mapGetters("pricing", {
      selectedProductTitle: "selectedProductTitle",
      selectedPrice: "selectedPrice",
      selectedBillingPeriod: "selectedBillingPeriod"
    })
  }
})
export default class ProductsComponent extends BaseComponent {
  $refs!: {
    errorModal: ErrorModalComponent;
  };
  private items = [] as SubscriptionProductDto[];
  private fromServer: boolean = true;
  private selectedBillingPeriod!: string;
  mounted() {
    // this.$store.commit("pricing/currency", "mxn");
    this.reload(true);
  }
  async reload(fromServer: boolean) {
    const debug = process.env.NODE_ENV !== "production";

    this.fromServer = fromServer;
    this.items = [];
    this.loading = true;
    services.subscriptionProducts
      .getProducts(fromServer, true)
      .then((response: SubscriptionProductDto[]) => {
        this.items = [];
        response?.forEach(product => {
          this.items.push(product);
        });
        if (response.length === 0 && this.fromServer && debug) {
          this.reload(false);
        }
      })
      .catch(error => {
        if (this.fromServer && debug) {
          this.reload(false);
        } else {
          this.$refs.errorModal.show(error);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  classForProduct(index) {
    // If there are 3 products, the last one full width
    if (this.products.length === 3) {
      if (index === this.products.length - 1) {
        return "p-2 xl:w-1/3 md:w-1/1 w-full";
      }
    } else if (this.products.length === 4) {
      return "p-2 w-full md:w-1/2 xl:w-1/4";
    } else if (this.products.length === 2 || this.products.length === 4) {
      return "p-2 xl:w-1/2 md:w-1/1 w-full";
    }
    return "p-2 xl:w-1/3 md:w-1/2 w-full";
  }
  private billingPeriodOnce(
    product: SubscriptionProductDto
  ): boolean | undefined {
    return (
      this.getPrice(product)?.billingPeriod === SubscriptionBillingPeriod.ONCE
    );
  }
  private getFeatureDescription(feature: SubscriptionFeatureDto) {
    if (!feature.translateInFrontend) {
      if (feature.value) {
        return `${feature.value}`;
      } else {
        return `${feature.key}`;
      }
    } else {
      return this.$t("marketing.pricing.features." + feature.key, [
        feature.value
      ]);
    }
  }
  private getProductFeatures(
    product: SubscriptionProductDto
  ): SubscriptionFeatureDto[] {
    return product.features;
    // const features = product.features.sort((x, y) => {
    //     return x.order > y.order ? 1 : -1;
    //   });
    //   return features;
  }
  private billingPeriod(product: SubscriptionProductDto): string {
    if (this.billingPeriodOnce(product)) {
      return this.$t("marketing.pricing.once").toString();
    } else {
      const idx = this.getPrice(product)?.billingPeriod ?? 0;
      return (
        "/" +
        this.$t("marketing.pricing." + SubscriptionBillingPeriod[idx] + "Short")
      );
    }
  }
  private productUrl(product: SubscriptionProductDto) {
    if (product.custom) {
      return "contact";
    }
    return "account.register";
  }
  private selectProduct(product: SubscriptionProductDto) {
    this.$store.commit("pricing/select", {
      product,
      billingPeriod: this.$store.state.pricing.billingPeriod
    });
  }
  private getPrice(
    product: SubscriptionProductDto
  ): SubscriptionPriceDto | undefined {
    const prices = product.prices.find(
      f =>
        (f.billingPeriod === this.$store.state.pricing.billingPeriod ||
          f.billingPeriod === SubscriptionBillingPeriod.ONCE) &&
        f.currency === this.$store.state.pricing.currency &&
        f.mode === this.$store.state.pricing.mode &&
        f.active
    );
    return prices;
  }
  private productHasPrices(product: SubscriptionProductDto): boolean {
    return product && product.prices && product.prices.length > 0;
  }
  private badgeFromProduct(product: SubscriptionProductDto): string {
    return product.badge;
    if (product.badge) {
      if (product.badge.includes(" ")) {
        return product.badge;
      }
      const translated = this.$t("marketing.pricing.badges." + product.badge);
      if (translated) {
        return translated.toString();
      } else {
        return product.badge;
      }
    }
    return "";
  }
  private getPriceAmount(product): number {
    return this.getPrice(product)?.price ?? 0;
  }
  private getPriceTrialDays(product): number {
    return this.getPrice(product)?.trialDays ?? 0;
  }
  private getPriceAmountBefore(product): number {
    return this.getPrice(product)?.priceBefore ?? 0;
  }
  private getTransaltedProductTitle(product): string {
    const translated = this.$t("marketing.pricing.products." + product.tier);
    if (translated) {
      return translated.toString();
    } else {
      return product.title;
    }
  }
  private getTransaltedProductDescription(product): string {
    const translated = this.$t(
      "marketing.pricing.productDescriptions." + product.tier
    );
    if (translated) {
      return translated.toString();
    } else {
      return product.description;
    }
  }
  get products(): SubscriptionProductDto[] {
    return (this.$store.state.pricing.products as SubscriptionProductDto[])
      .filter(f => this.getPrice(f) !== undefined && f.active)
      .sort((x, y) => {
        return x.tier > y.tier ? 1 : -1;
      });
  }
  get billingPeriods() {
    const periods: any[] = [];
    (this.$store.state.pricing.products as SubscriptionProductDto[]).map(f =>
      f.prices.forEach(price => {
        if (periods.includes(price.billingPeriod) === false) {
          periods.push(price.billingPeriod);
        }
      })
    );
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    return periods.filter(onlyUnique);
  }
  get warningText() {
    return (
      this.$t("admin.pricing.thesePricesAreFromFiles") +
      " ClientApp/src/store/modules/pricing/default-pricing.ts"
    );
  }
}
