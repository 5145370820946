























































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import UploadImage from "@/components/shared/forms/UploadImage.vue";
import Toggle from "@/components/shared/forms/Toggle.vue";
import { appThemes, appColors } from "@/store/modules/theme/themes";
import { TenantUpdateImageRequest } from "@/application/contracts/master/tenants/TenantUpdateImageRequest";
import { mapGetters } from "vuex";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import services from "@/services";

@Component({
  components: { SuccessModal, ErrorModal, UploadImage, Toggle },
  computed: {
    ...mapGetters("theme", {
      appTheme: "appTheme",
      appColor: "appColor"
    })
  }
})
export default class TenantSettingsComponent extends BaseComponent {
  $refs!: {
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
  };
  imageType: string = "";
  imageCurrent: string = "";
  tenant = {} as TenantDto;
  showUploadImage = false;
  uploadingImage: boolean = false;
  appThemes = appThemes;
  appColors = appColors;
  appTheme!: string;
  appColor!: string;
  created() {
    this.getCurrentTenantInForm();
  }
  getCurrentTenantInForm() {
    this.tenant.name = this.$store.state.tenant.current.name;
    this.tenant.subdomain = this.$store.state.tenant.current.subdomain;
    this.tenant.domain = this.$store.state.tenant.current.domain;
    this.tenant.appTheme = this.appTheme;
    this.tenant.appColor = this.appColor;
  }
  updateTenant() {
    services.tenants
      .update(this.tenant)
      .then(() => {
        services.tenants.getAll().then(() => {
          this.getCurrentTenantInForm();
        });

        this.$refs.successModal.show(this.$t("settings.tenant.updated"));
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  uploadImage(type) {
    this.imageType = type;
    if (type === "icon") {
      this.imageCurrent = this.tenantIcon;
    } else if (type === "logo") {
      this.imageCurrent = this.tenantLogo;
    } else if (type === "logoDarkmode") {
      this.imageCurrent = this.tenantLogoDarkMode;
    }
    this.showUploadImage = true;
  }
  deleteImage(type) {
    this.imageType = type;
    this.loadedImage(null);
  }
  loadedImage(image) {
    const payload: TenantUpdateImageRequest = {
      type: this.imageType,
      logo: this.imageType === "logo" ? image : "",
      icon: this.imageType === "icon" ? image : "",
      logoDarkmode: this.imageType === "logoDarkmode" ? image : ""
    };
    payload.type = this.imageType;
    this.uploadingImage = true;
    services.tenants
      .updateImage(payload)
      .then(() => {
        this.$store.commit("tenant/currentImage", {
          imageType: this.imageType,
          image
        });
        this.showUploadImage = false;
      })
      .catch(error => {
        console.error("Error: " + error);
      })
      .finally(() => {
        this.uploadingImage = false;
      });
  }
  get tenantIcon() {
    return this.$store.state.tenant.current.icon;
  }
  get tenantLogo() {
    return this.$store.state.tenant.current.logo;
  }
  get tenantLogoDarkMode() {
    return this.$store.state.tenant.current.logoDarkmode;
  }
}
