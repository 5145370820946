










































































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModalComponent from "@/components/shared/modals/SuccessModal.vue";
import ErrorModalComponent from "@/components/shared/modals/NewErrorModal.vue";
import { Prop } from "vue-property-decorator";
import { nextTick } from "process";
import { BarcodeReadLineDto } from "@/application/dtos/app/erp/v3/inventory/BarcodeReadLineDto";
import ButtonGroup from "@/components/shared/buttons/ButtonGroup.vue";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    ButtonGroup
  }
})
export default class BarcodeReadEdit extends BaseComponent {
  $refs!: {
    inputQuantity: HTMLInputElement;
    inputUnits: HTMLInputElement;
    errorModal: ErrorModalComponent;
    successModal: SuccessModalComponent;
  };
  @Prop({}) item!: BarcodeReadLineDto;
  @Prop({ default: "" }) forceMode!: string;
  private quantity: number = 0;
  private units: number = 0;
  private discount: number = 0;
  private unitOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  private mode: string = "";
  created() {
    window.addEventListener("keyup", this.keyEvent);
  }
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyEvent);
  }
  mounted() {
    if (this.forceMode) {
      this.mode = this.forceMode;
    } else {
      this.mode = this.item.lot ? "tare" : "net";
    }
    this.quantity = parseFloat(this.item.quantity.toFixed(2));
    this.units = this.item.units;
    this.discount = this.item.discount;
    this.focusInputQuantity();
  }
  focusInputQuantity() {
    nextTick(() => {
      this.$refs.inputQuantity.focus();
      this.$refs.inputQuantity.select();
    });
  }
  isNumber(val) {
    if (isNaN(Number(val.key))) {
      return val.preventDefault();
    }
  }
  cancel() {
    this.$emit("cancel");
  }
  save() {
    this.$emit(
      "edited",
      this.item,
      this.quantity,
      this.units,
      this.discount,
      this.mode
    );
  }
  remove() {
    this.$emit("delete", this.item);
  }
  setUnits(units: number) {
    this.units = units;
    this.focusInputQuantity();
  }
  keyEvent(event) {
    if (event.keyCode === 27) {
      this.cancel();
    }
    if (event.ctrlKey && event.keyCode) {
      if (event.keyCode >= 49 && event.keyCode <= 49 + 12) {
        this.setUnits(event.keyCode - 48);
      }
    }
  }
  get maxQuantity() {
    return this.item?.totalQuantity + 10 ?? 0;
  }
  get maxUnits() {
    return this.item?.totalUnits + 10 ?? 0;
  }
  get canEditQuantity() {
    return true;
  }
  get canEditUnits() {
    return true;
  }
}
